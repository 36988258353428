import React, {useState} from "react";
import {Account, Customer} from "../../../../models";
import {CustomerApi} from "../../../../services/customer/api";
import {JwtToken, GreenButton, SecureHTML} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";


interface Props {
    me: Account
    token: JwtToken
    customer: Customer
}

export function CustomerExportStatView(props: Props) {
    let [isDisabled, setIsDisabled] = useState(false)
    let [loading, setLoading] = useState(false)
    let [error, setError] = useState('')
    let [success, setSuccess] = useState('')


    let onClick = () => {
        setError('')
        setSuccess('')
        let confirm = window.confirm(
            getTranslation('MONITORING.VIEWS.CUSTOMER.EXPORT.CONFIRM_JS').format(props.me.user.email)
        )

        if (confirm) {
            CustomerApi.export_stats(
                props.token,
                props.customer.id,
            ).then(res => {
                if (res.statusCode === 204) {
                    setIsDisabled(true)
                    setSuccess(
                        getTranslation('MONITORING.VIEWS.CUSTOMER.EXPORT.SUCCESS').format(props.me.user.email)
                    )
                } else {
                    setError(getTranslation('MONITORING.VIEWS.CUSTOMER.EXPORT.ERROR'))
                    setLoading(false)
                }
            })
        }
    }

    return (
        <div className={
            'w-full lg:w-80 mt-6 py-6 px-5 border-2 border-gray-100 bg-gray-100 rounded-xl'
        }>
            {
                error.length > 0 &&
                <div role={'alert'} className={'text-red-500 text-center'}>
                    {error}
                </div>
            }
            {
                success.length > 0 &&
                <div role={'alert'} className={'text-green-500 text-center'}>
                    <SecureHTML>{success}</SecureHTML>
                </div>
            }
            {
                (success.length === 0 && error.length === 0) &&
                <>
                    <span className={'text-gray-500 font-medium md:w-3/5'}>
                        Exportation des données :
                    </span>
                    <GreenButton
                        data-testid={'customer-form-export-btn'}
                        text={getTranslation('MONITORING.VIEWS.CUSTOMER.EXPORT.SUBMIT_BUTTON')}
                        onClick={onClick}
                        disabled={loading || isDisabled}
                        className={'mt-3 mx-auto'}
                    />
                </>
            }
        </div>
    )
}