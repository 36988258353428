import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {Cache, JwtToken} from "@boomrank/react-components";
import {Account, AccountHasCustomer, Customer, Invoice} from "../../../../models";
import {StandaloneCustomer, StandaloneInvoice} from "../../../../models/standalone";

import {CustomerApi} from "../../../../services/customer/api";
import {CustomerListResponse} from "../../../../services/customer/responses";
import {CustomerFactory, StandaloneCustomerInvoicesFactory} from "../../../../services/customer/factory";
import {StandaloneInvoiceFactory} from "../../../../services/invoice/factory";

import {getTranslation} from "../../../../intl";
import {InvoiceFilterView} from "./filter";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheInvoices: Cache<Invoice>
    cacheAccounts: Cache<Account>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    reminder?: boolean
}

export function InvoiceView(props: Props) {
    let now = new Date()
    let today = now.getDate()
    let lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1))

    let [standaloneCustomerInvoices, setStandaloneCustomerInvoices] = useState<StandaloneCustomer[]>([])
    let [standaloneInvoices, setStandaloneInvoices] = useState<StandaloneInvoice[]>([])

    let unsubmittedInvoices = () => {
        if (today >= 1 && today <= 10) {
            if (standaloneCustomerInvoices.length > 0) {
                return true
            }
            return (
                standaloneInvoices.length > 0 &&
                standaloneInvoices.filter(s => s.submittedAt === null).length > 0
            )
        }
        return false
    }

    let getCustomersToInvoice = () => {
        let month = `${lastMonth.getFullYear()}-${lastMonth.getMonth()}`

        CustomerApi.toInvoice(
            props.token,
            month
        ).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as CustomerListResponse

                let customers = res.data.results.map((result) => {
                    return CustomerFactory.fromFragment(result)
                })

                let standaloneCustomers = customers.reduce(
                    (standalones: StandaloneCustomer[], customer: Customer) => {
                        let standalone = StandaloneCustomerInvoicesFactory.fromCustomer(
                            customer,
                            props.cacheInvoices.values(),
                            props.cacheAccountHasCustomers.values()
                        )

                        if (standalone.invoices.length === 0) {
                            if (standalone.accountIds.indexOf(props.me.id) !== -1) {
                                standalones.push(standalone)
                            }
                        }
                        return standalones
                    }, [])

                setStandaloneCustomerInvoices(standaloneCustomers)
            }
        })
    }

    let standaloneInvoicesFormatter = () => {
        let standaloneInvoices = props.cacheInvoices.values().reduce(
            (standalones: StandaloneInvoice[], invoice: Invoice) => {
                let standalone = StandaloneInvoiceFactory.fromInvoice(
                    invoice,
                    props.cacheCustomers.values(),
                    props.cacheAccounts.values(),
                    props.cacheAccountHasCustomers.values(),
                )

                if (props.me.isAdmin()) {
                    standalones.push(standalone)
                }
                if (
                    !props.me.isAdmin() &&
                    standalone.submittedAt === null &&
                    standalone.accountIds.indexOf(props.me.id) !== -1
                ) {
                    standalones.push(standalone)
                }
                return standalones
            }, []
        )

        setStandaloneInvoices(standaloneInvoices)
    }

    useEffect(() => {
        getCustomersToInvoice()
        standaloneInvoicesFormatter()
    }, [props.cacheInvoices, props.cacheCustomers]);


    if (props.reminder) {
        if (unsubmittedInvoices()) {
            return (
                <div
                    className={'p-6 flex flex-col lg:h-full lg:max-h-[550px] border-2 border-red-200 bg-red-200 rounded-lg'}>
                    <Link
                        to={'/dashboard/invoices'}
                        className={'text-red-500 text-xl'}
                    >
                        Clients actifs sans aucune facture renseignée
                    </Link>

                    <span
                        className={'text-red-500 text-xl'}> pour le mois : {lastMonth.toLocaleDateString('fr-FR', {month: "long"})}</span>
                </div>
            )
        }
        return <></>
    }

    return (
        <div className={'w-full mt-6 flex flex-col items-end'}>
            {
                standaloneCustomerInvoices.length > 0 &&
                <div className={'w-full mt-6 py-6 px-5 bg-red-100 rounded-xl'}>
                    <h3 className={'mb-3 text-br-red'}>
                        {getTranslation('MONITORING.VIEWS.INVOICE.MISSING.TITLE')} ({standaloneCustomerInvoices.length})
                    </h3>

                    <div className={'w-full flex flex-wrap gap-x-3 gap-y-3'}>
                        {
                            standaloneCustomerInvoices.map((standalone, idx) => {
                                return (
                                    <Link
                                        key={idx}
                                        to={`/dashboard/customer/${standalone.id}/invoices/add`}
                                        className={'p-3 rounded bg-white text-br-red font-medium hover:bg-br-green hover:text-white'}
                                    >
                                        {standalone.name}
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            }

            <InvoiceFilterView
                token={props.token}
                me={props.me}
                standaloneInvoices={standaloneInvoices}
            />
        </div>
    )
}