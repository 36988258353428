import React from "react";

import {Budget, CampaignGroup} from "../../../../models";

import BudgetInput from "../../../dummies/input/budget";
import {getTranslation} from "../../../../intl";
import {BasisInput} from "../../../dummies/input";


interface Props {
    campaignGroup: CampaignGroup
    onChange: (campaignGroup: CampaignGroup) => void
    disabled?: boolean
}

export function CampaignGroupBasisForm(props: Props) {
    let onChange = (name: string, value: string | number) => {
        props.onChange({
            ...props.campaignGroup,
            [name]: value
        });
    }

    let budgetUpdater = (budget: Budget) => {
        props.onChange({
            ...props.campaignGroup,
            budgetMin: budget.budgetMin,
            budgetMax: budget.budgetMax,
        });
    }


    return (
        <div className={'w-full lg:flex items-center justify-end'}>
            <BasisInput
                value={props.campaignGroup.name}
                onChange={onChange}
                type={"text"}
                placeholder={getTranslation('MONITORING.FORMS.CAMPAIGN_GROUP.LABELS.NAME')}
                name={"name"}
                disabled={props.disabled}
            />

            <BasisInput
                value={props.campaignGroup.key}
                onChange={onChange}
                type={"text"}
                placeholder={getTranslation('MONITORING.FORMS.CAMPAIGN_GROUP.LABELS.KEY')}
                name={"key"}
                disabled={props.disabled}
            />

            <BudgetInput
                budgetMin={props.campaignGroup.budgetMin}
                budgetMax={props.campaignGroup.budgetMax}
                budgetUpdater={budgetUpdater}
                className={'w-full lg:w-fit lg:flex'}
                disabled={props.disabled}
            />
        </div>
    )

}
