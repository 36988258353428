import React, {useEffect, useState} from "react";

import {Customer, Log, Account} from "../../../../models";

import {LogApi} from "../../../../services/log/api";
import {LogListResponse} from "../../../../services/log/responses";
import {LogFactory, StandaloneLogFactory} from "../../../../services/log/factory";

import {Cache, JwtToken, PlaceHolder} from "@boomrank/react-components";
import {CustomerLinks} from "../../../dummies/customer/links";
import {LogsTableView} from "./table";
import {StandaloneLog} from "../../../../models/standalone";


interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    cacheAccounts: Cache<Account>
}

export function LogListView (props: Props) {
    let [cacheLogs, setCacheLogs] = useState<Cache<Log>>(new Cache<Log>('id'))
    let [standaloneLogs, setStandaloneLogs] = useState<StandaloneLog[]>([])
    let [isLoading, setIsLoading] = useState(true)

    let getLogs = (url?: string) => {
        setIsLoading(true)

        LogApi.list(props.token, url).then((res) => {
            if(res.statusCode >= 200 && res.statusCode < 300) {
                res = res as LogListResponse;

                let logs = res.data.results.map((fragment) => {
                    return LogFactory.fromFragment(fragment)
                })

                logs = logs.filter((log) => log.customerId === props.customer.id)

                let cache = new Cache<Log>('id').add(logs)
                setCacheLogs(cacheLogs => cacheLogs.merge(cache))

                let standaloneLogs = cacheLogs.values().map((log) => {
                    return StandaloneLogFactory.fromLog(
                        log,
                        props.cacheAccounts.values()
                    )
                })
                setStandaloneLogs(standaloneLogs)

                if (res.data.next) {
                    return getLogs(res.data.next);
                }
            }
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getLogs();
    }, [])

    return (
        <div className={'mt-6'}>
            <div className={'flex items-center justify-end mb-10'}>
                <CustomerLinks customerId={props.customer.id} me={props.me} />
            </div>

            {
                props.customer.comment !== '' &&
                <div className={'my-10 p-4 bg-slate-100 text-br-blue-900 rounded-md'}>
                    <span><b>Commentaire</b> :</span>
                    <p className={'whitespace-pre'}>{props.customer.comment}</p>
                </div>
            }

            <LogsTableView
                standaloneLogs={standaloneLogs}
                isLoading={isLoading}
            />
        </div>
    )
}