import {Cache} from '@boomrank/react-components'
import {ProviderAccountFragment} from "./fragments";
import {Customer, Provider, ProviderAccount} from "../../models";
import {StandaloneProviderAccount} from "../../models/standalone";


export class ProviderAccountFactory {
    static fromFragment = (fragment: ProviderAccountFragment): ProviderAccount => {
        let providerAccount = new ProviderAccount()
        providerAccount.id = fragment.id
        providerAccount.createdAt = new Date(fragment.created_at)
        providerAccount.loginId = fragment.login_id
        providerAccount.name = fragment.name
        providerAccount.budgetMin = fragment.budget_min
        providerAccount.budgetMax = fragment.budget_max
        providerAccount.providerId = fragment.provider
        providerAccount.customerId = fragment.customer
        providerAccount.error = fragment.error
        return providerAccount
    }
}

export class StandaloneProviderAccountFactory {
    static fromProviderAccount = (
        providerAccount: ProviderAccount,
        cacheProviders: Cache<Provider>,
        cacheCustomers: Cache<Customer>
    ) : StandaloneProviderAccount => {
        let standalone = {...providerAccount} as StandaloneProviderAccount

        let provider = cacheProviders.values().find((p) => p.id === providerAccount.providerId)
        let customer = cacheCustomers.values().find((c) => c.id === providerAccount.customerId)

        if (provider) {
            standalone.providerName = provider.name
        }
        if (customer) {
            standalone.customerName = customer.name
        }

        return standalone
    }
}