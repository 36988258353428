import {defaultLanguage} from "../../../../../intl";
import classNames from "classnames";
import {StandaloneProviderAccountInvoice} from "../../../../../models/standalone";

interface Props {
    onClick: (standaloneProviderAccountInvoice: StandaloneProviderAccountInvoice) => void
    standaloneProviderAccountInvoices: StandaloneProviderAccountInvoice[]
    disabled?: boolean
}

export function SuggestionProviderAccountInvoicesForm(props: Props) {
    let disabled = props.disabled || false

    let className = classNames(
        'border p-3 select-none mt-1 flex justify-between text-sm',
        {
            'hover:bg-gray-50 hover:cursor-pointer': !disabled,
            'bg-gray-100': disabled
        }
    )

    let onClick = (standaloneProviderAccountInvoice: StandaloneProviderAccountInvoice) => {
        if (props.onClick && !disabled) {
            props.onClick(standaloneProviderAccountInvoice)
        }
    }

    return (
        <div className={'mt-6'}>
            <div>Factures média</div>
            <ul className={'grid grid-cols-3 gap-3'}>
                {
                    props.standaloneProviderAccountInvoices
                        .filter(standalone => standalone.providerAccount)
                        .map((standalone, idx) => {
                            return (
                                <li
                                    key={idx}
                                    data-testid={'invoice-suggestion-btn'}
                                    className={className}
                                    onClick={() =>{onClick(standalone)
                                    }}>
                                    <div>
                                        {
                                            standalone.providerAccount.providerId === 1 &&
                                            <i className={"w-8 fa-brands fa-google text-red-500"} title="google" />
                                        }
                                        {
                                            standalone.providerAccount.providerId === 2 &&
                                            <i className={"w-8 fa-brands fa-meta text-blue-700"} title="meta" />
                                        }
                                        {
                                            standalone.providerAccount.providerId === 3 &&
                                            <i className={"w-8 fa-solid fa-b text-cyan-500"} title="bing" />
                                        }
                                        {standalone.providerAccount.name}
                                        ({standalone.providerAccount.loginId})
                                    </div>
                                    <div className={'text-right'}>
                                        <div>
                                            {standalone.period.toLocaleDateString(defaultLanguage)}
                                        </div>
                                        <div className={'font-bold'}>
                                            {
                                                standalone.totalAmount.toLocaleString(
                                                    defaultLanguage,
                                                    { style: 'currency', currency: 'EUR' }
                                                )
                                            }
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                }
            </ul>
        </div>
    )
}