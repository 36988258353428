import React, {useEffect, useState} from "react";

import {BudgetAccountGroup, CampaignGroup, Customer, Provider, ProviderAccount} from "../../../../models";
import {Cache} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";
import {FormEvents} from "../../../dummies/form/events";
import {FormBudgets} from "../../../dummies/form/budgets";
import {Flag, FlaggedBudgetAccountGroup} from "../../views/budget/models";
import classNames from "classnames";
import {BudgetAccountGroupBasisForm} from "./basis";


interface Props {
    customer: Customer
    campaignGroup: CampaignGroup
    budgetAccountGroups: BudgetAccountGroup[]
    providerAccounts: ProviderAccount[]
    cacheProviders: Cache<Provider>
    onSubmit: (flaggedBudgetAccountGroups: FlaggedBudgetAccountGroup[]) => void
    disabled?: boolean
}

export function BudgetAccountGroupForm(props: Props) {
    let [flaggedBudgetAccountGroups, setFlaggedBudgetAccountGroups] = useState<FlaggedBudgetAccountGroup[]>([])
    let [disabled, setDisabled] = useState<boolean>(false)

    let onAdd = () => {
        let flaggedBag = new FlaggedBudgetAccountGroup()
        flaggedBag.flag = Flag.CREATED
        flaggedBag.budgetAccountGroup.campaignGroupId = props.campaignGroup.id
        flaggedBag.budgetAccountGroup.id = (new Date()).getTime()

        setFlaggedBudgetAccountGroups([flaggedBag, ...flaggedBudgetAccountGroups])
    }

    let onChange = (budgetAccountGroup: BudgetAccountGroup) => {
        let flagged = flaggedBudgetAccountGroups.find(fbag => fbag.budgetAccountGroup.id === budgetAccountGroup.id)

        if (flagged) {
            if (flagged.flag === Flag.NO_CHANGES) {
                flagged.flag = Flag.UPDATED
            }
            flagged.budgetAccountGroup = budgetAccountGroup
        }

        setFlaggedBudgetAccountGroups([...flaggedBudgetAccountGroups])

        if (budgetAccountGroup.budgetMax && budgetAccountGroup.budgetMin) {
            setDisabled(budgetAccountGroup.budgetMax < budgetAccountGroup.budgetMin)
        } else {
            setDisabled(false)
        }
    }

    let onSubmit = (flaggedBudgetAccountGroups: FlaggedBudgetAccountGroup[]) => {
        props.onSubmit(flaggedBudgetAccountGroups)
        setDisabled(true)
    }

    useEffect(() => {
        let flaggedBags = props.budgetAccountGroups
            .filter((bag) => bag.campaignGroupId === props.campaignGroup.id)
            .map((budgetAccountGroup: BudgetAccountGroup) => {
                let flaggedBag = new FlaggedBudgetAccountGroup()
                flaggedBag.flag = Flag.NO_CHANGES
                flaggedBag.budgetAccountGroup = budgetAccountGroup
                return flaggedBag
            })

        flaggedBags.reverse()
        setFlaggedBudgetAccountGroups(flaggedBags)
    }, [props.budgetAccountGroups])


    return (
        <div className={'w-full flex flex-col lg:flex-row border-gray-100 mb-0.5 lg:mb-0 lg:pl-24'}>
            <div className={'w-full pt-2 pb-8'}>
                <div className={'pl-12 w-full lg:pl-0 lg:flex lg:justify-between lg:items-center'}>
                    <FormEvents
                        className={'w-16 gap-x-1 flex items-center lg:items-start lg:justify-between'}
                        onSubmit={() => onSubmit(flaggedBudgetAccountGroups)}
                        submitTitle={getTranslation('MONITORING.FORMS.BUDGET_ACCOUNT_GROUP.SUBMIT_BUTTON')}
                        disabled={disabled}
                        onAdd={onAdd}
                    />

                    <FormBudgets
                        parentBudget={props.campaignGroup.budgetMax ? props.campaignGroup.budgetMax : props.customer.budgetMax}
                        budgetMin={flaggedBudgetAccountGroups.reduce(
                            (cost, flagged) => cost + flagged.budgetAccountGroup.budgetMin!, 0
                        )}
                        budgetMax={flaggedBudgetAccountGroups.reduce(
                            (cost, flagged) => cost + flagged.budgetAccountGroup.budgetMax!, 0
                        )}
                        budgetMinError={getTranslation('MONITORING.FORMS.BUDGET_ACCOUNT_GROUP.ERRORS.BUDGET_MIN')}
                        budgetMaxError={getTranslation('MONITORING.FORMS.BUDGET_ACCOUNT_GROUP.ERRORS.BUDGET_MAX')}
                        className={'lg:mr-16'}
                    />
                </div>

                <div className={'pl-12 w-full xl:pl-20 lg:flex lg:flex-col lg:justify-end'}>
                    {
                        flaggedBudgetAccountGroups.length > 0 &&
                        flaggedBudgetAccountGroups.map((flaggedBudgetAccountGroup: FlaggedBudgetAccountGroup) => (
                            <div
                                className={classNames(
                                    'flex mb-0.5 py-0.5',
                                    {'text-gray-500 font-medium': flaggedBudgetAccountGroup.flag !== Flag.NO_CHANGES}
                                )}
                                key={flaggedBudgetAccountGroup.id}
                            >
                                <BudgetAccountGroupBasisForm
                                    budgetAccountGroup={flaggedBudgetAccountGroup.budgetAccountGroup}
                                    providerAccounts={props.providerAccounts}
                                    cacheProviders={props.cacheProviders}
                                    onChange={onChange}
                                    disabled={props.disabled || false}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}