import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {
    CampaignGroup,
    Customer,
    ProviderAccount,
    Provider,
    Account,
    AccountHasCustomer,
    Identifier
} from "../../../../models";

import {StepPeriod} from "../../../../models/stepPeriod";
import {StatCampaignGroup, StatGlobalCampaignGroup} from "../../../../models/stats";

import {StandaloneCampaignGroup} from "../../../../models/standalone";
import {StandaloneCustomerFactory} from "../../../../services/customer/factory";
import {StandaloneCampaignGroupFactory} from "../../../../services/campaign-group/factory";

import {CustomerStatCampaignGroupApi} from "../../../../services/customer/stats/campaign-group/api";
import {StatCampaignGroupResponse} from "../../../../services/customer/stats/campaign-group/responses";
import {StatCampaignGroupFactory} from "../../../../services/customer/stats/campaign-group/factory";

import {CustomerStatGlobalCampaignGroupApi} from "../../../../services/customer/stats/global-campaign-group/api";
import {StatGlobalCampaignGroupListResponse} from "../../../../services/customer/stats/global-campaign-group/responses";
import {StatGlobalCampaignGroupFactory} from "../../../../services/customer/stats/global-campaign-group/factory";

import {Cache, JwtToken, PlaceHolder} from "@boomrank/react-components";
import {LineGraph} from "../../../dummies/graph/line";
import {StepPeriodSelect} from "../../../dummies/select/stepPeriod";
import {getTranslation} from "../../../../intl";
import {CustomerLinks} from "../../../dummies/customer/links";

import {CampaignGroupsTableView} from "./table";
import {CardCampaignGroups} from "../../../dummies/card/campaign-group/list";


interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    campaignGroups: CampaignGroup[]
    cacheAccounts: Cache<Account>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheProviders: Cache<Provider>
    cacheProviderAccounts: Cache<ProviderAccount>
    stepPeriod: StepPeriod
    onStepPeriodChange: (stepPeriod: StepPeriod) => void
    cacheUpdater: (obj: Identifier, remove: boolean) => void
}

export function CampaignGroupListView(props: Props) {
    let [isLoading, setIsLoading] = useState(false);

    let [standaloneCampaignGroups, setStandaloneCampaignGroups] = useState<StandaloneCampaignGroup[]>([])

    let [statGlobalCampaignGroup, setGlobalStatCampaignGroup] = useState<StatGlobalCampaignGroup[]>([]);
    let [statCampaignGroups, setStatCampaignGroups] = useState<StatCampaignGroup[]>([]);

    let standaloneCustomer = StandaloneCustomerFactory.fromCustomer(
        props.customer,
        props.cacheAccountHasCustomers.values(),
        props.cacheProviderAccounts.values(),
    )

    let campaignGroupsFormatter = (stats: StatCampaignGroup[]) => {
        let campaignGroups = props.campaignGroups.map((cg) => {
            return StandaloneCampaignGroupFactory.fromCampaignGroup(
                props.customer,
                cg,
                stats
            )
        })
        setStandaloneCampaignGroups(campaignGroups)
    }

    let addDefaultGlobalStatCampaignGroup = () => {
        let defaultStats = props.cacheProviders.values().map(provider => {
            let stat = new StatGlobalCampaignGroup()
            stat.name = provider.name
            stat.startedAt = props.stepPeriod.period.start
            return stat
        })
        setGlobalStatCampaignGroup(defaultStats)
    }

    let setStatGlobalCampaignGroupName = (providerAccount: ProviderAccount) => {
        let provider = props.cacheProviders.values()
            .find(p => p.id === providerAccount.providerId)
        let providerAccountName = providerAccount.name

        if (providerAccountName) {
            return provider!.name + ' - ' + providerAccount.name
        }
        return provider!.name + ' - ' + providerAccount.loginId
    }

    let fetchStats = (selected: StepPeriod) => {
        setIsLoading(true)
        setGlobalStatCampaignGroup([])
        setStatCampaignGroups([])

        CustomerStatGlobalCampaignGroupApi.list(
            props.token,
            props.customer.id,
            selected.period.start,
            selected.period.end,
            selected.stepChart
        ).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as StatGlobalCampaignGroupListResponse;

                let stats = response.data.map((fragment) => {
                    let providerAccount = props.cacheProviderAccounts.values()
                        .find(pa => pa.loginId === fragment.login_id)
                    fragment.name = setStatGlobalCampaignGroupName(providerAccount!)

                    return StatGlobalCampaignGroupFactory.fromFragment(fragment)
                })
                setGlobalStatCampaignGroup(stats)

                if (stats.length === 0) {
                    addDefaultGlobalStatCampaignGroup()
                }
            }
        })

        CustomerStatCampaignGroupApi.list(
            props.token,
            props.customer.id,
            selected.period.start,
            selected.period.end,
            selected.stepTable
        ).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as StatCampaignGroupResponse;

                let statCampaignGroups = response.data.map((fragment) => {
                    return StatCampaignGroupFactory.fromFragment(fragment)
                })
                setStatCampaignGroups(statCampaignGroups)
            }
        })
        setIsLoading(false)
    }

    const rangeChangeHandler = (selected: StepPeriod) => {
        props.onStepPeriodChange(selected);
        fetchStats(selected);
    };

    useEffect(() => {
        if (props.campaignGroups.length > 0 && statCampaignGroups.length > 0) {
            campaignGroupsFormatter(statCampaignGroups)
        }
    }, [props.campaignGroups, statCampaignGroups])


    return (
        <div className={'mt-6'}>
            <div className={'w-full lg:flex lg:items-center lg:justify-between'}>
                <StepPeriodSelect
                    onChange={rangeChangeHandler}
                    stepPeriod={props.stepPeriod}
                />

                <CustomerLinks customerId={props.customer.id} me={props.me} />
            </div>

            {
                props.customer.comment !== '' &&
                <div className={'mt-10 p-4 bg-slate-100 text-br-blue-900 rounded-md'}>
                    <span><b>Commentaire</b> :</span>
                    <p className={'whitespace-pre'}>{props.customer.comment}</p>
                </div>
            }

            {
                props.campaignGroups.length === 0 &&
                    <div className={
                        'w-full flex items-center justify-between my-10 p-6 ' +
                        'bg-slate-100 text-slate-500 rounded-xl'
                    }>
                        <span>
                            {
                                getTranslation('MONITORING.VIEWS.CAMPAIGN_GROUP.LIST.NO_GROUP.CUSTOMER')
                                    .format(props.customer.name)
                            }
                        </span>
                        <Link
                            className={'btn-blue p-3 rounded no-underline'}
                            to={`/dashboard/customer/${props.customer.id}/budgets`}
                        >
                            {getTranslation('MONITORING.VIEWS.CAMPAIGN_GROUP.LIST.NO_GROUP.CREATE_LINK')}
                        </Link>
                    </div>
            }
            {
                props.cacheProviderAccounts.values() &&
                props.campaignGroups.length > 0 &&
                <>
                    <div className={'my-10 lg:flex'}>
                        <LineGraph
                            data={statGlobalCampaignGroup}
                            stepPeriod={props.stepPeriod}
                        />

                        <CardCampaignGroups
                            standaloneCustomer={standaloneCustomer}
                            standaloneCampaignGroups={standaloneCampaignGroups}
                            statCampaignGroups={statCampaignGroups}
                            cacheAccounts={props.cacheAccounts}
                            cacheProviders={props.cacheProviders}
                            stepPeriod={props.stepPeriod}
                            isLoading={isLoading}
                        />
                    </div>

                    <div className={'my-2'}>
                        {
                            standaloneCampaignGroups.length > 0 &&
                            <CampaignGroupsTableView
                                token={props.token}
                                me={props.me}
                                standaloneCampaignGroups={standaloneCampaignGroups}
                                stepPeriod={props.stepPeriod}
                                columns={[
                                    'name', 'key', 'cost', 'trend', 'budgetMin', 'budgetMax', 'id'
                                ]}
                                cacheUpdater={props.cacheUpdater}
                                isLoading={isLoading}
                            />
                        }
                    </div>
                </>
            }
        </div>
    )
}

