import React from "react";
import {Link} from "react-router-dom";

import {Column, ColumnType, PaginateAdvancedTable, TableV2} from "@boomrank/react-components";
import {defaultLanguage, getTranslation, translator} from "../../../../../intl";
import {StandaloneCustomerFee} from "../../../../../models/standalone";
import {TableLoader} from "../../../../dummies/loaders/table";


interface Props {
    standaloneCustomersFee: StandaloneCustomerFee[]
    columns: (keyof StandaloneCustomerFee)[]
    isLoading?: boolean
}

export function CustomerFeeTableView(props: Props) {
    let isLoading = props.isLoading || false

    let defaultColumns: Column<StandaloneCustomerFee>[] = [
        {
            header: getTranslation('MONITORING.VIEWS.FEE.TABLE.CUSTOMER.NAME'),
            type: ColumnType.STRING,
            accessor: 'customerName',
            cell: (row: StandaloneCustomerFee, value: any) => {
                return (
                    <Link
                        to={`/dashboard/customer/${row.customerId}/fees`}
                        title={'Customer'}
                    >
                        {value}
                    </Link>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FEE.TABLE.CUSTOMER.STATE'),
            type: ColumnType.BOOLEAN,
            accessor: "activeCustomer",
            cell: (row: StandaloneCustomerFee, value: any) => {
                if (value) {
                    return (
                        <i
                            className="fa-solid fa-play text-br-green text-xl ml-4"
                            title={getTranslation(`MONITORING.VIEWS.FEE.TABLE.CUSTOMER.STATE_OPTIONS.${row.customerState}`)}
                        />
                    )
                }
                return (
                    <i
                        className="fa-solid fa-pause text-orange-500 text-xl ml-4"
                        title={getTranslation(`MONITORING.VIEWS.FEE.TABLE.CUSTOMER.STATE_OPTIONS.${row.customerState}`)}
                    />
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FEE.TABLE.CUSTOMER.CREATED_AT'),
            type: ColumnType.DATE,
            accessor: "customerCreatedAt",
            cell: (row: StandaloneCustomerFee, value: any) => {
                return value.toLocaleDateString(
                    defaultLanguage,
                    {month: 'long', year: 'numeric'}
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FEE.TABLE.CUSTOMER.LAST_UPDATE'),
            type: ColumnType.DATE,
            accessor: "customerLastUpdate",
            cell: (row: StandaloneCustomerFee, value: any) => {
                if (value) {
                    return value.toLocaleDateString(
                        defaultLanguage,
                        {month: "long", year: "numeric"}
                    )
                }
                return '-'
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FEE.TABLE.CUSTOMER.BUDGET_MIN'),
            type: ColumnType.NUMBER,
            accessor: "customerBudgetMin",
            cell: (row: StandaloneCustomerFee, value: any) => {
                if (value !== null) {
                    return value.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    )
                }
                return '-'
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FEE.TABLE.CUSTOMER.BUDGET_MAX'),
            type: ColumnType.NUMBER,
            accessor: "customerBudgetMax",
            cell: (row: StandaloneCustomerFee, value: any) => {
                if (value !== null) {
                    return value.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    )
                }
                return '-'
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FEE.TABLE.CUSTOMER.ACCOUNT'),
            type: ColumnType.STRING,
            accessor: "customerAccount",
        },
        {
            header: getTranslation('MONITORING.VIEWS.FEE.TABLE.CUSTOMER.INVOICES'),
            type: ColumnType.NUMBER,
            accessor: "nbInvoices",
        },
        {
            header: getTranslation('MONITORING.VIEWS.FEE.TABLE.FEE'),
            type: ColumnType.BOOLEAN,
            accessor: "activeFee",
            cell: (row: StandaloneCustomerFee, value: any) => {
                if (value) {
                    return (
                        <i
                            data-testid={'fee-table-check'}
                            className="fa-solid fa-circle-check text-br-green text-xl ml-12"
                        />
                    )
                }
                return (
                    <i
                        data-testid={'fee-table-xmark'}
                        className="fa-solid fa-circle-xmark text-br-red text-xl ml-12"
                    />
                )
            }
        },
    ]

    if (isLoading) {
        return <TableLoader defaultColumns={defaultColumns}/>
    }

    return (
        <TableV2
            rows={props.standaloneCustomersFee}
            columns={defaultColumns}
        >
            <PaginateAdvancedTable
                translator={translator}
                mobileColumnsDisplayed={[0, 2, 3]}
                initialState={{
                    pageSize: 100
                }}
            />
        </TableV2>
    )
}