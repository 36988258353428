import {SelectInput} from "./index";
import React, {useState} from "react";
import {Fee, FeeBracket} from "../../../models";



export interface Props {
    onSelect: (fee: Fee) => void
    disabled: boolean
}

export function SelectFeeTemplate(props: Props) {
    let [selectedOption, setSelectedOption] = useState<number>(0)

    // 500 e + 8% : most common
    let feeBracket1 = new FeeBracket()
    feeBracket1.rate = 8
    feeBracket1.priceHt = 500

    let feeTemplate1 = new Fee()
    feeTemplate1.feeBrackets = [feeBracket1]

    // 8% between 150 and 1500
    let feeBracket2 = new FeeBracket()
    feeBracket2.rate = 8

    let feeTemplate2 = new Fee()
    feeTemplate2.limitMin = 150
    feeTemplate2.limitMax = 1500
    feeTemplate2.feeBrackets = [feeBracket2]

    // 500e + 8% with minimum of 1200
    let feeBracket3 = new FeeBracket()
    feeBracket3.rate = 8
    feeBracket3.priceHt = 500

    let feeTemplate3 = new Fee()
    feeTemplate3.limitMin = 1200
    feeTemplate3.feeBrackets = [feeBracket3]

    // 9%
    let feeBracket4 = new FeeBracket()
    feeBracket4.rate = 9

    let feeTemplate4 = new Fee()
    feeTemplate4.feeBrackets = [feeBracket4]

    // 3.5%
    let feeBracket5 = new FeeBracket()
    feeBracket5.rate = 3.5

    let feeTemplate5 = new Fee()
    feeTemplate5.feeBrackets = [feeBracket5]

    let options = [
        {
            id: 0,
            content: 'Sélectionner',
            title: 'Sélectionner un template de règle',
            feeTemplate: new Fee()
        },
        {
            id: 1,
            content: '8% + 500 €',
            title: '8% appliqué + 500 € de fixe',
            feeTemplate: feeTemplate1
        },
        {
            id: 2,
            content: '8% (minimum de 150 € - maximum de 1500 €)',
            title: '8% appliqué avec un minimum de 150 € et un maximum de 1500 €',
            feeTemplate: feeTemplate2
        },
        {
            id: 3,
            content: '8% + 500€ (minimum de 1200 €)',
            title: '8% appliqué + 500 € de fixe avec un minimum de 1200 €',
            feeTemplate: feeTemplate3
        },
        {
            id: 4,
            content: '9%',
            title: '9% appliqué',
            feeTemplate: feeTemplate4
        },
        {
            id: 5,
            content: '3,5%',
            title: '3,5% appliqué',
            feeTemplate: feeTemplate5
        },
    ]

    let onChangeTemplate = (optionId: number) => {
        setSelectedOption(optionId)

        let option = options.find(o => o.id === optionId)

        if (option) {
            props.onSelect(option.feeTemplate)
        }
    }

    return (
        <SelectInput
            dataTestId={'form-fee-template'}
            name={'feeTemplate'}
            value={selectedOption}
            options={options}
            onChange={onChangeTemplate}
            disabled={props.disabled}
        />
    )
}