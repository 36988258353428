import React from "react";

import {Cache} from "@boomrank/react-components";
import {Account, Customer, Fee, Invoice} from "../../../../../models";
import {StandaloneCustomerFee} from "../../../../../models/standalone";
import {StandaloneCustomerFeesFactory} from "../../../../../services/customer/factory";
import {CustomerFeeFilterView} from "./filter";


interface Props {
    cacheFees: Cache<Fee>
    cacheAccounts: Cache<Account>
    cacheInvoices: Cache<Invoice>
    cacheCustomers: Cache<Customer>
}

export function CustomerFeeView(props: Props) {
    let standaloneCustomerFees = props.cacheCustomers.values().reduce(
        (standalones: StandaloneCustomerFee[], customer: Customer) => {
            let standalone = StandaloneCustomerFeesFactory.fromCustomer(
                customer,
                props.cacheFees.values(),
                props.cacheAccounts.values(),
                props.cacheInvoices.values(),
            )

            standalones.push(standalone)
            return standalones
        }, []
    )

    return <CustomerFeeFilterView standaloneCustomerFees={standaloneCustomerFees} />
}