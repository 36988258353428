import React from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {Account, Customer, Identifier} from "../../../../models";
import {Breadcrumb, Cache, JwtToken} from "@boomrank/react-components";
import {CustomerAddView} from "../../views/customer/add";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheAccounts: Cache<Account>
    cacheUpdater: (obj: Identifier) => void
}

export function CustomerAddRoute(props: Props) {
    let navigate = useNavigate()
    let location = useLocation()

    document.title = getTranslation('MONITORING.ROUTES.CUSTOMER.ADD_TITLE')

    let onRedirect = (customerId: number) => {
        return navigate('/dashboard/customer/' + customerId + '/edit');
    }


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {to: `/dashboard/customer/add`, content: 'Ajouter Annonceur', disabled: true},
                ]}
            />

            <CustomerAddView
                token={props.token}
                me={props.me}
                cacheCustomers={props.cacheCustomers}
                cacheAccounts={props.cacheAccounts}
                cacheUpdater={props.cacheUpdater}
                onRedirect={onRedirect}
            />
        </>
    )
}