import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import {CampaignGroup, Identifier,} from "../../../../models";
import {CampaignGroupApi} from "../../../../services/campaign-group/api";

import {FormErrorResponse, JwtToken, RedButton} from "@boomrank/react-components";
import {Errors} from "../../../dummies/errors";

import {getTranslation} from "../../../../intl";
import {StandaloneCampaignGroup} from "../../../../models/standalone";


interface Props {
    token: JwtToken
    campaignGroup: CampaignGroup | StandaloneCampaignGroup
    cacheUpdater: (obj: Identifier, remove: boolean) => void
    navigateTo?: string
}

export function CampaignGroupDeleteView(props: Props) {
    let navigate = useNavigate()

    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState<Errors>({});


    let onClick = (campaignGroup: CampaignGroup | StandaloneCampaignGroup) => {
        setLoading(true)

        let confirm = window.confirm(
            getTranslation('MONITORING.VIEWS.CAMPAIGN_GROUP.DELETE.CONFIRM_JS')
        )

        if (confirm) {
            CampaignGroupApi.delete(
                props.token,
                campaignGroup.id,
            ).then(res => {
                if (res.statusCode === 204) {
                    props.cacheUpdater(campaignGroup, true)

                    if (props.navigateTo) {
                        return navigate(props.navigateTo)
                    }
                } else {
                    res = res as FormErrorResponse;
                    setErrors(res.data)
                    setLoading(false)
                }
            })
        }
    }


    return (
        <RedButton
            data-testid={'customer-form-delete-btn'}
            text={<i className="fa-solid fa-trash" />}
            onClick={() => onClick(props.campaignGroup)}
            disabled={loading}
            className={'mx-auto'}
        />
    )
}