import React from "react";

import {getTranslation} from "../../../../../../intl";
import {CardCumulativeStatCustomer} from "../../../../../dummies/card/customer/cumulative-stats";
import {CardDashboardCustomer} from "../../../../../dummies/card/customer/dashboard";
import {CustomersTableView} from "../../table";
import {StandaloneCustomer} from "../../../../../../models/standalone";
import {Account, Identifier} from "../../../../../../models";
import {JwtToken} from "@boomrank/react-components";
import {StepPeriod} from "../../../../../../models/stepPeriod";

export interface Props {
    token: JwtToken
    me: Account
    accountCustomers: StandaloneCustomer[]
    followedCustomers: StandaloneCustomer[]
    companyCustomers: StandaloneCustomer[]
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
    stepPeriod: StepPeriod
}

export function CustomerListAsCardView(props: Props) {
    return (
        <div className={'w-full lg:w-2/3 pb-6'} data-testid={'dashboard-cards-theme'}>
            {
                props.accountCustomers.length > 0 &&
                <div className={'w-full py-6 px-5 border-2 border-slate-200 rounded-xl'}>
                    <CardCumulativeStatCustomer
                        standaloneCustomers={props.accountCustomers}
                    />

                    <div className={'w-full grid grid-cols-1 md:grid-cols-2 gap-3'}>
                        {
                            props.accountCustomers.map(customer => {
                                return (
                                    <CardDashboardCustomer
                                        key={customer.id}
                                        token={props.token}
                                        me={props.me}
                                        standaloneCustomer={customer}
                                        cacheUpdater={props.cacheUpdater}
                                        stepPeriod={props.stepPeriod}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            }

            {
                props.followedCustomers.length > 0 &&
                <div className={'w-full pb-6 px-5 mt-4 border-2 border-slate-200 rounded-xl'}>
                    <h3 className={'pt-6 text-xl cursor-pointer'}>
                        {
                            getTranslation('MONITORING.VIEWS.HOME.SUMMARY.FOLLOWED_CUSTOMERS')
                        } ({props.followedCustomers.length})
                    </h3>

                    <div className={'w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mt-4'}>
                        {
                            props.followedCustomers.map(customer => {
                                return (
                                    <CardDashboardCustomer
                                        key={customer.id}
                                        token={props.token}
                                        me={props.me}
                                        standaloneCustomer={customer}
                                        cacheUpdater={props.cacheUpdater}
                                        stepPeriod={props.stepPeriod}
                                        hideMetrics={true}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            }

            <details
                className={'w-full pb-6 px-5 mt-4 border-2 border-slate-200 rounded-xl'}
                open={props.accountCustomers.length === 0 && props.followedCustomers.length === 0}
            >
                <summary className={'pt-6 border-b border-white text-xl cursor-pointer'}>
                    {
                        getTranslation('MONITORING.VIEWS.HOME.SUMMARY.COMPANY_CUSTOMERS')
                    } ({props.companyCustomers.length})
                </summary>

                <CustomersTableView
                    token={props.token}
                    me={props.me}
                    standaloneCustomers={props.companyCustomers}
                    cacheUpdater={props.cacheUpdater}
                    stepPeriod={props.stepPeriod}
                />
            </details>
        </div>
    )
}