import {Account, Role} from "../../models";
import {MeFragment} from "./fragments";

export class MeFactory {
    static fromFragment = (fragment: MeFragment) : Account => {
        let me = new Account()
        me.id = fragment.id
        me.createdAt = new Date(fragment.created_at)
        me.user.id = fragment.user.id
        me.user.lastname = fragment.user.lastname
        me.user.firstname = fragment.user.firstname
        me.user.email = fragment.user.email
        me.user.isAdmin = fragment.user.is_admin
        me.user.isActive = fragment.user.is_active
        me.role = fragment.role as Role
        return me
    }
}