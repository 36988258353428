import {Account, AccountHasCustomer, Customer, Invoice, InvoiceItem} from "../../models";
import {InvoiceFragment, InvoiceItemFragment} from "./fragments";
import {StandaloneInvoice} from "../../models/standalone";
import {getTranslation} from "../../intl";


export class InvoiceItemFactory {
    static fromFragment = (fragment: InvoiceItemFragment): InvoiceItem => {
        let invoiceItem = new InvoiceItem()
        invoiceItem.id = fragment.id
        invoiceItem.createdAt = new Date(fragment.created_at)
        invoiceItem.invoiceId = fragment.invoice
        invoiceItem.providerAccountId = fragment.provider_account
        invoiceItem.label = fragment.label
        invoiceItem.priceHt = fragment.price_ht
        invoiceItem.description = fragment.description
        invoiceItem.applicableFee = fragment.applicable_fee
        return invoiceItem
    }
}

export class InvoiceFactory {
    static fromFragment = (fragment: InvoiceFragment): Invoice => {
        let invoiceItems = fragment.invoice_items.map(fragment => {
            return InvoiceItemFactory.fromFragment(fragment)
        })

        let invoice = new Invoice()
        invoice.id = fragment.id
        invoice.createdAt = new Date(fragment.created_at)
        invoice.updatedAt = new Date(fragment.updated_at)
        invoice.submittedAt = fragment.submitted_at ? new Date(fragment.submitted_at) : null
        invoice.accountId = fragment.account
        invoice.customerId = fragment.customer
        invoice.invoiceType = fragment.invoice_type
        invoice.ref = fragment.ref
        invoice.refDate = new Date(fragment.ref_date)
        invoice.invoiceItems = invoiceItems
        invoice.totalItems = fragment.total_items
        invoice.comment = fragment.comment
        return invoice
    }
}

export class StandaloneInvoiceFactory {
    static fromInvoice = (
        invoice: Invoice,
        customers: Customer[],
        accounts: Account[],
        accountHasCustomers?: AccountHasCustomer[],
    ): StandaloneInvoice => {
        let standalone = {...invoice} as StandaloneInvoice

        let year = invoice.refDate.getFullYear()
        let localeMonth = invoice.refDate.toLocaleDateString('fr-FR', {month: '2-digit'})

        standalone.refDateYear = invoice.refDate.getFullYear()
        standalone.refDateMonth = getTranslation(`MONTHS.${invoice.refDate.getMonth()}`)

        standalone.ref = `${year.toString()}-${localeMonth}-${invoice.customerId}${invoice.id}`

        if (customers.length > 0) {
            let invoiceCustomer =  customers.find(c => c.id === invoice.customerId)

            if (invoiceCustomer) {
                standalone.customerName = invoiceCustomer.name

                if (accountHasCustomers) {
                    let customerHasAccounts = accountHasCustomers
                        .filter(ahc => ahc.customerId === invoiceCustomer!.id)

                    if (customerHasAccounts) {
                        standalone.accountIds = customerHasAccounts.map(ahc => ahc.accountId)
                    }
                }
            }
        }

        let invoiceAccount = accounts.find(a => a.id === invoice.accountId)
        if (invoiceAccount) {
            let lastname = invoiceAccount.user.lastname
            let firstname = invoiceAccount.user.firstname.slice(0,1)

            standalone.accountName = `${lastname} ${firstname}.`
            }

        if (invoice.submittedAt !== null) {
            standalone.isSubmitted = 'Oui'
        } else {
            standalone.isSubmitted = 'Non'
        }

        standalone.invoiceTypeName = getTranslation(
            `MONITORING.VIEWS.INVOICE.INVOICE_TYPE.${standalone.invoiceType}`
        )

        return standalone
    }
}
