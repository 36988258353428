import {CumulativeChartData} from "@boomrank/react-components";
import {defaultLanguage} from "../../../../intl";


export class CumulativeChartDataWithDate extends CumulativeChartData {
    getXExists = (x: Date) => {
        let dates = this.x.map(d => d.toLocaleDateString(defaultLanguage))
        return dates.indexOf(x.toLocaleDateString(defaultLanguage)) !== -1
    }

    compare = (v1: {x: Date}, v2: {x: Date}): boolean => {
        return v1.x.toLocaleDateString(defaultLanguage) === v2.x.toLocaleDateString(defaultLanguage)
    }
}