import React, {useState} from "react";

import {Account, Customer, Fee, Identifier} from "../../../../models";
import {JwtToken} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";
import {FeeApi} from "../../../../services/fee/api";
import {Link} from "react-router-dom";
import {PlaceHolder} from "@boomrank/react-components";
import {CustomerLinks} from "../../../dummies/customer/links";
import {FeeUpdateResponse} from "../../../../services/fee/responses";
import {FeeFactory} from "../../../../services/fee/factory";
import {FeeCard} from "../../../dummies/card/fee";

interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    fees: Fee[]
    cacheUpdater: (obj: Identifier) => void
}

export function FeeListView (props: Props) {
    let [isLoading, setIsLoading] = useState(false)

    let onClick = (fee: Fee) => {
        setIsLoading(true)

        fee.enabledAt = new Date()
        FeeApi.update(props.token, fee).then((response) => {
            if(response.statusCode >= 200 && response.statusCode < 300) {
                props.fees.forEach((fee) => {
                    fee.enabledAt = null
                    props.cacheUpdater(fee)
                })

                response = response as FeeUpdateResponse;
                fee = FeeFactory.fromFragment(response.data)
                props.cacheUpdater(fee)
            }
            setIsLoading(false)
        })
    }

    if (!props.me.isAdmin()) {
        return (
            <div role={'alert'}>
                { getTranslation('MONITORING.VIEWS.FEE.ACCESS_DENIED') }
            </div>
        )
    }

    if (isLoading) {
        return (
            <PlaceHolder className={'h-32'} />
        )
    }

    return (
        <div className={'mt-6'}>
            <div className={'w-full lg:flex lg:flex-row-reverse'}>
                <CustomerLinks customerId={props.customer.id} me={props.me} />
            </div>

            {
                props.customer.comment !== '' &&
                <div className={'mt-10 p-4 bg-slate-100 text-br-blue-900 rounded-md'}>
                    <span><b>Commentaire</b> :</span>
                    <p className={'whitespace-pre'}>{props.customer.comment}</p>
                </div>
            }

            <div className={'flex flex-row-reverse py-6'}>
                <Link
                    to={`/dashboard/customer/${props.customer.id}/fees/add`}
                    className={'btn btn-green no-underline'}
                >
                    { getTranslation('MONITORING.VIEWS.FEE.LIST.ADD_NEW_FEE') }
                </Link>
            </div>

            <h2>{ getTranslation('MONITORING.VIEWS.FEE.LIST.TITLE') }</h2>
            <div className={'mt-6 grid grid-cols-4 gap-3'}>
                {
                    props.fees.map((fee, idx) => {
                        return (
                            <FeeCard
                                key={idx}
                                fee={fee}
                                onClick={onClick}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}