import React from "react";
import {Cache} from "@boomrank/react-components";

import {Account, Customer} from "../../../../models";
import {SelectInput} from "../../../dummies/select";
import {getTranslation} from "../../../../intl";


interface Props {
    customer: Customer
    accountId: number
    cacheAccounts: Cache<Account>
    onChange: (accountId: number) => void
    disabled?: boolean
}

export function AccountForm(props: Props) {
    let onChange = (accountId: number) => {
        props.onChange(accountId)
    }

    let selectedOptions = () => {
        let options = props.cacheAccounts.values().map((account) => {
            return {
                id: account.id,
                content: account.user.firstname + ' ' + account.user.lastname,
                title: account.user.firstname + ' ' + account.user.lastname
            }
        })

        options.unshift({
            id: 0,
            content: getTranslation('MONITORING.FORMS.ACCOUNT.OPTION.CONTENT'),
            title: getTranslation('MONITORING.FORMS.ACCOUNT.OPTION.TITLE'),
        })

        return options
    }


    return (
        <SelectInput
            options={selectedOptions()}
            onChange={onChange}
            name={"account"}
            value={props.accountId}
            disabled={props.disabled}
            classname={'mt-4 lg:mt-0'}
        />
    )
}