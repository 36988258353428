import React from "react";
import {Navigate, useLocation} from "react-router-dom";

import {Account, Customer, Fee, Invoice, Role} from "../../../../../models";

import {Breadcrumb, Cache} from "@boomrank/react-components";
import {getTranslation} from "../../../../../intl";
import {CustomerFeeView} from "../../../views/customer/fee";

export interface Props {
    me: Account
    cacheCustomers: Cache<Customer>
    cacheInvoices: Cache<Invoice>
    cacheAccounts: Cache<Account>
    cacheFees: Cache<Fee>
}

export function CustomerFeeRoute(props: Props) {
    let location = useLocation()

    if (!props.me.isAdmin()) {
        return <Navigate to={`/dashboard/`} replace={true}/>
    }

    document.title = getTranslation('MONITORING.ROUTES.FEE.TITLE.DEFAULT')

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {
                        to: `/dashboard/fees`,
                        content: getTranslation('MONITORING.ROUTES.FEE.BREADCRUMB.LIST'),
                        disabled: true
                    },
                ]}
            />

            <CustomerFeeView
                cacheFees={props.cacheFees}
                cacheAccounts={props.cacheAccounts}
                cacheInvoices={props.cacheInvoices}
                cacheCustomers={props.cacheCustomers}
            />
        </>
    )
}