import {FeeBracketFragment, FeeFragment} from "./fragments";
import {Fee, FeeBracket} from "../../models";


export class FeeBracketFactory {
    static fromFragment = (fragment: FeeBracketFragment) : FeeBracket => {
        let feeBracket = new FeeBracket()
        feeBracket.id = fragment.id
        feeBracket.createdAt = new Date(fragment.created_at)
        feeBracket.bracketMin = fragment.bracket_min
        feeBracket.bracketMax = fragment.bracket_max
        feeBracket.priceHt = fragment.price_ht
        feeBracket.rate = fragment.rate
        return feeBracket
    }
}

export class FeeFactory {
    static fromFragment = (fragment: FeeFragment) : Fee => {
        let feeBrackets = fragment.fee_brackets.map((f) => FeeBracketFactory.fromFragment(f))

        let enabledAt = null
        if (fragment.enabled_at) {
            enabledAt = new Date(fragment.enabled_at)
        }

        let fee = new Fee()
        fee.id = fragment.id
        fee.createdAt = new Date(fragment.created_at)
        fee.customerId = fragment.customer
        fee.limitMin = fragment.limit_min
        fee.limitMax = fragment.limit_max
        fee.enabledAt = enabledAt
        fee.feeBrackets = feeBrackets
        return fee
    }
}
