import {CampaignTypeFragment} from "./fragments";
import {CampaignType} from "../../models";


export class CampaignTypeFactory {
    static fromFragment = (fragment: CampaignTypeFragment): CampaignType => {
        let campaignType = new CampaignType()
        campaignType.id = fragment.id
        campaignType.createdAt = new Date(fragment.created_at)
        campaignType.name = fragment.name
        campaignType.providerId = fragment.provider
        return campaignType
    }
}