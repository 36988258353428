import React from "react";

import {
    Account,
    BudgetAccountGroup,
    CampaignGroup,
    Customer,
    Identifier,
    Provider,
    ProviderAccount,
} from "../../../../models";

import {Cache, JwtToken} from "@boomrank/react-components";
import {CustomerBudgetFormView} from "./customer";
import {CampaignGroupFormView} from "./campaign-group";
import {ProviderAccountBudgetFormView} from "./provider-account";
import {CustomerLinks} from "../../../dummies/customer/links";


interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    providerAccounts: ProviderAccount[]
    campaignGroups: CampaignGroup[]
    budgetAccountGroups: BudgetAccountGroup[]
    cacheProviders: Cache<Provider>
    cacheUpdater: (obj: Identifier, remove: boolean) => void
}

export function BudgetView(props: Props) {
    return (
        <>
            <div className={'flex items-center justify-end mt-6 mb-10'}>
                <CustomerLinks customerId={props.customer.id} me={props.me} />
            </div>

            {
                props.customer.comment !== '' &&
                <div className={'mt-10 p-4 bg-slate-100 text-br-blue-900 rounded-md'}>
                    <span><b>Commentaire</b> :</span>
                    <p className={'whitespace-pre'}>{props.customer.comment}</p>
                </div>
            }

            <CustomerBudgetFormView
                token={props.token}
                customer={props.customer}
                cacheUpdater={props.cacheUpdater}
            />

            <ProviderAccountBudgetFormView
                token={props.token}
                customer={props.customer}
                providerAccounts={props.providerAccounts}
                cacheProviders={props.cacheProviders}
                cacheUpdater={props.cacheUpdater}
            />

            <CampaignGroupFormView
                token={props.token}
                me={props.me}
                customer={props.customer}
                providerAccounts={props.providerAccounts}
                campaignGroups={props.campaignGroups}
                budgetAccountGroups={props.budgetAccountGroups}
                cacheProviders={props.cacheProviders}
                cacheUpdater={props.cacheUpdater}
            />
        </>
    )
}