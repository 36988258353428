import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import {StandaloneFinancialInvoice, StandaloneFinancialInvoiceItem} from "../../../../models/standalone";
import {StandaloneFinancialInvoiceItemFactory} from "../../../../services/financial_invoice/factory";
import {ExportApi} from "../../../../services/export/api";

import {JwtToken, Column, ColumnType, FullTable, PaginateAdvancedTable} from "@boomrank/react-components";
import {defaultLanguage, getTranslation, translator} from "../../../../intl";
import {TableLoader} from "../../../dummies/loaders/table";
import {State} from "../../../../models";


interface Props {
    token: JwtToken
    standaloneFinancialInvoices: StandaloneFinancialInvoice[]
    columns: (keyof StandaloneFinancialInvoice)[]
    isLoading?: boolean
}

export function FinancialInvoicesTableView(props: Props) {
    let isLoading = props.isLoading || false

    let columns = props.columns
    let defaultColumns: Column<StandaloneFinancialInvoice>[] = [
        {
            header: getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.TABLE.ID'),
            type: ColumnType.STRING,
            accessor: 'id',
            cell: (row: StandaloneFinancialInvoice, value: any) => {
                return (
                    <Link
                        to={`/dashboard/financial-invoices/${value}`}
                        className={classNames(
                            'w-full py-1 px-2 rounded-md xs:my-0 sm:my-0 text-white no-underline',
                            {'btn-gray': row.submittedAt !== null},
                            {'btn-green': row.submittedAt === null},
                        )}
                        title={
                            row.submittedAt !== null ?
                                getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.TABLE.TITLE.READ') :
                                getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.TABLE.TITLE.UPDATE')
                        }
                    >
                        {row.refId}
                    </Link>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.TABLE.CREATED_AT'),
            type: ColumnType.DATE,
            accessor: "createdAt",
            cell: (row: StandaloneFinancialInvoice, value: any) => {
                return value.toLocaleDateString(defaultLanguage)
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.TABLE.UPDATED_AT'),
            type: ColumnType.DATE,
            accessor: "updatedAt",
            cell: (row: StandaloneFinancialInvoice, value: any) => {
                return value.toLocaleDateString(defaultLanguage)
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.TABLE.CUSTOMER_NAME'),
            type: ColumnType.STRING,
            accessor: "customerName",
            cell: (row: StandaloneFinancialInvoice, value: any) => {
                return (
                    <Link
                        to={`/dashboard/customer/${row.customerId}/invoices`}
                        className={classNames(
                            'w-full xs:my-0 sm:my-0',
                            {'text-gray-500': row.submittedAt !== null}
                        )}
                    >
                        {
                            row.customerState === State.PAUSED &&
                            <i className={'mr-3 font-gold text-orange-500 fa-solid fa-pause'} title={
                                getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.PAUSED')
                            } />
                        }
                        {
                            row.customerState === State.ACTIVE &&
                            <i className={'mr-3 font-gold text-green-500 fa-solid fa-play'} title={
                                getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.PLAYING')
                            } />
                        }
                        {value}
                    </Link>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.TABLE.REF_DATE'),
            type: ColumnType.DATE,
            accessor: "refDate",
            cell: (row: StandaloneFinancialInvoice, value: any) => {
                return value.toLocaleDateString(
                    defaultLanguage,
                    {month: "long", year: "numeric"}
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.TABLE.TOTAL_NB_INVOICES'),
            type: ColumnType.NUMBER,
            accessor: "totalNbInvoices",
            cell: (row: StandaloneFinancialInvoice, value: any) => {
                let submittedInvoices = row.invoices.filter(i => i.submittedAt !== null)

                return (
                    <span className={classNames(
                        'w-full font-medium',
                        {'text-br-green': submittedInvoices.length === value},
                        {'text-red-500': submittedInvoices.length !== value},
                    )}>
                        {submittedInvoices.length} / {value}
                    </span>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.TABLE.TOTAL_INVOICES'),
            type: ColumnType.NUMBER,
            accessor: "totalInvoices",
            cell: (row: StandaloneFinancialInvoice, value: any) => {
                if (value) {
                    return value.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    )
                }
                return '-'
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.TABLE.TOTAL_FEES'),
            type: ColumnType.NUMBER,
            accessor: "totalFees",
            cell: (row: StandaloneFinancialInvoice, value: any) => {
                if (value) {
                    return value.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    )
                }
                return '-'
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.TABLE.FEES_RULE'),
            type: ColumnType.STRING,
            accessor: "feesRule",
            cell: (row: StandaloneFinancialInvoice, value: any) => {
                return value
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.TABLE.SUBMITTED_AT'),
            type: ColumnType.STRING,
            accessor: "isSubmitted",
            cell: (row: StandaloneFinancialInvoice, value: any) => {
                if (value && row.submittedAt) {
                    return (
                        <div className={'w-full'}>
                            <span className={'text-br-green font-medium'}>{value} </span>
                            <span className={'text-gray-500 text-light'}>({row.submittedAt.toLocaleDateString(defaultLanguage)})</span>
                        </div>
                    )
                }

                return (
                    <div className={'w-full text-red-500 font-medium'} data-testid={'invoice-table-unsubmitted-icon'}>
                        {value}
                    </div>
                )
            }
        },
    ]


    let onClickExport = (financialInvoices: StandaloneFinancialInvoice[]) => {
        let standalones: StandaloneFinancialInvoiceItem[] = []

        if (financialInvoices.length > 0) {
            financialInvoices.forEach((financialInvoice) => {
                financialInvoice.invoices.forEach((invoice) => {
                    invoice.invoiceItems.forEach((item) => {
                        let standalone = StandaloneFinancialInvoiceItemFactory.fromFinancialInvoice(
                            financialInvoice,
                            item
                        )
                        standalones.push(standalone)
                    })
                })
            })

            if (standalones.length > 0) {
                let items = standalones.reduce((
                    rows: StandaloneFinancialInvoiceItem[],
                    standalone: StandaloneFinancialInvoiceItem
                ) => {
                    let existingRow = rows.find((row) =>
                        row.financialInvoiceId === standalone.financialInvoiceId &&
                        row.providerAccountId === standalone.providerAccountId &&
                        row.refDate === standalone.refDate
                    )

                    if (!existingRow) {
                        rows.push(standalone)
                    }
                    if (existingRow) {
                        existingRow.invoiceItemPriceHt += standalone.invoiceItemPriceHt
                    }

                    return rows
                }, [])

                let financialInvoices = items.map((item, idx) => {
                    let previousItem = items[idx-1]
                    let sameItem = (previousItem && previousItem.financialInvoiceId === item.financialInvoiceId)

                    let refDate = sameItem && previousItem.refDate === item.refDate ? null : item.refDate
                    let client = sameItem ? null : item.customerName
                    let totalHt = sameItem ? null : item.customerPriceHt
                    let totalFees = sameItem ? null : item.customerFees
                    let feesRule = sameItem ? null : item.feesRule

                    return {
                        'Facture': item.financialInvoiceId,
                        'Client': client,
                        'Date': refDate,
                        'Plateforme': item.invoiceItemLabel,
                        'Prix HT': item.invoiceItemPriceHt,
                        'Total HT': totalHt,
                        'Honoraires': totalFees,
                        'Regle appliquee': feesRule,
                    }
                })

                ExportApi.csv(
                    props.token,
                    financialInvoices.sort((a, b) => a['Facture'] - b['Facture']),
                    'monitoring_sea_factures.csv'
                )
            }
        }
    }


    if (isLoading) {
        return <TableLoader defaultColumns={defaultColumns} />
    }

    return (
        <FullTable
            rows={props.standaloneFinancialInvoices}
            formatter={() => props.standaloneFinancialInvoices}
            showColumns={columns}
            columns={defaultColumns}
            onClickExport={onClickExport}
        >
            <PaginateAdvancedTable
                translator={translator}
                mobileColumnsDisplayed={[0, 2, 3]}
                initialState={{
                    pageSize: 100
                }}
            />
        </FullTable>
    )
}