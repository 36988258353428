import React, {useState} from "react";
import {FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {Account, Customer, Identifier, Invoice, ProviderAccount, ProviderAccountInvoice} from "../../../../models";
import {Errors} from "../../../dummies/errors";
import {InvoiceApi} from "../../../../services/invoice/api";
import {InvoiceUpdateResponse} from "../../../../services/invoice/responses";
import {InvoiceFactory} from "../../../../services/invoice/factory";
import {InvoiceForm} from "../../forms/invoice";
import {getTranslation} from "../../../../intl";
import {FormNotification} from "../../forms/models";
import {CustomerLinks} from "../../../dummies/customer/links";
import {useLocation} from "react-router-dom";
import {StatCustomer} from "../../../../models/stats";


interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    invoice: Invoice
    providerAccounts: ProviderAccount[]
    providerAccountInvoices: ProviderAccountInvoice[]
    cacheUpdater: (obj: Identifier) => void
    statsCustomer: StatCustomer[]
}

export function InvoiceEditView (props: Props) {
    let location = useLocation()

    let [loading, setLoading] = useState(false)
    let [message, setMessage] = useState(new FormNotification())
    let [errors, setErrors] = useState<Errors>({})

    let onSubmit = (invoice: Invoice) => {
        setLoading(true)

        InvoiceApi.update(
            props.token,
            props.invoice.id,
            invoice.invoiceType,
            invoice.ref,
            invoice.refDate,
            invoice.invoiceItems,
            invoice.comment,
            invoice.submittedAt
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as InvoiceUpdateResponse
                props.cacheUpdater(InvoiceFactory.fromFragment(res.data))

                if (invoice.submittedAt !== null) {
                    setMessage(
                        message.setSuccess(
                            getTranslation('MONITORING.VIEWS.INVOICE.EDIT.SUCCESS.SUBMIT')
                        )
                    )
                } else {
                    setMessage(
                        message.setSuccess(
                            getTranslation('MONITORING.VIEWS.INVOICE.EDIT.SUCCESS.UPDATE')
                        )
                    )
                }
            } else {
                res = res as FormErrorResponse;
                setErrors(res.data)
            }
        })
        setLoading(false)
    }

    if (location.state === 'invoiceAdd') {
        message.setSuccess(
            getTranslation('MONITORING.VIEWS.INVOICE.EDIT.SUCCESS.CREATE')
        )

        location.state = ''
    }


    return (
        <div className={'mt-6'}>
            <div className={'flex items-center justify-end mt-6 mb-10'}>
                <CustomerLinks customerId={props.customer.id} me={props.me} />
            </div>

            {
                props.customer.comment !== '' &&
                <div className={'mt-10 p-4 bg-slate-100 text-br-blue-900 rounded-md'}>
                    <span><b>Commentaire</b> :</span>
                    <p className={'whitespace-pre'}>{props.customer.comment}</p>
                </div>
            }

            <InvoiceForm
                customer={props.customer}
                invoice={props.invoice}
                onSubmit={onSubmit}
                onSubmitMessage={message}
                providerAccounts={props.providerAccounts}
                disabled={loading}
                errors={errors}
                statsCustomer={props.statsCustomer}
                providerAccountInvoices={props.providerAccountInvoices}
            />
        </div>
    )
}