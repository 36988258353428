import {CustomerFragment} from "./fragments";
import {Account, AccountHasCustomer, Customer, Fee, Invoice, ProviderAccount} from "../../models";
import {StatCustomer} from "../../models/stats";
import {StandaloneCustomer, StandaloneCustomerFee} from "../../models/standalone";


export class CustomerFactory {
    static fromFragment = (fragment: CustomerFragment): Customer => {
        let customer = new Customer()
        customer.id = fragment.id
        customer.createdAt = new Date(fragment.created_at)
        customer.lastUpdate = fragment.last_update ? new Date(fragment.last_update) : null
        customer.name = fragment.name
        customer.budgetMin = fragment.budget_min
        customer.budgetMax = fragment.budget_max
        customer.account = fragment.account
        customer.accountIds = fragment.accounts
        customer.state = fragment.state
        customer.comment = fragment.comment
        customer.financial_comment = fragment.financial_comment
        return customer
    }
}


export class StandaloneCustomerFactory {
    static fromCustomer = (
        customer: Customer,
        accountHasCustomers: AccountHasCustomer[],
        providerAccounts: ProviderAccount[],
        statCustomers?: StatCustomer[],
    ): StandaloneCustomer => {
        let standalone = {...customer} as StandaloneCustomer

        standalone.cost = 0
        standalone.trend = 0
        standalone.conversions = 0
        standalone.conversionsValue = 0

        standalone.nbCampaignGroups = 0
        standalone.nbCampaigns = 0
        standalone.providerAccounts = []

        let customerHasAccounts = accountHasCustomers
            .filter(ahc => ahc.customerId === customer.id)

        if (customerHasAccounts) {
            standalone.accountIds = customerHasAccounts.map(ahc => ahc.accountId)
            standalone.accountHasCustomers = customerHasAccounts
        }

        if (providerAccounts.length > 0) {
            let pas = providerAccounts.filter((pa) =>
                pa.customerId === customer.id
            )

            if (pas.length > 0) {
                standalone.providerAccounts = pas

                if (statCustomers) {
                    statCustomers.filter((s) => (
                        pas.map((pa) => pa.loginId).indexOf(s.loginId) !== -1
                    )).forEach((stat) => {
                        pas.forEach((pa) => {
                            if (pa.loginId === stat.loginId) {
                                pa.cost = stat.cost
                                pa.trend = stat.trend
                            }
                        })

                        standalone.cost += stat.cost
                        standalone.trend += stat.trend
                        standalone.conversions += stat.conversions
                        standalone.conversionsValue += stat.conversionsValue
                    })
                }
            }
        }

        return standalone
    }
}

export class StandaloneCustomerInvoicesFactory {
    static fromCustomer = (
        customer: Customer,
        invoices: Invoice[],
        accountHasCustomers: AccountHasCustomer[],
    ): StandaloneCustomer => {
        let standalone = {...customer} as StandaloneCustomer

        standalone.cost = 0
        standalone.trend = 0

        standalone.invoices = invoices.filter(
            invoice => invoice.customerId === customer.id
        )
        let customerHasAccounts = accountHasCustomers
            .filter(ahc => ahc.customerId === customer.id)

        if (customerHasAccounts) {
            standalone.accountIds = customerHasAccounts.map(ahc => ahc.accountId)
            standalone.accountHasCustomers = customerHasAccounts
        }
        return standalone
    }
}

export class StandaloneCustomerFeesFactory {
    static fromCustomer = (
        customer: Customer,
        fees: Fee[],
        accounts: Account[],
        invoices: Invoice[],
    ): StandaloneCustomerFee => {
        let standalone = new StandaloneCustomerFee()

        standalone.customerId = customer.id
        standalone.customerName = customer.name
        standalone.customerCreatedAt = customer.createdAt
        standalone.customerLastUpdate = customer.lastUpdate
        standalone.customerState = customer.state
        standalone.activeCustomer = (customer.state === 1)
        standalone.customerBudgetMin = customer.budgetMin
        standalone.customerBudgetMax = customer.budgetMax

        let account = accounts.find(a => a.id === customer!.account)
        if (account) {
            let lastname = account.user.lastname
            let firstname = account.user.firstname.slice(0,1)

            standalone.customerAccount = `${lastname} ${firstname}.`
        }

        if (fees.length > 0) {
            let fee = fees.find(f => f.customerId === customer.id && f.enabledAt !== null)
            standalone.activeFee = (fee !== undefined)
        }

        if (invoices.length > 0) {
            let customerInvoices = invoices.filter(i => i.customerId === customer.id)
            standalone.nbInvoices = customerInvoices.length
        }

        return standalone
    }
}