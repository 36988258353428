import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {
    CustomerCreateResponse,
    CustomerDeleteResponse, CustomerExportResponse,
    CustomerListResponse,
    CustomerUpdateResponse
} from "./responses";
import {endpoints} from "../api";


export class CustomerApi {
    static list(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | CustomerListResponse> {
        let url = endpoints.customer.list;
        if (baseUrl) {
            url = baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static toInvoice(
        token: JwtToken,
        month?: string,
        baseUrl?: string
    ): Promise<FormErrorResponse | CustomerListResponse> {
        let url = endpoints.customer.toInvoice;

        if (month) {
            url = url.replace(':month', month)
        }

        if (baseUrl) {
            url = baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static create(
        token: JwtToken,
        name: string,
        budgetMin: number | null,
        budgetMax: number | null,
        account: number | null,
        accounts: number[],
        comment: string,
        financial_comment: string | null
    ): Promise<FormErrorResponse | CustomerCreateResponse> {
        let url = endpoints.customer.create;
        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    name: name,
                    budget_min: budgetMin,
                    budget_max: budgetMax,
                    account: account,
                    accounts: accounts,
                    comment: comment,
                    financial_comment: financial_comment
                })
            }
        );
    }

    static update(
        token: JwtToken,
        pk: number,
        name: string,
        budgetMin: number | null,
        budgetMax: number | null,
        account: number | null,
        accounts: number[],
        comment: string,
        financial_comment: string
    ): Promise<FormErrorResponse | CustomerUpdateResponse> {
        let url = endpoints.customer.update.replace(':pk', pk.toString());
        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    name: name,
                    budget_min: budgetMin,
                    budget_max: budgetMax,
                    account: account,
                    accounts: accounts,
                    comment: comment,
                    financial_comment: financial_comment
                })
            }
        );
    }

    static update_state(
        token: JwtToken,
        pk: number,
        state: number
    ): Promise<FormErrorResponse | CustomerUpdateResponse> {
        let url = endpoints.customer.update.replace(':pk', pk.toString());
        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    state: state,
                })
            }
        );
    }

    static delete(
        token: JwtToken,
        pk: number,
    ): Promise<FormErrorResponse | CustomerDeleteResponse> {
        let url = endpoints.customer.delete.replace(':pk', pk.toString());
        return Api.call(
            url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
            }
        );
    }

    static export_stats(
        token: JwtToken,
        pk: number,
    ): Promise<FormErrorResponse | CustomerExportResponse> {
        let url = endpoints.customer.export_stats.replace(':pk', pk.toString());
        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
            }
        );
    }
}
