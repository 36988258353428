import classNames from "classnames";
import React from "react";


interface Props {
    showConversions: boolean
    onClick: (showConversions: boolean) => void
}

export function ToggleGraph(props: Props) {
    return (
        <div className={'w-full mt-10 flex items-start gap-x-2'}>
            <button
                data-testid={'toggle-graph-costs'}
                className={classNames(
                    'w-fit p-2 text-white text-center rounded-sm',
                    {'bg-br-green': !props.showConversions},
                    {'bg-br-blue': props.showConversions},
                )}
                onClick={() => props.onClick(false)}
            >
                Dépenses
            </button>

            <button
                data-testid={'toggle-graph-conversions'}
                className={classNames(
                    'w-fit p-2 text-white text-center rounded-sm',
                    {'bg-br-green': props.showConversions},
                    {'bg-br-blue': !props.showConversions},
                )}
                onClick={() => props.onClick(true)}
            >
                Conversions
            </button>
        </div>
    )
}