import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {
    Account,
    AccountHasCustomer,
    Campaign,
    CampaignType,
    Customer,
    Provider,
    ProviderAccount,
    State,
} from "../../../../models";
import {StatCampaign, StatCustomer} from "../../../../models/stats";
import {StepPeriod} from "../../../../models/stepPeriod";

import {CustomerStatCustomerApi} from "../../../../services/customer/stats/customer/api";
import {StatCustomerListResponse} from "../../../../services/customer/stats/customer/responses";
import {StatCustomerFactory} from "../../../../services/customer/stats/customer/factory";

import {CustomerStatCampaignApi} from "../../../../services/customer/stats/campaign/api";
import {StatCampaignListResponse} from "../../../../services/customer/stats/campaign/responses";
import {StatCampaignFactory} from "../../../../services/customer/stats/campaign/factory";

import {Cache, JwtToken} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";
import {StepPeriodSelect} from "../../../dummies/select/stepPeriod";
import {LineGraph} from "../../../dummies/graph/line";
import {ComposedGraph} from "../../../dummies/graph/composed";
import {CampaignsTableView} from "../campaign/table";
import {CustomerLinks} from "../../../dummies/customer/links";
import {CardCustomer} from "../../../dummies/card/customer/get";
import {StandaloneCampaign, StandaloneCustomer} from "../../../../models/standalone";
import {StandaloneCustomerFactory} from "../../../../services/customer/factory";
import {StandaloneCampaignFactory} from "../../../../services/campaign/factory";
import {ToggleGraph} from "../../../dummies/graph/toggle";


interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    campaigns: Campaign[]
    cacheAccounts: Cache<Account>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheProviderAccounts: Cache<ProviderAccount>
    cacheProviders: Cache<Provider>
    cacheCampaignTypes: Cache<CampaignType>
    stepPeriod: StepPeriod
    onStepPeriodChange: (stepPeriod: StepPeriod) => void
}

export function CustomerGetView(props: Props) {
    let [standaloneCustomer, setStandaloneCustomers] = useState<StandaloneCustomer>()
    let [standaloneCampaigns, setStandaloneCampaigns] = useState<StandaloneCampaign[]>([])

    let [loadingStatCustomers, setLoadingStatCustomers] = useState<boolean>(false)
    let [loadingStatCampaigns, setLoadingStatCampaigns] = useState<boolean>(false)

    let [statCustomers, setStatCustomers] = useState<StatCustomer[]>([])
    let [statCampaigns, setStatCampaigns] = useState<StatCampaign[]>([])

    let [showConversions, setShowConversions] = useState<boolean>(false)

    let customerFormatter = () => {
        let customer = StandaloneCustomerFactory.fromCustomer(
            props.customer,
            props.cacheAccountHasCustomers.values(),
            props.cacheProviderAccounts.values(),
            statCustomers
        )
        setStandaloneCustomers(customer)
    }

    let campaignsFormatter = () => {
        let campaigns = props.campaigns.map((campaign) => {
            return StandaloneCampaignFactory.fromCampaign(
                campaign,
                props.customer,
                props.cacheProviderAccounts,
                props.cacheProviders,
                props.cacheCampaignTypes,
                statCampaigns
            )
        })
        setStandaloneCampaigns(campaigns)
    }

    let addDefaultStatCustomers = () => {
        let defaultStats = props.cacheProviders.values().map(provider => {
            let stat = new StatCustomer()
            stat.name = provider.name
            stat.startedAt = props.stepPeriod.period.start
            return stat
        })
        setStatCustomers(defaultStats)
    }

    let setStatCustomerName = (providerAccount: ProviderAccount) => {
        let provider = props.cacheProviders.values()
            .find(p => p.id === providerAccount.providerId)

        if (providerAccount.name) {
            return provider!.name + ' - ' + providerAccount.name
        }
        return provider!.name + ' - ' + providerAccount.loginId
    }

    let fetchStats = (selected: StepPeriod) => {
        setLoadingStatCustomers(true)
        setStatCustomers([])

        setLoadingStatCampaigns(true)
        setStatCampaigns([])

        CustomerStatCustomerApi.list(
            props.token,
            props.customer.id,
            selected.period.start,
            selected.period.end,
            selected.stepChart
        ).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as StatCustomerListResponse;

                let stats = response.data.map((fragment) => {
                    let providerAccount = props.cacheProviderAccounts.values()
                        .find(pa => pa.loginId === fragment.login_id)

                    fragment.name = setStatCustomerName(providerAccount!)
                    return StatCustomerFactory.fromFragment(fragment)
                })
                setStatCustomers(stats);

                if (stats.length === 0) {
                    addDefaultStatCustomers()
                }
            }
            setLoadingStatCustomers(false)
        });

        CustomerStatCampaignApi.list(
            props.token,
            props.customer.id,
            selected.period.start,
            selected.period.end,
            selected.stepTable
        ).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as StatCampaignListResponse;

                let statCampaigns = response.data.map((fragment) => {
                    return StatCampaignFactory.fromFragment(fragment)
                })
                setStatCampaigns(statCampaigns)
            }
            setLoadingStatCampaigns(false)
        });
    }


    const rangeChangeHandler = (selected: StepPeriod) => {
        props.onStepPeriodChange(selected)
        fetchStats(selected)
    };

    useEffect(() => {
        customerFormatter()

        if (props.campaigns.length > 0 && statCampaigns.length > 0) {
            campaignsFormatter()
        }
    }, [props.customer, props.campaigns, statCustomers, statCampaigns]);


    return (
        <div className={'mt-6'}>
            <div className={'w-full lg:flex lg:items-center lg:justify-between'}>
                <StepPeriodSelect
                    onChange={rangeChangeHandler}
                    stepPeriod={props.stepPeriod}
                />

                <CustomerLinks customerId={props.customer.id} me={props.me} />
            </div>

            {
                props.customer.state === State.PAUSED &&
                <div role={'alert'} className={'mt-10 text-center p-4 bg-orange-100 rounded-md text-orange-500'}>
                    {
                        getTranslation('MONITORING.VIEWS.CUSTOMER.GET.PAUSED')
                        .render(
                            <b>{ getTranslation('STATE.4').toLowerCase() }</b>,
                            <Link
                                to={`/dashboard/customer/${props.customer.id}/edit`}
                                className={'text-orange-500 font-bold'}
                            >
                                Paramètres
                            </Link>
                        )
                    }
                </div>
            }

            {
                props.customer.comment !== '' &&
                <div className={'mt-10 p-4 bg-slate-100 text-br-blue-900 rounded-md'}>
                    <span><b>Commentaire</b> :</span>
                    <p className={'whitespace-pre'}>{props.customer.comment}</p>
                </div>
            }

            <ToggleGraph
                showConversions={showConversions}
                onClick={setShowConversions}
            />

            <div className={'mt-10 mb-2 lg:flex'}>
                <LineGraph
                    data={statCustomers}
                    stepPeriod={props.stepPeriod}
                    conversions={showConversions}
                />

                {
                    standaloneCustomer &&
                    <CardCustomer
                        standaloneCustomer={standaloneCustomer}
                        standaloneCampaigns={standaloneCampaigns}
                        cacheAccounts={props.cacheAccounts}
                        cacheProviders={props.cacheProviders}
                        stepPeriod={props.stepPeriod}
                        isLoading={(loadingStatCustomers && loadingStatCampaigns)}
                    />
                }
            </div>

            <ComposedGraph
                data={statCustomers}
                stepPeriod={props.stepPeriod}
                budgetMax={props.customer.budgetMax!}
                budgetMin={props.customer.budgetMin!}
            />

            <div className={'my-2'}>
                <CampaignsTableView
                    token={props.token}
                    standaloneCampaigns={standaloneCampaigns}
                    columns={[]}
                    stepPeriod={props.stepPeriod}
                    isLoading={loadingStatCampaigns}
                />
            </div>
        </div>
    )
}
