import React, {ReactNode} from "react";
import classNames from "classnames";
import {defaultLanguage} from "../../../intl";


interface Props {
    parentBudget: number | null
    budget: number
    error: string
    text?: string | ReactNode | ReactNode[]
    className?: string
}


export function FormBudgetTotal(props: Props) {
    let budgetIsOut = (props.parentBudget ? props.budget > props.parentBudget : false)

    return (
        <p
            className={classNames(
                'w-2/4 lg:w-52 mb-0 font-medium text-sm',
                props.className,
                { 'text-red-500 cursor-help': budgetIsOut }
            )}
            title={budgetIsOut ? props.error + ` [${props.parentBudget} €]` : ''}
        >
            {
                budgetIsOut &&
                <i className="fa-solid fa-triangle-exclamation mr-2" />
            }

            {props.text ? props.text : 'Total'} : {
                props.budget.toLocaleString(
                    defaultLanguage,
                    { style: 'currency', currency: 'EUR' }
                )
            }
        </p>
    )
}