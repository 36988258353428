import React from "react";
import classNames from "classnames";


interface Props {
    options: {
        id: number
        content: string
        title: string
        selected?: boolean
    }[]
    onChange: (id: number) => void
    name: string
    value: number
    dataTestId?: string
    disabled?: boolean
    legend?: string | React.ReactNode | React.ReactNode[]
    classname?: string
}

export function SelectInput(props: Props) {
    let handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.onChange(parseInt(event.target.value))
    }

    return (
        <fieldset className={classNames(
            'w-full border border-white',
            props.classname
        )}>
            {
                props.legend &&
                <legend className={'text-gray-500'}>{props.legend}</legend>
            }

            <select
                data-testid={props.dataTestId}
                disabled={props.disabled}
                name={props.name}
                value={props.value}
                onChange={handleChange}
                className={
                    'w-full px-3 py-3.5 bg-white cursor-pointer border border-gray-200 rounded-md ' +
                    'focus:outline-dashed focus:outline-2 focus:outline-gray-300'
            }
            >
                {
                    props.options.map((option, idx) => {
                        return (
                            <option
                                key={idx}
                                value={option.id}
                                title={option.title}
                            >
                                {option.content}
                            </option>
                        )
                    })
                }
            </select>
        </fieldset>
    )
}
