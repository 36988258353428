import React, {useState} from "react";

import {
    Customer,
    Identifier,
    Provider,
    ProviderAccount,
} from "../../../../../models";
import {Flag, FlaggedProviderAccount} from "../models";

import {ProviderAccountApi} from "../../../../../services/provider-account/api";
import {ProviderAccountUpdateResponse} from "../../../../../services/provider-account/responses";
import {ProviderAccountFactory} from "../../../../../services/provider-account/factory";
import {Errors} from "../../../../dummies/errors";

import {FormErrorResponse, Cache, JwtToken} from "@boomrank/react-components";
import {BudgetProviderAccountForm} from "../../../forms/provider-account/budget";
import {getTranslation} from "../../../../../intl";
import {FormNotification} from "../../../forms/models";


interface Props {
    token: JwtToken
    customer: Customer
    providerAccounts: ProviderAccount[]
    cacheProviders: Cache<Provider>
    cacheUpdater: (obj: Identifier, remove: boolean) => void
}

export function ProviderAccountBudgetFormView(props: Props) {
    let [loading, setLoading] = useState<boolean>(false);
    let [errors, setErrors] = useState<Errors>({});
    let [message, setMessage] = useState<FormNotification>(new FormNotification())


    let persistProviderAccount = (fpa: FlaggedProviderAccount) => {
        if (fpa.flag === Flag.UPDATED) {
            if (fpa.providerAccount.loginId === '') {
                setMessage(message.setError(
                    getTranslation('MONITORING.VIEWS.BUDGET.PROVIDER_ACCOUNT.ERRORS.LOGIN_ID')
                ))
            } else {
                editProviderAccountBudgets(fpa.providerAccount)
            }
        }
    }

    let editProviderAccountBudgets = (providerAccount: ProviderAccount) => {
        setLoading(true)

        if (providerAccount.name === null) {
            providerAccount.name = ''
        }

        ProviderAccountApi.update(
            props.token,
            providerAccount.id,
            providerAccount.loginId,
            providerAccount.name,
            providerAccount.budgetMin!,
            providerAccount.budgetMax!,
            providerAccount.providerId,
            providerAccount.customerId,
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as ProviderAccountUpdateResponse;
                props.cacheUpdater(ProviderAccountFactory.fromFragment(res.data), false);

                setMessage(message.setSuccess(
                    getTranslation('MONITORING.VIEWS.BUDGET.PROVIDER_ACCOUNT.SUCCESS.UPDATE')
                ))
            } else {
                res = res as FormErrorResponse;
                setErrors(res.data);
            }
            setLoading(false)
        })
    }

    let onSubmit = (flaggedProviderAccounts: FlaggedProviderAccount[]) => {
        setMessage(message.clear())

        if (flaggedProviderAccounts.length > 0) {
            flaggedProviderAccounts.forEach((providerAccount) => {
                persistProviderAccount(providerAccount);
            })
        }
    }


    return (
        <BudgetProviderAccountForm
            customer={props.customer}
            cacheProviders={props.cacheProviders}
            providerAccounts={props.providerAccounts}
            onSubmit={onSubmit}
            disabled={loading}
            message={message}
        />
    )
}