import React from 'react';
import {TooltipProps} from 'recharts';
import {NameType, ValueType} from "recharts/types/component/DefaultTooltipContent";
import {defaultLanguage, getTranslation} from "../../../../intl";
import {TheoreticalExpense} from "./theoretical-expense";


interface Props extends TooltipProps<ValueType, NameType> {
    datesRange?: Date[]
    minBudget?: number
    maxBudget?: number
    conversions?: boolean
}

export function CustomTooltip (props: Props) {
    let minExpense = 0
    let maxExpense = 0
    let day = 0

    let localeString = (value: number | ValueType) => {
        if (props.conversions) {
            return value.toLocaleString()
        }
        return value.toLocaleString(
            defaultLanguage,
            {style: 'currency', currency: 'EUR'}
        )
    }

    if (props.datesRange) {
        if (props.label) {
            day = props.label.getDate()
        }

        let date = props.datesRange.map((d) => d.getDate()).indexOf(day)

        if (props.maxBudget) {
            maxExpense = (props.maxBudget / props.datesRange.length) * (date + 1)
        }
        if (props.minBudget) {
            minExpense = (props.minBudget / props.datesRange.length) * (date + 1)
        }
    }


    if (props.active && props.payload && props.payload.length > 0) {
        let payload = props.payload

        let costByProvider = payload.map((p) => Number(p.value!))
        let totalCost = costByProvider.reduce(
            (cost, value) => cost + value, 0
        )

        return (
            <ul
                className="bg-white p-3 border flex flex-col"
                style={{'backgroundColor': '#e5e7eb'}}
            >
                {
                    costByProvider.length > 1 &&
                    <span className={'text-br-blue'}>
                        {getTranslation('DUMMIES.GRAPH.TOOLTIP.TITLE')}

                        <strong>{localeString(totalCost)}</strong>
                    </span>
                }

                <TheoreticalExpense
                    minExpense={minExpense}
                    maxExpense={maxExpense}
                />

                {
                    payload.map((p, idx) => {
                        let title = p.dataKey

                        let date = props.label!.toLocaleString(
                            defaultLanguage,
                            {day: 'numeric', month: 'numeric', year: 'numeric'}
                        )

                        let cost = localeString(p.value!)


                        return (
                            <li
                                key={idx} className={'flex items-center'}
                                style={{'color': '#333'}}
                            >
                                <span
                                    className={'p-1 rounded-full inline-block mr-2'}
                                    style={{'backgroundColor': p.color}}
                                >
                                </span>
                                <span>{date} : {title} - <b>{cost}</b></span>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    return <></>
}