import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {FeeCreateResponse, FeeListResponse, FeeUpdateResponse} from "./responses";
import {endpoints} from "../api";
import {Fee} from "../../models";


export class FeeApi {
    static list(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | FeeListResponse> {
        let url = endpoints.fee.list
        if (baseUrl) {
            url = baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static create(
        token: JwtToken,
        fee: Fee
    ): Promise<FormErrorResponse | FeeCreateResponse> {
        let url = endpoints.fee.create

        let feeBrackets = fee.feeBrackets.map((feeBracket) => {
            return {
                bracket_min: feeBracket.bracketMin,
                bracket_max: feeBracket.bracketMax,
                price_ht: feeBracket.priceHt,
                rate: feeBracket.rate,
            }
        })

        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    customer: fee.customerId,
                    limit_min: fee.limitMin,
                    limit_max: fee.limitMax,
                    enabled_at: fee.enabledAt,
                    fee_brackets: feeBrackets,
                }),
            }
        );
    }

    static update(
        token: JwtToken,
        fee: Fee
    ): Promise<FormErrorResponse | FeeUpdateResponse> {
        let url = endpoints.fee.update.replace(':pk', fee.id.toString());

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    enabled_at: fee.enabledAt,
                }),
            }
        );
    }
}