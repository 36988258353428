import React from "react";
import {useLocation} from "react-router-dom";

import {Breadcrumb, Cache, JwtToken} from "@boomrank/react-components";
import {Account, Customer, Fee} from "../../../models";
import {getTranslation} from "../../../intl";
import {StandaloneFinancialInvoice} from "../../../models/standalone";
import {FinancialInvoiceListView} from "../views/list";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheFees: Cache<Fee>
    standaloneFinancialInvoices: StandaloneFinancialInvoice[]
}

export function FinancialInvoiceListRoute(props: Props) {
    let location = useLocation()
    document.title = getTranslation('MONITORING.ROUTES.FINANCIAL_INVOICE.LIST_TITLE')

    let date = new Date()
    let today = date.getDate()

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {to: `/dashboard/financial-invoices/`, content: <>Finances</>},
                ]}
            />

            <FinancialInvoiceListView
                token={props.token}
                me={props.me}
                standaloneFinancialInvoices={props.standaloneFinancialInvoices}
                cacheCustomers={props.cacheCustomers}
                cacheFees={props.cacheFees}
                showReminderBtn={today >= 1 && today <= 10}
            />
        </>
    )
}