import React from "react";

import {Budget, Provider, ProviderAccount} from "../../../../models";
import BudgetInput from "../../../dummies/input/budget";
import {Cache} from "@boomrank/react-components";
import {ProviderIcon} from "../../../dummies/icon/provider";


interface Props {
    providerAccount: ProviderAccount
    cacheProviders: Cache<Provider>
    onChange: (providerAccount: ProviderAccount) => void
    disabled?: boolean
}

export function ProviderAccountBasisBudgetForm(props: Props) {
    let disabled = props.disabled || false

    let provider = props.cacheProviders.values()
        .find(p => p.id === props.providerAccount.providerId)

    let budgetUpdater = (budget: Budget) => {
        props.onChange({
            ...props.providerAccount,
            budgetMin: budget.budgetMin,
            budgetMax: budget.budgetMax,
        });
    }


    return (
        <div className={'w-full py-4 lg:flex items-center justify-end lg:py-0.5'}>
            <div className={'lg:w-96 lg:pl-2'}>
                {
                    provider &&
                    <p className={'mb-0'}>
                        <ProviderIcon providerName={provider.name} />
                        <span className={'font-medium'}>{
                            props.providerAccount.name ?
                                props.providerAccount.name :
                                props.providerAccount.loginId
                        }</span>
                    </p>
                }
            </div>

            <BudgetInput
                budgetMin={props.providerAccount.budgetMin}
                budgetMax={props.providerAccount.budgetMax}
                budgetUpdater={budgetUpdater}
                className={'w-full pl-6 md:pl-14 lg:pl-0 lg:w-fit lg:flex'}
                disabled={disabled}
            />
        </div>
    )
}
