import React from "react";
import {Link, useLocation} from "react-router-dom";

import {ExportApi} from "../../../../services/export/api";

import {JwtToken, Column, ColumnType, FullTable, PaginateAdvancedTable} from "@boomrank/react-components";
import {defaultLanguage, getTranslation, translator} from "../../../../intl";
import classNames from "classnames";
import {StandaloneInvoice} from "../../../../models/standalone";
import {Account} from "../../../../models";
import {TableLoader} from "../../../dummies/loaders/table";


interface Props {
    token: JwtToken
    standaloneInvoices: StandaloneInvoice[]
    columns: (keyof StandaloneInvoice)[]
    me?: Account
    isLoading?: boolean
}

export function InvoicesTableView(props: Props) {
    let location = useLocation()
    let isLoading = props.isLoading || false

    let columns = props.columns

    let defaultColumns: Column<StandaloneInvoice>[] = [
        {
            header: getTranslation('MONITORING.VIEWS.INVOICE.TABLE.REF'),
            type: ColumnType.STRING,
            accessor: 'ref',
            cell: (row: StandaloneInvoice, value: any) => {
                return (
                    <Link
                        to={`/dashboard/customer/${row.customerId}/invoices/${row.id}/edit`}
                        className={classNames(
                            'w-full py-1 px-2 rounded-md xs:my-0 sm:my-0 text-white no-underline',
                            {'btn-gray': row.submittedAt !== null},
                            {'btn-green': row.submittedAt === null},
                        )}
                        title={
                            row.submittedAt !== null ?
                                getTranslation('MONITORING.VIEWS.INVOICE.TABLE.TITLE.READ') :
                                getTranslation('MONITORING.VIEWS.INVOICE.TABLE.TITLE.UPDATE')
                        }
                    >
                        {value}
                    </Link>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.INVOICE.TABLE.ACCOUNT_NAME'),
            type: ColumnType.STRING,
            accessor: "accountName",
        },
        {
            header: getTranslation('MONITORING.VIEWS.INVOICE.TABLE.REF_DATE'),
            type: ColumnType.DATE,
            accessor: "refDate",
            cell: (row: StandaloneInvoice, value: any) => {
                return value.toLocaleDateString(
                    defaultLanguage,
                    {month: "long", year: "numeric"}
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.INVOICE.TABLE.INVOICE_TYPE'),
            type: ColumnType.STRING,
            accessor: "invoiceTypeName",
        },
        {
            header: getTranslation('MONITORING.VIEWS.INVOICE.TABLE.TOTAL_ITEMS'),
            type: ColumnType.NUMBER,
            accessor: "totalItems",
            cell: (row: StandaloneInvoice, value: any) => {
                return value.toLocaleString(
                    defaultLanguage,
                    {style: 'currency', currency: 'EUR'}
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.INVOICE.TABLE.CREATED_AT'),
            type: ColumnType.DATE,
            accessor: "createdAt",
            cell: (row: StandaloneInvoice, value: any) => {
                return value.toLocaleDateString(defaultLanguage)
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.INVOICE.TABLE.UPDATED_AT'),
            type: ColumnType.DATE,
            accessor: "updatedAt",
            cell: (row: StandaloneInvoice, value: any) => {
                return value.toLocaleDateString(defaultLanguage)
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.INVOICE.TABLE.SUBMITTED_AT'),
            type: ColumnType.STRING,
            accessor: "isSubmitted",
            cell: (row: StandaloneInvoice, value: any) => {
                if (value && row.submittedAt) {
                    return (
                        <div className={'w-full'}>
                            <span className={'text-br-green font-medium'}>{value} </span>
                            <span className={'text-gray-500 text-light'}>({row.submittedAt.toLocaleDateString(defaultLanguage)})</span>
                        </div>
                    )
                }

                return (
                    <div className={'w-full text-red-500 font-medium'} data-testid={'invoice-table-unsubmitted-icon'}>
                        {value}
                    </div>
                )
            }
        },
    ]

    if (!location.pathname.includes('customer')) {
        defaultColumns.splice(
            1, // start : index to insert item
            0, // deleteCount
            {
                header: getTranslation('MONITORING.VIEWS.INVOICE.TABLE.CUSTOMER_NAME'),
                type: ColumnType.STRING,
                accessor: "customerName",
            },
        )
    }

    let onClickExport = (invoices: StandaloneInvoice[]) => {
        let items: any[] = []

        if (invoices.length > 0) {
            invoices.forEach((invoice) => {
                invoice.invoiceItems.forEach((item) => {
                    items.push({
                        'ID Facture': invoice.id,
                        'Date': invoice.refDate.toLocaleDateString(
                            defaultLanguage, {month: "2-digit", year: 'numeric'}
                        ),
                        'Compte': invoice.customerName,
                        'Consultant': invoice.accountName,
                        'Reference code analytic': invoice.ref,
                        'Type': invoice.invoiceTypeName,
                        'Support': item.label,
                        'Prix HT': item.priceHt,
                        'Validee': invoice.isSubmitted,
                        'Prix HT total' : invoice.totalItems,
                    })
                })
            })

            if (items.length > 0) {
                ExportApi.csv(
                    props.token,
                    items.sort((a, b) => a['ID Facture'] - b['ID Facture']),
                    'monitoring_sea_factures.csv'
                )
            }
        }
    }


    if (isLoading) {
        return <TableLoader defaultColumns={defaultColumns} />
    }

    return (
        <FullTable
            rows={props.standaloneInvoices}
            formatter={() => props.standaloneInvoices}
            showColumns={columns}
            columns={defaultColumns}
            onClickExport={onClickExport}
        >
            <PaginateAdvancedTable
                translator={translator}
                mobileColumnsDisplayed={[0, 2, 3]}
                initialState={{
                    pageSize: 100
                }}
            />
        </FullTable>
    )
}