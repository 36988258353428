import React from "react";
import classNames from "classnames";

import {ProviderAccount} from "../../../../../../../models";
import {StepPeriod} from "../../../../../../../models/stepPeriod";
import {StandaloneCustomer} from "../../../../../../../models/standalone";

import {defaultLanguage, getTranslation} from "../../../../../../../intl";
import {ProviderIcon} from "../../../../../../dummies/icon/provider";


export interface Props {
    standaloneCustomer: StandaloneCustomer
    onBudgetUpdate: (providerAccount: ProviderAccount, budget: any) => void
    stepPeriod: StepPeriod
}

export function TableBodyProviderAccountsViews(props: Props) {
    let providers = [
        {id: 1, name: 'Google'},
        {id: 2, name: 'Meta'},
        {id: 3, name: 'Bing'},
        {id: 4, name: 'Pinterest'},
    ]

    let getPeriodBudget = (budget: number) => {
        if (budget && props.stepPeriod.period.key === '1W') {
            let month = props.stepPeriod.period.end.getMonth()
            let year = props.stepPeriod.period.end.getFullYear()
            let lastDay = new Date(year, month +1, 0).getDate()

            return (budget / lastDay) * 7
        }
        return budget
    }

    let setProviderName = (providerId: number) => {
        let provider = providers.find(p => p.id === providerId)
        return provider!.name
    }

    let setLocaleMetrics = (value: number | null) => {
        if (value) {
            return value.toLocaleString(
                defaultLanguage,
                { style: 'currency', currency: 'EUR'}
            )
        }
        return '-'
    }

    let setDatesInRange = (start: Date, end: Date) => {
        const date = new Date(start.getTime());
        const dates = [];

        while (date <= end) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return dates;
    }

    let setSuggested = (budget: number, cost: number, dates: Date[]) => {
        let remainingDays = dates.length

        let yesterday = new Date().getDate() - 1
        let date = dates.map((d) => d.getDate()).indexOf(yesterday)

        if (date !== -1) {
            remainingDays = dates.length - (date + 1)
        }

        return (budget - cost) / remainingDays
    }

    let onChangeBudget = (providerAccount: ProviderAccount, budget: any) => {
        props.onBudgetUpdate(providerAccount, budget)
    }


    return (
        <div className={'w-3/5'}>
            {
                props.standaloneCustomer.providerAccounts.map((pa, idx) => {
                    let providerName = setProviderName(pa.providerId)

                    let budgetMax: string | number = '-'
                    let suggested: string | number = '-'

                    if (pa.budgetMax) {
                        budgetMax = getPeriodBudget(pa.budgetMax)

                        if (pa.cost) {
                            let dates = setDatesInRange(
                                props.stepPeriod.period.start,
                                props.stepPeriod.period.end
                            )
                            suggested = setSuggested(budgetMax, pa.cost, dates)
                        }
                    }


                    return (
                        <div
                            key={idx}
                            className={
                                'w-full py-1 flex items-center justify-between ' +
                                'border-b border-gray-200 last:border-0'
                            }
                        >
                            <span className={'w-14 text-right'} title={pa.loginId}>
                                {
                                    <ProviderIcon
                                        providerName={providerName}
                                        loginId={pa.name ? pa.name : pa.loginId}
                                    />
                                }
                            </span>

                            <input
                                type={"number"}
                                name={"budgetMax"}
                                defaultValue={pa.budgetMax === null ? '' : pa.budgetMax}
                                placeholder={getTranslation('MONITORING.VIEWS.CUSTOMER.OPERATIONAL.BUDGET')}
                                onBlur={(event) => onChangeBudget(pa, event.target.value)}
                                className={'w-1/5 bg-transparent text-right'}
                            />

                            <span className={'w-1/5 text-right'}>
                                {setLocaleMetrics(pa.cost)}
                            </span>

                            <span className={'w-1/5 text-right'}>
                                {setLocaleMetrics(pa.trend)}
                            </span>

                            <span className={classNames(
                                'w-1/5 font-medium text-right',
                                {'text-red-500': typeof suggested === 'number' && suggested < 0},
                                {'text-br-green': typeof suggested === 'number' && suggested > 0},
                            )}>
                                {
                                    suggested.toLocaleString(
                                        defaultLanguage,
                                        {style: 'currency', currency: 'EUR'}
                                    )
                                }
                            </span>
                        </div>
                    )
                })
            }

            {
                props.standaloneCustomer.providerAccounts.length === 0 &&
                <div className={'w-full py-1 flex items-center justify-between'}>
                    <span className={'w-14 text-right'}>-</span>
                    <span className={'w-1/5 text-right'}>-</span>
                    <span className={'w-1/5 text-right'}>-</span>
                    <span className={'w-1/5 text-right'}>-</span>
                    <span className={'w-1/5 text-right'}>-</span>
                </div>
            }
        </div>
    )
}