import React, {useEffect, useMemo, useState} from "react";
import {Link, useLocation} from "react-router-dom";

import {StandaloneCampaign} from "../../../../models/standalone";
import {ExportApi} from "../../../../services/export/api";

import {JwtToken, Column, ColumnType, FullTable, PaginateAdvancedTable} from "@boomrank/react-components";
import {defaultLanguage, getTranslation, translator} from "../../../../intl";
import {ProviderIcon} from "../../../dummies/icon/provider";
import {TableLoader} from "../../../dummies/loaders/table";
import {StepPeriod} from "../../../../models/stepPeriod";


interface Props {
    token: JwtToken
    standaloneCampaigns: StandaloneCampaign[]
    columns: (keyof StandaloneCampaign)[]
    stepPeriod: StepPeriod
    campaignGroupName?: string
    isLoading?: boolean
}

export function CampaignsTableView(props: Props) {
    let location = useLocation()
    let isLoading = props.isLoading || false

    let filteredStandaloneCampaigns = props.standaloneCampaigns.filter(s => s.cost > 0)

    let [standaloneCampaigns, setStandaloneCampaigns] = useState<StandaloneCampaign[]>([])
    let [allCampaigns, setAllCampaigns] = useState<boolean>(false)

    let localeCurrency = (value: any) => {
        if (value) {
            return value.toLocaleString(
                defaultLanguage,
                {style: 'currency', currency: 'EUR'}
            )
        }
        return '-'
    }

    let localeDate = (date: Date) => {
        return date.toLocaleDateString(
            defaultLanguage,
            {day: "2-digit", month: "2-digit", year: 'numeric'}
        )
    }

    let defaultColumns: Column<StandaloneCampaign>[] = [
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN.TABLE.NAME'),
            type: ColumnType.STRING,
            accessor: 'name',
            cell: (row: StandaloneCampaign, value: any) => {
                return (
                    <Link
                        to={`/dashboard/customer/${row.customerId}/campaign/${row.id}`}
                        state={{
                            previousPath: location.pathname,
                            campaignGroupName: props.campaignGroupName
                        }}
                        className={'w-full xs:my-0 sm:my-0'}
                        title={'Voir les statistiques de la campagne'}
                    >
                        {value}
                    </Link>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN.TABLE.STATUS'),
            type: ColumnType.STRING,
            accessor: "status",
            cell: (row: StandaloneCampaign, value: any) => {
                return (
                    <div className={'w-full'}>
                        <i className={
                            getTranslation(`MONITORING.VIEWS.CAMPAIGN.ICON_STATUS.${value}`)
                        } />
                        <span className={'pl-2 text-slate-500'}>
                            {getTranslation(`MONITORING.VIEWS.CAMPAIGN.STATUS.${value}`)}
                        </span>
                    </div>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN.TABLE.CAMPAIGN_TYPE'),
            type: ColumnType.STRING,
            accessor: "campaignType",
            cell: (row: StandaloneCampaign, value: any) => {
                return (
                    <div className={'w-full'}>
                        {value.toLowerCase()}
                    </div>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN.TABLE.PROVIDER_NAME'),
            type: ColumnType.STRING,
            accessor: "provider",
            cell: (row: StandaloneCampaign, value: any) => {
                return (
                    <div className={'w-36'}>
                        <ProviderIcon providerName={value} />
                    </div>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN.TABLE.COST'),
            type: ColumnType.NUMBER,
            accessor: "cost",
            cell: (row: StandaloneCampaign, value: any) => {
                return (
                    <div className={'w-full text-right'}>
                        {localeCurrency(value)}
                    </div>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN.TABLE.TREND'),
            type: ColumnType.NUMBER,
            accessor: "trend",
            cell: (row: StandaloneCampaign, value: any) => {
                return (
                    <div className={'w-full text-right'}>
                        {localeCurrency(value)}
                    </div>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN.TABLE.CONVERSIONS'),
            type: ColumnType.NUMBER,
            accessor: "conversions",
            cell: (row: StandaloneCampaign, value: any) => {
                if (isNaN(value)) {
                    return (
                        <div className={'w-full text-right'}>-</div>
                    )
                }
                return (
                    <div className={'w-full text-right'}>
                        {
                            value ?
                                <>{value.toFixed(0).toLocaleString(defaultLanguage)}</> :
                                <>-</>
                        }
                    </div>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN.TABLE.COST_PER_CONVERSION'),
            type: ColumnType.NUMBER,
            accessor: "conversionsValue",
            cell: (row: StandaloneCampaign, value: any) => {
                if (isNaN(value)) {
                    return (
                        <div className={'w-full text-right'}>-</div>
                    )
                }
                return (
                    <div className={'w-full text-right'}>
                        {localeCurrency(value)}
                    </div>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN.TABLE.IMPRESSIONS'),
            type: ColumnType.NUMBER,
            accessor: "impressions",
            cell: (row: StandaloneCampaign, value: any) => {
                if (isNaN(value)) {
                    return (
                        <div className={'w-full text-right'}>-</div>
                    )
                }
                return (
                    <div className={'w-full text-right'}>
                        {
                            value ?
                                <>{value.toFixed(0).toLocaleString(defaultLanguage)}</> :
                                <>-</>
                        }
                    </div>
                )
            }
        },
    ]

    let onClickExport = (campaigns: StandaloneCampaign[]) => {
        if (campaigns.length > 0) {
            let exportCampaigns = campaigns.map((c) => {
                return {
                    'ID': c.id,
                    'Debut': localeDate(props.stepPeriod.period.start),
                    'Fin': localeDate(props.stepPeriod.period.end),
                    'Compte': c.customerName,
                    'Levier': c.provider,
                    'Identifiant': c.loginId,
                    'Campagne': c.name,
                    'Type': c.campaignType,
                    'Cout': c.cost,
                    'Trend': c.trend,
                    'Conversions': c.conversions,
                    'Cout par conversion': c.conversionsValue,
                    'Impressions': c.impressions,
                }
            }).sort((a, b) => a.Cout - b.Cout)

            ExportApi.csv(
                props.token,
                exportCampaigns,
                `monitoring_sea_campaigns.csv`
            )
        }
    }

    const sortBy = useMemo(() => [
        { id: "cost", desc: true }
    ], []);

    let showAllCampaigns = (value: boolean) => {
        setAllCampaigns(value)

        if (value) {
            setStandaloneCampaigns(props.standaloneCampaigns)
        } else {
            setStandaloneCampaigns(filteredStandaloneCampaigns)
        }
    }

    useEffect(() => {
        setStandaloneCampaigns(filteredStandaloneCampaigns)
    }, [props.standaloneCampaigns]);


    if (isLoading) {
        return <TableLoader defaultColumns={defaultColumns} />
    }

    return (
        <div className={'w-full mt-10'}>
            <div className={'w-full flex items-start gap-x-2'}>
                <button
                    className={!allCampaigns ? 'btn-green p-2 rounded-sm' : 'btn-blue p-2 rounded-sm'}
                    onClick={() => showAllCampaigns(false)}
                >
                    {getTranslation('MONITORING.VIEWS.CAMPAIGN.TABLE_BUTTONS.FILTERED')}
                </button>
                <button
                    className={allCampaigns ? 'btn-green p-2 rounded-sm' : 'btn-blue p-2 rounded-sm'}
                    onClick={() => showAllCampaigns(true)}
                >
                    {getTranslation('MONITORING.VIEWS.CAMPAIGN.TABLE_BUTTONS.ALL')}
                </button>
            </div>

            <FullTable
                rows={standaloneCampaigns}
                formatter={() => standaloneCampaigns}
                showColumns={props.columns}
                columns={defaultColumns}
                onClickExport={onClickExport}
            >
                <PaginateAdvancedTable
                    translator={translator}
                    mobileColumnsDisplayed={[0, 2, 3]}
                    initialState={{
                        sortBy: sortBy,
                        pageSize: 100
                    }}
                />
            </FullTable>
        </div>
    )
}