import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {LogListResponse} from "./responses";
import {endpoints} from "../api";


export class LogApi {
    static list(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | LogListResponse> {
        let url = endpoints.log.list
        if (baseUrl) {
            url = baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }
}