import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {
    Account,
    AccountHasCustomer,
    CampaignGroup,
    Customer,
    Identifier,
    Invoice,
    Notification,
    ProviderAccount,
} from "../../../models";
import {StatCustomer} from "../../../models/stats";
import {StepPeriod} from "../../../models/stepPeriod";
import {StandaloneCustomer} from "../../../models/standalone";

import {StatCustomerApi} from "../../../services/stats/customer/api";
import {StatCustomerListResponse} from "../../../services/stats/customer/responses";
import {StatCustomerFactory} from "../../../services/stats/customer/factory";
import {StandaloneCustomerFactory} from "../../../services/customer/factory";

import {Cache, JwtToken, PlaceHolder} from "@boomrank/react-components";
import {StepPeriodSelect} from "../../dummies/select/stepPeriod";
import {getTranslation} from "../../../intl";
import {UnreadNotificationListView} from "../../notification/views/list-unread";
import {InvoiceView} from "./invoice";
import {CustomerListAsTableView} from "./customer/list/operational";
import {CustomerListAsCardView} from "./customer/list/card";
import {DashboardThemeToggle} from "../../dummies/toggle/dashboard-theme";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheAccounts: Cache<Account>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheProviderAccounts: Cache<ProviderAccount>
    cacheCampaignGroups: Cache<CampaignGroup>
    cacheInvoices: Cache<Invoice>
    cacheNotifications: Cache<Notification>
    cacheUpdater: (obj: Identifier, remove?:boolean) => void
    stepPeriod: StepPeriod
    onStepPeriodChange: (stepPeriod: StepPeriod) => void
}

export function HomeView(props: Props) {
    let [isLoading, setIsLoading] = useState(false)
    let [operationalView, setOperationalView] = useState<boolean>(true)

    let [accountCustomers, setAccountCustomers] = useState<StandaloneCustomer[]>([])
    let [followedCustomers, setFollowedCustomers] = useState<StandaloneCustomer[]>([])
    let [companyCustomers, setCompanyCustomers] = useState<StandaloneCustomer[]>([])

    let [statCustomers, setStatCustomers] = useState<StatCustomer[]>([])

    let customerFormatter = (customers: Customer[]) => {
        let standaloneCustomers = customers.map((customer) => {
            return StandaloneCustomerFactory.fromCustomer(
                customer,
                props.cacheAccountHasCustomers.values(),
                props.cacheProviderAccounts.values(),
                statCustomers
            )
        }).sort((a, b) => a.name.localeCompare(b.name))

        // All company's customers
        setCompanyCustomers(standaloneCustomers)

        // Current account's customers
        setAccountCustomers(
            standaloneCustomers.filter((s) => s.account === props.me.id)
        )

        getFollowedCustomers(standaloneCustomers)
    }


    let getFollowedCustomers = (standaloneCustomers: StandaloneCustomer[]) => {
        let customers = standaloneCustomers
            .filter((s) => (s.accountIds.indexOf(props.me.id) !== -1) && (s.account !== props.me.id))
            .map(customer => customer)
        setFollowedCustomers(customers)
    }

    let getStats = (selected: StepPeriod) => {
        setIsLoading(true);
        setStatCustomers([]);

        StatCustomerApi.list(
            props.token,
            selected.period.start,
            selected.period.end,
            selected.stepTable
        ).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as StatCustomerListResponse;

                let stats = res.data.map((fragment) => {
                    return StatCustomerFactory.fromFragment(fragment)
                })
                setStatCustomers(stats)
            }
        })
        setIsLoading(false)
    }

    const rangeChangeHandler = (selected: StepPeriod) => {
        props.onStepPeriodChange(selected);
        getStats(selected);
    }

    useEffect(() => {
        customerFormatter(props.cacheCustomers.values())
    }, [
        props.cacheCustomers,
        props.cacheAccountHasCustomers,
        props.cacheProviderAccounts,
        statCustomers,
    ]);


    if (isLoading || companyCustomers.length === 0) {
        return <PlaceHolder className={'h32'}/>
    }

    return (
        <div className={'mt-6 w-full'}>
            <div className={'w-full md:flex md:items-center md:justify-between'}>
                {
                    props.cacheCustomers.values() &&
                    <StepPeriodSelect
                        onChange={rangeChangeHandler}
                        stepPeriod={props.stepPeriod}
                    />
                }

                <DashboardThemeToggle onToggle={setOperationalView} />

                <Link
                    to={'/dashboard/customer/add'}
                    className={'btn-blue no-underline p-2 text-center rounded-sm md:w-56'}
                >
                    {getTranslation('MONITORING.VIEWS.HOME.BUTTONS.ADD_CUSTOMER')}
                </Link>
            </div>

            <div className={'mt-6 flex flex-col-reverse gap-y-3 lg:flex-row lg:gap-x-4 lg:gap-y-0'}>
                {
                    operationalView &&
                    <CustomerListAsTableView
                        token={props.token}
                        me={props.me}
                        accountCustomers={accountCustomers}
                        followedCustomers={followedCustomers}
                        companyCustomers={companyCustomers}
                        cacheUpdater={props.cacheUpdater}
                        stepPeriod={props.stepPeriod}
                    />
                }

                {
                    !operationalView &&
                    <CustomerListAsCardView
                        token={props.token}
                        me={props.me}
                        accountCustomers={accountCustomers}
                        followedCustomers={followedCustomers}
                        companyCustomers={companyCustomers}
                        cacheUpdater={props.cacheUpdater}
                        stepPeriod={props.stepPeriod}
                    />
                }

                <div className={'w-full h-min lg:w-1/3 flex flex-col gap-y-4'}>
                    <InvoiceView
                        token={props.token}
                        me={props.me}
                        cacheCustomers={props.cacheCustomers}
                        cacheAccounts={props.cacheAccounts}
                        cacheInvoices={props.cacheInvoices}
                        cacheAccountHasCustomers={props.cacheAccountHasCustomers}
                        reminder={true}
                    />

                    <UnreadNotificationListView
                        token={props.token}
                        cacheCustomers={props.cacheCustomers}
                        cacheNotifications={props.cacheNotifications}
                        cacheUpdater={props.cacheUpdater}
                    />
                </div>
            </div>
        </div>
    )
}