import React from "react";

import {getTranslation} from "../../../../../../intl";
import {CardCumulativeStatCustomer} from "../../../../../dummies/card/customer/cumulative-stats";
import {StandaloneCustomer} from "../../../../../../models/standalone";
import {Account, Identifier} from "../../../../../../models";
import {JwtToken} from "@boomrank/react-components";
import {StepPeriod} from "../../../../../../models/stepPeriod";
import {CustomerListOperationalView} from "./table";

export interface Props {
    token: JwtToken
    me: Account
    accountCustomers: StandaloneCustomer[]
    followedCustomers: StandaloneCustomer[]
    companyCustomers: StandaloneCustomer[]
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
    stepPeriod: StepPeriod
}

export function CustomerListAsTableView(props: Props) {
    return (
        <div className={'w-full lg:w-2/3 pb-6'} data-testid={'dashboard-tables-theme'}>
            {
                props.accountCustomers.length > 0 &&
                <div className={'w-full py-6 px-5 border-2 border-slate-200 rounded-xl'}>
                    <CardCumulativeStatCustomer
                        standaloneCustomers={props.accountCustomers}
                    />

                    <CustomerListOperationalView
                        token={props.token}
                        me={props.me}
                        standaloneCustomers={props.accountCustomers}
                        cacheUpdater={props.cacheUpdater}
                        stepPeriod={props.stepPeriod}
                    />
                </div>
            }

            {
                props.followedCustomers.length > 0 &&
                <div className={'w-full pb-6 px-5 mt-4 border-2 border-slate-200 rounded-xl'}>
                    <h3 className={'pt-6 text-xl cursor-pointer'}>
                        {
                            getTranslation('MONITORING.VIEWS.HOME.SUMMARY.FOLLOWED_CUSTOMERS')
                        } ({props.followedCustomers.length})
                    </h3>

                    <CustomerListOperationalView
                        token={props.token}
                        me={props.me}
                        standaloneCustomers={props.followedCustomers}
                        cacheUpdater={props.cacheUpdater}
                        stepPeriod={props.stepPeriod}
                    />

                </div>
            }

            <details
                className={'w-full pb-6 px-5 mt-4 border-2 border-slate-200 rounded-xl'}
                open={props.accountCustomers.length === 0 && props.followedCustomers.length === 0}
            >
                <summary className={'pt-6 border-b border-white text-xl cursor-pointer'}>
                    {
                        getTranslation('MONITORING.VIEWS.HOME.SUMMARY.COMPANY_CUSTOMERS')
                    } ({props.companyCustomers.length})
                </summary>

                <CustomerListOperationalView
                    token={props.token}
                    me={props.me}
                    standaloneCustomers={props.companyCustomers}
                    cacheUpdater={props.cacheUpdater}
                    stepPeriod={props.stepPeriod}
                    useSearch={true}
                />
            </details>
        </div>
    )
}