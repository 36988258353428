import React, {useEffect, useState} from "react";
import {Checkbox, Cache} from "@boomrank/react-components";
import {Account, AccountHasCustomer, Customer} from "../../../../models";
import {v4} from "uuid";


interface Props {
    customer: Customer
    cacheAccounts: Cache<Account>
    accountHasCustomers?: AccountHasCustomer[]
    onChangeAccountIds: (hasAccount: number[]) => void
    disabled?: boolean
}

export function AccountHasCustomerForm(props: Props) {
    let [accountIds, setAccountIds] = useState<number[]>([]);

    let onAddAccount = (name: string, value: number) => {
        setAccountIds([value, ...accountIds])
        props.onChangeAccountIds([value, ...accountIds])
    }

    let onRemoveAccount = (name: string, value: number) => {
        let ids = [...accountIds]

        let index = ids.indexOf(value)
        if (index === -1) {
            ids.push(value)
        } else {
            ids.splice(index, 1)
        }
        setAccountIds(ids)
        props.onChangeAccountIds(ids)
    }


    useEffect(() => {
        if (props.accountHasCustomers) {
            setAccountIds(props.accountHasCustomers.map(ahc => ahc.accountId))
        }
    }, [props.accountHasCustomers])


    return (
        <div className={'mt-3 grid grid-cols-2 gap-3 h-80'}>
            <div className={'max-h-80 overflow-y-auto px-3 py-1'}>
                {
                    accountIds.length > 0 &&
                    accountIds.map((accountId) => {
                        let accounts = props.cacheAccounts.get(accountId)
                        if (accounts.length > 0) {
                            let account = accounts[0]
                            return (
                                <div className={'mb-3 select-none bg-white'} key={account.id}>
                                    <Checkbox
                                        label={`${account.user.firstname} ${account.user.lastname}`}
                                        name={'accountHasCustomer'}
                                        onClick={onRemoveAccount}
                                        value={account.id}
                                        alias={`${account.user.firstname.slice(0, 1)}${account.user.lastname.slice(0, 1)}`}
                                        isChecked={true}
                                        isDisabled={props.disabled!}
                                    />
                                </div>
                            )
                        }
                        return <div key={v4()}></div>
                    })
                }
            </div>

            <div className={'max-h-80 overflow-y-auto px-3 py-1'}>
                {
                    props.cacheAccounts.values().map((account) => {
                        if (accountIds.indexOf(account.id) === -1) {
                            return (
                                <div className={'mb-3 select-none'} key={account.id}>
                                    <Checkbox
                                        label={`${account.user.firstname} ${account.user.lastname}`}
                                        name={'accountHasCustomer'}
                                        onClick={onAddAccount}
                                        value={account.id}
                                        alias={`${account.user.firstname.slice(0, 1)}${account.user.lastname.slice(0, 1)}`}
                                        isChecked={(accountIds.indexOf(account.id) !== -1)}
                                        isDisabled={props.disabled!}
                                    />
                                </div>
                            )
                        }
                        return <div key={v4()}></div>
                    })
                }
            </div>
        </div>
    )
}