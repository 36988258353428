import React from "react";
import {Cache} from "@boomrank/react-components";

import {Account, Provider} from "../../../../models";
import {StandaloneCampaignGroup, StandaloneCustomer} from "../../../../models/standalone";
import {StepPeriod} from "../../../../models/stepPeriod";
import {StatCampaignGroup} from "../../../../models/stats";

import {CardTemplate} from "../index";
import {CardIconSection} from "../icon";
import {CardBudgetSection} from "../budget";
import {getTranslation} from "../../../../intl";


interface Props {
    standaloneCustomer: StandaloneCustomer
    standaloneCampaignGroups: StandaloneCampaignGroup[]
    statCampaignGroups: StatCampaignGroup[]
    cacheAccounts: Cache<Account>
    cacheProviders: Cache<Provider>
    stepPeriod: StepPeriod
    isLoading?: boolean
}

export function CardCampaignGroups(props: Props) {
    return (
        <CardTemplate
            title={getTranslation('DUMMIES.CARD.CAMPAIGN_GROUP.LIST.TITLE')}
            isLoading={props.isLoading || false}
        >
            <div className={'h-full flex flex-col justify-between'}>
                <CardBudgetSection
                    cost={props.statCampaignGroups.reduce(
                        (cost, stat) => cost + stat.cost, 0
                    )}
                    trend={props.statCampaignGroups.reduce(
                        (trend, stat) => trend + stat.trend, 0
                    )}
                    budgetMin={props.standaloneCampaignGroups.reduce(
                        (min, cg) => min + cg.budgetMin!, 0
                    )}
                    budgetMax={props.standaloneCampaignGroups.reduce(
                        (max, cg) => max + cg.budgetMax!, 0
                    )}
                    stepPeriod={props.stepPeriod}
                    legend={true}
                />

                <CardIconSection
                    standaloneCustomer={props.standaloneCustomer}
                    cacheAccounts={props.cacheAccounts}
                    cacheProviders={props.cacheProviders}
                />
            </div>
        </CardTemplate>
    )
}