import React, {useEffect, useState} from "react";

import {Customer, Provider, ProviderAccount} from "../../../../models";
import {Cache} from "@boomrank/react-components";
import {FormNotification} from "../models";
import {FormEvents} from "../../../dummies/form/events";
import {getTranslation} from "../../../../intl";
import {FormBudgets} from "../../../dummies/form/budgets";
import {FormMessage} from "../../../dummies/form/message";
import {Flag, FlaggedProviderAccount} from "../../views/budget/models";
import classNames from "classnames";
import {ProviderAccountBasisBudgetForm} from "./basis-budget";


interface Props {
    customer: Customer
    cacheProviders: Cache<Provider>
    providerAccounts: ProviderAccount[]
    onSubmit: (flaggedProviderAccounts: FlaggedProviderAccount[]) => void
    disabled: boolean
    message: FormNotification
}

export function BudgetProviderAccountForm(props: Props) {
    let [flaggedProviderAccounts, setFlaggedProviderAccounts] = useState<FlaggedProviderAccount[]>([])

    let onChange = (providerAccount: ProviderAccount) => {
        let flagged = flaggedProviderAccounts.find(fpa => fpa.providerAccount.id === providerAccount.id)

        if (flagged) {
            if (flagged.flag === Flag.NO_CHANGES) {
                flagged.flag = Flag.UPDATED
            }
            flagged.providerAccount = providerAccount
        }

        setFlaggedProviderAccounts([...flaggedProviderAccounts])
    }

    let onSubmit = (flaggedProviderAccounts: FlaggedProviderAccount[]) => {
        props.onSubmit(flaggedProviderAccounts)
    }

    useEffect(() => {
        let flaggedPas = props.providerAccounts.map((providerAccount: ProviderAccount) => {
            let flaggedPa = new FlaggedProviderAccount()
            flaggedPa.flag = Flag.NO_CHANGES
            flaggedPa.providerAccount = providerAccount
            return flaggedPa
        })

        flaggedPas.reverse()
        setFlaggedProviderAccounts(flaggedPas)
    }, [props.providerAccounts])


    return (
        <div>
            <div className={'w-full mt-6 py-6 px-5 border-2 border-slate-200 rounded-xl'}>
                <FormEvents
                    className={'w-full flex justify-end gap-x-1 mb-3'}
                    onSubmit={() => onSubmit(flaggedProviderAccounts)}
                    submitTitle={getTranslation('MONITORING.FORMS.PROVIDER_ACCOUNT.SUBMIT_BUTTON')}
                    disabled={props.disabled}
                />

                <div className={'w-full my-3 lg:flex-col lg:items-end'}>
                    <div className={'w-full my-3 lg:flex lg:justify-between lg:mb-3'}>
                        <h3 className={'w-full lg:w-2/4 my-0'}>{
                            getTranslation('MONITORING.FORMS.PROVIDER_ACCOUNT.TITLE')
                        }</h3>

                        <FormBudgets
                            parentBudget={props.customer.budgetMax}
                            budgetMin={flaggedProviderAccounts.reduce(
                                (cost, flagged) => cost + flagged.providerAccount.budgetMin!, 0
                            )}
                            budgetMax={flaggedProviderAccounts.reduce(
                                (cost, flagged) => cost + flagged.providerAccount.budgetMax!, 0
                            )}
                            budgetMinError={getTranslation('MONITORING.FORMS.PROVIDER_ACCOUNT.ERRORS.BUDGET_MIN')}
                            budgetMaxError={getTranslation('MONITORING.FORMS.PROVIDER_ACCOUNT.ERRORS.BUDGET_MAX')}
                        />
                    </div>

                    <FormMessage formNotification={props.message} />

                    {
                        flaggedProviderAccounts.length > 0 &&
                        flaggedProviderAccounts.map((fpa: FlaggedProviderAccount) => (
                            <div key={fpa.providerAccount.id} className={classNames(
                                'w-full odd:bg-white even:bg-slate-50',
                                {'text-gray-500 font-medium': fpa.flag !== Flag.NO_CHANGES}
                            )}>
                                <ProviderAccountBasisBudgetForm
                                    providerAccount={fpa.providerAccount}
                                    cacheProviders={props.cacheProviders}
                                    onChange={onChange}
                                    disabled={props.disabled}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
