import React, {useEffect, useState} from "react";

import {
    Account,
    AccountHasCustomer,
    Customer,
    Identifier,
    Provider,
    ProviderAccount,
} from "../../../../models";
import {StandaloneProviderAccount} from "../../../../models/standalone";
import {FormNotification} from "../../forms/models";

import {CustomerApi} from "../../../../services/customer/api";
import {CustomerUpdateResponse} from "../../../../services/customer/responses";
import {CustomerFactory} from "../../../../services/customer/factory";

import {CustomerHasAccountApi} from "../../../../services/customer/has-account/api";
import {AccountHasCustomerListResponse} from "../../../../services/customer/has-account/responses";
import {AccountHasCustomerFactory} from "../../../../services/customer/has-account/factory";

import {FormErrorResponse, Cache, JwtToken} from "@boomrank/react-components";
import {Errors} from "../../../dummies/errors";

import {getTranslation} from "../../../../intl";
import {ProviderAccountEditView} from "../provider-account";
import {CustomerForm} from "../../forms/customer";
import {CustomerStateForm} from "../../forms/customer/state";
import {CustomerLinks} from "../../../dummies/customer/links";
import {FormMessage} from "../../../dummies/form/message";
import {CustomerDeleteView} from "./delete";
import {CustomerExportStatView} from "./export";


interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    accountHasCustomers: AccountHasCustomer[]
    providerAccounts: ProviderAccount[]
    standaloneProviderAccounts: StandaloneProviderAccount[]
    cacheAccounts: Cache<Account>
    cacheProviders: Cache<Provider>,
    cacheUpdater: (obj: Identifier, remove: boolean) => void
}

export function CustomerEditView(props: Props) {
    let [loading, setLoading] = useState<boolean>(false)
    let [errors, setErrors] = useState<Errors>({})
    let [message, setMessage] = useState(new FormNotification())

    let getCustomerHasAccounts = (customer: Customer) => {
        CustomerHasAccountApi.list(
            props.token,
            customer.id
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as AccountHasCustomerListResponse;

                res.data.results.forEach((fragment) => {
                    props.cacheUpdater(AccountHasCustomerFactory.fromFragment(fragment), false)
                })
            }
            setLoading(false);
        })
    }

    let onStateSubmit = (customer: Customer) => {
        setLoading(true)
        setMessage(message.clear())

        CustomerApi.update_state(
            props.token,
            customer.id,
            customer.state,
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as CustomerUpdateResponse;

                props.cacheUpdater(CustomerFactory.fromFragment(res.data), false)

                setMessage(
                    message.setSuccess(
                        getTranslation(`MONITORING.VIEWS.CUSTOMER.EDIT.SUCCESS.STATE_UPDATE.${customer.state}`)
                    )
                );
            } else {
                res = res as FormErrorResponse;
                setErrors(res.data);
            }
            setLoading(false);
        })
    }

    let onSubmit = (customer: Customer) => {
        setLoading(true)
        setMessage(message.clear())

        if (customer.account === 0) {
            customer.account = null
        }

        CustomerApi.update(
            props.token,
            customer.id,
            customer.name,
            customer.budgetMin,
            customer.budgetMax,
            customer.account,
            customer.accountIds,
            customer.comment,
            customer.financial_comment
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as CustomerUpdateResponse;

                let deletedAccountHasCustomer = props.accountHasCustomers
                    .filter(ahc => ahc.customerId === customer.id)
                deletedAccountHasCustomer.forEach(ahc => props.cacheUpdater(ahc, true))

                let updatedCustomer = CustomerFactory.fromFragment(res.data)
                updatedCustomer.accountIds = customer.accountIds
                props.cacheUpdater(updatedCustomer, false)

                getCustomerHasAccounts(updatedCustomer)

                setMessage(
                    message.setSuccess(
                        getTranslation('MONITORING.VIEWS.CUSTOMER.EDIT.SUCCESS.UPDATE')
                    )
                );
            } else {
                res = res as FormErrorResponse;
                setErrors(res.data);
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        getCustomerHasAccounts(props.customer);
    }, []);


    return (
        <div className={'mt-6'}>
            <div className={'flex items-center justify-end mb-10'}>
                <CustomerLinks customerId={props.customer.id} me={props.me} />
            </div>

            <div className={'w-full md:flex md:justify-end md:gap-x-6'}>
                {
                    props.me.isAdmin() &&
                    <CustomerDeleteView
                        token={props.token}
                        customer={props.customer}
                        cacheUpdater={props.cacheUpdater}
                        navigateTo={'/dashboard'}
                    />
                }
                <CustomerExportStatView
                    me={props.me}
                    token={props.token}
                    customer={props.customer}
                />
                <CustomerStateForm
                    customer={props.customer}
                    onSubmit={onStateSubmit}
                    disabled={loading}
                    errors={errors}
                />

                <div>
                    <div className={'w-full flex justify-between lg:w-80 mt-6 py-4 px-5 bg-slate-100 rounded-xl'}>
                        <span>{getTranslation('MONITORING.VIEWS.CUSTOMER.EDIT.DATE.CREATED_AT')}</span>
                        <span className={'font-medium'}>{props.customer.createdAt.toLocaleDateString(
                            'fr-FR', {day: "numeric" ,month: "long", year: "numeric"}
                        )}</span>
                    </div>
                    <div className={'w-full flex justify-between lg:w-80 mt-6 py-4 px-5 bg-slate-100 rounded-xl'}>
                        <span>{getTranslation('MONITORING.VIEWS.CUSTOMER.EDIT.DATE.LAST_UPDATE')}</span>
                        <span className={'font-medium'}>
                            {
                                props.customer.lastUpdate !== null ?
                                props.customer.lastUpdate.toLocaleDateString(
                                    'fr-FR', {day: "numeric" ,month: "long", year: "numeric"}
                                ) : '-'
                            }
                        </span>
                    </div>
                </div>
            </div>

            <FormMessage formNotification={message} />

            <CustomerForm
                me={props.me}
                customer={props.customer}
                cacheAccounts={props.cacheAccounts}
                accountHasCustomers={props.accountHasCustomers}
                onSubmit={onSubmit}
                disabled={loading}
                errors={errors}
            />

            <ProviderAccountEditView
                token={props.token}
                customer={props.customer}
                providerAccounts={props.providerAccounts}
                cacheProviders={props.cacheProviders}
                standaloneProviderAccounts={props.standaloneProviderAccounts}
                cacheUpdater={props.cacheUpdater}
            />
        </div>
    )
}