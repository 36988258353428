import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {endpoints} from "../api";
import {
    BudgetAccountGroupListResponse,
    BudgetAccountGroupCreateResponse,
    BudgetAccountGroupUpdateResponse
} from "./responses";


export class BudgetAccountGroupApi {
    static list(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | BudgetAccountGroupListResponse> {
        let url = endpoints.budgetAccountGroup.list
        if (baseUrl) {
            url = baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static create(
        token: JwtToken,
        createdAt: string,
        budgetMin: number | null,
        budgetMax: number | null,
        campaignGroupId: number,
        providerAccountId: number,
    ): Promise<FormErrorResponse | BudgetAccountGroupCreateResponse> {
        let url = endpoints.budgetAccountGroup.create

        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    created_at: createdAt,
                    budget_min: budgetMin,
                    budget_max: budgetMax,
                    campaign_group: campaignGroupId,
                    provider_account: providerAccountId,
                })
            }
        );
    }

    static update(
        token: JwtToken,
        pk: number,
        budgetMin: number | null,
        budgetMax: number | null,
        campaignGroupId: number,
        providerAccountId: number,
    ): Promise<FormErrorResponse | BudgetAccountGroupUpdateResponse> {
        let url = endpoints.budgetAccountGroup.update
            .replace(':pk', pk.toString())

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    budget_min: budgetMin,
                    budget_max: budgetMax,
                    campaign_group: campaignGroupId,
                    provider_account: providerAccountId,
                })
            }
        );
    }
}