import React from "react";

import {StepPeriod} from "../../../models/stepPeriod";

import {ProgressBar} from "../progressBar";
import {SuggestedBudget} from "../budget/suggested";
import classNames from "classnames";
import {ProgressBarLegend} from "../progressBar/legend";


interface Props {
    cost: number
    trend: number
    budgetMin: number | null
    budgetMax: number | null
    stepPeriod: StepPeriod
    hideMetrics?: boolean
    legend?: boolean
}

export function CardBudgetSection(props: Props) {
    let legend = props.legend || false

    let getPeriodBudget = (budget: number, stepPeriod: StepPeriod) => {
        if (budget && stepPeriod.period.key === '1W') {
            let month = stepPeriod.period.end.getMonth()
            let year = stepPeriod.period.end.getFullYear()
            let lastDay = new Date(year, month +1, 0).getDate()

            return (budget / lastDay) * 7
        }
        return budget
    }

    let getPeriodBudgetByTrend = (budgetMin: number, budgetMax: number, trend: number) => {
        if (!budgetMax && budgetMin && (trend < budgetMin)) {
            return getPeriodBudget(budgetMin, props.stepPeriod)
        }
        if (budgetMax){
            return getPeriodBudget(budgetMax, props.stepPeriod)
        }
    }


    return (
        <div>
            <ProgressBar
                trend={props.trend!}
                cost={props.cost!}
                minValue={getPeriodBudget(props.budgetMin!, props.stepPeriod)}
                maxValue={getPeriodBudget(props.budgetMax!, props.stepPeriod)}
            />

            {
                legend &&
                (props.cost > 0 || props.trend > 0) &&
                <ProgressBarLegend
                    budgetMin={props.budgetMin}
                    budgetMax={props.budgetMax}
                    cost={props.cost}
                    trend={props.trend}
                />
            }

            {
                !props.hideMetrics &&
                props.cost > 0 &&
                props.trend > 0 &&
                <div className={'w-full flex flex-col'}>
                    {
                        (props.stepPeriod.period.key === '1W' || props.stepPeriod.period.key === '1M') &&
                        <SuggestedBudget
                            cost={props.cost}
                            budget={getPeriodBudgetByTrend(props.budgetMin!, props.budgetMax!, props.trend)}
                            stepPeriod={props.stepPeriod}
                        />
                    }
                </div>
            }
        </div>
    )
}