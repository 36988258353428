import {BudgetAccountGroupFragment} from "./fragments";
import {BudgetAccountGroup} from "../../models";


export class BudgetAccountGroupFactory {
    static fromFragment = (fragment: BudgetAccountGroupFragment) : BudgetAccountGroup => {
        let budgetAccountGroup = new BudgetAccountGroup()
        budgetAccountGroup.id = fragment.id
        budgetAccountGroup.createdAt = new Date(fragment.created_at)
        budgetAccountGroup.budgetMin = fragment.budget_min
        budgetAccountGroup.budgetMax = fragment.budget_max
        budgetAccountGroup.campaignGroupId = fragment.campaign_group
        budgetAccountGroup.providerAccountId = fragment.provider_account
        return budgetAccountGroup
    }
}