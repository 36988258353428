import {Campaign, CampaignType, Customer, Provider, ProviderAccount, Status} from "../../models";
import {CampaignFragment} from "./fragments";

import {Cache} from '@boomrank/react-components'
import {StandaloneCampaign} from "../../models/standalone";
import {StatCampaign} from "../../models/stats";


export class CampaignFactory {
    static fromFragment = (fragment: CampaignFragment):  Campaign => {
        let campaign = new Campaign()
        campaign.id = fragment.id
        campaign.createdAt = new Date(fragment.created_at)
        campaign.name = fragment.name
        campaign.key = fragment.key
        campaign.campaignTypeId = fragment.campaign_type
        campaign.providerAccountId = fragment.provider_account
        campaign.status = fragment.status as Status
        return campaign
    }
}

export class StandaloneCampaignFactory {
    static fromCampaign = (
        campaign: Campaign,
        customer: Customer,
        cacheProviderAccounts: Cache<ProviderAccount>,
        cacheProviders: Cache<Provider>,
        cacheCampaignTypes: Cache<CampaignType>,
        statCampaigns?: StatCampaign[]
    ): StandaloneCampaign => {
        let providerAccount = cacheProviderAccounts.values()
            .find(pa => pa.id === campaign.providerAccountId)

        let provider = cacheProviders.values()
            .find(p => p.id === providerAccount!.providerId)

        let campaignType = cacheCampaignTypes.values()
            .find(ct => ct.id === campaign.campaignTypeId)

        let standalone = new StandaloneCampaign()

        if (providerAccount) {
            standalone.loginId = providerAccount.loginId
        }
        if (provider) {
            standalone.provider = provider.name
        }
        if (campaignType) {
            standalone.campaignType = campaignType.name.replaceAll('_', ' ')
        }
        if (statCampaigns) {
            statCampaigns.filter((s) => (
                s.campaignKey === campaign.key
            )).forEach((stat) => {
                standalone.cost += stat.cost
                standalone.trend += stat.trend

                standalone.purchases = stat.purchases
                standalone.purchaseRoas = stat.purchaseRoas
                standalone.costPerPurchase = stat.costPerPurchase
                standalone.leads = stat.leads
                standalone.costPerLead = stat.costPerLead

                standalone.conversions = stat.conversions
                standalone.conversionsValue = stat.conversionsValue

                standalone.impressions = stat.impressions

                // if (stat.costPerPurchase > 0) {
                //     standalone.conversions += stat.purchases
                //     standalone.conversionsValue += stat.costPerPurchase
                // }
                // if (stat.costPerLead > 0) {
                //     standalone.conversions += stat.leads
                //     standalone.conversionsValue += stat.costPerLead
                // }
                // if (stat.conversionsValue > 0) {
                //     standalone.conversions += stat.conversions
                //     standalone.conversionsValue += stat.conversionsValue
                // }
            })
        }

        standalone.id = campaign.id
        standalone.customerId = customer.id
        standalone.customerName = customer.name
        standalone.name = campaign.name
        standalone.key = campaign.key
        standalone.status = campaign.status.toString()
        return standalone
    }
}