import {LogFragment} from "./fragments";
import {Account, Log} from "../../models";
import {StandaloneLog} from "../../models/standalone";


export class LogFactory {
    static fromFragment = (fragment: LogFragment) : Log => {
        let log = new Log()
        log.id = fragment.id
        log.createdAt = new Date(fragment.created_at)
        log.username = fragment.username
        log.customerId = fragment.args.customer_id
        log.instanceId = fragment.args.instance_id
        log.instanceName = fragment.args.instance_name
        log.code = fragment.code
        log.args = fragment.args.changes
        return log
    }
}

export class StandaloneLogFactory {
    static fromLog = (
        log: Log,
        accounts: Account[]
    ): StandaloneLog => {
        let standalone = {...log} as StandaloneLog

        let account = accounts.find(a => a.user.username === log.username)

        if (account) {
            standalone.accountUser = `${account.user.firstname} ${account.user.lastname}`
        }

        return standalone
    }
}