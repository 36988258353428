import React from "react";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";

import {
    Account,
    CampaignGroup,
    Customer,
    ProviderAccount,
    Provider,
    AccountHasCustomer,
    Identifier
} from "../../../../models";

import {StepPeriod} from "../../../../models/stepPeriod";

import {Breadcrumb, Cache, JwtToken, PageNotFoundView} from "@boomrank/react-components";
import {CampaignGroupListView} from "../../views/campaign-group/list";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheProviders: Cache<Provider>
    cacheAccounts: Cache<Account>
    cacheProviderAccounts: Cache<ProviderAccount>
    cacheCampaignGroups: Cache<CampaignGroup>
    stepPeriod: StepPeriod
    onStepPeriodChange: (stepPeriod: StepPeriod) => void
    cacheUpdater: (obj: Identifier, remove: boolean) => void
}

export function CampaignGroupListRoute(props: Props) {
    let location = useLocation()
    let params = useParams()

    let customer = props.cacheCustomers.values()
        .find(c => c.id === parseInt(params.customerId!))

    let campaignGroups = props.cacheCampaignGroups.values()
        .filter(cg => cg.customerId === parseInt(params.customerId!))


    if (!customer) {
        return <PageNotFoundView />
    }

    document.title = getTranslation('MONITORING.ROUTES.CAMPAIGN_GROUP.LIST_TITLE').format(customer.name);


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {to: `/dashboard/customer/${customer.id}/detail`, content: <>{customer.name}</>},
                    {
                        to: `/dashboard/customer/${customer.id}/campaign-groups`,
                        content: getTranslation('MONITORING.ROUTES.CAMPAIGN_GROUP.BREADCRUMB'),
                        disabled: true
                    },
                ]}
            />

            <CampaignGroupListView
                token={props.token}
                me={props.me}
                customer={customer}
                campaignGroups={campaignGroups}
                cacheAccounts={props.cacheAccounts}
                cacheProviders={props.cacheProviders}
                cacheProviderAccounts={props.cacheProviderAccounts}
                cacheAccountHasCustomers={props.cacheAccountHasCustomers}
                onStepPeriodChange={props.onStepPeriodChange}
                stepPeriod={props.stepPeriod}
                cacheUpdater={props.cacheUpdater}
            />
        </>
    )
}

