import React, {useState} from 'react';
import {
    LineChart as RechartsLineChart,
    Tooltip as RechartsTooltip,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Label,
    ReferenceLine,
    Legend,
} from 'recharts';
import {ChartData} from "@boomrank/react-components";


export interface LineChartProps {
    data: ChartData
    minTickGap?: number
    strokeDasharray?: number | string
    tooltip?: React.ReactElement
    maxLineY?: number | string
    minLineY?: number | string
    maxLineLabel?: string
    minLineLabel?: string
    showLegend?: boolean
}

export function LineChart (props: LineChartProps) {
    let [chartLabels, setChartLabels] = useState<any[]>([])

    let data = props.data.getData()

    let legendOnClick = (legend: any) => {
        let isActive = !legend.inactive
        let chart = chartLabels.find(chart => chart.dataKey === legend.payload.dataKey)

        if (isActive && !chart) {
            setChartLabels([
                ...chartLabels,
                legend
            ])
        }

        if (!isActive && chart) {
            setChartLabels((label) =>
                label.filter((c) => c.dataKey !== chart.dataKey)
            )
        }
    }

    return (
        <ResponsiveContainer height={320}>
            <RechartsLineChart data={data as any} margin={{ right: 50, top: 25, left: 25, bottom: 25 }}>
                <CartesianGrid strokeDasharray={props.strokeDasharray} />
                <XAxis dataKey={'x'} minTickGap={props.minTickGap} >
                    <Label position="bottom" />
                </XAxis>
                <YAxis>
                    <Label angle={-90} position={'insideLeft'} offset={-40} />
                </YAxis>

                {
                    props.tooltip &&
                    <RechartsTooltip content={props.tooltip} />
                }
                {
                    !props.tooltip &&
                    <RechartsTooltip />
                }
                {
                    Object.keys(props.data.entries).map((key, idx) => {
                        let entry = props.data.entries[key]
                        let hiddenChart = chartLabels.find(chart => chart.dataKey === entry.label)

                        return (
                            <Line
                                type="monotone"
                                key={idx}
                                dataKey={key}
                                fill={entry.color}
                                stroke={entry.color}
                                className={'text-xxs md:text-xs lg:text-base'}
                                hide={hiddenChart}
                            />
                        )
                    })
                }

                {
                    props.maxLineY &&
                    <ReferenceLine
                        y={props.maxLineY}
                        stroke="#35B6AD"
                        strokeWidth={1.5}
                        strokeDasharray="3 3"
                        ifOverflow="extendDomain"
                    />
                }
                {
                    props.minLineY &&
                    <ReferenceLine
                        y={props.minLineY}
                        stroke="#35B6AD"
                        strokeWidth={1.5}
                        strokeDasharray="3 3"
                        ifOverflow="extendDomain"
                    />
                }

                {
                    props.showLegend &&
                    <Legend onClick={legendOnClick} />
                }
            </RechartsLineChart>
        </ResponsiveContainer>
    );
}
