import React, {useEffect, useState} from "react";


export interface Props {
    onToggle: (value: boolean) => void
}

export function DashboardThemeToggle(props: Props) {
    let currentPreference = localStorage.getItem('dashboardTheme')
    if (!currentPreference) {
        localStorage.setItem('dashboardTheme', 'tables')
    }

    let [operationalView, setOperationalView] = useState<boolean>(currentPreference === 'tables')

    let onToggle = (value: boolean) => {
        if (value) {
            localStorage.setItem('dashboardTheme', 'tables')
        }
        if (!value) {
            localStorage.setItem('dashboardTheme', 'cards')
        }

        setOperationalView(value)
        props.onToggle(value)
    }

    useEffect(() => {
        props.onToggle(currentPreference === 'tables')
    }, [])


    return (
        <button
            data-testid={'dashboard-toggle-btn'}
            onClick={() => onToggle(!operationalView)}
            className={'btn-blue p-2 text-center rounded-sm mr-2'}
        >
            {operationalView ? 'Cartes' : 'Tables'}
        </button>
    )
}