import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";

import {
    Account,
    AccountHasCustomer,
    Customer,
    Identifier,
    Provider,
    ProviderAccount,
} from "../../../../models";

import {Breadcrumb, Cache, JwtToken, PageNotFoundView} from "@boomrank/react-components";
import {CustomerEditView} from "../../views/customer/edit";
import {getTranslation} from "../../../../intl";
import {StandaloneProviderAccountFactory} from "../../../../services/provider-account/factory";
import {StandaloneProviderAccount} from "../../../../models/standalone";

interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheProviderAccounts: Cache<ProviderAccount>
    cacheAccounts: Cache<Account>
    cacheProviders: Cache<Provider>,
    cacheUpdater: (obj: Identifier, remove: boolean) => void
}

export function CustomerEditRoute(props: Props) {
    let location = useLocation()
    let params = useParams()

    let customer = props.cacheCustomers.values()
        .find(c => c.id === parseInt(params.customerId!))

    let [accountHasCustomers, setAccountHasCustomers] = useState<AccountHasCustomer[]>([])
    let [providerAccounts, setProviderAccounts] = useState<ProviderAccount[]>([])
    let [standaloneProviderAccounts, setStandaloneProviderAccounts] = useState<StandaloneProviderAccount[]>([])


    let getAccountHasCustomers = () => {
        let accountCustomers = props.cacheAccountHasCustomers.values()
            .filter(ahc => ahc.customerId === parseInt(params.customerId!))
        setAccountHasCustomers(accountCustomers)

        if (customer) {
            customer.accountIds = accountHasCustomers.map((ahc) => ahc.accountId)
        }
    }

    let getProviderAccounts = () => {
        let providerAccounts = props.cacheProviderAccounts.values()
            .filter(pa => pa.customerId === parseInt(params.customerId!))
        setProviderAccounts(providerAccounts)
    }

    let providerAccountFormatter = () => {
        let standalones = props.cacheProviderAccounts.values().map((pa) => {
            return StandaloneProviderAccountFactory.fromProviderAccount(
                pa,
                props.cacheProviders,
                props.cacheCustomers
            )
        })
        setStandaloneProviderAccounts(standalones)
    }

    useEffect(() => {
        getAccountHasCustomers()
        getProviderAccounts()
        providerAccountFormatter()
    }, [params.customerId, props.cacheProviderAccounts, props.cacheAccountHasCustomers]);


    if (!customer) {
        return <PageNotFoundView />
    }

    document.title = getTranslation('MONITORING.ROUTES.CUSTOMER.EDIT_TITLE')
        .format(customer.name);


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {to: `/dashboard/customer/${customer.id}/detail`, content: <>{customer.name}</>},
                    {to: `/dashboard/customer/${customer.id}/edit`, content: 'Modifier', disabled: true},
                ]}
            />

            <CustomerEditView
                token={props.token}
                me={props.me}
                customer={customer}
                accountHasCustomers={accountHasCustomers}
                providerAccounts={providerAccounts}
                standaloneProviderAccounts={standaloneProviderAccounts}
                cacheAccounts={props.cacheAccounts}
                cacheProviders={props.cacheProviders}
                cacheUpdater={props.cacheUpdater}
            />
        </>
    )
}