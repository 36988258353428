import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Cache} from "@boomrank/react-components";

import {Account, Customer, Role} from "../../../../models";

import {getTranslation} from "../../../../intl";
import {BasisInput} from "../../../dummies/input";


interface Props {
    me: Account
    customer: Customer
    cacheCustomers?: Cache<Customer>
    onChange: (customer: Customer) => void
    disabled: boolean
}

export function CustomerBasisForm(props: Props) {
    let [existingCustomers, setExistingCustomers] = useState<Customer[]>([])

    let onChange = (name: string, value: string) => {
        let customer = {
            ...props.customer,
            [name]: value,
        }

        if (name === 'name' && value === '') {
            setExistingCustomers([])
        }

        if (props.cacheCustomers && name === 'name' && value !== '') {
            let customerName = customer.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

            let customers = props.cacheCustomers.values().filter(
                (customer) => {
                    let name = customer.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                    return name.toLowerCase().includes(customerName.toLowerCase())
                }
            )

            setExistingCustomers(customers)
        }

        props.onChange(customer)
    }

    return (
        <div className={'w-full my-2 lg:my-0 lg:col-start-1 lg:col-end-3'}>
            <div className={'w-full h-fit relative'}>
                    <span className={'text-gray-500 font-medium'}>
                        {getTranslation('MONITORING.FORMS.CUSTOMER.LABELS.NAME')}
                    </span>

                <BasisInput
                    dataTestId={'customer-form-name'}
                    value={props.customer.name}
                    onChange={onChange}
                    type={"text"}
                    placeholder={getTranslation('MONITORING.FORMS.CUSTOMER.PLACEHOLDERS.NAME')}
                    name={"name"}
                    disabled={props.disabled}
                />

                {
                    existingCustomers.length > 0 &&
                    <div className={'w-full p-2 absolute top-[100%] bg-red-200 rounded z-10'}>
                        <span className={'text-red-500'}>Vous recherchez peut-être le client suivant ?</span>

                        <div>
                            {
                                existingCustomers.map((customer) => {
                                    return (
                                        <Link
                                            to={`/dashboard/customer/${customer.id}/detail`}
                                            key={customer.id}
                                            className={'mr-2 text-red-500'}
                                        >
                                            {customer.name}
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </div>

            <div className={'w-full relative my-3 lg:mt-3 lg:mb-0'}>
                <span className={'text-gray-500 font-medium'}>
                    {getTranslation('MONITORING.FORMS.CUSTOMER.LABELS.COMMENT')}
                </span>

                <textarea
                    data-testid={'customer-form-comment'}
                    value={props.customer.comment}
                    name={"comment"}
                    onChange={e => onChange('comment', e.target.value)}
                    className={
                        'w-full p-3 border border-gray-200 rounded-md ' +
                        'focus:outline-dashed focus:outline-2 focus:outline-gray-300'
                    }
                    disabled={props.disabled}
                />
            </div>
            {
                (
                    props.me.role === Role.ASSOCIATE ||
                    props.me.role === Role.FINANCE ||
                    props.me.role === Role.HEAD_OF
                ) &&
                <div className={'w-full relative my-3 lg:mt-3 lg:mb-3'}>
                    <span className={'text-gray-500 font-medium'}>
                        {getTranslation('MONITORING.FORMS.CUSTOMER.LABELS.FINANCIAL_COMMENT')}
                    </span>

                    <textarea
                        data-testid={'customer-form-financial-comment'}
                        value={props.customer.financial_comment}
                        name={"financial_comment"}
                        onChange={e => onChange('financial_comment', e.target.value)}
                        className={
                            'w-full p-3 border border-gray-200 rounded-md ' +
                            'focus:outline-dashed focus:outline-2 focus:outline-gray-300'
                        }
                        disabled={props.disabled}
                    />
                </div>
            }
        </div>
    )
}
