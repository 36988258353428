import {StepPeriod} from "../../../models/stepPeriod";
import {defaultLanguage, getTranslation} from "../../../intl";
import classNames from "classnames";
import {useEffect, useState} from "react";

interface Props {
    stepPeriod: StepPeriod
    cost: number
    budget: number | null | undefined
}

export function SuggestedBudget(props: Props) {
    let [suggestedBudget, setSuggestedBudget] = useState<number | null>(null)
    let [suggestedModificator, setSuggestedModificator] = useState<number | null>(null)

    let getDatesInRange = (start: Date, end: Date) => {
        const date = new Date(start.getTime());
        const dates = [];

        while (date <= end) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

    let setSuggested = (budget: number, dates: Date[]) => {
        let remainingDays = dates.length

        let yesterday = new Date().getDate() - 1
        let date = dates.map((d) => d.getDate()).indexOf(yesterday)

        if (date !== -1) {
            remainingDays = dates.length - (date + 1)
        }

        let suggested = (budget - props.cost) / remainingDays
        setSuggestedBudget(suggested)
    }

    let setModificator = (dates: Date[], suggest: number) => {
        let yesterday = new Date().getDate() - 1
        let date = dates.map((d) => d.getDate()).indexOf(yesterday)

        if (date === -1) {
            date = 0
        }

        let dailyTrend = props.cost / (date + 1)
        setSuggestedModificator(suggest - dailyTrend)
    }

    let dates = getDatesInRange(
        props.stepPeriod.period.start,
        props.stepPeriod.period.end
    )

    useEffect(() => {
        if (props.budget !== undefined && props.budget !== null) {
            setSuggested(props.budget, dates)

            if (suggestedBudget) {
                setModificator(dates, suggestedBudget)
            }
        }
    }, [dates, props.budget])


    return (
        <div className={'w-full mt-6'}>
            {
                suggestedBudget &&
                <div className={'w-full py-1.5 px-1 border-b-2 border-slate-200 flex justify-between'}>
                    <span className={'text-sm text-gray-500'}>
                        {getTranslation('DUMMIES.BUDGET.SUGGESTED.BUDGET')}
                    </span>

                    <div className={classNames(
                        'text-base font-medium text-center',
                        {'text-red-500': suggestedBudget < 0}
                    )}>
                        {
                            suggestedBudget.toLocaleString(
                                defaultLanguage,
                                {style: 'currency', currency: 'EUR'}
                            )
                        }
                    </div>
                </div>
            }

            {
                suggestedBudget &&
                suggestedModificator &&
                <div className={'w-full py-1.5 px-1 flex justify-between'}>
                    <span className={'text-sm text-gray-500'}>
                        {getTranslation('DUMMIES.BUDGET.SUGGESTED.MODIFICATOR')}
                    </span>

                    <div className={classNames(
                        'text-base font-medium text-center',
                        {'text-red-500': suggestedModificator < 0},
                        {'text-green-500': suggestedModificator > 0},
                    )}>
                        {
                            suggestedModificator < 0 ?
                                <i className="fa-solid fa-caret-down px-1"/> :
                                <i className="fa-solid fa-caret-up px-1"/>
                        }
                        {
                            suggestedModificator.toLocaleString(
                                defaultLanguage,
                                {style: 'currency', currency: 'EUR'}
                            )
                        }
                    </div>
                </div>
            }
        </div>
    )
}