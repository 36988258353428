import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {StatCampaignGroupResponse,} from "./responses";
import {endpoints} from "../../../api";


export class CustomerStatCampaignGroupApi {
    static list(
        token: JwtToken,
        customerId: number,
        from: Date,
        to: Date,
        step: string,
    ): Promise<FormErrorResponse | StatCampaignGroupResponse> {
        let url = endpoints.customer.statCampaignGroup.list
            .replace(':customerId', customerId.toString())
            .replace(':from', from!.toLocaleDateString('fr-CA'))
            .replace(':to', to!.toLocaleDateString('fr-CA'))
            .replace(':step', step)

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static get(
        token: JwtToken,
        customerId: number,
        campaignGroupId: number,
        from?: Date,
        to?: Date,
        step?: string,
    ): Promise<FormErrorResponse | StatCampaignGroupResponse> {
        let url = endpoints.customer.statCampaignGroup.retrieve
            .replace(':customerId', customerId.toString())
            .replace(':campaignGroupId', campaignGroupId.toString())
            .replace(':from', from!.toLocaleDateString('fr-CA'))
            .replace(':to', to!.toLocaleDateString('fr-CA'))
            .replace(':step', step!)
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }
}