import React, {useState} from "react";

import {Customer} from "../../../../models";
import {Errors} from "../../../dummies/errors";

import {getTranslation} from "../../../../intl";
import classNames from "classnames";


interface Props {
    customer: Customer
    onSubmit: (customer: Customer) => void
    disabled: boolean
    errors: Errors
}

export function CustomerStateForm(props: Props) {
    let [customer, setCustomer] = useState<Customer>(props.customer)

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let c = {
            ...customer,
            state: customer.state === 1 ? 2 : 1
        }

        setCustomer(c)
        props.onSubmit(c)
    }


    return (
        <div className={
            'w-full lg:w-80 mt-6 py-6 px-5 ' +
            'border-2 border-slate-200 rounded-xl'
        }>
            <span>{getTranslation('MONITORING.FORMS.CUSTOMER.STATE.TITLE')}</span>

            <div className={'w-fit mt-5 mx-auto flex gap-x-2 items-center'}>
                <span className={classNames(
                    'font-medium',
                    {'text-orange-500': customer.state === 2},
                    {'text-gray-900 dark:text-gray-300': customer.state === 1},
                )}>
                    En pause
                </span>

                <label className={'relative inline-flex items-center cursor-pointer'}>
                    <input
                        data-testid={'customer-form-toggle-state'}
                        type={'checkbox'}
                        name={'state'}
                        className={'sr-only peer'}
                        checked={customer.state === 1}
                        onChange={onChange}
                    />

                    <div className={
                        'w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-300 ' +
                        'rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full ' +
                        'peer-checked:after:border-white after:content-[\'\'] ' +
                        'after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 ' +
                        'after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-br-green-500'
                    }/>
                </label>

                <span className={classNames(
                    'font-medium',
                    {'text-br-green': customer.state === 1},
                    {'text-gray-900 dark:text-gray-300': customer.state === 2},
                )}>
                    Activé
                </span>
            </div>
        </div>
    )
}
