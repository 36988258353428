import {getTranslation} from "../intl";

export enum Step {
    ONE_DAY = '1D',
    ONE_WEEK = '1W',
    ONE_MONTH = '1M',
    ONE_YEAR = '1Y',
}

export enum PeriodKey {
    YESTERDAY = '-1D',
    CURRENT_WEEK = '1W',
    ONE_WEEK_AGO = '-1W',
    CURRENT_MONTH = '1M',
    ONE_MONTH_AGO = '-1M',
}


export class Period {
    label: string = ''
    start: Date = new Date()
    end: Date = new Date()
    key: PeriodKey = PeriodKey.CURRENT_MONTH

    constructor() {
        this.init();
    }

    init() {}
}

export class CurrentWeekPeriod extends Period {
    init() {
        let date = new Date(Date.now());

        this.label = getTranslation('SELECT_PERIOD.CURRENT_WEEK')
        this.start = new Date(date.setDate(date.getDate() - date.getDay() + 1))
        this.end = new Date(date.setDate(date.getDate() - date.getDay() + 7))
        this.key = PeriodKey.CURRENT_WEEK
    }
}

export class OneWeekAgoPeriod extends Period {
    init() {
        let date = new Date(Date.now());

        this.label = getTranslation('SELECT_PERIOD.PREVIOUS_WEEK')
        this.start = new Date(date.setDate(date.getDate() - date.getDay() - 6))
        this.end = new Date(date.setDate(date.getDate() - date.getDay() + 7))
        this.key = PeriodKey.ONE_WEEK_AGO
    }
}

export class CurrentMonthPeriod extends Period {
    init() {
        let date = new Date(Date.now());

        this.label = getTranslation('SELECT_PERIOD.CURRENT_MONTH')
        this.start = new Date(date.getFullYear(), date.getMonth(), 1)
        this.end = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        this.key = PeriodKey.CURRENT_MONTH
    }
}

export class OneMonthAgoPeriod extends Period {
    init() {
        let date = new Date(Date.now());

        this.label = getTranslation('SELECT_PERIOD.PREVIOUS_MONTH')
        this.start = new Date(date.getFullYear(), date.getMonth() - 1, 1)
        this.end = new Date(date.getFullYear(), date.getMonth(), 0)
        this.key = PeriodKey.ONE_MONTH_AGO
    }
}


export class StepPeriod {
    id: number = 0
    stepChart: Step = Step.ONE_DAY
    stepTable: Step = Step.ONE_MONTH
    period: Period = new CurrentMonthPeriod()
}

export class StepPeriodFactory {
    static getStepPeriod(key: PeriodKey) {
        if(key === PeriodKey.CURRENT_MONTH) {
            let stepPeriod = new StepPeriod();
            stepPeriod.id = 0
            stepPeriod.stepChart = Step.ONE_DAY
            stepPeriod.stepTable = Step.ONE_MONTH
            stepPeriod.period = new CurrentMonthPeriod()
            return stepPeriod
        }

        if(key === PeriodKey.CURRENT_WEEK) {
            let stepPeriod = new StepPeriod();
            stepPeriod.id = 1
            stepPeriod.stepChart = Step.ONE_DAY
            stepPeriod.stepTable = Step.ONE_WEEK
            stepPeriod.period = new CurrentWeekPeriod()
            return stepPeriod
        }

        if(key === PeriodKey.ONE_WEEK_AGO) {
            let stepPeriod = new StepPeriod();
            stepPeriod.id = 2
            stepPeriod.stepChart = Step.ONE_DAY
            stepPeriod.stepTable = Step.ONE_WEEK
            stepPeriod.period = new OneWeekAgoPeriod()
            return stepPeriod
        }

        if(key === PeriodKey.ONE_MONTH_AGO) {
            let stepPeriod = new StepPeriod();
            stepPeriod.id = 3
            stepPeriod.stepChart = Step.ONE_DAY
            stepPeriod.stepTable = Step.ONE_MONTH
            stepPeriod.period = new OneMonthAgoPeriod()
            return stepPeriod
        }

        return new StepPeriod();
    }
}