import React, {useState} from "react";
import {getTranslation} from "../../../../intl";
import {FeeBracket} from "../../../../models";
import {GreenButton, RedButton} from "@boomrank/react-components"

interface Props {
    feeBracket: FeeBracket,
    onDelete?: (feeBracket: FeeBracket) => void,
    onChange?: (feeBracket: FeeBracket) => void,
    onSubmit?: (feeBracket: FeeBracket) => void,
    onError?: () => void,
    disabled?: boolean
    isValid?: boolean
}

export function FeeBracketForm(props: Props) {
    let [feeBracket, setFeeBracket] = useState<FeeBracket>(props.feeBracket)
    let [errors, setErrors] = useState<string[]>([])

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrors([])
        let value : string | number = event.target.value.trim()

        let f = {
            ...feeBracket,
            [event.target.name]: value
        }
        setFeeBracket(f)

        if (props.onChange) {
            props.onChange(f)
        }
    }

    let onDelete = () => {
        if (props.onDelete) {
            props.onDelete(feeBracket)
        }
    }

    let onSubmit = () => {
        let isValid = true
        let errors: string[] = []

        if (isNaN(feeBracket.rate) || feeBracket.rate.toString().length === 0) {
            feeBracket.rate = 0
        }
        if (isNaN(feeBracket.priceHt) || feeBracket.priceHt.toString().length === 0) {
            feeBracket.priceHt = 0
        }

        if (feeBracket.rate === 0 && feeBracket.priceHt === 0) {
            errors = [
                ...errors,
                getTranslation('MONITORING.FORMS.FEE.BRACKET.RATE_OR_PRICE_HT_ZERO')
            ]
        }

        if (errors.length > 0) {
            isValid = false
            setErrors(errors)
        }

        if (isValid) {
            if(props.onSubmit) {
                props.onSubmit(feeBracket)
            }
        } else {
            if (props.onError) {
                props.onError()
            }
        }
    }

    return (
        <>
            {
                errors.length > 0 &&
                <div role={'alert'} className={'col-span-5 p-6 text-br-red border border-br-red bg-red-50 my-3'}>
                    {
                        errors.map((e, idx: number) => {
                            return (
                                <div key={idx}>{e}</div>
                            )
                        })
                    }
                </div>
            }
            <input
                className={'p-3 border rounded w-full'}
                placeholder={feeBracket.bracketMin ? feeBracket.bracketMin.toString() : getTranslation('MONITORING.FORMS.FEE.BRACKET.PLACEHOLDER_BRACKET_MIN')}
                onChange={onChange}
                type={'text'}
                name={'bracketMin'}
                value={feeBracket.bracketMin || ''}
                disabled={props.disabled || false}
            />

            <input
                className={'p-3 border rounded w-full'}
                placeholder={feeBracket.bracketMax ? feeBracket.bracketMax.toString() : getTranslation('MONITORING.FORMS.FEE.BRACKET.PLACEHOLDER_BRACKET_MAX')}
                onChange={onChange}
                type={'text'}
                name={'bracketMax'}
                value={feeBracket.bracketMax || ''}
                disabled={props.disabled || false}
            />

            <input
                className={'p-3 border rounded w-full'}
                placeholder={feeBracket.rate ? feeBracket.rate.toString() : getTranslation('MONITORING.FORMS.FEE.BRACKET.PLACEHOLDER_RATE')}
                onChange={onChange}
                type={'text'}
                name={'rate'}
                value={feeBracket.rate}
                disabled={props.disabled || false}
            />

            <input
                className={'p-3 border rounded w-full'}
                placeholder={feeBracket.priceHt ? feeBracket.priceHt.toString() : getTranslation('MONITORING.FORMS.FEE.BRACKET.PLACEHOLDER_PRICE_HT')}
                onChange={onChange}
                type={'text'}
                name={'priceHt'}
                value={feeBracket.priceHt}
                disabled={props.disabled || false}
            />
            <div className={'grid grid-cols-2 gap-3'}>
                {
                    props.isValid !== true ?
                        <GreenButton text={ getTranslation('MONITORING.FORMS.FEE.BRACKET.SUBMIT_BUTTON') } onClick={onSubmit} />
                    :
                        <div className={'text-br-green text-center p-3'}>
                            <i className={"fa fa-circle-check mr-3"}/> { getTranslation('MONITORING.FORMS.FEE.BRACKET.IS_VALID') }
                        </div>

                }
                <RedButton
                    className={'ml-3 !px-3'}
                    text={ getTranslation('MONITORING.FORMS.FEE.BRACKET.DELETE_BUTTON') }
                    onClick={onDelete}
                />
            </div>
        </>
    )
}