import React from "react";
import {Link} from "react-router-dom";

import {JwtToken} from "@boomrank/react-components";
import {Account, Identifier, ProviderAccount, State} from "../../../../../../../models";
import {StandaloneCustomer} from "../../../../../../../models/standalone";
import {StepPeriod} from "../../../../../../../models/stepPeriod";

import {getTranslation} from "../../../../../../../intl";
import {TableBodyProviderAccountsViews} from "./table-body-provider-account";
import {NotificationEditView} from "../../../../../../notification/views/edit";
import {AccountHasCustomerEditView} from "../../../../account-has-customer/edit";


interface Props {
    token: JwtToken
    me: Account
    standaloneCustomers: StandaloneCustomer[]
    onBudgetUpdate: (providerAccount: ProviderAccount, budget: any) => void
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
    stepPeriod: StepPeriod
    isLoading?: boolean
}

export function CustomerOperationalTableBodyView(props: Props) {
    return (
        <div>
            {
                props.standaloneCustomers.map((standaloneCustomer, idx) => {
                    let hasAccount = standaloneCustomer.accountHasCustomers.find((ahc) =>
                        ahc.accountId === props.me.id &&
                        ahc.customerId === standaloneCustomer.id
                    )

                    return (
                        <div
                            key={idx}
                            className={'w-full p-2 flex items-start justify-between even:bg-gray-100'}
                        >
                            <div className={'w-1/5'}>
                                <span>
                                    {
                                        standaloneCustomer.state === State.PAUSED &&
                                        <i className={'mr-3 font-gold text-orange-500 fa-solid fa-pause'} title={
                                            getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.PAUSED')
                                        } />
                                    }
                                        {
                                            standaloneCustomer.state === State.ACTIVE &&
                                            <i className={'mr-3 font-gold text-green-500 fa-solid fa-play'} title={
                                                getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.PLAYING')
                                            } />
                                        }
                                </span>

                                <Link
                                    to={`/dashboard/customer/${standaloneCustomer.id}`}
                                    className={'w-full xs:my-0 sm:my-0'}
                                    title={'Voir les statistiques de l\'annonceur'}
                                >
                                    {standaloneCustomer.name}
                                </Link>
                            </div>

                            <TableBodyProviderAccountsViews
                                standaloneCustomer={standaloneCustomer}
                                onBudgetUpdate={props.onBudgetUpdate}
                                stepPeriod={props.stepPeriod}
                            />

                            <div className={'w-28 flex justify-end gap-x-2'}>
                                <AccountHasCustomerEditView
                                    me={props.me}
                                    token={props.token}
                                    standaloneCustomer={standaloneCustomer}
                                    accountHasCustomer={hasAccount}
                                    cacheUpdater={props.cacheUpdater}
                                />

                                <NotificationEditView
                                    token={props.token}
                                    customerId={standaloneCustomer.id}
                                    accountId={props.me.id}
                                    customerHasAccount={hasAccount!}
                                    cacheUpdater={props.cacheUpdater}
                                />
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}