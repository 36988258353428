import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import { endpoints } from "../../../api";
import {StatCampaignListResponse} from "./responses";


export class CustomerStatCampaignApi {
    static list(
        token: JwtToken,
        customerId: number,
        from: Date,
        to: Date,
        step: string
    ): Promise<FormErrorResponse | StatCampaignListResponse> {
        let url = endpoints.customer.statCampaign.list
            .replace(':customerId', customerId.toString())
            .replace(':from', from!.toLocaleDateString('fr-CA'))
            .replace(':to', to!.toLocaleDateString('fr-CA'))
            .replace(':step', step!)

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static get(
        token: JwtToken,
        customerId: number,
        campaignKey: string,
        from?: Date,
        to?: Date,
        step?: string
    ): Promise<FormErrorResponse | StatCampaignListResponse> {
        let url = endpoints.customer.statCampaign.retrieve
            .replace(':customerId', customerId.toString())
            .replace(':campaignKey', campaignKey)
            .replace(':from', from!.toLocaleDateString('fr-CA'))
            .replace(':to', to!.toLocaleDateString('fr-CA'))
            .replace(':step', step!)

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }
}