import React, {useState} from "react";

import {StandaloneCustomerFee} from "../../../../../models/standalone";
import {CustomerFeeTableView} from "./table";
import {Option} from "../../invoice/filter/filters";
import {CustomerFeeFilters} from "./filters";


interface Props {
    standaloneCustomerFees: StandaloneCustomerFee[]
}

export function CustomerFeeFilterView(props: Props) {
    let [filteredRows, setFilteredRows] = useState<StandaloneCustomerFee[]>(props.standaloneCustomerFees)
    let [isLoading, setIsLoading] = useState<boolean>(true)

    let uniqueValues = (options: Option[], value: string | number) => {
        let values = options.map((option) => option.value)

        if (!values.includes(value)) {
            options.push({ value: value, active: false, selected: false })
        }
        return options
    }

    // GroupFilter : return uniques values for each filter
    let uniqueAccounts = props.standaloneCustomerFees.reduce(
        (accounts: Option[], standalone: StandaloneCustomerFee) => {
            return uniqueValues(accounts, standalone.customerAccount)
        }, []
    ).sort((a, b) => a.value.localeCompare(b.value))

    let uniqueActiveCustomers = [
        {value: 'Actif', active: false, selected: false, alias: true},
        {value: 'En pause', active: false, selected: false, alias: false}
    ]

    let uniqueActiveFees = [
        {value: 'Appliqué', active: false, selected: false, alias: true},
        {value: 'Non renseigné', active: false, selected: false, alias: false}
    ]

    let getActiveFilters = () => {
        let accounts = uniqueAccounts.map((v) => v.value)
        let activeCustomers = uniqueActiveCustomers.map((v) => v.alias)
        let activeFees = uniqueActiveFees.map((v) => v.alias)

        if (filteredRows.length > 0) {
            filteredRows.reduce(
                (rows, standalone: StandaloneCustomerFee) => {
                    let account = standalone.customerAccount
                    let activeCustomer = standalone.activeCustomer
                    let activeFee = standalone.activeFee

                    if (accounts.includes(account)) {
                        let index = accounts.indexOf(account)
                        uniqueAccounts[index].active = true
                    }
                    if (activeCustomers.includes(activeCustomer)) {
                        let index = activeCustomers.indexOf(activeCustomer)
                        uniqueActiveCustomers[index].active = true
                    }
                    if (activeFees.includes(activeFee)) {
                        let index = activeFees.indexOf(activeFee)
                        uniqueActiveFees[index].active = true
                    }

                    return rows
                }
            )
        }
    }
    getActiveFilters()

    let onFilter = (rows: any[]) => {
        setFilteredRows(rows)

        if (rows.length > 0 || rows.length === 0) {
            setIsLoading(false)
        }
    }


    return (
        <div className={'w-full mt-6 py-6 px-5 border-2 border-slate-200 rounded-xl'}>
            <CustomerFeeFilters
                standaloneCustomerFees={props.standaloneCustomerFees}
                onFilter={onFilter}
                filterGroups={{
                    accounts: uniqueAccounts,
                    activeCustomers: uniqueActiveCustomers,
                    activeFees: uniqueActiveFees,
                }}
                isLoading={isLoading}
            />

            <div className={'mt-10'}>
                <CustomerFeeTableView
                    standaloneCustomersFee={filteredRows}
                    columns={[]}
                    isLoading={isLoading}
                />
            </div>
        </div>
    )
}