import {v4} from "uuid";
import {InvoiceItem} from "../../../models";
import {Flag} from "../views/budget/models";


export class FlaggedInvoiceItem {
    public id = v4()
    public flag: Flag = Flag.NO_CHANGES
    public invoiceItem: InvoiceItem = new InvoiceItem()
    public deleted: boolean = false
}


export class FormNotification {
    public id = v4()
    public show = false
    public content = ''
    public success = false

    clear() {
        this.show = false
        this.content = ''
        this.success = false
        return this
    }

    setSuccess(content: string) {
        this.show = true
        this.content = content
        this.success = true
        return this
    }

    setError(content: string) {
        this.show = true
        this.content = content
        this.success = false
        return this
    }
}