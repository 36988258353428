import React from "react";


interface Props {
    providerName: string
    loginId?: string
}

export function ProviderIcon(props: Props) {
    let providerName = props.providerName.toLowerCase()

    let brandIcon = (brand: string) => {
        if (brand === 'google') {
            return 'fa-brands fa-google text-red-500'
        }
        if (brand === 'meta') {
            return 'fa-brands fa-meta text-blue-700'
        }
        if (brand === 'bing') {
            return 'fa-solid fa-b text-cyan-500'
        }
    }


    return (
        <i className={`w-8 ${brandIcon(providerName)}`}
           title={props.loginId ? props.loginId : providerName}
        />
    )
}
