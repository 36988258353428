import React, {useState} from "react";
import classNames from "classnames";

import {Cache, JwtToken} from "@boomrank/react-components";
import {FinancialInvoiceFilterView} from "./filter";

import {Account, Customer, Fee} from "../../../models";
import {StandaloneFinancialInvoice} from "../../../models/standalone";
import {getTranslation} from "../../../intl";
import {AccountApi} from "../../../services/account/api";


interface Props {
    token: JwtToken
    me: Account
    standaloneFinancialInvoices: StandaloneFinancialInvoice[]
    cacheCustomers: Cache<Customer>
    cacheFees: Cache<Fee>
    showReminderBtn: boolean
}

export function FinancialInvoiceListView(props: Props) {
    let [message, setMessage] = useState({success: false, content: ''})
    let [isLoading, setIsLoading] = useState<boolean>(false)

    let sendReminder = () => {
        setMessage({success: false, content: ''})

        let confirm = window.confirm(
            getTranslation('MONITORING.VIEWS.INVOICE.REMINDER.CONFIRM_JS')
        )

        if (confirm) {
            setIsLoading(true)
            AccountApi.reminders(props.token)
                .then((res) => {
                    if (res.statusCode >= 200 && res.statusCode < 300) {
                        setMessage({
                            success: true,
                            content: getTranslation('MONITORING.VIEWS.INVOICE.REMINDER.RESPONSE.SUCCESS')
                        })
                    } else {
                        setMessage({
                            success: false,
                            content: getTranslation('MONITORING.VIEWS.INVOICE.REMINDER.RESPONSE.ERROR')
                        })
                    }
                    setIsLoading(false)
                })
        }
    }


    return (
        <div className={'w-full mt-6 flex flex-col items-end'}>
            {
                message.content !== '' &&
                <div className={classNames(
                    'w-full font-medium py-6 px-4 my-3 rounded-lg',
                    {'border text-white bg-br-green-500 border-br-green-500': message.success},
                    {'border text-red-500  bg-red-100 border-red-100': !message.success},
                )}>
                    <span>{message.content}</span>
                </div>
            }

            {
                props.showReminderBtn &&
                <button
                    className={'btn btn-blue lg:w-fit'}
                    onClick={sendReminder}
                    disabled={isLoading}
                >
                    {getTranslation('MONITORING.VIEWS.INVOICE.REMINDER.BUTTON')}
                </button>
            }


            <FinancialInvoiceFilterView
                token={props.token}
                me={props.me}
                standaloneFinancialInvoices={props.standaloneFinancialInvoices}
            />
        </div>
    )
}