import React, {useState} from "react";

import {
    Customer,
    Identifier,
    Invoice,
    InvoiceItem,
    Account,
    ProviderAccount,
    ProviderAccountInvoice
} from "../../../../models";
import {InvoiceApi} from "../../../../services/invoice/api";

import {FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {InvoiceCreateResponse} from "../../../../services/invoice/responses";
import {InvoiceFactory} from "../../../../services/invoice/factory";
import {InvoiceForm} from "../../forms/invoice";
import {Errors} from "../../../dummies/errors";
import {CustomerLinks} from "../../../dummies/customer/links";
import {FormNotification} from "../../forms/models";
import {SelectInvoiceTemplate} from "../../../dummies/select/invoiceTemplate";
import {StatCustomer} from "../../../../models/stats";


interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    invoices: Invoice[]
    providerAccounts: ProviderAccount[]
    providerAccountInvoices: ProviderAccountInvoice[]
    cacheUpdater: (obj: Identifier) => void
    onRedirect: (invoiceId: number) => void
    statsCustomer: StatCustomer[]
}

export function InvoiceAddView (props: Props) {
    let date = new Date()
    let previousDate = date.setMonth(date.getMonth() - 1)
    let startDate = new Date(previousDate).setDate(1)

    let addItemCSS = () => {
        let invoiceItemCSS = new InvoiceItem()
        invoiceItemCSS.label = 'CSS (honoraires non applicables)'
        invoiceItemCSS.applicableFee = false
        invoiceItemCSS.isCss = true
        return invoiceItemCSS
    }

    let newInvoice = new Invoice()
    newInvoice.customerId = props.customer.id
    newInvoice.refDate = new Date(startDate)
    newInvoice.invoiceType = 2

    if (props.providerAccounts.find(pa => pa.providerId === 1)) {
        newInvoice.invoiceItems = [addItemCSS()]
    } else {
        newInvoice.invoiceItems = [new InvoiceItem()]
    }

    let [invoice, setInvoice] = useState<Invoice>(newInvoice)
    let [loading, setLoading] = useState(false)
    let [errors, setErrors] = useState<Errors>({})


    let onSubmit = (invoice: Invoice) => {
        setLoading(true)

        InvoiceApi.create(
            props.token,
            props.me.id,
            props.customer.id,
            invoice.invoiceType,
            invoice.ref,
            invoice.refDate,
            invoice.invoiceItems,
            invoice.comment,
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as InvoiceCreateResponse

                let newInvoice = InvoiceFactory.fromFragment(res.data)
                props.cacheUpdater(newInvoice)
                props.onRedirect(newInvoice.id)
            } else {
                res = res as FormErrorResponse;
                setErrors(res.data)
            }
        })
        setLoading(false)
    }

    let setInvoiceItem = (item: InvoiceItem) => {
        let invoiceItem = new InvoiceItem()
        invoiceItem.label = item.label
        invoiceItem.priceHt = 0
        invoiceItem.providerAccountId = item.providerAccountId
        return invoiceItem
    }

    let onSelect = (invoiceId: number) => {
        let selectedInvoice = props.invoices.find(
            (invoice) => invoice.id === invoiceId
        )

        if (selectedInvoice) {
            let invoice = new Invoice()
            invoice.ref = selectedInvoice.ref
            invoice.refDate = selectedInvoice.refDate
            invoice.invoiceType = selectedInvoice.invoiceType
            invoice.comment = selectedInvoice.comment
            invoice.submittedAt = null

            invoice.invoiceItems = selectedInvoice.invoiceItems
                .map((item) => (setInvoiceItem(item)))

            setInvoice(invoice)
        } else {
            setInvoice(newInvoice)
        }
    }


    return (
        <div className={'mt-6'}>
            <div className={'flex items-center justify-end mt-6 mb-10'}>
                <CustomerLinks customerId={props.customer.id} me={props.me} />
            </div>

            {
                props.customer.comment !== '' &&
                <div className={'mt-10 p-4 bg-slate-100 text-br-blue-900 rounded-md'}>
                    <span><b>Commentaire</b> :</span>
                    <p className={'whitespace-pre'}>{props.customer.comment}</p>
                </div>
            }

            {
                props.invoices.length > 0 &&
                <SelectInvoiceTemplate
                    invoices={props.invoices}
                    onSelect={onSelect}
                    disabled={loading}
                />
            }

            <InvoiceForm
                customer={props.customer}
                invoice={invoice}
                onSubmit={onSubmit}
                onSubmitMessage={new FormNotification()}
                providerAccounts={props.providerAccounts}
                disabled={loading}
                errors={errors}
                statsCustomer={props.statsCustomer}
                providerAccountInvoices={props.providerAccountInvoices}
            />
        </div>
    )
}