import React from "react";
import {Column} from "@boomrank/react-components";


interface Props {
    defaultColumns: Column<any>[]
}

export function TableLoader(props: Props) {
    return (
        <div
            data-testid={'table-loader'}
            className={'animate-pulse mt-4 w-full p-4 bg-gray-50 rounded shadow-md'}
        >
            <div className={'w-full p-3.5 flex justify-between items-center'}>
                {
                    props.defaultColumns.map((c, idx) => {
                        return (
                            <div key={idx} className={'w-20 h-3 rounded-lg bg-slate-500'}/>
                        )
                    })
                }
            </div>
            {
                props.defaultColumns.map((c, idx) => {
                    return (
                        <div key={idx} className={'w-full p-3.5 flex justify-between items-center'}>
                            {
                                props.defaultColumns.map((c, idx) => {
                                    return <div key={idx} className={'w-32 h-2 rounded-lg bg-slate-300'}/>
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}