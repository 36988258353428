import React from "react";
import {Link} from "react-router-dom";
import {DashboardTemplate, FeedbackTypeformPopover, ProfileMenu} from '@boomrank/react-components';
import {v4} from 'uuid';
import {Account} from "../../../models";
import {getTranslation} from "../../../intl";


export function Template(props: {
    me: Account
    headline: React.ReactElement | React.ReactElement[] | string
    children?: React.ReactElement | React.ReactElement[] | string
}) {
    let profileItems = [
        <a
            key={v4()}
            className={'hover:text-br-blue-500'}
            href={'https://boomrank.io/dashboard/profile/'}
            target={'_blank'} rel="noreferrer"
        >
            {getTranslation('DUMMIES.TEMPLATE.MENU_ITEMS.ME')}
        </a>,
        <Link
            key={v4()}
            className={'hover:text-br-blue-500'}
            to={
                props.me.isAdmin() ? '/dashboard/financial-invoices' : '/dashboard/invoices'
            }
        >
            {getTranslation('DUMMIES.TEMPLATE.MENU_ITEMS.INVOICES')}
        </Link>,
        <Link
            key={v4()}
            className={'hover:text-br-blue-500'}
            to={'/logout'}
        >
            {getTranslation('DUMMIES.TEMPLATE.MENU_ITEMS.LOGOUT')}
        </Link>
    ]

    if (props.me.isAdmin()) {
        profileItems.splice(2, 0,
            <Link
                key={v4()}
                className={'hover:text-br-blue-500'}
                to={'/dashboard/fees'}
            >
                {getTranslation('DUMMIES.TEMPLATE.MENU_ITEMS.FEES')}
            </Link>,
        )
    }


    let menuItems = [
        <Link
            key={v4()}
            to={'/dashboard/notifications'}
            title={getTranslation('DUMMIES.TEMPLATE.MENU_ITEMS.NOTIFICATION_TITLE')}
            className={'btn-blue no-underline mr-4 py-4 w-14 rounded-full text-center hidden sm:block'}
        >
            <i className="fa-solid fa-bell" />
        </Link>,
        <ProfileMenu
            key={v4()}
            user={props.me.user}
            items={profileItems}
        />
    ]

    if (props.me.user.email.indexOf('@digimood.com') !== -1) {
        menuItems.unshift(
            <a href={'https://faq.digimood.io/docs/documentation-seo/boomrank-9905/'}
               target={'_blank'}
               key={v4()}
               title={'Besoin d\'aide ?'}
               className={'mr-4 py-4 w-14 text-center rounded-full bg-br-green text-white cursor-pointer no-underline hidden sm:block'}
            >
                <i className="fa-regular fa-circle-question" />
            </a>,
        )
    }

    return (
        <DashboardTemplate
            headline={
                <h1 className={"text-white"}>{props.headline}</h1>
            }
            logo={
                <Link
                    to={'/dashboard/'}
                    className={'uppercase font-bold no-underline text-sm md:text-lg lg:text-2xl'}
                >
                    Monitoring <span className={'text-gray-400'}>SEA</span>
                </Link>
            }
            menuItems={menuItems}
        >

            <div className="w-full mx-auto lg:pt-6 pb-24">
                { props.children }
            </div>

            <div className={'text-xs text-br-green text-right'}>
                build #{ process.env.REACT_APP_RELEASE_BUILD } - { process.env.REACT_APP_RELEASE_DATE }
                {
                    <FeedbackTypeformPopover
                        id={'b6tP5J7P'}
                        hidden={{
                            'email': props.me.user.email,
                            'firstname': props.me.user.firstname,
                            'url': window.location.href,
                            'application': 'monitoring-sea',
                            'build': `${process.env.REACT_APP_RELEASE_BRANCH}-${process.env.REACT_APP_RELEASE_BUILD}`,
                        }}
                        autoClose={3000}
                    />
                }
            </div>
        </DashboardTemplate>
    )
}