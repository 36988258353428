import React, {useEffect, useState, Dispatch, SetStateAction} from "react";
import {Route, Routes} from "react-router-dom";

import {
    Account,
    AccountHasCustomer,
    BudgetAccountGroup,
    Campaign,
    CampaignGroup,
    CampaignType,
    Customer,
    Identifier,
    Provider,
    ProviderAccount,
    Notification,
    Invoice,
    Fee, FinancialInvoice
} from "../../models";
import {PeriodKey, StepPeriod, StepPeriodFactory} from "../../models/stepPeriod";
import {StandaloneCampaignGroup} from "../../models/standalone";

import {AccountApi} from "../../services/account/api";
import {AccountFactory} from "../../services/account/factory";
import {AccountListResponse} from "../../services/account/responses";

import {CustomerApi} from "../../services/customer/api";
import {CustomerFactory} from "../../services/customer/factory";
import {CustomerListResponse} from "../../services/customer/responses";

import {AccountHasCustomerApi} from "../../services/account-has-customer/api";
import {AccountHasCustomerFactory} from "../../services/account-has-customer/factory";
import {AccountHasCustomerListResponse} from "../../services/account-has-customer/responses";

import {ProviderApi} from "../../services/provider/api";
import {ProviderFactory} from "../../services/provider/factory";
import {ProviderListResponse} from "../../services/provider/responses";

import {ProviderAccountApi} from "../../services/provider-account/api";
import {ProviderAccountFactory} from "../../services/provider-account/factory";
import {ProviderAccountListResponse} from "../../services/provider-account/responses";

import {CampaignTypeApi} from "../../services/campaign-type/api";
import {CampaignTypeFactory} from "../../services/campaign-type/factory";
import {CampaignTypeListResponse} from "../../services/campaign-type/responses";

import {CampaignApi} from "../../services/campaign/api";
import {CampaignFactory} from "../../services/campaign/factory";
import {CampaignListResponse} from "../../services/campaign/responses";

import {CampaignGroupApi} from "../../services/campaign-group/api";
import {CampaignGroupFactory} from "../../services/campaign-group/factory";
import {CampaignGroupListResponse} from "../../services/campaign-group/responses";

import {BudgetAccountGroupApi} from "../../services/budget-account-group/api";
import {BudgetAccountGroupFactory} from "../../services/budget-account-group/factory";
import {BudgetAccountGroupListResponse} from "../../services/budget-account-group/responses";

import {InvoiceApi} from "../../services/invoice/api";
import {InvoiceListResponse} from "../../services/invoice/responses";
import {InvoiceFactory} from "../../services/invoice/factory";

import {FeeApi} from "../../services/fee/api";
import {FeeListResponse} from "../../services/fee/responses";
import {FeeFactory} from "../../services/fee/factory";

import {NotificationApi} from "../../services/notification/api";
import {NotificationFactory} from "../../services/notification/factory";
import {NotificationListResponse} from "../../services/notification/responses";


import {HomeRoute} from "./routes/home";
import {CustomerAddRoute} from "./routes/customer/add";
import {CustomerGetRoute} from "./routes/customer/get";
import {CustomerEditRoute} from "./routes/customer/edit";
import {BudgetRoute} from "./routes/budget";
import {CampaignGetRoute} from "./routes/campaign/get";
import {CampaignGroupListRoute} from "./routes/campaign-group/list";
import {CampaignGroupGetRoute} from "./routes/campaign-group/get";
import {LogListRoute} from "./routes/log/list";
import {NotificationListRoute} from "../notification/routes/list";

import {Cache, JwtToken, PlaceHolder} from "@boomrank/react-components";
import {CustomerRoute} from "./routes/customer";
import {FeeListRoute} from "./routes/fee/list";
import {FeeAddRoute} from "./routes/fee/add";
import {InvoiceListRoute} from "./routes/invoice/list";
import {InvoiceEditRoute} from "./routes/invoice/edit";
import {InvoiceAddRoute} from "./routes/invoice/add";
import {InvoiceRoute} from "./routes/invoice";
import {CustomerFeeRoute} from "./routes/customer/fee";
import {FinancialRoute} from "../financial/routes";


interface Props {
    token: JwtToken
    me: Account
}

export function Monitoring(props: Props) {
    let [cacheAccounts, setCacheAccounts] = useState<Cache<Account>>(new Cache<Account>('id'));
    let [cacheCustomers, setCacheCustomers] = useState<Cache<Customer>>(new Cache<Customer>('id'));
    let [cacheProviders, setCacheProviders] = useState<Cache<Provider>>(new Cache<Provider>('id'));
    let [cacheCampaignTypes, setCacheCampaignTypes] = useState<Cache<CampaignType>>(new Cache<CampaignType>('id'));
    let [cacheAccountHasCustomers, setCacheAccountHasCustomers] = useState<Cache<AccountHasCustomer>>(new Cache<AccountHasCustomer>('id'));
    let [cacheProviderAccounts, setCacheProviderAccounts] = useState<Cache<ProviderAccount>>(new Cache<ProviderAccount>('id'))
    let [cacheCampaignGroups, setCacheCampaignGroups] = useState<Cache<CampaignGroup>>(new Cache<CampaignGroup>('id'));
    let [cacheCampaigns, setCacheCampaigns] = useState<Cache<Campaign>>(new Cache<Campaign>('id'));
    let [cacheBudgetAccountGroups, setCacheBudgetAccountGroups] = useState<Cache<BudgetAccountGroup>>(new Cache<BudgetAccountGroup>('id'));
    let [cacheInvoices, setCacheInvoices] = useState<Cache<Invoice>>(new Cache<Invoice>('id'))
    let [cacheFees, setCacheFees] = useState<Cache<Fee>>(new Cache<Fee>('id'))
    let [cacheNotifications, setCacheNotifications] = useState<Cache<Notification>>(new Cache<Notification>('id'));

    let [accountsLoaded, setAccountsLoaded] = useState<boolean>(false)
    let [customersLoaded, setCustomersLoaded] = useState<boolean>(false)
    let [providersLoaded, setProvidersLoaded] = useState<boolean>(false)
    let [campaignTypesLoaded, setCampaignTypesLoaded] = useState<boolean>(false)
    let [accountHasCustomersLoaded, setAccountHasCustomersLoaded] = useState<boolean>(false)
    let [providerAccountsLoaded, setProviderAccountsLoaded] = useState<boolean>(false)
    let [campaignGroupsLoaded, setCampaignGroupsLoaded] = useState<boolean>(false)
    let [campaignsLoaded, setCampaignsLoaded] = useState<boolean>(false)
    let [budgetAccountGroupsLoaded, setBudgetAccountGroupsLoaded] = useState<boolean>(false)
    let [invoicesLoaded, setInvoicesLoaded] = useState<boolean>(false)
    let [feesLoaded, setFeesLoaded] = useState<boolean>(false)
    let [notificationsLoaded, setNotificationsLoaded] = useState<boolean>(false)

    let [stepPeriod, setStepPeriod] = useState<StepPeriod>(
        StepPeriodFactory.getStepPeriod(PeriodKey.CURRENT_MONTH)
    )


    let getAccounts = (url?: string) => {
        AccountApi.list(props.token, url).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as AccountListResponse;

                let accounts = res.data.results.map((fragment) => {
                    return AccountFactory.fromFragment(fragment)
                });
                let cache = new Cache<Account>('id').add(accounts)
                setCacheAccounts(cacheAccounts => cacheAccounts.merge(cache))

                if (res.data.next) {
                    return getAccounts(res.data.next);
                }
            }
            setAccountsLoaded(true)
        })
    }

    let getCustomers = (url?: string) => {
        CustomerApi.list(props.token, url).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as CustomerListResponse;

                let customers = res.data.results.map((fragment) => {
                    return CustomerFactory.fromFragment(fragment)
                });
                let cache = new Cache<Customer>('id').add(customers)
                setCacheCustomers(cacheCustomers => cacheCustomers.merge(cache))

                if (res.data.next) {
                    return getCustomers(res.data.next);
                }
            }
            setCustomersLoaded(true)
        })
    }

    let getCampaignTypes = (url?: string) => {
        CampaignTypeApi.list(props.token, url).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as CampaignTypeListResponse;

                let campaignTypes = res.data.results.map((fragment) => {
                    return CampaignTypeFactory.fromFragment(fragment);
                })
                let cache = new Cache<CampaignType>('id').add(campaignTypes)
                setCacheCampaignTypes(cacheCampaignTypes => cacheCampaignTypes.merge(cache))

                if (res.data.next) {
                    return getCampaignTypes(res.data.next);
                }
            }
            setCampaignTypesLoaded(true)
        })
    }

    let getProviders = (url?: string) => {
        ProviderApi.list(props.token, url).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as ProviderListResponse;

                let providers = res.data.results.map((fragment) => {
                    return ProviderFactory.fromFragment(fragment);
                })
                let cache = new Cache<Provider>('id').add(providers)
                setCacheProviders(cacheProviders => cacheProviders.merge(cache))

                if (res.data.next) {
                    return getProviders(res.data.next);
                }
            }
            setProvidersLoaded(true)
        })
    }

    let getAccountHasCustomers = (url?: string) => {
        if (!url) {
            setCacheAccountHasCustomers(cacheAccountHasCustomers)
        }

        AccountHasCustomerApi.list(props.token, url).then((res) => {
            if(res.statusCode >= 200 && res.statusCode < 300) {
                res = res as AccountHasCustomerListResponse;

                let accountHasCustomers = res.data.results.map((fragment) => {
                    return AccountHasCustomerFactory.fromFragment(fragment);
                })
                let cache = new Cache<AccountHasCustomer>('id').add(accountHasCustomers)
                setCacheAccountHasCustomers(cacheAccountHasCustomers => cacheAccountHasCustomers.merge(cache))

                if (res.data.next) {
                    return getAccountHasCustomers(res.data.next);
                }
            }
            setAccountHasCustomersLoaded(true)
        })
    }

    let getProviderAccounts = (url?: string) => {
        ProviderAccountApi.list(props.token, url).then((res) => {
            if(res.statusCode >= 200 && res.statusCode < 300) {
                res = res as ProviderAccountListResponse;

                let providerAccounts = res.data.results.map((fragment) => {
                    return ProviderAccountFactory.fromFragment(fragment)
                })
                let cache = new Cache<ProviderAccount>('id').add(providerAccounts)
                setCacheProviderAccounts(cacheProviderAccounts => cacheProviderAccounts.merge(cache))

                if (res.data.next) {
                    return getProviderAccounts(res.data.next);
                }
            }
            setProviderAccountsLoaded(true)
        })
    }

    let getCampaigns = (url?: string) => {
        CampaignApi.list(props.token, url).then((res) => {
            if(res.statusCode >= 200 && res.statusCode < 300) {
                res = res as CampaignListResponse;

                let campaigns = res.data.results.map((fragment) => {
                    return CampaignFactory.fromFragment(fragment)
                })
                let cache = new Cache<Campaign>('id').add(campaigns)
                setCacheCampaigns(cacheCampaigns => cacheCampaigns.merge(cache))

                if (res.data.next) {
                    return getCampaigns(res.data.next);
                }
            }
            setCampaignsLoaded(true)
        })
    }

    let getCampaignGroups = (url?: string) => {
        CampaignGroupApi.list(props.token, url).then((res) => {
            if(res.statusCode >= 200 && res.statusCode < 300) {
                res = res as CampaignGroupListResponse;

                let campaignGroups = res.data.results.map((fragment) => {
                    return CampaignGroupFactory.fromFragment(fragment)
                })
                let cache = new Cache<CampaignGroup>('id').add(campaignGroups)
                setCacheCampaignGroups(cacheCampaignGroups => cacheCampaignGroups.merge(cache))

                if (res.data.next) {
                    return getCampaignGroups(res.data.next);
                }
            }
            setCampaignGroupsLoaded(true)
        })
    }

    let getBudgetAccountGroups = (url?: string) => {
        BudgetAccountGroupApi.list(props.token, url).then((res) => {
            if(res.statusCode >= 200 && res.statusCode < 300) {
                res = res as BudgetAccountGroupListResponse;

                let budgetAccountGroups = res.data.results.map((fragment) => {
                    return BudgetAccountGroupFactory.fromFragment(fragment)
                })
                let cache = new Cache<BudgetAccountGroup>('id').add(budgetAccountGroups)
                setCacheBudgetAccountGroups(cacheBudgetAccountGroups => cacheBudgetAccountGroups.merge(cache))

                if (res.data.next) {
                    return getBudgetAccountGroups(res.data.next);
                }
            }
            setBudgetAccountGroupsLoaded(true)
        })
    }

    let getInvoices = (url?: string) => {
        InvoiceApi.list(props.token, url).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as InvoiceListResponse

                let invoices = res.data.results.map((fragment) => {
                    return InvoiceFactory.fromFragment(fragment)
                })
                let cache = new Cache<Invoice>('id').add(invoices)
                setCacheInvoices(cacheInvoices => cacheInvoices.merge(cache))

                if (res.data.next) {
                    return getInvoices(res.data.next)
                }
            }
            setInvoicesLoaded(true)
        })
    }

    let getFees = (url?: string) => {
        FeeApi.list(props.token, url).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as FeeListResponse
                let fees = res.data.results.map((fragment) => {
                    return FeeFactory.fromFragment(fragment)
                })
                let cache = new Cache<Fee>('id').add(fees)
                setCacheFees(cacheFees => cacheFees.merge(cache))

                if (res.data.next) {
                    return getFees(res.data.next)
                }
            }
            setFeesLoaded(true)
        })
    }

    let getNotifications = (url?: string) => {
        NotificationApi.list(props.token, url).then((res) => {
            if(res.statusCode >= 200 && res.statusCode < 300) {
                res = res as NotificationListResponse;

                let notifications = res.data.results.map((fragment) => {
                    return NotificationFactory.fromFragment(fragment)
                })
                let cache = new Cache<Notification>('id').add(notifications)
                setCacheNotifications(cacheNotifications => cacheNotifications.merge(cache))

                if (res.data.next) {
                    return getNotifications(res.data.next);
                }
            }
            setNotificationsLoaded(true)
        })
    }

    let cacheUpdater = (obj: Identifier, remove = false) => {
        let cache: Cache<any> | undefined
        let setCache: Dispatch<SetStateAction<Cache<any>>> | undefined

        if (obj instanceof Customer) {
            cache = cacheCustomers
            setCache = setCacheCustomers
        }
        if (obj instanceof Account) {
            cache = cacheAccounts
            setCache = setCacheAccounts
        }
        if (obj instanceof AccountHasCustomer) {
            cache = cacheAccountHasCustomers
            setCache = setCacheAccountHasCustomers
        }
        if (obj instanceof ProviderAccount) {
            cache = cacheProviderAccounts
            setCache = setCacheProviderAccounts
        }
        if (obj instanceof CampaignGroup || obj instanceof StandaloneCampaignGroup) {
            cache = cacheCampaignGroups
            setCache = setCacheCampaignGroups
        }
        if (obj instanceof Campaign) {
            cache = cacheCampaigns
            setCache = setCacheCampaigns
        }
        if (obj instanceof BudgetAccountGroup) {
            cache = cacheBudgetAccountGroups
            setCache = setCacheBudgetAccountGroups
        }
        if (obj instanceof Invoice) {
            cache = cacheInvoices
            setCache = setCacheInvoices
        }
        if (obj instanceof Fee) {
            cache = cacheFees
            setCache = setCacheFees
        }
        if (obj instanceof Notification) {
            cache = cacheNotifications
            setCache = setCacheNotifications
        }

        if (cache !== undefined && setCache !== undefined) {
            cache.add(obj)
            if (remove) {
                cache.delete(obj)
            }
            setCache({
                ...cache
            })
        }
    }

    let refreshCacheInvoices = () => {
        getInvoices()
    }


    useEffect(() => {
        getAccounts()
        getCustomers()
        getAccountHasCustomers()
        getProviders()
        getCampaignTypes()
        getProviderAccounts()
        getCampaignGroups()
        getCampaigns()
        getBudgetAccountGroups()
        getInvoices()
        getFees()
        getNotifications()
    }, [props.me])

    let onStepPeriodChange = (stepPeriod: StepPeriod) => {
        setStepPeriod(stepPeriod)
    }

    
    if (
        accountsLoaded &&
        customersLoaded &&
        accountHasCustomersLoaded &&
        providersLoaded &&
        campaignTypesLoaded &&
        providerAccountsLoaded &&
        campaignGroupsLoaded &&
        campaignsLoaded &&
        budgetAccountGroupsLoaded &&
        invoicesLoaded &&
        feesLoaded &&
        notificationsLoaded
    ) {
        return (
            <div>
                <Routes>
                    <Route
                        index
                        element={
                            <HomeRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheAccounts={cacheAccounts}
                                cacheAccountHasCustomers={cacheAccountHasCustomers}
                                cacheProviderAccounts={cacheProviderAccounts}
                                cacheCampaignGroups={cacheCampaignGroups}
                                cacheInvoices={cacheInvoices}
                                cacheNotifications={cacheNotifications}
                                cacheUpdater={cacheUpdater}
                                stepPeriod={stepPeriod}
                                onStepPeriodChange={onStepPeriodChange}
                            />
                        }
                    />
                    <Route
                        path={'customer/add'}
                        element={
                            <CustomerAddRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheAccounts={cacheAccounts}
                                cacheUpdater={cacheUpdater}
                            />
                        }
                    />
                    <Route
                        path={'customer/:customerId'}
                        element={
                            <CustomerRoute
                                cacheCustomers={cacheCustomers}
                                cacheCampaignGroups={cacheCampaignGroups}
                            />
                        }
                    />
                    <Route
                        path={'customer/:customerId/detail'}
                        element={
                            <CustomerGetRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheCampaigns={cacheCampaigns}
                                cacheAccountHasCustomers={cacheAccountHasCustomers}
                                cacheProviders={cacheProviders}
                                cacheProviderAccounts={cacheProviderAccounts}
                                cacheAccounts={cacheAccounts}
                                cacheCampaignTypes={cacheCampaignTypes}
                                onStepPeriodChange={onStepPeriodChange}
                                stepPeriod={stepPeriod}
                            />
                        }
                    />
                    <Route
                        path={'customer/:customerId/edit'}
                        element={
                            <CustomerEditRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheAccountHasCustomers={cacheAccountHasCustomers}
                                cacheAccounts={cacheAccounts}
                                cacheProviders={cacheProviders}
                                cacheProviderAccounts={cacheProviderAccounts}
                                cacheUpdater={cacheUpdater}
                            />
                        }
                    />
                    <Route
                        path={'customer/:customerId/budgets'}
                        element={
                            <BudgetRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheAccountHasCustomers={cacheAccountHasCustomers}
                                cacheProviderAccounts={cacheProviderAccounts}
                                cacheCampaignGroups={cacheCampaignGroups}
                                cacheBudgetAccountGroups={cacheBudgetAccountGroups}
                                cacheProviders={cacheProviders}
                                cacheUpdater={cacheUpdater}
                            />
                        }
                    />
                    <Route
                        path={'customer/:customerId/campaign-groups'}
                        element={
                            <CampaignGroupListRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheAccountHasCustomers={cacheAccountHasCustomers}
                                cacheProviders={cacheProviders}
                                cacheProviderAccounts={cacheProviderAccounts}
                                cacheCampaignGroups={cacheCampaignGroups}
                                cacheAccounts={cacheAccounts}
                                cacheUpdater={cacheUpdater}
                                stepPeriod={stepPeriod}
                                onStepPeriodChange={onStepPeriodChange}
                            />
                        }
                    />
                    <Route
                        path={'customer/:customerId/campaign-group/:campaignGroupId'}
                        element={
                            <CampaignGroupGetRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheAccountHasCustomers={cacheAccountHasCustomers}
                                cacheProviders={cacheProviders}
                                cacheProviderAccounts={cacheProviderAccounts}
                                cacheCampaignGroups={cacheCampaignGroups}
                                cacheAccounts={cacheAccounts}
                                cacheCampaignTypes={cacheCampaignTypes}
                                onStepPeriodChange={onStepPeriodChange}
                                stepPeriod={stepPeriod}
                            />
                        }
                    />
                    <Route
                        path={'customer/:customerId/campaign/:campaignId'}
                        element={
                            <CampaignGetRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheProviders={cacheProviders}
                                cacheProviderAccounts={cacheProviderAccounts}
                                cacheAccounts={cacheAccounts}
                                cacheAccountHasCustomers={cacheAccountHasCustomers}
                                cacheCampaigns={cacheCampaigns}
                                cacheCampaignTypes={cacheCampaignTypes}
                                onStepPeriodChange={onStepPeriodChange}
                                stepPeriod={stepPeriod}
                            />
                        }
                    />
                    <Route
                        path={'customer/:customerId/invoices'}
                        element={
                            <InvoiceListRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheInvoices={cacheInvoices}
                                cacheAccounts={cacheAccounts}
                            />
                        }
                    />
                    <Route
                        path={'customer/:customerId/invoices/add'}
                        element={
                            <InvoiceAddRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheProviderAccounts={cacheProviderAccounts}
                                cacheInvoices={cacheInvoices}
                                cacheUpdater={cacheUpdater}
                            />
                        }
                    />
                    <Route
                        path={'customer/:customerId/invoices/:invoiceId/edit'}
                        element={
                            <InvoiceEditRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheInvoices={cacheInvoices}
                                cacheProviderAccounts={cacheProviderAccounts}
                                cacheUpdater={cacheUpdater}
                            />
                        }
                    />
                    <Route
                        path={'customer/:customerId/logs'}
                        element={
                            <LogListRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheAccounts={cacheAccounts}
                            />
                        }
                    />
                    <Route
                        path={'customer/:customerId/fees'}
                        element={
                            <FeeListRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheFees={cacheFees}
                                cacheUpdater={cacheUpdater}
                            />
                        }
                    />

                    <Route
                        path={'customer/:customerId/fees/add'}
                        element={
                            <FeeAddRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheFees={cacheFees}
                                cacheUpdater={cacheUpdater}
                            />
                        }
                    />
                    <Route
                        path={'invoices'}
                        element={
                            <InvoiceRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheInvoices={cacheInvoices}
                                cacheAccounts={cacheAccounts}
                                cacheAccountHasCustomers={cacheAccountHasCustomers}
                            />
                        }
                    />
                    <Route
                        path={'fees'}
                        element={
                            <CustomerFeeRoute
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheInvoices={cacheInvoices}
                                cacheAccounts={cacheAccounts}
                                cacheFees={cacheFees}
                            />
                        }
                    />
                    <Route
                        path={'notifications'}
                        element={
                            <NotificationListRoute
                                token={props.token}
                                me={props.me}
                                cacheCustomers={cacheCustomers}
                                cacheAccountHasCustomers={cacheAccountHasCustomers}
                                cacheNotifications={cacheNotifications}
                                cacheUpdater={cacheUpdater}
                            />
                        }
                    />
                    <Route
                        path={'financial-invoices/*'}
                        element={
                            <FinancialRoute
                                token={props.token}
                                me={props.me}
                                cacheAccounts={cacheAccounts}
                                cacheCustomers={cacheCustomers}
                                cacheInvoices={cacheInvoices}
                                cacheFees={cacheFees}
                                cacheUpdater={cacheUpdater}
                                refreshCacheInvoices={refreshCacheInvoices}
                            />
                        }
                    />
                </Routes>
            </div>
        )
    }

    return <PlaceHolder className={'h-32 mt-10'} />
}
