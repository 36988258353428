import React from "react";
import {getTranslation} from "../../../../../../../intl";


export function CustomerOperationalTableHeaderView() {
    return (
        <div className={'w-full p-2 flex justify-between font-medium'}>
                <span className={'w-1/5'}>
                    {getTranslation('MONITORING.VIEWS.CUSTOMER.OPERATIONAL.NAME')}
                </span>

            <div className={'w-3/5 flex items-start justify-between'}>
                    <span className={'w-14 text-right'}>
                        {getTranslation('MONITORING.VIEWS.CUSTOMER.OPERATIONAL.PROVIDER')}
                    </span>
                <span className={'w-1/5 text-right'}>
                        {getTranslation('MONITORING.VIEWS.CUSTOMER.OPERATIONAL.BUDGET')}
                    </span>
                <span className={'w-1/5 text-right'}>
                        {getTranslation('MONITORING.VIEWS.CUSTOMER.OPERATIONAL.COST')}
                    </span>
                <span className={'w-1/5 text-right'}>
                        {getTranslation('MONITORING.VIEWS.CUSTOMER.OPERATIONAL.TREND')}
                    </span>
                <span className={'w-1/5 text-right'}>
                        {getTranslation('MONITORING.VIEWS.CUSTOMER.OPERATIONAL.SUGGESTED')}
                    </span>
            </div>

            <span className={'w-28 text-right'}>
                   {getTranslation('MONITORING.VIEWS.CUSTOMER.OPERATIONAL.ACTION')}
                </span>
        </div>
    )
}