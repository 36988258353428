import React from "react";
import classNames from "classnames";
import {FormBudgetTotal} from "./budget-total";


interface Props {
    parentBudget: number | null
    budgetMin: number
    budgetMax: number
    budgetMinError: string
    budgetMaxError: string
    className?: string
}


export function FormBudgets(props: Props) {
    return (
        <div className={classNames(
            'flex items-end',
            props.className
        )}>
            <FormBudgetTotal
                parentBudget={props.parentBudget}
                budget={props.budgetMin!}
                error={props.budgetMinError}
            />
            <FormBudgetTotal
                parentBudget={props.parentBudget}
                budget={props.budgetMax}
                error={props.budgetMaxError}
            />
        </div>
    )
}