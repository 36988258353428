import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import classNames from "classnames";
import {Cache, JwtToken, PlaceHolder, SecureHTML} from "@boomrank/react-components";

import {defaultLanguage, getTranslation} from "../../../intl";
import {Customer, Identifier, Notification} from "../../../models";
import {NotificationApi} from "../../../services/notification/api";
import {NotificationUpdateResponse} from "../../../services/notification/responses";
import {NotificationFactory} from "../../../services/notification/factory";


interface Props {
    token: JwtToken
    cacheCustomers: Cache<Customer>
    cacheNotifications: Cache<Notification>
    cacheUpdater: (obj: Identifier) => void
}

export function UnreadNotificationListView(props: Props) {
    let [loading, setLoading] = useState<boolean>(false)
    let [notifications, setNotifications] = useState<Notification[]>([])

    let date = new Date(Date.now())
    let fromOneWeek = new Date(date.setDate(date.getDate() - date.getDay() - 6))

    let getNotifications = () => {
        let customerIds = props.cacheCustomers.values().map(c => c.id)

        let notifications = props.cacheNotifications.values()
            .filter(n =>
                customerIds.indexOf(n.customerId) !== -1 &&
                n.createdAt > fromOneWeek &&
                !n.markAsRead
            )
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))

        setNotifications(notifications)

        if (notifications.length === 0) {
            setLoading(false)
        }
    }

    let onClick = () => {
        setLoading(true)
        markAllAsRead(notifications)

        if (notifications.length === 0) {
            setLoading(false)
        }
    }

    let markAllAsRead = (notifications: Notification[]) => {
        notifications.forEach((notification) => {
            NotificationApi.update(
                props.token,
                notification.id,
                !notification.markAsRead
            ).then((res) => {
                if (res.statusCode >= 200 && res.statusCode < 300) {
                    res = res as NotificationUpdateResponse;
                    props.cacheUpdater(NotificationFactory.fromFragment(res.data));
                }
            })
        })
    }

    useEffect(() => {
        getNotifications()
    }, [props.cacheNotifications]);


    if (loading) {
        return (
            <PlaceHolder
                className={'p-6 lg:h-full lg:max-h-[550px] border-2 border-slate-200 rounded-xl'}
            />
        )
    }

    return (
        <div className={'p-6 lg:max-h-[550px] border-2 border-slate-200 rounded-lg'}>
            <div className={'w-full pb-3 flex justify-between items-center'}>
                <h3 className={'my-0'}>
                    <Link to={'/dashboard/notifications/'} className={'mr-1'}>Notifications récentes</Link>
                    {
                        notifications &&
                        <span>({notifications.length})</span>
                    }
                </h3>

                <button
                    className={classNames(
                        'w-10 h-12 rounded-sm',
                        {
                            'btn-gray': notifications.length === 0,
                            'btn-blue': notifications.length > 0,
                        }
                    )}
                    title={'Marquer comme lue la sélection'}
                    onClick={onClick}
                >
                    <i className="fa-regular fa-flag" />
                </button>
            </div>

            <ul className={'flex flex-col overflow-y-auto h-96 lg:h-auto lg:max-h-[450px] p-2'}>
                {
                    notifications.length > 0 &&
                    notifications.map((notification: Notification, idx) => {
                        let createdAt = notification.createdAt.toLocaleDateString(
                            defaultLanguage,
                            { year:"numeric", month:"numeric", day:"numeric"}
                        )

                        let customer = props.cacheCustomers.values()
                            .find((c) => c.id === notification.customerId)

                        if (customer) {
                            let linkTo = `/dashboard/customer/${notification.customerId}/detail`

                            if (['Nx000', 'Nx001'].indexOf(notification.code) !== -1) {
                                linkTo = `/dashboard/customer/${notification.customerId}/invoices/add`
                            }

                            return (
                                <li
                                    key={idx}
                                    className={'py-1.5 flex flex-col border-t-2 border-t-white'}
                                >
                                    <Link className={'font-medium w-fit'} to={linkTo}>
                                        {customer.name}
                                    </Link>

                                    <div>
                                        <span className={'text-slate-500'}>{createdAt} : </span>
                                        {
                                            notification.message &&
                                            <span>{notification.message}</span>
                                        }

                                        {
                                            !notification.message &&
                                            <SecureHTML>
                                                {
                                                    getTranslation(`NOTIFICATION.VIEWS.LIST_UNREAD.CODES.${notification.code.toUpperCase()}`)
                                                        .format(...notification.args)
                                                }
                                            </SecureHTML>
                                        }
                                    </div>
                                </li>
                            )
                        }

                        return <div key={idx}></div>
                    })
                }
            </ul>
        </div>
    )
}