import {ChartEntry, CumulativeChartData} from "@boomrank/react-components";
import React, {useMemo} from "react";
import {
    StatCampaign,
    StatCampaignGroup,
    StatCustomer
} from "../../../../models/stats";
import {CustomTooltip} from "../tooltip";
import {StepPeriod} from "../../../../models/stepPeriod";
import {BarChart} from "./chart";
import {getTranslation} from "../../../../intl";
import {CumulativeChartDataWithDate} from "./models";


export const GOOGLE_COLORS = [
    "#DB4437",
    "#C23D30",
    "#E8493A",
    "#9C3127",
    "#5C1D17",
]
export const META_COLORS = [
    "#1778F2",
    "#166DD9",
    "#1981FF",
    "#125AB3",
    "#0B3A73",
]

interface Props {
    data: StatCustomer[] | StatCampaignGroup[] | StatCampaign[]
    stepPeriod: StepPeriod
    budgetMin?: number | null | undefined
    budgetMax?: number | null | undefined
    budgetMinLabel?: string
    budgetMaxLabel?: string
}

export function ComposedGraph(props: Props) {
    let startDate = props.stepPeriod.period.start
    let endDate = props.stepPeriod.period.end

    let setColorByProvider = (chartData: CumulativeChartData) => {
        let googleStats: ChartEntry[] = []
        let metaStats: ChartEntry[] = []

        Object.keys(chartData.entries).forEach((entryKey) => {
            let chartEntry = chartData.getEntry(entryKey)

            chartEntry!.stackId = 'a'
            chartEntry!.label = entryKey

            if (entryKey.includes('Google')) {
                googleStats.push(chartEntry!)
            } else if (entryKey.includes('Meta')) {
                metaStats.push(chartEntry!)
            }
        })

        googleStats.forEach((entry, idx) => {
            entry.color = GOOGLE_COLORS[idx]
        })
        metaStats.forEach((entry, idx) => {
            entry.color = META_COLORS[idx]
        })

        return chartData
    }

    let getDatesInRange = (start: Date, end: Date) => {
        const date = new Date(start.getTime());
        const dates = [];

        while (date <= end) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

    let generateDefaultValue = (chartData: CumulativeChartDataWithDate, dates: Date[], names: string[]) => {
        dates.forEach((date) => {
            names.forEach((name) => {
                chartData.add(name, date, 0)
            })
        })

        return chartData
    }

    let formatStats = (
        data: StatCustomer[] | StatCampaignGroup[] | StatCampaign[],
        stepPeriod: StepPeriod
    ): CumulativeChartDataWithDate => {
        let chartData = new CumulativeChartDataWithDate()
        let dates = getDatesInRange(stepPeriod.period.start, stepPeriod.period.end)
        let statsName = Array.from(new Set(data.map(item => item.name)))

        generateDefaultValue(chartData, dates, statsName.sort());

        data.forEach((stat) => {
            chartData.add(
                stat.name,
                stat.startedAt,
                stat.cost,
            )
        })

        setColorByProvider(chartData)
        return chartData;
    }

    const data = useMemo(() =>
        formatStats(props.data, props.stepPeriod),
        [props.data, props.stepPeriod]
    )


    let getLastDayOfMonth = (stepPeriod: StepPeriod) => {
        let month = stepPeriod.period.end.getMonth()
        let year = stepPeriod.period.end.getFullYear()

        return new Date(year, month +1, 0).getDate()
    }

    let getEndPeriodBudget = (budget: number, stepPeriod: StepPeriod) => {
        if (stepPeriod.period.key === '1W' || stepPeriod.period.key === '-1W') {
            let lastDay = getLastDayOfMonth(stepPeriod)
            return (budget / lastDay) * 7
        }
        return budget
    }

    let getStartPeriodBudget = (budget: number, stepPeriod: StepPeriod) => {
        if (stepPeriod.period.key === '1W' || stepPeriod.period.key === '-1W') {
            let lastDay = getLastDayOfMonth(stepPeriod)
            return (budget / lastDay)
        }
        return budget / stepPeriod.period.end.getDate()
    }


    return (
        <div className={'w-full h-96 lg:h-[30rem] mb-10 border-2 border-slate-200 rounded-xl pt-3'}>
            <div className={'w-full flex justify-between items-center'}>
                <h4 className={'px-3 text-gray-500 w-fit'}>
                    {getTranslation('DUMMIES.GRAPH.COMPOSED.TITLE')}
                </h4>

                <div className={'flex items-end justify-end text-sm gap-x-6 pr-6 text-gray-500 mt-6'}>
                    {
                        props.budgetMin &&
                        <div className={'flex items-center gap-x-1'}>
                            <span className={'h-2 w-2 rounded-full bg-orange-500'}></span>
                            <p className={'mb-0'}>{getTranslation('DUMMIES.GRAPH.COMPOSED.BUDGET_MIN')}</p>
                        </div>
                    }
                    {
                        props.budgetMax &&
                        <div className={'flex items-center gap-x-1'}>
                            <span className={'h-2 w-2 rounded-full bg-br-green'}></span>
                            <p className={'mb-0'}>{getTranslation('DUMMIES.GRAPH.COMPOSED.BUDGET_MAX')}</p>
                        </div>
                    }
                </div>
            </div>

            <BarChart
                data={data}
                minTickGap={200}
                strokeDasharray={"3, 3"}
                minSegment={
                    props.budgetMin
                    && data.x.length > 1 ?
                        [
                            {
                                x: startDate,
                                y: getStartPeriodBudget(props.budgetMin, props.stepPeriod)
                            },
                            {
                                x: endDate,
                                y: getEndPeriodBudget(props.budgetMin, props.stepPeriod)
                            },
                        ] :
                        []
                }
                maxSegment={
                    props.budgetMax
                    && data.x.length > 1 ?
                        [
                            {
                                x: startDate,
                                y: getStartPeriodBudget(props.budgetMax, props.stepPeriod)
                            },
                            {
                                x: endDate,
                                y: getEndPeriodBudget(props.budgetMax, props.stepPeriod)
                            },
                        ] :
                        []
                }
                tooltip={
                    (props.stepPeriod.period.key === '1W' || props.stepPeriod.period.key === '1M') ?
                        <CustomTooltip
                            datesRange={
                                getDatesInRange(props.stepPeriod.period.start, props.stepPeriod.period.end)
                            }
                            minBudget={
                                getEndPeriodBudget(props.budgetMin!, props.stepPeriod)
                            }
                            maxBudget={
                                getEndPeriodBudget(props.budgetMax!, props.stepPeriod)
                            }
                        />
                        :
                        <CustomTooltip />
                }
                showLegend={true}
            />
        </div>
    )
}