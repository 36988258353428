import {StatCustomerFragment} from "./fragments";
import {StatCustomer} from "../../../models/stats";

export class StatCustomerFactory {
    static fromFragment = (fragment: StatCustomerFragment): StatCustomer => {
        return {
            periodKey: new Date(fragment.period_key),
            startedAt: new Date(fragment.started_at),
            loginId: fragment.login_id,
            cost: fragment.cost,
            step: fragment.step,
            trend: fragment.trend,
            name: fragment.name,
            impressions: fragment.impressions,
            purchases: fragment.purchases,
            purchaseRoas: fragment.purchase_roas,
            costPerPurchase: fragment.cost_per_purchase,
            leads: fragment.leads,
            costPerLead: fragment.cost_per_lead,
            conversions: fragment.conversions,
            conversionsValue: fragment.conversions_value,
        }
    }
}