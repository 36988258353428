import React, {useEffect, useState} from "react";
import {Budget} from "../../../models";
import classNames from "classnames";
import {getTranslation} from "../../../intl";


interface Props {
    budgetMin: number | null
    budgetMax: number | null
    budgetUpdater: (budget: Budget) => void
    disabled?: boolean
    className?: string
    onError?: (budget: Budget) => void
}

export default function BudgetInput(props: Props) {
    let disabled = props.disabled || false
    let [budget, setBudget] = useState<Budget>(new Budget())
    let [error, setError] = useState<boolean>(false)


    let onChangeBudgetMin = (event: React.ChangeEvent<HTMLInputElement>) => {
        checkBudget({
            ...budget,
            budgetMin: convertValue(event.target.value)
        })
    }

    let onChangeBudgetMax = (event: React.ChangeEvent<HTMLInputElement>) => {
        checkBudget({
            ...budget,
            budgetMax: convertValue(event.target.value)
        })
    }

    let convertValue = (value: string) => {
        if (value.length === 0) {
            return null
        }
        return parseFloat(value.replaceAll(',', '.'))
    }

    let budgetIsValid = (budget: Budget) => {
        if(budget.budgetMin !== null && budget.budgetMax !== null) {
            return (budget.budgetMin < budget.budgetMax)
        }
        return true
    }

    let checkBudget = (budget: Budget) => {
        setBudget(budget)

        if (budgetIsValid(budget)) {
            setError(false)
        } else {
            onError(budget)
            setError(true)
        }

        props.budgetUpdater(budget)
    }

    let onError = (budget: Budget) => {
        if (props.onError) {
            props.onError(budget)
        }
    }

    useEffect(() => {
        setBudget({
            budgetMin: props.budgetMin,
            budgetMax: props.budgetMax
        })
    }, [props.budgetMin, props.budgetMax])


    return (
        <div className={
            classNames(
                props.className,
                {'text-red-500 font-medium' : error},
                {'text-black' : !error},
            )
        }>
            <input
                type={"number"}
                name={"budgetMin"}
                defaultValue={budget.budgetMin === null ? '' : budget.budgetMin}
                placeholder={getTranslation('DUMMIES.INPUT.BUDGET.BUDGET_MIN')}
                onChange={onChangeBudgetMin}
                disabled={disabled}
                className={classNames(
                    'w-full md:w-2/4 lg:w-52 lg:ml-1 h-fit p-3 border rounded-md ' +
                    'focus:outline-dashed focus:outline-2 focus:outline-gray-300 ' +
                    {'border-red-500' : error},
                    {'border-gray-200' : !error},
                )}
                title={error ? getTranslation('DUMMIES.INPUT.BUDGET.BUDGET_ERROR') : ''}
            />
            <input
                type={"number"}
                name={"budgetMax"}
                defaultValue={budget.budgetMax === null ? '' : budget.budgetMax}
                placeholder={getTranslation('DUMMIES.INPUT.BUDGET.BUDGET_MAX')}
                onChange={onChangeBudgetMax}
                disabled={disabled}
                className={classNames(
                    'w-full md:w-2/4 lg:w-52 lg:ml-1 h-fit p-3 border rounded-md ' +
                    'focus:outline-dashed focus:outline-2 focus:outline-gray-300 ' +
                    {'border-red-500' : error},
                    {'border-gray-200' : !error},
                )}
                title={error ? getTranslation('DUMMIES.INPUT.BUDGET.BUDGET_ERROR') : ''}
            />
        </div>
    )
}
