import React from "react";
import {Provider, ProviderAccount} from "../../../../models";
import {BasisInput} from "../../../dummies/input";
import {SelectInput} from "../../../dummies/select";
import {getTranslation} from "../../../../intl";
import {Cache} from "@boomrank/react-components";


interface Props {
    cacheProviders: Cache<Provider>,
    providerAccount: ProviderAccount,
    onChange: (providerAccount: ProviderAccount) => void
    error: boolean
    errorMessage: string
    disabled?: boolean
}

export function ProviderAccountBasisForm(props: Props) {
    let disabled = props.disabled || false

    let selectedOptions = () => {
        return props.cacheProviders.values().map((provider) => {
            return {
                id: provider.id,
                content: provider.name,
                title: provider.name
            }
        })
    }

    let onChangeProvider = (id: number) => {
        let provider = props.cacheProviders.values().find(p => p.id === id)

        props.onChange({
            ...props.providerAccount,
            providerId: provider!.id
        });
    }

    let onChange = (name: string, value: string | number) => {
        props.onChange({
            ...props.providerAccount,
            [name]: value
        })
    }


    return (
        <div className={'w-full mb-5 lg:flex lg:gap-x-3 lg:mb-1 border border-transparent rounded'}>
            <SelectInput
                options={selectedOptions()}
                onChange={onChangeProvider}
                name={"provider"}
                value={props.providerAccount.providerId ? props.providerAccount.providerId : 0}
                disabled={disabled}
            />
            <BasisInput
                type={"text"}
                value={props.providerAccount.loginId}
                name={"loginId"}
                onChange={onChange}
                placeholder={getTranslation('MONITORING.FORMS.PROVIDER_ACCOUNT.LABELS.LOGIN_ID')}
                error={props.error}
                disabled={disabled}
            />
            <BasisInput
                type={"text"}
                value={props.providerAccount.name}
                name={"name"}
                onChange={onChange}
                placeholder={getTranslation('MONITORING.FORMS.PROVIDER_ACCOUNT.LABELS.NAME')}
                disabled={disabled}
            />

            <span className={'w-24 flex justify-center items-center text-red-500'}>
                {
                    props.error &&
                    props.errorMessage &&
                    <i
                        className="fa-solid fa-triangle-exclamation"
                        title={props.errorMessage}
                    />
                }
            </span>
        </div>
    )
}
