import {defaultLanguage, getTranslation} from "../../../intl";
import React from "react";
import {GreenButton, SecureHTML} from "@boomrank/react-components";
import classNames from "classnames";
import {Fee} from "../../../models";

interface Props {
    fee: Fee
    onClick?: (fee: Fee) => void
}

export function FeeCard(props: Props) {
    let className = classNames(
        'border border-1 rounded p-3 flex flex-col',
        {
            'border-br-blue': props.fee.enabledAt !== null,
            'bg-gray-50': props.fee.enabledAt === null
        }
    )

    let onClick = (fee: Fee) => {
        if (props.onClick) {
            props.onClick(fee)
        }
    }

    return (
        <div key={props.fee.id} className={className}>
            <div className={'flex flex-row-reverse mb-3'}>
                <div className={'text-xs text-gray-400'}>
                    {props.fee.createdAt.toLocaleDateString(defaultLanguage)}
                    <i className={'fa-regular fa-clock ml-1'} />
                </div>
            </div>
            <div className={'grow'}>
                <div className={'grid grid-cols-2 gap-3'}>
                    {
                        (props.fee.limitMin === null && props.fee.limitMax === null) &&
                        <>
                            <div>
                                { getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.LABEL_LIMIT') }
                            </div>
                            <div className={'text-right'}>
                                { getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.LABEL_NO_LIMIT') }
                            </div>
                        </>
                    }
                    {
                        (props.fee.limitMin !== null || props.fee.limitMax !== null) &&
                        <>
                            <div>
                                { getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.LABEL_LIMIT') }
                            </div>
                            <div className={'text-right'}>
                                {
                                    props.fee.limitMin !== null &&
                                    <>
                                        {
                                            props.fee.limitMax !== null ?
                                                <>{ getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.LABEL_BETWEEN') } </>
                                                :
                                                <>{ getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.LABEL_MIN') } </>
                                        }
                                        {props.fee.limitMin}€
                                    </>
                                }
                                {
                                    props.fee.limitMax !== null &&
                                    <>
                                        {
                                            props.fee.limitMin !== null ?
                                                <> { getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.LABEL_AND') } </>
                                                :
                                                <>{ getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.LABEL_MAX') } </>
                                        }
                                        {props.fee.limitMax}€
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
                <div className={'mt-3'}>
                    {
                        props.fee.feeBrackets.length > 0 &&
                        <h4>{ getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.TITLE_RULES') }</h4>
                    }
                    <div className={'mt-1 grid grid-cols-2 gap-1'}>
                        {
                            props.fee.feeBrackets.map((fb, idx: number) => {
                                return (
                                    <React.Fragment key={idx}>
                                        <div>
                                            {
                                                fb.bracketMin !== null &&
                                                <>
                                                    {
                                                        fb.bracketMax !== null ?
                                                            <>{ getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.LABEL_BETWEEN') } </>
                                                            :
                                                            <>{ getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.LABEL_MIN') } </>
                                                    }
                                                    {fb.bracketMin}€
                                                </>
                                            }
                                            {
                                                fb.bracketMax !== null &&
                                                <>
                                                    {
                                                        fb.bracketMin !== null ?
                                                            <> { getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.LABEL_AND') } </>
                                                            :
                                                            <>{ getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.LABEL_MAX') } </>
                                                    }
                                                    {fb.bracketMax}€
                                                </>
                                            }
                                        </div>
                                        <div className={'text-right'}>
                                            {
                                                fb.rate > 0 &&
                                                <>{fb.rate}%</>
                                            }
                                            {
                                                fb.priceHt > 0 &&
                                                <>
                                                    {
                                                        fb.rate > 0 &&
                                                        <> + </>
                                                    }
                                                    {fb.priceHt.toLocaleString(defaultLanguage)}€
                                                </>
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className={'mt-3 text-center'}>
                {
                    props.fee.enabledAt === null ?
                        <GreenButton
                            className={'m-auto'}
                            text={
                                getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.BUTTON_ENABLE')
                            }
                            onClick={
                                () => onClick(props.fee)
                            }
                        />
                        :
                        <div className={'text-gray-500 text-xs'}>
                            <SecureHTML>
                                {
                                    getTranslation('MONITORING.VIEWS.FEE.LIST.CARD.ENABLED_AT').format(
                                        props.fee.enabledAt.toLocaleString(defaultLanguage)
                                    )
                                }
                            </SecureHTML>
                        </div>
                }
            </div>
        </div>
    )
}