import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {
    FinancialInvoiceListResponse,
    FinancialInvoiceUpdateResponse
} from "./responses";
import {endpoints} from "../api";
import {Invoice} from "../../models";


export class FinancialInvoiceApi {
    static list(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | FinancialInvoiceListResponse> {
        let url = endpoints.financial_invoice.list
        if (baseUrl) {
            url = baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static update(
        token: JwtToken,
        pk: number,
        invoices: Invoice[],
    ): Promise<FormErrorResponse | FinancialInvoiceUpdateResponse> {
        let url = endpoints.financial_invoice.update.replace(':pk', pk.toString())

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    invoices: invoices,
                    submitted_at : new Date()
                })
            }
        );
    }
}