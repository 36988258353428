import {StatGlobalCampaignGroupFragment} from "./fragments";
import {StatGlobalCampaignGroup} from "../../../../models/stats";
import {Step} from "../../../../models/stepPeriod";


export class StatGlobalCampaignGroupFactory {
    static fromFragment = (fragment: StatGlobalCampaignGroupFragment): StatGlobalCampaignGroup => {
        let statGlobalCampaignGroup = new StatGlobalCampaignGroup()
        statGlobalCampaignGroup.periodKey = new Date(fragment.period_key)
        statGlobalCampaignGroup.startedAt = new Date(fragment.started_at)
        statGlobalCampaignGroup.loginId = fragment.login_id
        statGlobalCampaignGroup.cost = fragment.cost
        statGlobalCampaignGroup.step = fragment.step as Step
        statGlobalCampaignGroup.trend = fragment.trend
        statGlobalCampaignGroup.name = fragment.name
        statGlobalCampaignGroup.impressions = fragment.impressions
        statGlobalCampaignGroup.purchases = fragment.purchases
        statGlobalCampaignGroup.purchaseRoas = fragment.purchase_roas
        statGlobalCampaignGroup.costPerPurchase = fragment.cost_per_purchase
        statGlobalCampaignGroup.leads = fragment.leads
        statGlobalCampaignGroup.costPerLead = fragment.cost_per_lead
        statGlobalCampaignGroup.conversions = fragment.conversions
        statGlobalCampaignGroup.conversionsValue = fragment.conversions_value
        return statGlobalCampaignGroup
    }
}