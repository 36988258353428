import React from "react";
import {InvoiceItem, ProviderAccount} from "../../../../../models";
import {BasisInput} from "../../../../dummies/input";
import {getTranslation} from "../../../../../intl";
import {SelectInput} from "../../../../dummies/select";
import classNames from "classnames";


interface Props {
    invoiceItem: InvoiceItem
    providerAccounts: ProviderAccount[]
    onChange: (invoiceItem: InvoiceItem) => void
    onDelete: (invoiceItem: InvoiceItem) => void
    disabled: boolean
}

export function InvoiceItemBasisForm(props: Props) {
    let providers = [
        {id: 1, name: 'Google', icon: 'fa-brands fa-google text-red-500'},
        {id: 2, name: 'Meta', icon: 'fa-brands fa-meta text-blue-700'},
        {id: 3, name: 'Bing', icon: 'fa-solid fa-b text-cyan-500'},
    ]

    let onChange = (name: string, value: string) => {
        if (name === 'priceHt') {
            let priceHt = value.replaceAll(',', '.')

            props.onChange({
                ...props.invoiceItem,
                priceHt: parseFloat(priceHt)
            })
        } else {
            props.onChange({
                ...props.invoiceItem,
                [name]: value
            })
        }
    }

    let onClick = () => {
        props.onChange({
            ...props.invoiceItem,
            applicableFee: !props.invoiceItem.applicableFee
        })
    }

    let onSelectChange = (providerAccountId: number) => {
        props.onChange({
            ...props.invoiceItem,
            providerAccountId: providerAccountId
        })
    }

    let selectOptions = props.providerAccounts.map(providerAccount => {
        let provider = providers.find(p => p.id === providerAccount.providerId)

        return {
            id: providerAccount.id,
            content: provider!.name + ' - ' + providerAccount.loginId,
            title: providerAccount.name || providerAccount.loginId,
        }
    })

    return (
        <div className={'w-full sm:flex items-center justify-end sm:gap-x-1'}>
            <button
                className={classNames(
                    'w-full text-md lg:w-40 p-3 rounded-md disabled:bg-gray-200 disabled:text-gray-400 border border-transparent',
                    {'bg-emerald-200 text-emerald-600': props.invoiceItem.applicableFee},
                    {'bg-orange-200 text-orange-600': !props.invoiceItem.applicableFee},
                )}
                onClick={onClick}
                disabled={props.invoiceItem.isCss || props.disabled}
                title={
                    props.invoiceItem.applicableFee ? 'Honoraires applicables' : 'Honoraires non applicables (CSS, ...)'
                }
            >
                {
                    props.invoiceItem.applicableFee ?
                        <i className={'fa-solid fa-circle-check'} /> :
                        <i className={'fa-solid fa-circle-xmark'} />
                }
            </button>

            <BasisInput
                dataTestId={'invoiceItem-label'}
                type={"text"}
                name={"label"}
                value={props.invoiceItem.label}
                onChange={onChange}
                placeholder={getTranslation('MONITORING.FORMS.INVOICE.ITEM.PLACEHOLDERS.LABEL')}
                className={'w-full lg:w-4/6'}
                disabled={props.invoiceItem.isCss || props.disabled}
            />

            <BasisInput
                dataTestId={'invoiceItem-priceHt'}
                type={"number"}
                name={"priceHt"}
                value={props.invoiceItem.priceHt}
                onChange={onChange}
                placeholder={getTranslation('MONITORING.FORMS.INVOICE.ITEM.PLACEHOLDERS.PRICE_HT')}
                className={'w-full lg:w-1/6'}
                disabled={props.disabled}
            />

            <SelectInput
                name={"providerAccountId"}
                options={[
                    {
                        id: 0,
                        content: getTranslation('MONITORING.FORMS.INVOICE.ITEM.OPTION.DEFAULT'),
                        title: getTranslation('MONITORING.FORMS.INVOICE.ITEM.OPTION.DEFAULT'),
                    },
                    ...selectOptions
                ]}
                value={props.invoiceItem.providerAccountId || 0}
                onChange={onSelectChange}
                classname={'w-full lg:w-1/6'}
                disabled={props.disabled}
            />

            <button
                data-testid={'invoiceItem-delete-btn'}
                onClick={() => props.onDelete(props.invoiceItem)}
                className={'w-8 px-2 h-full bg-red-500 text-center rounded-md border border-white'}
                title={getTranslation('MONITORING.FORMS.INVOICE.ITEM.BUTTON.DELETE')}
                disabled={props.disabled}
            >
                <i className="fa-solid fa-xmark text-white text-xl" />
            </button>
        </div>
    )

}
