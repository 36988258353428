import React from "react";

import {DataFilters} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";
import {StandaloneFinancialInvoice} from "../../../../models/standalone";
import {State} from "../../../../models";


export interface Option {
    value: any;
    active: boolean;
    selected: boolean;
}

interface FilterGroups {
    years: Option[],
    months: Option[],
    customers: Option[],
    submitted: Option[],
    customerStates: Option[],
    // accounts: Option[]
}

export interface Props {
    standaloneFinancialInvoices: StandaloneFinancialInvoice[]
    onFilter: (standaloneFinancialInvoices: StandaloneFinancialInvoice[]) => void
    filterGroups: FilterGroups
    isLoading?: boolean
}

export function FinancialInvoiceFilters(props: Props) {
    let filterGroups: any[] = [
        {
            field: 'refDateYear',
            label: getTranslation('MONITORING.VIEWS.INVOICE.FILTER.OPTIONS.YEARS'),
            options: props.filterGroups.years,
            operator: 'custom',
            test: (row: StandaloneFinancialInvoice, search: number) => {
                return row.refDateYear === search
            }
        },
        {
            field: 'refDateMonth',
            label: getTranslation('MONITORING.VIEWS.INVOICE.FILTER.OPTIONS.MONTHS'),
            options: props.filterGroups.months,
            operator: 'custom',
            test: (row: StandaloneFinancialInvoice, search: string) => {
                return row.refDateMonth === search
            }
        },
        {
            field: 'customerName',
            label: getTranslation('MONITORING.VIEWS.INVOICE.FILTER.OPTIONS.CUSTOMERS'),
            options: props.filterGroups.customers,
            operator: 'contains'
        },
        {
            field: 'isSubmitted',
            label: getTranslation('MONITORING.VIEWS.INVOICE.FILTER.OPTIONS.SUBMITTED'),
            options: props.filterGroups.submitted,
            operator: 'contains',
        },
        {
            field: 'customerState',
            label: getTranslation('MONITORING.VIEWS.INVOICE.FILTER.OPTIONS.CUSTOMER_STATES'),
            options: props.filterGroups.submitted,
            operator: 'custom',
            test: (row: StandaloneFinancialInvoice, search: string) => {
                if (search === 'Oui') {
                    return row.customerState === State.ACTIVE
                }
                if (search === 'Non') {
                    return row.customerState === State.PAUSED
                }
            }
        },
    ]

    return (
        <DataFilters
            data={props.standaloneFinancialInvoices}
            onFilter={props.onFilter}
            filterGroups={filterGroups}
            isLoading={props.isLoading || false}
        />
    )
}