import React from "react";


export function CardLoader() {
    return (
        <div
            data-testid={'card-loader'}
            className={
                'relative w-full h-auto p-3 my-3 flex flex-col border-2 border-slate-200 rounded-xl ' +
                'overflow-hidden lg:w-1/4 lg:h-[26rem] lg:ml-2 lg:my-0 lg:overflow-y-auto'
            }
        >
            <div className={'animate-pulse w-40 h-4 mt-2 mb-16 bg-slate-500 rounded-full'}></div>

            <div className={'w-full h-20'}>
                <div className={'animate-pulse w-full h-3.5 bg-slate-300 rounded-full'}></div>
            </div>
            <div className={'w-full h-20'}>
                <div className={'animate-pulse w-20 h-3 bg-slate-300 rounded-full'}></div>
                <div className={'animate-pulse w-20 h-3 bg-slate-300 rounded-full'}></div>
            </div>
        </div>
    )
}