
/**
 * @param {string} ONE_DAY
 * @param {string} ONE_WEEK
 * @param {string} ONE_MONTH
 * @param {string} ONE_YEAR
 * */
enum Step {
    ONE_DAY = '1D',
    ONE_WEEK = '1W',
    ONE_MONTH = '1M',
    ONE_YEAR = '1Y',
}

/**
 * @param {Date} period_key
 * @param {Date} started_at
 * @param {string} login_id
 * @param {number} cost
 * @param {Step} step
 * @param {number} trend
 * @param {string} name
 * @param {number} purchases
 * @param {number} purchaseRoas
 * @param {number} costPerPurchase
 * @param {number} leads
 * @param {number} costPerLead
 * @param {number} conversions
 * @param {number} conversionsValue
 * */
export class StatCustomer {
    periodKey: Date = new Date()
    startedAt: Date = new Date()
    loginId: string = ''
    cost: number = 0
    step: Step = Step.ONE_DAY || Step.ONE_WEEK  || Step.ONE_MONTH || Step.ONE_YEAR
    trend: number = 0
    name: string = ''
    impressions: number = 0
    purchases: number = 0
    purchaseRoas: number = 0
    costPerPurchase: number = 0
    leads: number = 0
    costPerLead: number = 0
    conversions: number = 0
    conversionsValue: number = 0
}

/**
 * @param {Date} period_key
 * @param {Date} started_at
 * @param {string} login_id
 * @param {number} cost
 * @param {Step} step
 * @param {number} trend
 * @param {string} name
 * @param {number} purchases
 * @param {number} purchaseRoas
 * @param {number} costPerPurchase
 * @param {number} leads
 * @param {number} costPerLead
 * @param {number} conversions
 * @param {number} conversionsValue
 * */
export class StatGlobalCampaignGroup {
    periodKey: Date = new Date()
    startedAt: Date = new Date()
    loginId: string = ''
    cost: number = 0
    step: Step = Step.ONE_DAY || Step.ONE_WEEK  || Step.ONE_MONTH || Step.ONE_YEAR
    trend: number = 0
    name: string = ''
    impressions: number = 0
    purchases: number = 0
    purchaseRoas: number = 0
    costPerPurchase: number = 0
    leads: number = 0
    costPerLead: number = 0
    conversions: number = 0
    conversionsValue: number = 0
}

/**
 * @param {Date} period_key
 * @param {Date} started_at
 * @param {string} login_id
 * @param {number} campaign_group_id
 * @param {number} cost
 * @param {Step} step
 * @param {number} trend
 * @param {string} name
 * @param {number} purchases
 * @param {number} purchaseRoas
 * @param {number} costPerPurchase
 * @param {number} leads
 * @param {number} costPerLead
 * @param {number} conversions
 * @param {number} conversionsValue
 * */
export class StatCampaignGroup {
    periodKey: Date = new Date()
    startedAt: Date = new Date()
    loginId: string = ''
    campaignGroupId: string = ''
    cost: number = 0
    step: Step = Step.ONE_DAY || Step.ONE_WEEK  || Step.ONE_MONTH || Step.ONE_YEAR
    trend: number = 0
    name: string = ''
    impressions: number = 0
    purchases: number = 0
    purchaseRoas: number = 0
    costPerPurchase: number = 0
    leads: number = 0
    costPerLead: number = 0
    conversions: number = 0
    conversionsValue: number = 0
}

/**
 * @param {Date} period_key
 * @param {Date} started_at
 * @param {string} login_id
 * @param {string} campaign_key
 * @param {number} cost
 * @param {Step} step
 * @param {number} trend
 * @param {string} name
 * @param {number} purchases
 * @param {number} purchaseRoas
 * @param {number} costPerPurchase
 * @param {number} leads
 * @param {number} costPerLead
 * @param {number} conversions
 * @param {number} conversionsValue
 * */
export class StatCampaign {
    periodKey: Date = new Date()
    startedAt: Date = new Date()
    name: string = ''
    loginId: string = ''
    campaignKey: string = ''
    step: Step = Step.ONE_DAY || Step.ONE_WEEK  || Step.ONE_MONTH || Step.ONE_YEAR
    cost: number = 0
    trend: number = 0
    impressions: number = 0
    purchases: number = 0
    purchaseRoas: number = 0
    costPerPurchase: number = 0
    leads: number = 0
    costPerLead: number = 0
    conversions: number = 0
    conversionsValue: number = 0
}
