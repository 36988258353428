import React, {useState} from "react";

import {Budget, Customer} from "../../../../models";
import BudgetInput from "../../../dummies/input/budget";
import {getTranslation} from "../../../../intl";
import {FormNotification} from "../models";
import {FormMessage} from "../../../dummies/form/message";
import {FormEvents} from "../../../dummies/form/events";


interface Props {
    customer: Customer
    onSubmit: (customer: Customer) => void
    message: FormNotification
    disabled?: boolean
}

export function CustomerBudgetForm(props: Props) {
    let disabled = props.disabled || false

    let [customer, setCustomer] = useState<Customer>(props.customer)

    let onSubmit = (customer: Customer) => {
        props.onSubmit(customer)
    }

    let budgetUpdater = (budget: Budget) => {
        setCustomer({
            ...customer,
            budgetMin: budget.budgetMin,
            budgetMax: budget.budgetMax,
        })
    }


    return (
        <div className={'w-full mt-6 py-6 px-5 border-2 border-slate-200 rounded-xl'}>
            <FormEvents
                className={'w-full flex justify-end gap-x-1 mb-3'}
                onSubmit={() => onSubmit(customer)}
                submitTitle={getTranslation('MONITORING.FORMS.CUSTOMER.BUDGET.SUBMIT_BUTTON')}
                disabled={disabled}
            />

            <FormMessage formNotification={props.message} />

            <div className={'w-full lg:flex lg:items-end lg:justify-between'}>
                <h3 className={'w-full my-0'}>{
                    getTranslation('MONITORING.FORMS.CUSTOMER.BUDGET.TITLE')
                }</h3>

                <BudgetInput
                    budgetMin={customer.budgetMin}
                    budgetMax={customer.budgetMax}
                    budgetUpdater={budgetUpdater}
                    className={'w-full pl-6 md:pl-14 lg:pl-0 lg:flex items-center justify-end'}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}
