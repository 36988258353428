import React, {useState} from "react";

import {
    BudgetAccountGroup,
    CampaignGroup,
    Customer,
    Identifier,
    Provider,
    ProviderAccount,
} from "../../../../../models";

import {FormErrorResponse, Cache, PlaceHolder, JwtToken} from "@boomrank/react-components";
import {BudgetAccountGroupApi} from "../../../../../services/budget-account-group/api";
import {
    BudgetAccountGroupCreateResponse,
    BudgetAccountGroupUpdateResponse
} from "../../../../../services/budget-account-group/responses";
import {BudgetAccountGroupFactory} from "../../../../../services/budget-account-group/factory";
import {Errors} from "../../../../dummies/errors";
import {Flag, FlaggedBudgetAccountGroup} from "../models";
import {BudgetAccountGroupForm} from "../../../forms/budget-account-group";


interface Props {
    token: JwtToken
    customer: Customer
    campaignGroup: CampaignGroup
    providerAccounts: ProviderAccount[]
    budgetAccountGroups: BudgetAccountGroup[]
    cacheProviders: Cache<Provider>
    cacheUpdater: (obj: Identifier, remove: boolean) => void
    submittedResponse: (edited: boolean) => void
}

export function BudgetAccountGroupFormView(props: Props) {
    let [loading, setLoading] = useState<boolean>(false);
    let [errors, setErrors] = useState<Errors>({});

    let persistBudgetAccountGroup = (fbag: FlaggedBudgetAccountGroup) => {
        if (fbag.flag === Flag.CREATED) {
            addBudgetAccountGroup(fbag.budgetAccountGroup)
        }
        if (fbag.flag === Flag.UPDATED) {
            editBudgetAccountGroup(fbag.budgetAccountGroup)
        }
    }

    let addBudgetAccountGroup = (bag: BudgetAccountGroup) => {
        setLoading(true)

        if (bag.providerAccountId === 0) {
            bag.providerAccountId = props.providerAccounts[0].id
        }

        BudgetAccountGroupApi.create(
            props.token,
            new Date().toString(),
            bag.budgetMin,
            bag.budgetMax,
            props.campaignGroup.id,
            bag.providerAccountId,
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as BudgetAccountGroupCreateResponse;
                props.cacheUpdater(BudgetAccountGroupFactory.fromFragment(res.data), false);

                props.submittedResponse(false)
            } else {
                res = res as FormErrorResponse;
                setErrors(res.data);
            }
            setLoading(false)
        })
    }

    let editBudgetAccountGroup = (bag: BudgetAccountGroup) => {
        setLoading(true)

        BudgetAccountGroupApi.update(
            props.token,
            bag.id,
            bag.budgetMin,
            bag.budgetMax,
            props.campaignGroup.id,
            bag.providerAccountId,
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as BudgetAccountGroupUpdateResponse;
                props.cacheUpdater(BudgetAccountGroupFactory.fromFragment(res.data), false);

                props.submittedResponse(true)
            } else {
                res = res as FormErrorResponse;
                setErrors(res.data)
            }
            setLoading(false)
        })
    }

    let onSubmit = (flaggedBudgetAccountGroups : FlaggedBudgetAccountGroup[]) => {
        if (flaggedBudgetAccountGroups.length > 0) {
            flaggedBudgetAccountGroups.forEach((budgetAccountGroup) => {
                persistBudgetAccountGroup(budgetAccountGroup);
            })
        }
    }


    return (
        <BudgetAccountGroupForm
            customer={props.customer}
            campaignGroup={props.campaignGroup}
            budgetAccountGroups={props.budgetAccountGroups}
            providerAccounts={props.providerAccounts}
            cacheProviders={props.cacheProviders}
            onSubmit={onSubmit}
            disabled={loading}
        />
    );
}