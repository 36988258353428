import React, {useState} from "react";

import {JwtToken} from "@boomrank/react-components";

import {FinancialInvoiceFilters, Option} from "./filters";
import {StandaloneFinancialInvoice} from "../../../../models/standalone";
import {Account, State} from "../../../../models";
import {FinancialInvoicesTableView} from "./table";


interface Props {
    token: JwtToken
    me: Account
    standaloneFinancialInvoices: StandaloneFinancialInvoice[]
}

export function FinancialInvoiceFilterView(props: Props) {
    let months = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet',
        'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ]

    let [filteredRows, setFilteredRows] = useState<StandaloneFinancialInvoice[]>([])
    let [isLoading, setIsLoading] = useState<boolean>(true)

    let uniqueValues = (options: Option[], value: string | number) => {
        let values = options.map((option) => option.value)

        if (!values.includes(value)) {
            options.push({
                value: value,
                active: false,
                selected: false
            })
        }
        return options
    }

    // GroupFilter : return uniques values for each filter
    let uniqueYears = props.standaloneFinancialInvoices.reduce(
        (years: Option[], standaloneFinancialInvoice: StandaloneFinancialInvoice) => {
            return uniqueValues(years, standaloneFinancialInvoice.refDateYear)
        }, []
    ).sort((a, b) => a.value - b.value)

    let uniqueMonths = props.standaloneFinancialInvoices.reduce(
        (months: Option[], standaloneFinancialInvoice: StandaloneFinancialInvoice) => {
            return uniqueValues(months, standaloneFinancialInvoice.refDateMonth)
        }, []
    ).sort((a, b) => months.indexOf(a.value) - months.indexOf(b.value))

    let uniqueCustomers = props.standaloneFinancialInvoices.reduce(
        (customers: Option[], standaloneFinancialInvoice: StandaloneFinancialInvoice) => {
            return uniqueValues(customers, standaloneFinancialInvoice.customerName)
        }, []
    ).sort((a, b) => a.value.localeCompare(b.value))

    let uniqueSubmitted = [
        {value: 'Oui', active: false, selected: false},
        {value: 'Non', active: false, selected: false}
    ]

    let uniqueCustomerState = [
        {value: 'Oui', active: false, selected: false},
        {value: 'Non', active: false, selected: false}
    ]


    let getActiveFilters = () => {
        let years = uniqueYears.map((v) => v.value)
        let months = uniqueMonths.map((v) => v.value)
        let customers = uniqueCustomers.map((v) => v.value)
        let submitteds = uniqueSubmitted.map((v) => v.value)
        let customerStates = uniqueCustomerState.map((v) => {
            if (v.value === 'Oui') {
                return State.ACTIVE
            }
            return State.PAUSED
        })

        filteredRows.reduce(
            (rows, standaloneFinancialInvoice: StandaloneFinancialInvoice) => {
                let year = standaloneFinancialInvoice.refDateYear
                let month = standaloneFinancialInvoice.refDateMonth
                let customer = standaloneFinancialInvoice.customerName
                let submitted = standaloneFinancialInvoice.isSubmitted
                let customerState = standaloneFinancialInvoice.customerState

                if (years.includes(year)) {
                    let index = years.indexOf(year)
                    uniqueYears[index].active = true
                }
                if (months.includes(month)) {
                    let index = months.indexOf(month)
                    uniqueMonths[index].active = true
                }
                if (customers.includes(customer)) {
                    let index = customers.indexOf(customer)
                    uniqueCustomers[index].active = true
                }
                if (submitteds.includes(submitted)) {
                    let index = submitteds.indexOf(submitted)
                    uniqueSubmitted[index].active = true
                }
                if (customerStates.includes(customerState)) {
                    let index = customerStates.indexOf(customerState)
                    uniqueCustomerState[index].active = true
                }

                return rows
            }, []
        )
    }
    getActiveFilters()

    let onFilter = (rows: any[]) => {
        setFilteredRows(rows)

        if (rows.length > 0 || rows.length === 0) {
            setIsLoading(false)
        }
    }


    return (
        <div className={'w-full mt-6 py-6 px-5 border-2 border-slate-200 rounded-xl relative'}>
            {
                props.me.isAdmin() ?
                    <h3 className={'mb-3'}>Factures</h3> :
                    <h3 className={'mb-3 text-red-500'}>Factures non validées</h3>
            }

            <FinancialInvoiceFilters
                standaloneFinancialInvoices={props.standaloneFinancialInvoices}
                onFilter={onFilter}
                filterGroups={{
                    years: uniqueYears,
                    months: uniqueMonths,
                    customers: uniqueCustomers,
                    submitted: uniqueSubmitted,
                    customerStates: uniqueCustomerState
                }}
                isLoading={isLoading}
            />

            <div className={'mt-10'}>
                <FinancialInvoicesTableView
                    token={props.token}
                    standaloneFinancialInvoices={filteredRows.sort((a, b) => (a.refId < b.refId ? 1 : -1))}
                    columns={[
                        'id',
                        'customerName',
                        'refDate',
                        'totalNbInvoices',
                        'totalInvoices',
                        'totalFees',
                        'feesRule',
                        'isSubmitted',
                    ]}
                    isLoading={isLoading}
                />
            </div>
        </div>
    )
}