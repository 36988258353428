import React, {useEffect, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";

import {Account} from "../models";

import {AuthApi} from "../services/auth/api";
import {MeFactory} from "../services/auth/factory";
import {MeSuccess} from "../services/auth/responses";

import {Template} from "./dummies/template";
import {Breadcrumb, JwtToken, PlaceHolder} from "@boomrank/react-components";
import {getTranslation} from "../intl";
import {Monitoring} from "./monitoring";


interface Props {
    token: JwtToken
}

export function Dashboard(props: Props) {
    let location = useLocation()

    let [me, setMe] = useState<Account | null>(null)

    let getMe = () => {
        AuthApi.getMe(props.token).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                let r = res as MeSuccess
                setMe(MeFactory.fromFragment(r.data))
            }
        })
    }

    useEffect(() => {
        getMe()
    }, [])


    if (!me) {
        return (
            <Template
                me={new Account()}
                headline={getTranslation('TEMPLATE.HELLO')}
            >
                <Breadcrumb
                    location={location}
                    links={[{
                        to: `/dashboard/`, content: <i className="fa-solid fa-house"/>
                    }]}
                />
                <PlaceHolder className={'h-32 mt-10'} />
            </Template>
        )
    }

    return (
        <Template
            me={me}
            headline={getTranslation('TEMPLATE.HELLO_USER').format(me.user.firstname)}
        >
            <Routes>
                <Route path={'*'} element={
                    <Monitoring
                        token={props.token}
                        me={me}
                    />
                }
                />
            </Routes>
        </Template>
    )
}
