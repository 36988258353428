import React from "react";
import classNames from "classnames";


interface Props {
    type: string
    name: string;
    value: string | number | null;
    onChange: (name: string, value: string) => void
    placeholder: string
    dataTestId?: string
    disabled?: boolean
    className?: string
    error?: boolean
    legend?: string | React.ReactNode | React.ReactNode[]
}

export function BasisInput(props: Props) {
    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.name, event.target.value)
    }

    return (
        <fieldset className={classNames(
            props.className,
            {'w-full border border-red-500' : props.error},
            {'w-full border border-white': !props.error}
        )}>
            {
                props.legend &&
                <legend className={'text-gray-500'}>{props.legend}</legend>
            }

            <input
                data-testid={props.dataTestId}
                value={props.value ? props.value : ''}
                onChange={onChange}
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                className={classNames(
                    'w-full p-3 border border-gray-200 rounded-md ' +
                    'focus:outline-dashed focus:outline-2 focus:outline-gray-300',
                    {'text-red-500 font-medium' : props.error}
                )}
                disabled={props.disabled}
            />
        </fieldset>
    )
}
