import {defaultLanguage} from "../../../../../intl";
import classNames from "classnames";
import {StandaloneStatCustomer} from "../../../../../models/standalone";

interface Props {
    onClick: (standaloneStatCustomer: StandaloneStatCustomer) => void
    standaloneStatsCustomer: StandaloneStatCustomer[]
    disabled?: boolean
}

export function SuggestionStatsCustomerForm(props: Props) {
    let disabled = props.disabled || false

    let className = classNames(
        'border p-3 select-none mt-1 flex justify-between text-sm',
        {
            'hover:bg-gray-50 hover:cursor-pointer': !disabled,
            'bg-gray-100': disabled
        }
    )

    let onClick = (standaloneStatsCustomer: StandaloneStatCustomer) => {
        if (props.onClick && !disabled) {
            props.onClick(standaloneStatsCustomer)
        }
    }
    return (
        <div className={'mt-6'}>
            <div>Suggestions de montants</div>
            <ul className={'grid grid-cols-3 gap-3'}>
                {
                    props.standaloneStatsCustomer.map((ssc, idx) => {
                        return (
                            <li key={idx} data-testid={'invoice-suggestion-btn'} className={className} onClick={() =>{
                                onClick(ssc)
                            }}>
                                <div>
                                    {
                                        ssc.providerAccount.providerId === 1 &&
                                        <i className={"w-8 fa-brands fa-google text-red-500"} title="google" />
                                    }
                                    {
                                        ssc.providerAccount.providerId === 2 &&
                                        <i className={"w-8 fa-brands fa-meta text-blue-700"} title="meta" />
                                    }
                                    {ssc.providerAccount.name}({ssc.loginId})
                                </div>
                                <div className={'text-right'}>
                                    <div>
                                        {ssc.startedAt.toLocaleDateString(defaultLanguage)}
                                    </div>
                                    <div className={'font-bold'}>
                                        {
                                            ssc.cost.toLocaleString(
                                                defaultLanguage,
                                                { style: 'currency', currency: 'EUR' }
                                            )
                                        }
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}