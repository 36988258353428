import {useEffect, useState} from "react";
import classNames from "classnames";
import {Link} from "react-router-dom";
import {defaultLanguage, getTranslation} from "../../../intl";

import {Customer, Fee, FinancialInvoice, Identifier, Invoice} from "../../../models";
import {FinancialInvoiceFactory} from "../../../services/financial_invoice/factory";
import {Cache, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {FinancialInvoiceApi} from "../../../services/financial_invoice/api";
import {FinancialInvoiceUpdateResponse} from "../../../services/financial_invoice/responses";
import {InvoiceApi} from "../../../services/invoice/api";
import {InvoiceUpdateResponse} from "../../../services/invoice/responses";
import {InvoiceFactory} from "../../../services/invoice/factory";
import {StandaloneFinancialInvoice} from "../../../models/standalone";
import {FeeCard} from "../../dummies/card/fee";



export interface Props {
    token: JwtToken
    cacheFees: Cache<Fee>
    cacheCustomers: Cache<Customer>
    standaloneFinancialInvoice: StandaloneFinancialInvoice
    onSubmit: (financialInvoice: FinancialInvoice) => void
    cacheUpdater: (obj: Identifier) => void
}

export function FinancialInvoiceEditView(props: Props) {
    let [fee, setFee] = useState<Fee>()

    let [operationMessage, setOperationMessage] = useState({
        show: false,
        success: false,
        content: ''
    })

    let submitFinancialInvoice = () => {
        setOperationMessage({show: false, success: false, content: ''})

        FinancialInvoiceApi.update(
            props.token,
            props.standaloneFinancialInvoice.id,
            props.standaloneFinancialInvoice.invoices,
        ).then((res) => {
            let content = getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.EDIT.SUBMIT.SUCCESS_UPDATE')

            if (res.statusCode >= 200 || res.statusCode <= 300) {
                res = res as FinancialInvoiceUpdateResponse
                props.onSubmit(FinancialInvoiceFactory.fromFragment(res.data))
            } else {
                res = res as FormErrorResponse;
                content = getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.EDIT.SUBMIT.ERROR')
            }

            setOperationMessage({
                show: true,
                success: res.statusCode >= 200 || res.statusCode <= 300,
                content: content
            })
        })
    }

    let rejectInvoice = (invoice: Invoice) => {
        setOperationMessage({show: false, success: false, content: ''})

        InvoiceApi.reject(
            props.token,
            invoice.id,
        ).then((res) => {
            let content = getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.EDIT.SUBMIT.SUCCESS_REJECT_INVOICE')

            if (res.statusCode >= 200 || res.statusCode <= 300) {
                res = res as InvoiceUpdateResponse
                props.cacheUpdater(InvoiceFactory.fromFragment(res.data))
            } else {
                res = res as FormErrorResponse;
                content = getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.EDIT.SUBMIT.ERROR')
            }

            setOperationMessage({
                show: true,
                success: res.statusCode >= 200 || res.statusCode <= 300,
                content: content
            })
        })
    }

    useEffect(() => {
        let fees = props.cacheFees.values().filter((f) =>
            f.enabledAt != null && f.customerId === props.standaloneFinancialInvoice.customerId
        )
        if (fees.length > 0) {
            setFee(fees[0])
        }
    }, [props.standaloneFinancialInvoice.customerId])


    return (
        <div className={'w-full mt-6 flex flex-col items-end'}>
            {
                operationMessage.show &&
                <div className={classNames(
                    'w-full font-medium py-6 px-4 my-3 rounded-lg',
                    {'bg-teal-100 text-br-green' : operationMessage.success},
                    {'bg-red-100 text-br-red' : !operationMessage.success},
                )}>
                    {operationMessage.content}
                </div>
            }

            <button
                onClick={() => submitFinancialInvoice()}
                className={'w-fit btn-green p-2 text-center rounded-sm disabled:hover:bg-gray-100'}
                disabled={props.standaloneFinancialInvoice.submittedAt !== null}
            >
                {getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.EDIT.BUTTON.SUBMIT')}
            </button>

            <div className={'w-full mt-6 py-6 px-5 border-2 border-slate-200 rounded-xl'}>
                <div className={'w-full mb-3 p-4 bg-slate-50 rounded-md lg:flex lg:justify-between lg:items-center'}>
                    <div>
                        <h2>{props.standaloneFinancialInvoice.customerName}</h2>
                        <span className={'text-lg'}>
                            Montants investis pour le mois de :
                            <strong> {props.standaloneFinancialInvoice.refDateMonth} {props.standaloneFinancialInvoice.refDateYear}</strong>
                        </span>
                    </div>

                    <div className={classNames(
                        'w-fit p-4 rounded-sm text-lg font-medium',
                        {'bg-slate-200 text-slate-700': props.standaloneFinancialInvoice.submittedAt},
                        {'bg-orange-100 text-orange-500': !props.standaloneFinancialInvoice.submittedAt},
                    )}>
                        Confirmée :
                        {
                            props.standaloneFinancialInvoice.submittedAt ?
                                <span className={'ml-2'}>
                                    {props.standaloneFinancialInvoice.submittedAt.toLocaleDateString()}
                                </span> :
                                <i className={'fa-solid fa-circle-xmark ml-2'} />
                        }
                    </div>
                </div>
                <div className={'p-6 md:flex gap-3 bg-slate-50'}>
                    <div className={'w-full md:w-1/2'}>
                        <h3 className={'mb-3'}>Commentaires</h3>
                        {
                            props.standaloneFinancialInvoice.customerComment &&
                            <div className="py-2 bg-slate-50 text-br-blue-900 rounded-md">
                                <p className="whitespace-pre-line">{props.standaloneFinancialInvoice.customerComment}</p>
                            </div>
                        }
                        {
                            props.standaloneFinancialInvoice.customerFinancialComment &&
                            <div className="mt-3 py-2 bg-orange-50 text-br-blue-900 rounded-md">
                                <p className="whitespace-pre-line">{props.standaloneFinancialInvoice.customerFinancialComment}</p>
                            </div>
                        }
                    </div>
                    <div className={'w-full md:w-1/2'}>
                        <h3 className={'mb-3'}>Honoraires</h3>
                        {
                            fee ? <FeeCard fee={fee} /> : <>Pas d'honoraires.</>
                        }
                    </div>
                </div>
                <div className={'w-full flex pb-4 px-4 mt-3'}>
                    <span className={'w-1/12 font-medium'}>Réference</span>
                    <span className={'w-2/12 font-medium'}>Type</span>
                    <span className={'w-2/12 font-medium'}>Confirmée</span>
                    <span className={'w-3/12 font-medium'}>Libellés</span>
                    <span className={'w-1/12 font-medium text-right'}>Montants investis</span>
                    <span className={'w-2/12 font-medium text-right'}>Consultant</span>
                    <span className={'w-1/12 font-medium text-right'}>Action</span>
                </div>

                {
                    props.standaloneFinancialInvoice &&
                    props.standaloneFinancialInvoice.invoices.map((invoice, idx) => {
                        return (
                            <div key={idx}>
                                <div className={'w-full py-6 px-4 flex border-t even:bg-slate-50'}>
                                    <Link
                                        to={`/dashboard/customer/${invoice.customerId}/invoices/${invoice.id}/edit`}
                                        className={'w-1/12'}
                                    >
                                        {props.standaloneFinancialInvoice.refDateYear}-
                                        {props.standaloneFinancialInvoice.refDate.getMonth()}{invoice.id}
                                    </Link>

                                    <span className={'w-2/12'}>
                                        {getTranslation(`MONITORING.VIEWS.FINANCIAL_INVOICE.INVOICE.TYPE.${invoice.invoiceType}`)}
                                    </span>

                                    <div className={'w-2/12 text-br-green'}>
                                        {
                                            invoice.submittedAt ?
                                                <i className={'fa-solid fa-circle-check text-lg mr-4'} /> :
                                                <i className={'fa-solid fa-circle-xmark text-br-red text-lg'} />
                                        }
                                        {
                                            invoice.submittedAt &&
                                            <span>{invoice.submittedAt.toLocaleDateString()}</span>
                                        }
                                    </div>

                                    <div className={'w-4/12'}>
                                        {
                                            invoice.invoiceItems.map((item, idx) => {
                                                return (
                                                    <div
                                                        key={idx}
                                                        className={'w-full py-1 flex border-t border-gray-300 first:border-0'}
                                                    >
                                                        <span className={'w-2/3'}>{item.label}</span>
                                                        <span className={'w-1/3 text-right'}>
                                                                {item.priceHt.toLocaleString(
                                                                    defaultLanguage,
                                                                    {style: 'currency', currency: 'EUR'}
                                                                )}
                                                            </span>
                                                    </div>
                                                )
                                            })
                                        }

                                        <span className={'w-full block text-md text-right font-medium pt-3'}>
                                            Total avec honoraires applicables : {invoice.totalItems.toLocaleString(
                                                defaultLanguage,
                                                {style: 'currency', currency: 'EUR'}
                                            )}
                                        </span>
                                    </div>

                                    <div className={'w-2/12 text-right'}>
                                        {invoice.accountName}
                                    </div>

                                    <div className={'w-1/12 flex justify-end'}>
                                        <button
                                            onClick={() => rejectInvoice(invoice)}
                                            className={'btn-red h-fit w-12 p-2 text-center rounded-sm disabled:hover:bg-gray-100'}
                                            title={getTranslation('MONITORING.VIEWS.FINANCIAL_INVOICE.EDIT.BUTTON.REJECT')}
                                            disabled={
                                                invoice.submittedAt === null ||
                                                props.standaloneFinancialInvoice.submittedAt !== null
                                            }
                                        >
                                            <i className={'fa-solid fa-file-pen'} />
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {
                                        invoice.comment &&
                                        <div className="p-3 bg-slate-100 text-br-blue-900 rounded-md mb-3">
                                            <span><b>Commentaire</b> :</span>
                                            <p className="whitespace-pre">{invoice.comment}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })
                }

                <div className={'mt-10 flex justify-end gap-x-3'}>
                    <div className={classNames(
                        'w-fit p-4 rounded-md text-lg font-medium',
                        {'bg-slate-800 text-white ': props.standaloneFinancialInvoice.totalFees},
                        {'bg-red-100 text-br-red': !props.standaloneFinancialInvoice.totalFees},
                    )}>
                        Honoraires :
                        {
                            props.standaloneFinancialInvoice.totalFees ?
                                <span className={'ml-2'}>
                                    {props.standaloneFinancialInvoice.totalFees.toLocaleString(
                                        defaultLanguage,
                                        {style: 'currency', currency: 'EUR'}
                                    )}
                                </span> :
                                <i className={'fa-solid fa-circle-xmark ml-2'} />
                        }
                    </div>

                    <div className={classNames(
                        'w-fit p-4 rounded-md text-lg font-medium',
                        {'bg-slate-800 text-white': props.standaloneFinancialInvoice.totalInvoicesFees},
                        {'bg-gray-100 text-gray-400': !props.standaloneFinancialInvoice.totalInvoicesFees},
                    )}>
                        Base HT (honoraires applicables) :
                        {
                            props.standaloneFinancialInvoice.totalInvoicesFees ?
                                <span className={'ml-2'}>
                                    {props.standaloneFinancialInvoice.totalInvoicesFees.toLocaleString(
                                        defaultLanguage,
                                        {style: 'currency', currency: 'EUR'}
                                    )}
                                </span> :
                                <i className={'fa-solid fa-circle-xmark ml-2'} />
                        }
                    </div>
                </div>

                {
                    props.standaloneFinancialInvoice.totalInvoicesNoFees > 0 &&
                    <div className={'mt-3 flex justify-end gap-x-3'}>
                        <div className={classNames(
                            'w-fit p-4 rounded-md text-lg font-medium bg-gray-200 text-gray-600',
                        )}>
                            Base HT (honoraires non applicables) :
                            <span className={'ml-2'}>
                                {props.standaloneFinancialInvoice.totalInvoicesNoFees.toLocaleString(
                                    defaultLanguage,
                                    {style: 'currency', currency: 'EUR'}
                                )}
                            </span>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}