import {endpoints as boomrankEndpoints} from '@boomrank/react-components'

let BACKEND_API = process.env.REACT_APP_BACKEND_API

export const endpoints = {
    ...boomrankEndpoints,
    account: {
        list: BACKEND_API+'/api/account/',
        reminders: BACKEND_API+'/api/account/reminders/',
    },
    accountHasCustomer: {
        list: BACKEND_API+'/api/account_has_customer/',
    },
    budgetAccountGroup: {
        list: BACKEND_API+'/api/budget_account_group/',
        create: BACKEND_API+'/api/budget_account_group/',
        update: BACKEND_API+'/api/budget_account_group/:pk/',
    },
    campaign: {
        list: BACKEND_API+'/api/campaign/',
    },
    campaignGroup: {
        list: BACKEND_API+'/api/campaign_group/',
        create: BACKEND_API+'/api/campaign_group/',
        update: BACKEND_API+'/api/campaign_group/:pk/',
        delete: BACKEND_API+'/api/campaign_group/:pk/',
    },
    campaignType: {
        list: BACKEND_API+'/api/campaign_type/',
    },
    customer: {
        list: BACKEND_API+'/api/customer/',
        toInvoice: BACKEND_API+'/api/customer/to_invoice/?month=:month',
        create: BACKEND_API+'/api/customer/',
        update: BACKEND_API+'/api/customer/:pk/',
        delete: BACKEND_API+'/api/customer/:pk/',
        export_stats: BACKEND_API+'/api/customer/:pk/export_stats/',
        hasAccounts: {
            list: BACKEND_API+'/api/customer/:customerId/has_accounts/',
            update: BACKEND_API+'/api/customer/:customerId/has_accounts/:pk/',
            create: BACKEND_API+'/api/customer/:customerId/has_accounts/',
        },
        statCustomer: {
            list: BACKEND_API+'/api/customer/:customerId/stat_customer/?from=:from&to=:to&step=:step',
        },
        statCampaign: {
            list: BACKEND_API+'/api/customer/:customerId/stat_campaign/?from=:from&to=:to&step=:step',
            retrieve: BACKEND_API+'/api/customer/:customerId/stat_campaign/?campaign_key=:campaignKey&from=:from&to=:to&step=:step'
        },
        statGlobalCampaignGroup: {
            list: BACKEND_API+'/api/customer/:customerId/stat_global_campaign_group/?from=:from&to=:to&step=:step'
        },
        statCampaignGroup: {
            list: BACKEND_API+'/api/customer/:customerId/stat_campaign_group/?from=:from&to=:to&step=:step',
            retrieve: BACKEND_API+'/api/customer/:customerId/stat_campaign_group/?campaign_group_id=:campaignGroupId&from=:from&to=:to&step=:step'
        }
    },
    export: {
        csv: BACKEND_API+'/api/export/csv/',
    },
    log: {
        list: BACKEND_API+'/api/log/',
    },
    fee: {
        list: BACKEND_API+'/api/fee/',
        create: BACKEND_API+'/api/fee/',
        update: BACKEND_API+'/api/fee/:pk/',
    },
    notification: {
        list: BACKEND_API+'/api/notification/',
        update: BACKEND_API+'/api/notification/:objectId/'
    },
    provider: {
        list: BACKEND_API+'/api/provider/',
    },
    providerAccount: {
        list: BACKEND_API+'/api/provider_account/',
        create: BACKEND_API+'/api/provider_account/',
        update: BACKEND_API+'/api/provider_account/:pk/',
    },
    providerAccountInvoice: {
        list: BACKEND_API+'/api/provider_account/:pk/invoice/',
    },
    statCustomer: {
        list: BACKEND_API+'/api/stat_customer/?from=:from&to=:to&step=:step',
    },
    invoice: {
        list: BACKEND_API+'/api/invoice/',
        create: BACKEND_API+'/api/invoice/',
        update: BACKEND_API+'/api/invoice/:pk/',
    },
    financial_invoice: {
        list: BACKEND_API+'/api/financial_invoice/',
        update: BACKEND_API+'/api/financial_invoice/:pk/',
    },
}