import React from "react";
import {defaultLanguage, getTranslation} from "../../../intl";
import classNames from "classnames";


interface Props {
    value: number | null
    leftPercent: string
    className: string
    pointerClassName: string
    translationField: string
}

export function ProgressBarElement (props: Props) {
    if (props.value) {
        let valueToLocaleString = (value: number) => {
            return value.toLocaleString(
                defaultLanguage,
                {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 0
                }
            )
        }

        return (
            <div
                className={classNames(
                    'absolute w-fit h-full bg-transparent text-center progressBarElement',
                    props.className
                )}
                style={{ left: `${props.leftPercent}%` }}
            >
                <div className={'relative w-fit h-full flex flex-col elementWrapper'}>
                    <span
                        id={`progressBar${props.translationField}`}
                        className={`absolute text-xs left-2/4 font-medium`}
                        style={{ transform: 'translateX(-50%)' }}
                        title={
                            getTranslation(`DUMMIES.PROGRESS_BAR.ELEMENT.TITLE.${props.translationField}`)
                                .format(valueToLocaleString(props.value))
                        }
                    >
                        {valueToLocaleString(props.value)}
                    </span>

                    <div
                        className={classNames(
                            'absolute top-2/4 left-2/4 rounded',
                            props.pointerClassName
                        )}
                        style={{ transform: 'translate(-50%, -50%)' }}
                        title={
                            getTranslation(`DUMMIES.PROGRESS_BAR.ELEMENT.TITLE.${props.translationField}`)
                                .format(valueToLocaleString(props.value))
                        }
                    />
                </div>
            </div>
        )
    }

    return <></>
}
