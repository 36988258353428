import {CampaignGroupFragment} from "./fragments";
import {CampaignGroup, Customer} from "../../models";
import {CampaignFactory} from "../campaign/factory";

import {StandaloneCampaignGroup} from "../../models/standalone";
import {StatCampaignGroup} from "../../models/stats";


export class CampaignGroupFactory {
    static fromFragment = (fragment: CampaignGroupFragment): CampaignGroup => {
        let campaigns = fragment.campaigns.map((f) => (CampaignFactory.fromFragment(f)))

        let campaignGroup = new CampaignGroup()
        campaignGroup.id = fragment.id
        campaignGroup.createdAt = new Date(fragment.created_at)
        campaignGroup.name = fragment.name
        campaignGroup.key = fragment.key
        campaignGroup.budgetMin = fragment.budget_min
        campaignGroup.budgetMax = fragment.budget_max
        campaignGroup.customerId = fragment.customer
        campaignGroup.campaigns = campaigns
        return campaignGroup
    }
}

export class StandaloneCampaignGroupFactory {
    static fromCampaignGroup = (
        customer: Customer,
        campaignGroup: CampaignGroup,
        statCampaignGroups?: StatCampaignGroup[],
    ): StandaloneCampaignGroup => {
        let standalone = new StandaloneCampaignGroup()

        if (statCampaignGroups) {
            statCampaignGroups.filter((s) => (
                parseInt(s.campaignGroupId) === campaignGroup.id
            )).forEach((stat) => {
                standalone.cost += stat.cost
                standalone.trend += stat.trend
                standalone.conversions += stat.conversions
                standalone.conversionsValue += stat.conversionsValue
            })
        }

        standalone.id = campaignGroup.id
        standalone.customerId = customer.id
        standalone.customerName = customer.name
        standalone.name = campaignGroup.name
        standalone.key = campaignGroup.key
        standalone.budgetMin = campaignGroup.budgetMin
        standalone.budgetMax = campaignGroup.budgetMax
        standalone.nbCampaigns = campaignGroup.campaigns.length
        return standalone
    }
}