import {StatCampaignGroupFragment} from "./fragments";
import {StatCampaignGroup} from "../../../../models/stats";
import {Step} from "../../../../models/stepPeriod";


export class StatCampaignGroupFactory {
    static fromFragment = (fragment: StatCampaignGroupFragment): StatCampaignGroup => {
        let statCampaignGroup = new StatCampaignGroup()
        statCampaignGroup.periodKey = new Date(fragment.period_key)
        statCampaignGroup.startedAt = new Date(fragment.started_at)
        statCampaignGroup.loginId = fragment.login_id
        statCampaignGroup.campaignGroupId = fragment.campaign_group_id
        statCampaignGroup.cost = fragment.cost
        statCampaignGroup.step = fragment.step as Step
        statCampaignGroup.trend = fragment.trend
        statCampaignGroup.name = fragment.name
        statCampaignGroup.impressions = fragment.impressions
        statCampaignGroup.purchases = fragment.purchases
        statCampaignGroup.purchaseRoas = fragment.purchase_roas
        statCampaignGroup.costPerPurchase = fragment.cost_per_purchase
        statCampaignGroup.leads = fragment.leads
        statCampaignGroup.costPerLead = fragment.cost_per_lead
        statCampaignGroup.conversions = fragment.conversions
        statCampaignGroup.conversionsValue = fragment.conversions_value
        return statCampaignGroup
    }
}