import React, {useEffect, useState} from "react";

import {Account, Customer, Invoice} from "../../../../models";
import {Cache, JwtToken} from "@boomrank/react-components";
import {CustomerLinks} from "../../../dummies/customer/links";
import {Link} from "react-router-dom";
import {InvoicesTableView} from "./table";
import {StandaloneInvoice} from "../../../../models/standalone";
import {StandaloneInvoiceFactory} from "../../../../services/invoice/factory";


interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    cacheInvoices: Cache<Invoice>
    cacheAccounts: Cache<Account>
}

export function InvoiceListView (props: Props) {
    let [standaloneInvoices, setStandaloneInvoices] = useState<StandaloneInvoice[]>([])
    let [isLoading, setIsLoading] = useState<boolean>(false)

    let standaloneInvoicesFormatter = () => {
        setIsLoading(true)
        let invoices = props.cacheInvoices.values()
            .filter((invoice) => invoice.customerId === props.customer.id)
            .map((invoice) => {
                    return StandaloneInvoiceFactory.fromInvoice(
                        invoice,
                        [props.customer],
                        props.cacheAccounts.values(),
                    )
                }
            ).sort((a, b) => Number(b.refDate) - Number(a.refDate))

        setStandaloneInvoices(invoices)
        setIsLoading(false)
    }

    useEffect(() => {
        standaloneInvoicesFormatter()
    }, [props.cacheInvoices, props.customer]);


    return (
        <div className={'mt-6'}>
            <div className={'w-full lg:flex lg:flex-row-reverse'}>
                <CustomerLinks customerId={props.customer.id} me={props.me} />
            </div>

            {
                props.customer.comment !== '' &&
                <div className={'mt-10 p-4 bg-slate-100 text-br-blue-900 rounded-md'}>
                    <span><b>Commentaire</b> :</span>
                    <p className={'whitespace-pre'}>{props.customer.comment}</p>
                </div>
            }


            <div className={'w-full lg:flex lg:flex-row-reverse py-6'}>
                <Link
                    to={`/dashboard/customer/${props.customer.id}/invoices/add`}
                    className={'btn btn-green no-underline w-fit p-2 text-white text-center rounded-sm'}
                >
                    Ajouter un montant
                </Link>
            </div>

            <div className={'my-10'}>
                <InvoicesTableView
                    token={props.token}
                    standaloneInvoices={standaloneInvoices}
                    columns={[
                        'ref',
                        'refDate',
                        'invoiceTypeName',
                        'totalItems',
                        'isSubmitted',
                    ]}
                    isLoading={isLoading}
                />
            </div>
        </div>
    )
}