import React, {useState} from 'react';
import {
    BarChart as RechartsBarChart,
    Tooltip as RechartsTooltip,
    Bar,
    CartesianGrid,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Label,
    LabelProps,
    LabelList,
    ReferenceLine,
    Legend,
} from 'recharts';
import classNames from "classnames";
import {ChartData} from "@boomrank/react-components";
import {defaultLanguage} from "../../../../intl";

export interface BarChartProps {
    data: ChartData
    minTickGap?: number
    strokeDasharray?: number | string
    tooltip?: React.ReactElement
    labelList?: React.ReactElement
    showLabelList?: boolean
    maxSegment?: {x: Date; y: number;}[]
    minSegment?: {x: Date; y: number;}[]
    showLegend?: boolean
}

export function BarChart (props: BarChartProps) {
    let [chartLabels, setChartLabels] = useState<any[]>([])

    let barLabelAngle = 0
    let barLabelColor = '#666'
    let barLabelClassname = classNames()
    let position: LabelProps['position'] = "top"

    if (Object.keys(props.data.entries).length > 0) {
        barLabelAngle = 90
        barLabelColor = '#FFF'
        position = "insideEnd"
        barLabelClassname = classNames(
            'hidden'
        )
    }


    let data = props.data.getData()

    let getYAxisDomain = () => {
        if (props.maxSegment && props.maxSegment[1]) {
            return props.maxSegment[1].y
        }

        return 0
    }

    let legendOnClick = (legend: any) => {
        let isActive = !legend.inactive
        let chart = chartLabels.find(chart => chart.dataKey === legend.payload.dataKey)

        if (isActive && !chart) {
            setChartLabels([
                ...chartLabels,
                legend
            ])
        }

        if (!isActive && chart) {
            setChartLabels((label) =>
                label.filter((c) => c.dataKey !== chart.dataKey)
            )
        }
    }


    let formatSegment = (segment: {x: Date, y: number}[]) => {
        return segment.map((s) => {
            return {
                x :  s.x.getTime(),
                y :  s.y
            }
        })
    }

    return (
        <ResponsiveContainer height={320}>
            <RechartsBarChart
                data={data as any}
                margin={{ right: 50, top: 25, left: 25, bottom: 25 }}
            >
                <CartesianGrid strokeDasharray={props.strokeDasharray} />

                <XAxis
                    dataKey={'x'}
                    minTickGap={props.minTickGap}
                    tickFormatter={(value) => value.toLocaleDateString(defaultLanguage)}
                >
                    <Label position="bottom" />
                </XAxis>

                <YAxis type="number"
                       tickFormatter={(value) => value.toFixed(0)}
                       domain={[0, getYAxisDomain()]}
                >
                    <Label angle={-90} position={'insideLeft'} offset={-40} />
                </YAxis>

                {
                    props.tooltip &&
                    <RechartsTooltip content={props.tooltip} />
                }
                {
                    !props.tooltip &&
                    <RechartsTooltip />
                }

                {
                    Object.keys(props.data.entries).map((key, idx) => {
                        let entry = props.data.entries[key]
                        let hiddenChart = chartLabels.find(chart => chart.dataKey === entry.label)

                        return (
                            <Bar
                                key={idx}
                                dataKey={key}
                                fill={entry.color}
                                stackId={entry.stackId}
                                className={'text-xxs md:text-xs lg:text-base'}
                                hide={hiddenChart}
                            >
                                {
                                    props.showLabelList &&
                                    <LabelList
                                        content={props.labelList}
                                        className={barLabelClassname}
                                        position={position}
                                        angle={barLabelAngle}
                                        fill={barLabelColor}
                                    />
                                }
                            </Bar>
                        )
                    })
                }

                {
                    props.maxSegment &&
                    <ReferenceLine
                        segment={formatSegment(props.maxSegment)}
                        stroke="#35B6AD"
                        strokeWidth={4.5}
                        strokeDasharray="3 3"
                        ifOverflow="extendDomain"
                    />
                }
                {
                    props.minSegment &&
                    <ReferenceLine
                        segment={formatSegment(props.minSegment)}
                        stroke="#E8900A"
                        strokeWidth={4.5}
                        strokeDasharray="3 3"
                        ifOverflow="extendDomain"
                    />
                }

                {
                    props.showLegend &&
                    <Legend onClick={legendOnClick} />
                }
            </RechartsBarChart>
        </ResponsiveContainer>
    );
}
