import React, {useEffect, useState} from "react";

import {FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {Account, Identifier, ProviderAccount} from "../../../../../../../models";
import {StandaloneCustomer} from "../../../../../../../models/standalone";
import {StepPeriod} from "../../../../../../../models/stepPeriod";

import {ProviderAccountApi} from "../../../../../../../services/provider-account/api";
import {ProviderAccountUpdateResponse} from "../../../../../../../services/provider-account/responses";
import {ProviderAccountFactory} from "../../../../../../../services/provider-account/factory";

import {CustomerOperationalTableHeaderView} from "./table-header";
import {CustomerOperationalTableBodyView} from "./table-body";


interface Props {
    token: JwtToken
    me: Account
    standaloneCustomers: StandaloneCustomer[]
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
    stepPeriod: StepPeriod
    isLoading?: boolean
    useSearch?: boolean
}

export function CustomerListOperationalView(props: Props) {
    let [standaloneCustomers, setStandaloneCustomers] = useState<StandaloneCustomer[]>([])

    let onBudgetUpdate = (providerAccount: ProviderAccount, budget:any) => {
        if (budget === "") {
            budget = null
        }

        ProviderAccountApi.update(
            props.token,
            providerAccount.id,
            providerAccount.loginId,
            providerAccount.name,
            providerAccount.budgetMin!,
            budget,
            providerAccount.providerId,
            providerAccount.customerId,
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as ProviderAccountUpdateResponse;
                props.cacheUpdater(ProviderAccountFactory.fromFragment(res.data));
            } else {
                res = res as FormErrorResponse;
            }
        })
    }

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let target = event.target.value

        let filtered = props.standaloneCustomers.filter((sc) =>
            sc.name.toLowerCase().includes(target.toLowerCase())
        )
        setStandaloneCustomers(filtered)

        if (event.target.value === '') {
            setStandaloneCustomers(props.standaloneCustomers)
        }
    }

    useEffect(() => {
        setStandaloneCustomers(props.standaloneCustomers)
    }, [props.standaloneCustomers]);


    return (
        <div className={'mt-4 w-full p-3'}>
            {
                props.useSearch &&
                <input
                    type={'search'}
                    onChange={onChange}
                    className={
                        'w-full mb-5 p-3 border border-gray-200 rounded-md ' +
                        'focus:outline-dashed focus:outline-2 focus:outline-gray-300'
                    }
                    placeholder={'Rechercher un client...'}
                />
            }

            <CustomerOperationalTableHeaderView />

            <CustomerOperationalTableBodyView
                token={props.token}
                me={props.me}
                standaloneCustomers={standaloneCustomers}
                onBudgetUpdate={onBudgetUpdate}
                cacheUpdater={props.cacheUpdater}
                stepPeriod={props.stepPeriod}
            />
        </div>
    )
}