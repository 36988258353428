import React, {useEffect, useState} from "react";
import {BrowserRouter, Routes} from "react-router-dom";
import {Route} from "react-router";

import {
    AuthApi,
    AuthLoginRoute,
    AuthLogoutRoute,
    AuthCallbackRoute,
    Firewall,
    JwtToken,
    JwtTokenFactory,
    PageDefaultView,
    PageNotFoundView,
    PrivateRoute,
    RefreshTokenSuccess,
    StorageJwtToken,
} from "@boomrank/react-components";

import {Dashboard} from "./dashboard";


export function App() {
    let storage = new StorageJwtToken(process.env.REACT_APP_KEY_STORAGE!)
    const [token, setToken] = useState<JwtToken | null>(storage.get());

    let refreshToken = (token: JwtToken) => {
        AuthApi.getRefreshToken(token.refresh)
            .then((response) => {
                response = response as RefreshTokenSuccess;
                if (response.statusCode === 200) {
                    let newToken = JwtTokenFactory.fromFragment({
                        refresh: token.refresh,
                        ...response.data
                    })

                    token.access = newToken.access
                    token.expired_at = newToken.expired_at

                    storage.set(token);
                    setToken(token);
                } else {
                    storage.remove();
                    setToken(null);
                }
            })
    }

    let verifyToken = (token: JwtToken) => {
        AuthApi.getVerifyToken(token)
            .then(response => {
                if (response.statusCode === 401) {
                    refreshToken(token)
                }
            })
    }

    let onCheck = () => {
        if (token) {
            let today = new Date();
            if (today > token.expired_at) {
                refreshToken(token)
            }
        }
    }

    useEffect(() => {
        if (token) {
            verifyToken(token)
        }
    }, [token])

    return (
        <div className={process.env.REACT_APP_RELEASE_BRANCH}>
            <BrowserRouter>
                <Routes>
                    <Route index element={<PageDefaultView />}/>
                    <Route path={'/login'} element={
                        <AuthLoginRoute
                            img={
                                <img
                                    src={'https://cdn.boomrank.io/digimood.io/logo/company_monitoring-sea.png'}
                                    alt={'Monitoring SEA logo'}
                                    className={'h-32'}
                                />
                            }
                            label={
                                <>MONITORING <span className="text-gray-400">SEA</span></>
                            }
                        />
                    }/>
                    <Route path={'/logout'} element={
                        <AuthLogoutRoute
                            redirect={'/'}
                            keyStorage={process.env.REACT_APP_KEY_STORAGE!}
                        />
                    }/>
                    <Route path={'/callback'} element={
                        <AuthCallbackRoute
                            keyStorage={process.env.REACT_APP_KEY_STORAGE!}
                            redirectUrl={'/dashboard/'}
                            onGetAccessTokenSuccess={(token) => setToken(token)}
                        />
                    }/>
                    <Route path={'/dashboard/*'} element={
                        <PrivateRoute token={token!}>
                            <Firewall token={token!} onCheck={onCheck}>
                                <Dashboard token={token!} />
                            </Firewall>
                        </PrivateRoute>
                    }/>
                    <Route path={'*'} element={<PageNotFoundView />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}
