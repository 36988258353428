import React, {useMemo} from "react";

import {Column, ColumnType, PaginateAdvancedTable, SecureHTML, TableV2} from "@boomrank/react-components";
import {defaultLanguage, getTranslation, translator} from "../../../../intl";
import {StandaloneLog} from "../../../../models/standalone";
import {TableLoader} from "../../../dummies/loaders/table";


interface Props {
    standaloneLogs: StandaloneLog[]
    isLoading?: boolean
}

export function LogsTableView(props: Props) {
    let isLoading = props.isLoading || false

    let defaultColumns: Column<StandaloneLog>[] = [
        {
            header: getTranslation('MONITORING.VIEWS.LOG.TABLE.CREATED_AT'),
            type: ColumnType.DATE,
            accessor: 'createdAt',
            cell: (row: StandaloneLog, value: any) => {
                return (
                    <span className={'text-slate-500 italic'}>
                        { row.createdAt.toLocaleDateString(defaultLanguage) }
                    </span>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.LOG.TABLE.ACCOUNT'),
            type: ColumnType.STRING,
            accessor: 'accountUser',
            cell: (row: StandaloneLog, value: any) => {
                return <>{value}</>
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.LOG.TABLE.MESSAGE'),
            type: ColumnType.STRING,
            accessor: 'code',
            cell: (row: StandaloneLog, value: any) => {
                let code = value
                let args: any = row.args
                let name = row.instanceName

                let defaultArgs = args.filter((arg: string | number | null) => arg != null)

                if ([0, 1, 2, 3, 4, 5].includes(Number(code.at(-1))) && (code !== 'Lx500' || code !== 'Lx501')) {
                    let budgets = defaultArgs.filter((arg: string | number) => typeof arg === "number")
                        .map((b: number) => b.toLocaleString(
                            defaultLanguage,
                            { style: 'currency', currency: 'EUR' }
                        ))

                    defaultArgs = [name, ...budgets]
                }

                let message = getTranslation(`MONITORING.VIEWS.LOG.CODES.${code.toUpperCase()}`)
                    .format(...defaultArgs)

                if (code === 'Lx108' || code === 'Lx109') {
                    message = getTranslation(`MONITORING.VIEWS.LOG.CODES.${code.toUpperCase()}`)
                        .format(row.accountUser)
                }

                if (code === 'Lx500' || code === 'Lx501') {
                    message = getTranslation(`MONITORING.VIEWS.LOG.CODES.${code.toUpperCase()}`)
                        .format(args)
                }

                return <SecureHTML>{message}</SecureHTML>
            }
        },
    ]

    const sortBy = useMemo(() => [
        {id: "createdAt", asc: true}
    ], []);

    if (isLoading) {
        return <TableLoader defaultColumns={defaultColumns} />
    }

    return (
        <TableV2
            rows={props.standaloneLogs}
            columns={defaultColumns}
        >
            <PaginateAdvancedTable
                translator={translator}
                mobileColumnsDisplayed={[0, 1, 2]}
                initialState={{
                    sortBy: sortBy,
                    pageSize: 100
                }}
            />
        </TableV2>
    )
}