import React from "react";

import {Account, Provider} from "../../../models";
import {StandaloneCampaign, StandaloneCustomer} from "../../../models/standalone";

import {Cache} from "@boomrank/react-components";
import {ProviderAccountIcon} from "../icon/provider-account";
import {AccountIcon} from "../icon/account";


interface Props {
    standaloneCustomer: StandaloneCustomer
    standaloneCampaign?: StandaloneCampaign
    cacheAccounts: Cache<Account>
    cacheProviders: Cache<Provider>
}

export function CardIconSection(props: Props) {
    return (
        <div className={'h-14 flex justify-evenly items-end'}>
            <ProviderAccountIcon
                standaloneCustomer={props.standaloneCustomer}
                standaloneCampaign={props.standaloneCampaign}
                cacheProviders={props.cacheProviders}
            />

            <AccountIcon
                standaloneCustomer={props.standaloneCustomer}
                cacheAccounts={props.cacheAccounts}
            />
        </div>
    )
}