import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";

import {Breadcrumb, Cache, JwtToken, PageNotFoundView} from "@boomrank/react-components";
import {Customer, Account} from "../../../../models";
import {LogListView} from "../../views/log/list";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheAccounts: Cache<Account>
}

export function LogListRoute (props: Props) {
    let location = useLocation()
    let params = useParams()

    let [customer, setCustomer] = useState<Customer>()

    useEffect(() => {
        if (params.customerId) {
            let customers = props.cacheCustomers.get(params.customerId!);
            if (customers.length > 0) {
                setCustomer(customers[0])
            }
        }
    }, [props.cacheCustomers, params.customerId]);

    if (!customer) {
        return <PageNotFoundView />
    }

    document.title = getTranslation('MONITORING.ROUTES.LOG.LIST_TITLE')
        .format(customer.name);


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {to: `/dashboard/customer/${customer.id}/detail`, content: <>{customer.name}</>},
                    {
                        to: `/dashboard/customer/${customer.id}/logs`,
                        content: getTranslation('MONITORING.ROUTES.LOG.BREADCRUMB'),
                        disabled:true
                    },
                ]}
            />

            <LogListView
                token={props.token}
                me={props.me}
                customer={customer}
                cacheAccounts={props.cacheAccounts}
            />
        </>
    )
}