import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useParams} from "react-router";

import {Breadcrumb, Cache, JwtToken, PageNotFoundView} from "@boomrank/react-components";
import {Customer, Account, Fee, Identifier} from "../../../../models";
import {getTranslation} from "../../../../intl";
import {FeeAddView} from "../../views/fee/add";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheFees: Cache<Fee>
    cacheUpdater: (obj: Identifier) => void
}

export function FeeAddRoute (props: Props) {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()

    let [customer, setCustomer] = useState<Customer | null | undefined>(undefined)
    let [fees, setFees] = useState<Fee[]>([])

    useEffect(() => {
        if (params.customerId) {
            let customers = props.cacheCustomers.get(params.customerId!);
            if (customers.length > 0) {
                let customer = customers[0]
                setCustomer(customer)

                fees = props.cacheFees.values().filter(fee => fee.customerId === customer.id)
                setFees(fees)
            } else {
                setCustomer(null)
            }
        }
    }, [props.cacheCustomers, params.customerId]);

    if (customer === null) {
        return <PageNotFoundView />
    }
    if (customer === undefined) {
        return <></>
    }

    let onSubmit = (fee: Fee) => {
        navigate(`/dashboard/customer/${fee.customerId}/fees`)
    }

    document.title = getTranslation('MONITORING.ROUTES.FEE.TITLE.ADD')
        .format(customer.name);

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: <>Dashboard</>},
                    {to: `/dashboard/customer/${customer.id}/detail/`, content: <>{customer.name}</>},
                    {
                        to: `/dashboard/customer/${customer.id}/fees`,
                        content: <>{getTranslation('MONITORING.ROUTES.FEE.BREADCRUMB.LIST')}</>,
                    },
                    {
                        to: `/dashboard/customer/${customer.id}/fees/add`,
                        content: <>{getTranslation('MONITORING.ROUTES.FEE.BREADCRUMB.ADD')}</>,
                    },
                ]}
            />

            <FeeAddView
                token={props.token}
                me={props.me}
                customer={customer}
                fees={fees}
                onSubmit={onSubmit}
                cacheUpdater={props.cacheUpdater}
            />
        </>
    )
}