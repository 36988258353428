import React from "react";
import {Link} from "react-router-dom";

import {Identifier, Account, State} from "../../../../models";
import {StandaloneCustomer} from "../../../../models/standalone";
import {StepPeriod} from "../../../../models/stepPeriod";

import {Column, ColumnType, JwtToken, PaginateAdvancedTable, TableV2} from "@boomrank/react-components";
import {defaultLanguage, getTranslation, translator} from "../../../../intl";
import {BudgetCell} from "../../../dummies/budget/budget-cell";
import {NotificationEditView} from "../../../notification/views/edit";
import {TableLoader} from "../../../dummies/loaders/table";
import {AccountHasCustomerEditView} from "../account-has-customer/edit";


interface Props {
    token: JwtToken
    me: Account
    standaloneCustomers: StandaloneCustomer[]
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
    stepPeriod: StepPeriod
    isLoading?: boolean
}

export function CustomersTableView(props: Props) {
    let isLoading = props.isLoading || false

    let defaultColumns: Column<StandaloneCustomer>[] = [
        {
            header: getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.NAME'),
            type: ColumnType.STRING,
            accessor: 'name',
            cell: (row: StandaloneCustomer, value: any) => {
                return (
                    <Link
                        to={`/dashboard/customer/${row.id}`}
                        className={'w-full xs:my-0 sm:my-0'}
                        title={'Voir les statistiques de l\'annonceur'}
                    >
                        {value}
                    </Link>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.STATE'),
            type: ColumnType.STRING,
            accessor: 'state',
            cell: (row: StandaloneCustomer, value: any) => {
                return (
                    <>
                        {
                            row.state === State.PAUSED &&
                            <i className={'mr-3 font-gold text-orange-500 fa-solid fa-pause'} title={
                                getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.PAUSED')
                            } />
                        }
                        {
                            row.state === State.ACTIVE &&
                            <i className={'mr-3 font-gold text-green-500 fa-solid fa-play'} title={
                                getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.PLAYING')
                            } />
                        }
                    </>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.COST'),
            type: ColumnType.STRING | ColumnType.NUMBER,
            accessor: 'cost',
            cell: (row: StandaloneCustomer, value: any) => {
                if (row.cost!) {
                    return value.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    )
                }
                return '-'
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.TREND'),
            type: ColumnType.STRING | ColumnType.NUMBER,
            accessor: 'trend',
            cell: (row: StandaloneCustomer, value: any) => {
                if (row.trend!) {
                    let trendIsOutOfBudget = () => {
                        if (props.stepPeriod.period.key === '1W' || props.stepPeriod.period.key === '1M') {
                            return (row.budgetMax && row.trend && (row.trend > row.budgetMax))
                        }

                        return false
                    }

                    return (
                        <div className={
                            trendIsOutOfBudget() ?
                                'text-red-500 font-medium' : ''
                        }>
                            {
                                trendIsOutOfBudget() &&
                                <i
                                    className="fa-solid fa-triangle-exclamation mr-2"
                                    title={getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.OUT_OF_BUDGET')}
                                />
                            }

                            {
                                row.trend.toLocaleString(
                                    defaultLanguage,
                                    {style: 'currency', currency: 'EUR'}
                                )
                            }
                        </div>
                    )
                }
                return '-'
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.BUDGET'),
            type: ColumnType.STRING | ColumnType.NUMBER,
            accessor: 'budgetMax',
            cell: (row: StandaloneCustomer, value: any) => {
                return (
                    <BudgetCell
                        budgetMin={row.budgetMin}
                        budgetMax={row.budgetMax}
                        stepPeriod={props.stepPeriod}
                    />
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CUSTOMER.TABLE.ACTION'),
            type: ColumnType.STRING | ColumnType.NUMBER,
            accessor: 'id',
            cell: (row: StandaloneCustomer, value: any) => {
                let hasAccount = row.accountHasCustomers.find((ahc) =>
                    ahc.accountId === props.me.id &&
                    ahc.customerId === row.id
                )

                return (
                    <div className={'w-fit flex gap-x-1.5'}>
                        <AccountHasCustomerEditView
                            me={props.me}
                            token={props.token}
                            standaloneCustomer={row}
                            accountHasCustomer={hasAccount}
                            cacheUpdater={props.cacheUpdater}
                        />

                        <NotificationEditView
                            token={props.token}
                            customerId={row.id}
                            accountId={props.me.id}
                            customerHasAccount={hasAccount!}
                            cacheUpdater={props.cacheUpdater}
                            className={'w-14'}
                        />
                    </div>
                )
            }
        },
    ]


    if (isLoading) {
        return (
            <TableLoader defaultColumns={defaultColumns} />
        )
    }

    return (
        <div className={'mt-4 w-full p-3'}>
            <TableV2
                rows={props.standaloneCustomers}
                columns={defaultColumns}
            >
                <PaginateAdvancedTable
                    translator={translator}
                />
            </TableV2>
        </div>
    )
}