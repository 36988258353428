import {ProviderAccountInvoiceFragment} from "./fragments";
import {ProviderAccount, ProviderAccountInvoice} from "../../models";
import {StandaloneProviderAccountInvoice} from "../../models/standalone";

export class ProviderAccountInvoiceFactory {
    static fromFragment = (fragment: ProviderAccountInvoiceFragment): ProviderAccountInvoice => {
        let providerAccountInvoice = new ProviderAccountInvoice()
        providerAccountInvoice.id = fragment.id
        providerAccountInvoice.createdAt = new Date(fragment.created_at)
        providerAccountInvoice.period = new Date(fragment.period)
        providerAccountInvoice.ref = fragment.ref
        providerAccountInvoice.currencyCode = fragment.currency_code
        providerAccountInvoice.totalAmount = fragment.total_amount
        return providerAccountInvoice
    }
}

export class StandaloneProviderAccountInvoiceFactory {
    static fromProviderAccountInvoice = (
        providerAccountInvoice: ProviderAccountInvoice,
        providerAccounts: ProviderAccount[],
    ): StandaloneProviderAccountInvoice => {
        let standalone = {...providerAccountInvoice} as StandaloneProviderAccountInvoice
        let providerAccount = providerAccounts.find(pa => pa.id === providerAccountInvoice.providerAccountId)
        if (providerAccount) {
            standalone.providerAccount = providerAccount
        }
        return standalone
    }
}