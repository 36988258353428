import React, {useState} from "react";

import {JwtToken} from "@boomrank/react-components";
import {Account} from "../../../../../models";
import {StandaloneInvoice} from "../../../../../models/standalone";

import {InvoicesTableView} from "../table";
import {InvoiceFilters, Option} from "./filters";


interface Props {
    token: JwtToken
    me: Account
    standaloneInvoices: StandaloneInvoice[]
}

export function InvoiceFilterView(props: Props) {
    let months = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet',
        'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ]

    let [filteredRows, setFilteredRows] = useState<StandaloneInvoice[]>([])
    let [isLoading, setIsLoading] = useState<boolean>(true)

    let uniqueValues = (options: Option[], value: string | number) => {
        let values = options.map((option) => option.value)

        if (!values.includes(value)) {
            options.push({
                value: value,
                active: false,
                selected: false
            })
        }
        return options
    }

    // GroupFilter : return uniques values for each filter
    let uniqueYears = props.standaloneInvoices.reduce(
        (years: Option[], standaloneInvoice: StandaloneInvoice) => {
            return uniqueValues(years, standaloneInvoice.refDateYear)
        }, []
    ).sort((a, b) => a.value - b.value)

    let uniqueMonths = props.standaloneInvoices.reduce(
        (months: Option[], standaloneInvoice: StandaloneInvoice) => {
            return uniqueValues(months, standaloneInvoice.refDateMonth)
        }, []
    ).sort((a, b) => months.indexOf(a.value) - months.indexOf(b.value))

    let uniqueCustomers = props.standaloneInvoices.reduce(
        (customers: Option[], standaloneInvoice: StandaloneInvoice) => {
            return uniqueValues(customers, standaloneInvoice.customerName)
        }, []
    ).sort((a, b) => a.value.localeCompare(b.value))

    let uniqueAccounts = props.standaloneInvoices.reduce(
        (accounts: Option[], standaloneInvoice: StandaloneInvoice) => {
            return uniqueValues(accounts, standaloneInvoice.accountName)
        }, []
    ).sort((a, b) => a.value.localeCompare(b.value))

    let uniqueInvoiceTypes = props.standaloneInvoices.reduce(
        (invoiceTypes: Option[], standaloneInvoice: StandaloneInvoice) => {
            return uniqueValues(invoiceTypes, standaloneInvoice.invoiceTypeName)
        }, []
    ).sort((a, b) => a.value.localeCompare(b.value))

    let uniqueSubmitted = [
        {value: 'Oui', active: false, selected: false},
        {value: 'Non', active: false, selected: false}
    ]


    let getActiveFilters = () => {
        let years = uniqueYears.map((v) => v.value)
        let months = uniqueMonths.map((v) => v.value)
        let customers = uniqueCustomers.map((v) => v.value)
        let accounts = uniqueAccounts.map((v) => v.value)
        let invoiceTypes = uniqueInvoiceTypes.map((v) => v.value)
        let submitteds = uniqueSubmitted.map((v) => v.value)

        filteredRows.reduce(
            (rows, standaloneInvoice: StandaloneInvoice) => {
                let year = standaloneInvoice.refDateYear
                let month = standaloneInvoice.refDateMonth
                let customer = standaloneInvoice.customerName
                let account = standaloneInvoice.accountName
                let invoiceType = standaloneInvoice.invoiceTypeName
                let submitted = standaloneInvoice.isSubmitted

                if (years.includes(year)) {
                    let index = years.indexOf(year)
                    uniqueYears[index].active = true
                }
                if (months.includes(month)) {
                    let index = months.indexOf(month)
                    uniqueMonths[index].active = true
                }
                if (customers.includes(customer)) {
                    let index = customers.indexOf(customer)
                    uniqueCustomers[index].active = true
                }
                if (accounts.includes(account)) {
                    let index = accounts.indexOf(account)
                    uniqueAccounts[index].active = true
                }
                if (invoiceTypes.includes(invoiceType)) {
                    let index = invoiceTypes.indexOf(invoiceType)
                    uniqueInvoiceTypes[index].active = true
                }
                if (submitteds.includes(submitted)) {
                    let index = submitteds.indexOf(submitted)
                    uniqueSubmitted[index].active = true
                }

                return rows
            }, []
        )
    }
    getActiveFilters()

    let onFilter = (rows: any[]) => {
        setFilteredRows(rows)

        if (rows.length > 0 || rows.length === 0) {
            setIsLoading(false)
        }
    }


    return (
        <div className={'w-full mt-6 py-6 px-5 border-2 border-slate-200 rounded-xl'}>
            {
                props.me.isAdmin() ?
                    <h3 className={'mb-3'}>Factures</h3> :
                    <h3 className={'mb-3 text-red-500'}>Factures non validées</h3>
            }

            <InvoiceFilters
                standaloneInvoices={props.standaloneInvoices}
                onFilter={onFilter}
                filterGroups={{
                    years: uniqueYears,
                    months: uniqueMonths,
                    customers: uniqueCustomers,
                    invoiceTypes: uniqueInvoiceTypes,
                    submitted: uniqueSubmitted,
                    accounts: uniqueAccounts
                }}
                isAdmin={props.me.isAdmin()}
                isLoading={isLoading}
            />

            <div className={'mt-10'}>
                <InvoicesTableView
                    token={props.token}
                    standaloneInvoices={filteredRows}
                    columns={[
                        'ref',
                        'customerName',
                        'accountName',
                        'refDate',
                        'invoiceTypeName',
                        'totalItems',
                        'isSubmitted',
                    ]}
                    me={props.me}
                    isLoading={isLoading}
                />
            </div>
        </div>
    )
}