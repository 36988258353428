import React from "react";
import classNames from "classnames";


export interface Props {
    budgetMin: number | null
    budgetMax: number | null
    cost: number
    trend: number
}

export function ProgressBarLegend(props: Props) {
    let trendIsOut = () => {
        if (props.trend && props.budgetMin && props.budgetMax) {
            return (props.trend > props.budgetMax) || (props.trend < props.budgetMin)
        }
        return false
    }

    return (
        <div className={'w-full flex flex-wrap items-center justify-between bg-slate-100 rounded-lg p-2'}>
            {
                props.budgetMin !== null && props.budgetMin > 0 &&
                <div className={'flex items-center gap-x-1 text-xs'}>
                    <span className={'h-2 w-2 rounded-full bg-br-blue-300'} />
                    <span className={'text-br-blue-300'}>Budget min</span>
                </div>
            }
            {
                props.cost > 0 &&
                <div className={'flex items-center gap-x-1 text-xs'}>
                    <span className={'h-2 w-2 rounded-full bg-br-blue-900'} />
                    <span className={'text-br-blue-900'}>Dépenses</span>
                </div>
            }
            {
                props.trend > 0 &&
                <div className={'flex items-center gap-x-1 text-xs'}>
                            <span className={classNames(
                                'h-2 w-2 rounded-full',
                                {'bg-br-red-500': trendIsOut()},
                                {'bg-green-500': !trendIsOut()},
                            )} />
                    <span className={classNames(
                        {'text-br-red-500': trendIsOut()},
                        {'text-green-500': !trendIsOut()},
                    )}>Trend</span>
                </div>
            }
            {
                props.budgetMax !== null &&
                <div className={'flex items-center gap-x-1 text-xs'}>
                    <span className={'h-2 w-2 rounded-full bg-br-blue-500'} />
                    <span className={'text-br-blue-500'}>Budget max</span>
                </div>
            }
        </div>
    )
}