import React from "react";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";

import {Breadcrumb, Cache, JwtToken, PageNotFoundView} from "@boomrank/react-components";
import {
    Account,
    AccountHasCustomer,
    Campaign,
    CampaignType,
    Customer,
    Provider,
    ProviderAccount,
} from "../../../../models";
import {StepPeriod} from "../../../../models/stepPeriod";
import {CampaignGetView} from "../../views/campaign/get";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheProviders: Cache<Provider>
    cacheAccounts: Cache<Account>
    cacheProviderAccounts: Cache<ProviderAccount>
    cacheCampaigns: Cache<Campaign>
    cacheCampaignTypes: Cache<CampaignType>
    stepPeriod: StepPeriod
    onStepPeriodChange: (stepPeriod: StepPeriod) => void
}

export function CampaignGetRoute(props: Props) {
    let location = useLocation()
    let params = useParams()

    let customer = props.cacheCustomers.values()
        .find(customer => customer.id === parseInt(params.customerId!))

    let campaign = props.cacheCampaigns.values()
        .find(campaign => campaign.id === parseInt(params.campaignId!))


    if (!customer || !campaign) {
        return <PageNotFoundView />
    }

    document.title = getTranslation('MONITORING.ROUTES.CAMPAIGN.GET_TITLE').format(
        customer.name,
        campaign.name
    );


    return (
        <>
            {
                location.state &&
                location.state.campaignGroupName &&
                location.state.previousPath.includes('campaign-group') ?
                    <Breadcrumb
                        location={location}
                        links={[
                            {to: `/dashboard/`, content: 'Dashboard'},
                            {to: `/dashboard/customer/${customer.id}/detail`, content: <>{customer.name}</>},
                            {
                                to: `/dashboard/customer/${customer.id}/campaign-groups`,
                                content: getTranslation('MONITORING.ROUTES.CAMPAIGN_GROUP.BREADCRUMB')
                            },
                            {
                                to: location.state.previousPath,
                                content: <>{location.state.campaignGroupName}</>,
                            },
                            {
                                to: `/dashboard/customer/${customer.id}/campaign/${campaign.id}`,
                                content: getTranslation('MONITORING.ROUTES.CAMPAIGN.BREADCRUMB').format(campaign.name),
                                disabled: true
                            },
                        ]}
                    />
                    :
                    <Breadcrumb
                        location={location}
                        links={[
                            {to: `/dashboard/`, content: 'Dashboard'},
                            {to: `/dashboard/customer/${customer.id}/detail`, content: <>{customer.name}</>},
                            {
                                to: `/dashboard/customer/${customer.id}/campaign/${campaign.id}`,
                                content: getTranslation('MONITORING.ROUTES.CAMPAIGN.BREADCRUMB').format(campaign.name),
                                disabled: true
                            },
                        ]}
                    />
            }

            <CampaignGetView
                token={props.token}
                me={props.me}
                customer={customer}
                campaign={campaign}
                cacheAccounts={props.cacheAccounts}
                cacheProviders={props.cacheProviders}
                cacheCampaignTypes={props.cacheCampaignTypes}
                cacheProviderAccounts={props.cacheProviderAccounts}
                cacheAccountHasCustomers={props.cacheAccountHasCustomers}
                stepPeriod={props.stepPeriod}
                onStepPeriodChange={props.onStepPeriodChange}
            />
        </>
    )
}
