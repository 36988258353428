import {NotificationFragment} from "./fragments";
import {Notification} from "../../models";


export class NotificationFactory {
    static fromFragment = (fragment: NotificationFragment) : Notification => {
        let reminderCodes = ['Nx000', 'Nx001']
        let budgetCodes = ['Nx101', 'Nx102', 'Nx103', 'Nx104']

        let notification = new Notification()
        notification.id = fragment.id
        notification.createdAt = new Date(fragment.created_at)
        notification.markAsRead = fragment.mark_as_read
        notification.code = fragment.code
        notification.username = fragment.username
        notification.customerId = fragment.args.customer_id
        notification.message = fragment.args.message

        if (reminderCodes.indexOf(notification.code) !== -1) {
            notification.customerName = fragment.args.customer_name
        }

        if (budgetCodes.indexOf(notification.code) !== -1) {
            notification.instanceName = fragment.args.instance_name
            notification.args = [
                fragment.args.informations.trend,
                fragment.args.informations.budget_min,
                fragment.args.informations.budget_max,
            ]
        }
        return notification
    }
}