import React, {useEffect, useState} from "react";

import {Invoice, InvoiceItem, ProviderAccount} from "../../../../../models";
import {Errors} from "../../../../dummies/errors";
import {FormEvents} from "../../../../dummies/form/events";
import {getTranslation} from "../../../../../intl";
import {FlaggedInvoiceItem} from "../../models";
import {Flag} from "../../../views/budget/models";
import {InvoiceItemBasisForm} from "./basis";
import classNames from "classnames";
import {FormBudgetTotal} from "../../../../dummies/form/budget-total";
import {BlueButton} from "@boomrank/react-components";


interface Props {
    invoice: Invoice
    providerAccounts: ProviderAccount[]
    onChange: (flaggedInvoiceItems: FlaggedInvoiceItem[]) => void
    errors: Errors
    disabled: boolean
}

export function InvoiceItemForm(props: Props) {
    let [flaggedInvoiceItems, setFlaggedInvoiceItems] = useState<FlaggedInvoiceItem[]>([])

    let onAddCSS = () => {
        let flagged = new FlaggedInvoiceItem()
        flagged.flag = Flag.CREATED
        flagged.invoiceItem.id = (new Date()).getTime()
        flagged.invoiceItem.providerAccountId = null
        flagged.invoiceItem.label = 'CSS (honoraires non applicables)'
        flagged.invoiceItem.applicableFee = false
        flagged.invoiceItem.isCss = true

        setFlaggedInvoiceItems([
            flagged,
            ...flaggedInvoiceItems
        ])
    }

    let onAdd = () => {
        let flagged = new FlaggedInvoiceItem()
        flagged.flag = Flag.CREATED
        flagged.invoiceItem.id = (new Date()).getTime()
        flagged.invoiceItem.providerAccountId = null

        setFlaggedInvoiceItems([
            flagged,
            ...flaggedInvoiceItems
        ])
    }

    let onChange = (invoiceItem: InvoiceItem) => {
        let flagged = flaggedInvoiceItems.find(fit => fit.invoiceItem.id === invoiceItem.id)

        if (flagged) {
            if (flagged.flag === Flag.NO_CHANGES) {
                flagged.flag = Flag.UPDATED
            }
            flagged.invoiceItem = invoiceItem
        }

        setFlaggedInvoiceItems([...flaggedInvoiceItems])
        props.onChange([...flaggedInvoiceItems])
    }

    let onDelete = (invoiceItem: InvoiceItem) => {
        let flagged = flaggedInvoiceItems.find(fit => fit.invoiceItem.id === invoiceItem.id)

        if (flagged) {
            flagged.deleted = true
        }

        let flaggedItems = flaggedInvoiceItems.filter(fit => !fit.deleted)

        setFlaggedInvoiceItems(flaggedItems)
        props.onChange(flaggedItems)
    }

    useEffect(() => {
        let flaggeds = props.invoice.invoiceItems.map((invoiceItem: InvoiceItem, idx: number) => {
            let fit = new FlaggedInvoiceItem()
            fit.flag = Flag.NO_CHANGES
            fit.invoiceItem = invoiceItem

            if (invoiceItem.id === 0) {
                fit.invoiceItem.id = (new Date()).getTime() + idx
            }
            return fit
        })

        setFlaggedInvoiceItems(flaggeds)
    }, [props.invoice.invoiceItems])


    return (
        <div className={'mt-8 w-full'}>
            <div className={'w-full flex flex-wrap justify-end gap-x-3'}>
                <FormEvents
                    className={'w-fit flex justify-end'}
                    onSubmit={onAdd}
                    submitTitle={getTranslation('MONITORING.FORMS.INVOICE.ITEM.SUBMIT_BUTTON')}
                    disabled={props.disabled}
                />
                <BlueButton
                    data-testid={'add-css-btn'}
                    className={'w-fit flex justify-end'}
                    onClick={onAddCSS}
                    text={getTranslation('MONITORING.FORMS.INVOICE.ITEM.CSS_BUTTON')}
                    disabled={props.disabled}
                />
            </div>

            {
                flaggedInvoiceItems.length > 0 &&
                <div className={'w-full mt-6'}>
                    <div className={'w-full sm:flex items-center justify-end text-gray-500 pr-10'}>
                        <span className={'w-1/2 text-sm pl-2 lg:w-40'}>
                            {getTranslation('MONITORING.FORMS.INVOICE.ITEM.LABELS.FEES')}
                        </span>
                        <span className={'w-1/2 text-sm pl-2 lg:w-4/6'}>
                            {getTranslation('MONITORING.FORMS.INVOICE.ITEM.LABELS.LABEL')}
                        </span>
                        <span className={'w-1/2 text-sm pl-2 lg:w-1/6'}>
                            {getTranslation('MONITORING.FORMS.INVOICE.ITEM.LABELS.PRICE_HT')}
                        </span>
                        <span className={'w-1/2 text-sm pl-2 lg:w-1/6'}>
                            {getTranslation('MONITORING.FORMS.INVOICE.ITEM.LABELS.PROVIDER_ACCOUNT')}
                        </span>
                    </div>

                    {
                        flaggedInvoiceItems.map(flagged => {
                            return (
                                <div
                                    key={flagged.invoiceItem.id}
                                    className={classNames(
                                        'w-full flex',
                                        {'text-gray-500 font-medium': flagged.flag !== Flag.NO_CHANGES}
                                    )}
                                >
                                    <InvoiceItemBasisForm
                                        invoiceItem={flagged.invoiceItem}
                                        providerAccounts={props.providerAccounts}
                                        onChange={onChange}
                                        onDelete={onDelete}
                                        disabled={props.disabled}
                                    />
                                </div>
                            )
                        })
                    }

                    <div className={'w-full mt-2 flex justify-end md:pr-8'}>
                        <div className={'w-1/3 p-3 bg-slate-100 rounded-lg'}>
                            <FormBudgetTotal
                                text={'Total prix HT'}
                                budget={flaggedInvoiceItems.reduce(
                                    (price, flagged) => price + Number(flagged.invoiceItem.priceHt), 0
                                )}
                                className={'text-base lg:w-full'}
                                parentBudget={null}
                                error={''}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
