import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {Breadcrumb, Cache, JwtToken} from "@boomrank/react-components";
import {Notification, Customer, AccountHasCustomer, Account, Identifier} from "../../../models";
import {NotificationListView} from "../views/list";
import {getTranslation} from "../../../intl";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheNotifications: Cache<Notification>
    cacheUpdater: (obj: Identifier) => void
}

export function NotificationListRoute (props: Props) {
    let location = useLocation()

    let [customers, setCustomers] = useState<Customer[]>([])
    let [notifications, setNotifications] = useState<Notification[]>([])

    let getCustomerIds = () => {
        return props.cacheAccountHasCustomers.values()
            .filter((ahc) => ahc.accountId === props.me.id)
            .map(ahc => ahc.customerId)
    }

    let getCustomers = (customerIds: number[]) => {
        let customers = props.cacheCustomers.values()
            .filter(c => customerIds.indexOf(c.id) !== -1)
            .map(customer => customer)

        setCustomers(customers)
    }

    let getNotifications = (customerIds: number[]) => {
        let notifications = props.cacheNotifications.values()
            .filter(n => customerIds.indexOf(n.customerId) !== -1)
            .map(notification => notification)

        setNotifications(notifications)
    }

    useEffect(() => {
        let customerIds = getCustomerIds()

        getCustomers(customerIds)
        getNotifications(customerIds)
    }, [props.cacheCustomers ,props.cacheAccountHasCustomers, props.cacheNotifications]);

    document.title = getTranslation('NOTIFICATION.ROUTES.LIST.TITLE');


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {
                        to: `/dashboard/notifications`,
                        content: getTranslation('NOTIFICATION.ROUTES.LIST.BREADCRUMB'),
                        disabled:true
                    },
                ]}
            />

            <NotificationListView
                token={props.token}
                customers={customers}
                notifications={notifications}
                cacheUpdater={props.cacheUpdater}
            />
        </>
    )
}