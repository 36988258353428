import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";

import {
    AccountHasCustomer,
    BudgetAccountGroup,
    CampaignGroup,
    Customer,
    Identifier,
    Account,
    Provider,
    ProviderAccount,
} from "../../../../models";

import {Breadcrumb, Cache, JwtToken, PageNotFoundView} from "@boomrank/react-components";
import {BudgetView} from "../../views/budget";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheProviderAccounts: Cache<ProviderAccount>
    cacheCampaignGroups: Cache<CampaignGroup>
    cacheBudgetAccountGroups: Cache<BudgetAccountGroup>
    cacheProviders: Cache<Provider>
    cacheUpdater: (obj: Identifier, remove: boolean) => void
}

export function BudgetRoute(props: Props) {
    let location = useLocation()
    let params = useParams()

    let customer = props.cacheCustomers.values()
        .find(c => c.id === parseInt(params.customerId!))

    let [providerAccounts, setProviderAccounts] = useState<ProviderAccount[]>([])
    let [campaignGroups, setCampaignGroups] = useState<CampaignGroup[]>([]);
    let [budgetAccountGroups, setBudgetAccountGroups] = useState<BudgetAccountGroup[] | []>([]);

    let getCustomerHasAccounts = () => {
        customer!.accountIds = props.cacheAccountHasCustomers.values()
            .filter(ahc => ahc.customerId === parseInt(params.customerId!))
            .map((ahc) => ahc.accountId)
    }

    let getProviderAccounts = () => {
        let providerAccounts = props.cacheProviderAccounts.values()
            .filter(pa => pa.customerId === parseInt(params.customerId!))
        setProviderAccounts(providerAccounts);

        return providerAccounts.map((providerAccount) => providerAccount.id)
    }

    let getCampaignGroups = () => {
        let campaignGroups = props.cacheCampaignGroups.values()
            .filter(cg => cg.customerId === parseInt(params.customerId!))
        setCampaignGroups(campaignGroups);
    }

    let getBudgetAccountGroups = (providerAccountIds: number[]) => {
        let budgetAccountGroups = props.cacheBudgetAccountGroups.values()
            .filter(bag => providerAccountIds.indexOf(bag.providerAccountId) !== -1)
        setBudgetAccountGroups(budgetAccountGroups);
    }

    useEffect(() => {
        getCustomerHasAccounts()
        getCampaignGroups()

        let providerAccountIds = getProviderAccounts()
        getBudgetAccountGroups(providerAccountIds)
    },[
        params.customerId,
        props.cacheAccountHasCustomers,
        props.cacheProviderAccounts,
        props.cacheCampaignGroups,
        props.cacheBudgetAccountGroups
    ])

    if (!customer) {
        return <PageNotFoundView />
    }

    document.title = getTranslation('MONITORING.ROUTES.BUDGET.EDIT_TITLE')
        .format(customer.name);


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {to: `/dashboard/customer/${customer.id}/detail`, content: <>{customer.name}</>},
                    {to: `/dashboard/customer/${customer.id}/budgets`, content: 'Budgets', disabled: true},
                ]}
            />

            <BudgetView
                token={props.token}
                me={props.me}
                customer={customer}
                providerAccounts={providerAccounts}
                campaignGroups={campaignGroups}
                budgetAccountGroups={budgetAccountGroups}
                cacheProviders={props.cacheProviders}
                cacheUpdater={props.cacheUpdater}
            />
        </>
    )
}