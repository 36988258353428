import {Translator} from "@boomrank/react-components";
import {LANG as LANG_FR} from "./fr-FR";


export const translator = new Translator('fr-FR')
translator.addTranslation('fr-FR', LANG_FR)

export const defaultLanguage = translator.getDefaultLanguage()

export function getTranslation(key: string) {
    return translator.getTranslation2(key)
}