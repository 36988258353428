import React, {MouseEventHandler} from "react";
import {getTranslation} from "../../../intl";
import {BlueButton} from "@boomrank/react-components";


interface Props {
    className: string
    onSubmit: MouseEventHandler | undefined
    submitTitle: string
    disabled: boolean
    onAdd?: MouseEventHandler | undefined
}

export function FormEvents(props: Props) {
    return (
        <div className={props.className}>
            {
                props.onAdd &&
                <BlueButton
                    data-testid={'add-btn'}
                    onClick={props.onAdd}
                    text={getTranslation('DUMMIES.FORM.EVENTS.ADD_BUTTON')}
                    disabled={props.disabled}
                />
            }

            <BlueButton
                data-testid={'submit-btn'}
                onClick={props.onSubmit}
                text={props.submitTitle}
                disabled={props.disabled}
            />
        </div>
    )
}