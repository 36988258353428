import React, {useEffect, useState} from "react";
import {Customer, Notification} from "../../../models";
import {Link} from "react-router-dom";
import {SecureHTML} from "@boomrank/react-components";
import {SelectInput} from "../../dummies/select";
import classNames from "classnames";
import {defaultLanguage, getTranslation} from "../../../intl";


interface Props {
    customers: Customer[]
    notifications: Notification[]
    notificationUpdater: (notification: Notification) => void
}


export function NotificationTableView(props: Props) {
    let [selectedCustomer, setSelectedCustomer] = useState<number>(0)
    let [notifications, setNotifications] = useState<Notification[]>(props.notifications)

    let selectedOptions = () => {
        let options = props.customers.map((customer) => {
            return {
                id: customer.id,
                content: customer.name,
                title: customer.name,
            }
        })

        options.unshift({
            id: 0,
            content: getTranslation('NOTIFICATION.VIEWS.TABLE.SELECT_OPTION.DEFAULT'),
            title: getTranslation('NOTIFICATION.VIEWS.TABLE.SELECT_OPTION.TITLE'),
        })

        return options
    }

    let onChange = (id: number) => {
        setSelectedCustomer(id)
    }

    let handleRead = (notification: Notification) => {
        props.notificationUpdater(notification)
    }

    let getCustomer = (notification: Notification) => {
        return props.customers.find((customer) => customer.id === notification.customerId)
    }

    useEffect(() => {
        if (selectedCustomer === 0) {
            setNotifications(props.notifications)
        } else {
            setNotifications(props.notifications.filter(n => n.customerId === selectedCustomer))
        }
    }, [props.notifications, selectedCustomer]);


    return (
        <div className={'mt-2 lg:mt-0'}>
            <SelectInput
                options={selectedOptions()}
                onChange={onChange}
                name={'customer'}
                value={selectedCustomer}
                classname={'w-full sm:w-72'}
            />

            <div className={'mt-12'}>
                {
                    notifications.map((notification: Notification, idx) => {
                        let formatedDate = notification.createdAt.toLocaleDateString(defaultLanguage)
                        let createdAt = formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1);

                        let customer = getCustomer(notification)

                        if (customer) {
                            let linkTo = `/dashboard/customer/${notification.customerId}/detail`

                            if (['Nx000', 'Nx001'].indexOf(notification.code) !== -1) {
                                linkTo = `/dashboard/customer/${notification.customerId}/invoices/add`
                            }

                            return (
                                <div
                                    key={idx}
                                    className={classNames(
                                        'p-2 border-b-2 flex flex-row sm:flex-row',
                                        {
                                            'bg-slate-50 border-b-white': notification.markAsRead,
                                            'bg-white border-b-slate-50': !notification.markAsRead
                                        }
                                    )}
                                >
                                    <div className={'w-full flex flex-col sm:flex-row'}>
                                        <p className={'w-full text-slate-500 italic text-sm mb-0 pb-0 sm:w-40'}>{createdAt}</p>
                                        <div className={'w-full flex flex-col mb-0'}>
                                            {
                                                notification.customerId &&
                                                <Link
                                                    className={'font-medium  w-fit'}
                                                    to={linkTo}
                                                >
                                                    {customer.name}
                                                </Link>
                                            }

                                            <div className={'text-slate-700 mb-0 pb-0'}>
                                                {
                                                    notification.message &&
                                                    <span>{notification.message}</span>
                                                }

                                                {
                                                    !notification.message &&
                                                    <SecureHTML>
                                                        {
                                                            getTranslation(`NOTIFICATION.VIEWS.TABLE.CODES.${notification.code.toUpperCase()}`)
                                                                .format(...notification.args)
                                                        }
                                                    </SecureHTML>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={'flex item-start justify-center sm:items-center pl-3 sm:pl-6 md:pl-8'}>
                                        <button
                                            onClick={() => handleRead(notification)}
                                            className={classNames(
                                                'w-10 h-12 rounded-sm',
                                                {
                                                    'btn-gray': notification.markAsRead,
                                                    'btn-blue': !notification.markAsRead,
                                                }
                                            )}
                                        >
                                            {
                                                notification.markAsRead ?
                                                    <i className="fa-solid fa-flag"></i> :
                                                    <i className="fa-regular fa-flag"></i>
                                            }
                                        </button>
                                    </div>
                                </div>
                            )
                        }

                        return <div key={idx}></div>
                    })
                }
            </div>
        </div>
    )
}
