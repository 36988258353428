import {ChartData, ChartEntry, CumulativeChartData} from "@boomrank/react-components";
import React, {useMemo} from "react";
import {
    StatCampaign,
    StatGlobalCampaignGroup,
    StatCampaignGroup,
    StatCustomer,
} from "../../../../models/stats";
import {CustomTooltip} from "../tooltip";
import {StepPeriod} from "../../../../models/stepPeriod";
import {LineChart} from "./chart";
import {defaultLanguage, getTranslation} from "../../../../intl";

export const GOOGLE_COLORS = [
    "#DB4437",
    "#C23D30",
    "#E8493A",
    "#9C3127",
    "#5C1D17",
]
export const META_COLORS = [
    "#1778F2",
    "#166DD9",
    "#1981FF",
    "#125AB3",
    "#0B3A73",
]

interface Props {
    data: StatCustomer[] | StatGlobalCampaignGroup[] | StatCampaignGroup[] | StatCampaign[]
    stepPeriod: StepPeriod
    conversions?: boolean
    budgetMin?: number | null | undefined
    budgetMax?: number | null | undefined
    budgetMinLabel?: string
    budgetMaxLabel?: string
}

export function LineGraph(props: Props) {
    let conversions = props.conversions || false

    let startDate = props.stepPeriod.period.start
    let endDate = props.stepPeriod.period.end

    let setColorByProvider = (chartData: ChartData) => {
        let googleStats: ChartEntry[] = []
        let metaStats: ChartEntry[] = []

        Object.keys(chartData.entries).forEach((entryKey) => {
            let chartEntry = chartData.getEntry(entryKey)
            chartEntry!.label = entryKey

            if (entryKey.includes('Google')) {
                googleStats.push(chartEntry!)
            } else if (entryKey.includes('Meta')) {
                metaStats.push(chartEntry!)
            }
        })

        googleStats.forEach((entry, idx) => {
            entry.color = GOOGLE_COLORS[idx]
        })
        metaStats.forEach((entry, idx) => {
            entry.color = META_COLORS[idx]
        })

        return chartData
    }

    let getDatesInRange = () => {
        const start = new Date(new Date(startDate));
        const end = new Date(new Date(endDate));

        const date = new Date(start.getTime());
        const dates = [];

        while (date <= end) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

    let generateDefaultValue = (chartData: CumulativeChartData, dates: Date[], names: string[]) => {
        dates.forEach((date) => {
            let localeDate = date.toLocaleDateString(defaultLanguage)

            names.forEach((name) => {
                chartData.add(name, localeDate, 0)
            })
        })

        return chartData
    }

    let formatChart = (
        data: StatCustomer[] | StatGlobalCampaignGroup[] | StatCampaignGroup[] | StatCampaign[],
        conversions: boolean
    ): ChartData => {
        let chartData = new ChartData()
        let dates = getDatesInRange()
        let statsName = Array.from(new Set(data.map(item => item.name)))

        generateDefaultValue(chartData, dates, statsName.sort());

        if (conversions) {
            data.forEach((stat) => {
                let conversion = 0
                if (stat.conversions > 0) {
                    conversion = stat.conversions
                }

                chartData.add(
                    stat.name,
                    stat.startedAt.toLocaleDateString(defaultLanguage),
                    Number(conversion.toFixed(2)),
                )
            })
        } else {
            data.forEach((stat) => {
                chartData.add(
                    stat.name,
                    stat.startedAt.toLocaleDateString(defaultLanguage),
                    Number(stat.cost!.toFixed(2)),
                )
            })
        }

        setColorByProvider(chartData)
        return chartData
    }
    const data = useMemo(() => formatChart(props.data, conversions), [props.data, props.conversions])


    return (
        <div className={'h-96 lg:w-3/4 lg:h-[26rem] border-2 border-slate-200 rounded-xl pt-3'}>
            <h4 className={'px-3 text-gray-500'}>
                {!conversions && getTranslation('DUMMIES.GRAPH.LINE.TITLE')}
                {conversions && getTranslation('DUMMIES.GRAPH.LINE.CONVERSION_TITLE')}
            </h4>

            <LineChart
                data={data}
                minTickGap={200}
                strokeDasharray={"3, 3"}
                maxLineY={props.budgetMax ? props.budgetMax : undefined}
                minLineY={props.budgetMin ? props.budgetMin : undefined}
                maxLineLabel={props.budgetMax ? props.budgetMaxLabel : undefined}
                minLineLabel={props.budgetMin ? props.budgetMinLabel : undefined}
                tooltip={<CustomTooltip conversions={conversions} />}
                showLegend={true}
            />
        </div>
    )
}