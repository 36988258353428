import React, {useState} from "react";

import {Account} from "../../../models";
import {StandaloneCustomer} from "../../../models/standalone";
import {Cache} from "@boomrank/react-components";


interface Props {
    standaloneCustomer: StandaloneCustomer
    cacheAccounts: Cache<Account>
}


export function AccountIcon(props: Props) {
    let [iconIsHover, setIconIsHover] = useState<boolean>(false)

    let accountIds = props.standaloneCustomer.accountHasCustomers.map((ahc) => ahc.accountId)
    let accounts = props.cacheAccounts.get(accountIds)

    let showDetails = (sup: number) => {
        if (sup === 0) {
            setIconIsHover(false)
        } else {
            setIconIsHover(!iconIsHover)
        }
    }


    return (
        <div
            role={"tooltip"}
            className={'my-3'}
            onMouseEnter={() => showDetails(accounts.length)}
            onMouseLeave={() => showDetails(accounts.length)}
        >
            <div className={'w-10 h-max-content rounded-sm flex justify-between'}>
                <i className={'fa-solid fa-users text-br-blue mr-1'} title={'Consultants'} />
                <sup className={'py-2 px-1 bg-slate-200 rounded-full text-slate-600'}>{accounts.length}</sup>
            </div>

            {
                iconIsHover &&
                <div
                    className={'absolute w-10/12 border border-slate-300 rounded p-2 flex flex-col text-slate-600 text-sm z-10 bg-white shadow-2xl'}
                    style={{bottom: '10%', left: '50%', transform: 'translate(-50%, -50%)'}}
                >
                    {
                        accounts.length > 0 &&
                        accounts.map((account, idx) => {
                            return <span key={idx}>{account.user.firstname} {account.user.lastname}</span>
                        })
                    }
                </div>
            }
        </div>
    )
}