import {FinancialInvoiceFragment} from "./fragments";
import {Account, Customer, Fee, FinancialInvoice, Invoice, InvoiceItem} from "../../models";
import {InvoiceFactory} from "../invoice/factory";
import {
    StandaloneFinancialInvoice,
    StandaloneFinancialInvoiceItem,
} from "../../models/standalone";
import {defaultLanguage, getTranslation} from "../../intl";

export class FinancialInvoiceFactory {
    static fromFragment = (fragment: FinancialInvoiceFragment): FinancialInvoice => {
        let invoices = fragment.invoices.map(fragment => {
            return InvoiceFactory.fromFragment(fragment)
        })

        let financialInvoice = new FinancialInvoice()
        financialInvoice.id = fragment.id
        financialInvoice.createdAt = new Date(fragment.created_at)
        financialInvoice.updatedAt = new Date(fragment.updated_at)
        financialInvoice.submittedAt = fragment.submitted_at ? new Date(fragment.submitted_at) : null
        financialInvoice.customerId = fragment.customer
        financialInvoice.refDate = new Date(fragment.ref_date)
        financialInvoice.feeId = fragment.fee
        financialInvoice.feeBracketId = fragment.fee_bracket
        financialInvoice.invoices = invoices
        financialInvoice.totalInvoices = fragment.total_invoices
        financialInvoice.totalFees = fragment.total_fees
        return financialInvoice
    }
}

export class StandaloneFinancialInvoiceFactory {
    static fromFinancialInvoice = (
        financialInvoice: FinancialInvoice,
        customers: Customer[],
        fees: Fee[],
        accounts?: Account[]
    ): StandaloneFinancialInvoice => {
        let standalone = {...financialInvoice} as StandaloneFinancialInvoice

        let year = financialInvoice.refDate.getFullYear()
        let month = financialInvoice.refDate.getMonth()

        standalone.refDateYear = year
        standalone.refDateMonth = getTranslation(`MONTHS.${month}`)
        standalone.refId = parseInt(`
            ${year}${financialInvoice.refDate.toLocaleDateString('fr-FR', {month: '2-digit'})}${financialInvoice.id}
        `)

        if (customers.length > 0) {
            let customer = customers.find(c => c.id === financialInvoice.customerId)

            if (customer) {
                standalone.customerName = customer.name
                standalone.customerFinancialComment = customer.financial_comment
                standalone.customerState = customer.state
                standalone.customerComment = customer.comment
            }
        }

        standalone.invoices = financialInvoice.invoices
        standalone.totalNbInvoices = financialInvoice.invoices.length

        standalone.totalInvoicesFees = 0
        standalone.totalInvoicesNoFees = 0

        if (accounts) {
            standalone.invoices.forEach((invoice) => {
                let account = accounts.find(account => account.id === invoice.accountId)
                invoice.accountName = account ? `${account.user.lastname} ${account.user.firstname.slice(0, 1)}.` : ''

                let totalInvoicesNoFees = invoice.invoiceItems.filter((item) => !item.applicableFee)
                    .reduce((price, item) => price + item.priceHt, 0)
                standalone.totalInvoicesNoFees += totalInvoicesNoFees

                let totalInvoicesFees = invoice.invoiceItems.filter((item) => item.applicableFee)
                    .reduce((price, item) => price + item.priceHt, 0)
                standalone.totalInvoicesFees += totalInvoicesFees
            })
        }

        if (financialInvoice.submittedAt !== null) {
            standalone.isSubmitted = 'Oui'
        } else {
            standalone.isSubmitted = 'Non'
        }

        if (financialInvoice.feeId !== null) {
            let rule = ''
            standalone.fee = fees.find((fee) => fee.id === financialInvoice.feeId)

            if (standalone.fee && financialInvoice.feeBracketId) {
                standalone.feeBracket = standalone.fee.feeBrackets.find((fb) =>
                    fb.id === financialInvoice.feeBracketId
                )

                if (standalone.fee.limitMin && financialInvoice.totalFees === standalone.fee.limitMin) {
                    rule = `Minimum de ${standalone.fee.limitMin.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    )}`
                }
                if (standalone.fee.limitMax && financialInvoice.totalFees === standalone.fee.limitMax) {
                    rule = `Maximum de ${standalone.fee.limitMax.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    )}`
                }

                if (standalone.feeBracket && rule === '') {
                    let ruleItems = []

                    if (standalone.feeBracket.rate > 0) {
                        ruleItems.push(`${standalone.feeBracket.rate}%`)
                    }
                    if (standalone.feeBracket.priceHt > 0) {
                        ruleItems.push(
                            `${standalone.feeBracket.priceHt.toLocaleString(
                                defaultLanguage,
                                {style: 'currency', currency: 'EUR'}
                            )}`
                        )
                    }

                    rule = ruleItems.join(' + ')
                }

                standalone.feesRule = rule
            }
        }

        return standalone
    }
}

export class StandaloneFinancialInvoiceItemFactory {
    static fromFinancialInvoice = (
        financialInvoice: StandaloneFinancialInvoice,
        invoiceItem: InvoiceItem,
    ): StandaloneFinancialInvoiceItem => {
        let standalone = new StandaloneFinancialInvoiceItem()

        standalone.financialInvoiceId = financialInvoice.id
        standalone.refDate = financialInvoice.refDate.toLocaleDateString(
            defaultLanguage, {month: "2-digit", year: 'numeric'}
        )

        standalone.customerName = financialInvoice.customerName
        standalone.customerPriceHt = financialInvoice.totalInvoices
        standalone.customerFees = financialInvoice.totalFees
        standalone.feesRule = financialInvoice.feesRule

        standalone.invoiceItemId = invoiceItem.id
        standalone.invoiceItemLabel = invoiceItem.label

        standalone.providerAccountId = invoiceItem.providerAccountId
        standalone.invoiceItemPriceHt = invoiceItem.priceHt

        return standalone
    }
}
