import React from "react";
import classNames from "classnames";

import {Account, AccountHasCustomer, Customer, Identifier} from "../../../../models";
import {FormErrorResponse, JwtToken} from "@boomrank/react-components";

import {CustomerApi} from "../../../../services/customer/api";
import {CustomerUpdateResponse} from "../../../../services/customer/responses";
import {CustomerFactory} from "../../../../services/customer/factory";
import {CustomerHasAccountApi} from "../../../../services/customer/has-account/api";
import {AccountHasCustomerListResponse} from "../../../../services/customer/has-account/responses";
import {AccountHasCustomerFactory} from "../../../../services/customer/has-account/factory";
import {StandaloneCustomer} from "../../../../models/standalone";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    me: Account
    standaloneCustomer: StandaloneCustomer
    accountHasCustomer: AccountHasCustomer | undefined
    cacheUpdater: (obj: Identifier, remove: boolean) => void
}

export function AccountHasCustomerEditView(props: Props) {
    let disabled = props.standaloneCustomer.account === props.me.id

    let getTitle = () => {
        let title = getTranslation('MONITORING.VIEWS.ACCOUNT_HAS_CUSTOMER.NOT_PERMITTED')

        if (props.accountHasCustomer && props.standaloneCustomer.account !== props.me.id) {
            title = getTranslation('MONITORING.VIEWS.ACCOUNT_HAS_CUSTOMER.UNFOLLOW')
        }
        if (!props.accountHasCustomer) {
            title = getTranslation('MONITORING.VIEWS.ACCOUNT_HAS_CUSTOMER.FOLLOW')
        }
        return title
    }

    let getCustomerHasAccounts = (customer: Customer) => {
        CustomerHasAccountApi.list(
            props.token,
            customer.id
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as AccountHasCustomerListResponse;

                res.data.results.forEach((fragment) => {
                    props.cacheUpdater(AccountHasCustomerFactory.fromFragment(fragment), false)
                })
            }
        })
    }

    let onClick = (accountHasCustomer: AccountHasCustomer | undefined) => {
        let accountIds = props.standaloneCustomer.accountIds

        if (accountHasCustomer) {
            accountIds = props.standaloneCustomer.accountIds.filter((accountId) =>
                accountId !== accountHasCustomer.accountId
            )
        } else {
            accountIds.push(props.me.id)
        }

        CustomerApi.update(
            props.token,
            props.standaloneCustomer.id,
            props.standaloneCustomer.name,
            props.standaloneCustomer.budgetMin,
            props.standaloneCustomer.budgetMax,
            props.standaloneCustomer.account,
            accountIds,
            props.standaloneCustomer.comment,
            props.standaloneCustomer.financial_comment
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as CustomerUpdateResponse;

                let deletedAccountHasCustomer = props.standaloneCustomer.accountHasCustomers
                    .filter(ahc => ahc.customerId === props.standaloneCustomer.id)
                deletedAccountHasCustomer.forEach(ahc => props.cacheUpdater(ahc, true))

                let updatedCustomer = CustomerFactory.fromFragment(res.data)
                updatedCustomer.accountIds = accountIds
                props.cacheUpdater(updatedCustomer, false)

                getCustomerHasAccounts(updatedCustomer)
            } else {
                res = res as FormErrorResponse;
                console.error(res)
            }
        })
    }


    return (
        <button
            className={classNames(
                'w-12 py-2 rounded-sm text-sm text-center text-white cursor-pointer ' +
                'disabled:text-gray-300 disabled:bg-gray-200 disabled:cursor-not-allowed',
                {'bg-br-blue-500': !disabled && !props.accountHasCustomer},
                {'bg-orange-300': !disabled && props.accountHasCustomer}
            )}
            title={getTitle()}
            onClick={() => onClick(props.accountHasCustomer)}
            disabled={disabled}
        >
            {
                props.accountHasCustomer ?
                    <i className={"fa-solid fa-star"} /> :
                    <i className={"fa-regular fa-star"} />
            }
        </button>
    )
}