import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {StatCustomerListResponse} from "./responses";
import {endpoints} from "../../api";


export class StatCustomerApi {
    static list(
        token: JwtToken,
        from: Date,
        to: Date,
        step: string
    ): Promise<FormErrorResponse | StatCustomerListResponse> {
        let url = endpoints.statCustomer.list
            .replace(':from', from.toLocaleDateString('fr-CA'))
            .replace(':to', to.toLocaleDateString('fr-CA'))
            .replace(':step', step)

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }
}