import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {
    CampaignGroupCreateResponse, CampaignGroupDeleteResponse,
    CampaignGroupListResponse,
    CampaignGroupUpdateResponse,
} from "./responses";
import {endpoints} from "../api";


export class CampaignGroupApi {
    static list(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | CampaignGroupListResponse> {
        let url = endpoints.campaignGroup.list
        if (baseUrl) {
            url = baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static create(
        token: JwtToken,
        name: string,
        key: string,
        budgetMin: number | null,
        budgetMax: number | null,
        customerId: number,
    ): Promise<FormErrorResponse | CampaignGroupCreateResponse> {
        let url = endpoints.campaignGroup.create

        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    name: name,
                    key: key,
                    budget_min: budgetMin,
                    budget_max: budgetMax,
                    customer: customerId,
                })
            }
        );
    }

    static update(
        token: JwtToken,
        pk: number,
        name: string,
        key: string,
        budgetMin: number | null,
        budgetMax: number | null,
        customerId: number,
    ): Promise<FormErrorResponse | CampaignGroupUpdateResponse> {
        let url = endpoints.campaignGroup.update.replace(':pk', pk.toString())

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    name: name,
                    key: key,
                    budget_min: budgetMin,
                    budget_max: budgetMax,
                    customer: customerId,
                })
            }
        );
    }

    static delete(
        token: JwtToken,
        pk: number,
    ): Promise<FormErrorResponse | CampaignGroupDeleteResponse> {
        let url = endpoints.campaignGroup.delete.replace(':pk', pk.toString());
        return Api.call(
            url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
            }
        );
    }
}