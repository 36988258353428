import React from "react";
import {Link} from "react-router-dom";

import {Identifier, Account, State} from "../../../../models";
import {StepPeriod} from "../../../../models/stepPeriod";

import {NotificationEditView} from "../../../notification/views/edit";
import {JwtToken} from "@boomrank/react-components";
import {CardBudgetSection} from "../budget";
import {StandaloneCustomer} from "../../../../models/standalone";
import {getTranslation} from "../../../../intl";
import {AccountHasCustomerEditView} from "../../../monitoring/views/account-has-customer/edit";


interface Props {
    token: JwtToken
    me: Account
    standaloneCustomer: StandaloneCustomer
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
    stepPeriod: StepPeriod
    hideMetrics?: boolean
}

export function CardDashboardCustomer(props: Props) {
    let hasAccount = props.standaloneCustomer.accountHasCustomers.find((ahc) =>
        ahc.accountId === props.me.id &&
        ahc.customerId === props.standaloneCustomer.id
    )

    return (
        <div className={'p-3 overflow-hidden bg-white relative border-2 border-slate-200 rounded-lg'}>
            <div className={'w-full flex justify-between items-center mb-4'}>
                <Link
                    to={`/dashboard/customer/${props.standaloneCustomer.id}`}
                    className={'text-lg lg:text-xl'}
                >
                    {props.standaloneCustomer.name}
                </Link>

                <div className={'w-fit flex gap-x-1.5'}>
                    <AccountHasCustomerEditView
                        me={props.me}
                        token={props.token}
                        standaloneCustomer={props.standaloneCustomer}
                        accountHasCustomer={hasAccount}
                        cacheUpdater={props.cacheUpdater}
                    />

                    <NotificationEditView
                        token={props.token}
                        accountId={props.me.id}
                        customerId={props.standaloneCustomer.id}
                        customerHasAccount={hasAccount!}
                        cacheUpdater={props.cacheUpdater}
                    />
                </div>
            </div>

            {
                props.standaloneCustomer.state === State.ACTIVE ?
                    <CardBudgetSection
                        cost={props.standaloneCustomer.cost}
                        trend={props.standaloneCustomer.trend}
                        budgetMin={props.standaloneCustomer.budgetMin!}
                        budgetMax={props.standaloneCustomer.budgetMax!}
                        stepPeriod={props.stepPeriod}
                        hideMetrics={props.hideMetrics || false}
                    />
                :
                    <div className={'text-center text-orange-500 font-bold p-4 bg-orange-100 rounded-md'}>
                        { getTranslation('STATE.4') }
                    </div>
            }
        </div>
    )
}