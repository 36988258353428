import React, {useEffect, useState} from "react";

import {
    Account,
    AccountHasCustomer,
    Campaign,
    CampaignType,
    Customer,
    Provider,
    ProviderAccount,
} from "../../../../models";
import {StatCampaign} from "../../../../models/stats";
import {StepPeriod} from "../../../../models/stepPeriod";

import {CustomerStatCampaignApi} from "../../../../services/customer/stats/campaign/api";
import {StatCampaignListResponse} from "../../../../services/customer/stats/campaign/responses";
import {StatCampaignFactory} from "../../../../services/customer/stats/campaign/factory";


import {Cache, JwtToken} from "@boomrank/react-components";
import {StepPeriodSelect} from "../../../dummies/select/stepPeriod";
import {LineGraph} from "../../../dummies/graph/line";
import {ComposedGraph} from "../../../dummies/graph/composed";
import {CustomerLinks} from "../../../dummies/customer/links";
import {CardCampaign} from "../../../dummies/card/campaign/get";
import {StandaloneCampaignFactory} from "../../../../services/campaign/factory";
import {StandaloneCampaign} from "../../../../models/standalone";
import {StandaloneCustomerFactory} from "../../../../services/customer/factory";


interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    campaign: Campaign
    cacheAccounts: Cache<Account>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheProviders: Cache<Provider>
    cacheProviderAccounts: Cache<ProviderAccount>
    cacheCampaignTypes: Cache<CampaignType>
    stepPeriod: StepPeriod
    onStepPeriodChange: (stepPeriod: StepPeriod) => void
}

export function CampaignGetView(props: Props) {
    let [isLoading, setIsLoading] = useState(false);
    let [statCampaigns, setStatCampaigns] = useState<StatCampaign[]>([]);

    let [standaloneCampaign, setStandaloneCampaign] = useState<StandaloneCampaign>()

    let standaloneCustomer = StandaloneCustomerFactory.fromCustomer(
        props.customer,
        props.cacheAccountHasCustomers.values(),
        props.cacheProviderAccounts.values(),
    )

    let campaignFormatter = () => {
        let campaign = StandaloneCampaignFactory.fromCampaign(
            props.campaign,
            props.customer,
            props.cacheProviderAccounts,
            props.cacheProviders,
            props.cacheCampaignTypes,
            statCampaigns
        )
        setStandaloneCampaign(campaign)
    }

    let addDefaultStat = () => {
        let stat = new StatCampaign()
        stat.name = props.campaign.name
        stat.campaignKey = props.campaign.key
        stat.loginId = props.campaign.key
        stat.startedAt = props.stepPeriod.period.start

        setStatCampaigns([stat])
    }

    let fetchStats = (selected: StepPeriod) => {
        setIsLoading(true)
        setStatCampaigns([])

        CustomerStatCampaignApi.get(
            props.token,
            props.customer.id,
            props.campaign.key,
            selected.period.start,
            selected.period.end,
            selected.stepChart
        ).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as StatCampaignListResponse;

                let statCampaigns = response.data.map((fragment) => {
                    fragment.name = props.campaign.name
                    return StatCampaignFactory.fromFragment(fragment)
                });

                setStatCampaigns(statCampaigns);

                if (statCampaigns.length === 0) {
                    addDefaultStat()
                }
            }
        })

        setIsLoading(false);
    };

    const rangeChangeHandler = (selected: StepPeriod) => {
        props.onStepPeriodChange(selected)
        fetchStats(selected)
    }

    useEffect(() => {
        campaignFormatter()
    }, [props.customer, props.campaign, statCampaigns]);


    return (
        <div className={'mt-6'}>
            {
                props.campaign &&
                <div className={'w-full lg:flex lg:items-center lg:justify-between'}>
                    <StepPeriodSelect
                        onChange={rangeChangeHandler}
                        stepPeriod={props.stepPeriod}
                    />

                    <CustomerLinks customerId={props.customer.id} me={props.me}/>
                </div>
            }

            {
                props.customer.comment !== '' &&
                <div className={'mt-10 p-4 bg-slate-100 text-br-blue-900 rounded-md'}>
                    <span><b>Commentaire</b> :</span>
                    <p className={'whitespace-pre'}>{props.customer.comment}</p>
                </div>
            }

            <div className={'mt-10 mb-2 lg:flex'}>
                <LineGraph
                    data={statCampaigns}
                    stepPeriod={props.stepPeriod}
                />

                {
                    standaloneCustomer &&
                    standaloneCampaign &&
                    <CardCampaign
                        standaloneCustomer={standaloneCustomer}
                        standaloneCampaign={standaloneCampaign}
                        statCampaigns={statCampaigns}
                        cacheAccounts={props.cacheAccounts}
                        cacheProviders={props.cacheProviders}
                        stepPeriod={props.stepPeriod}
                        isLoading={isLoading}
                    />
                }
            </div>

            <ComposedGraph
                data={statCampaigns}
                stepPeriod={props.stepPeriod}
            />
        </div>
    )
}
