import React, {useEffect, useState} from "react";
import {Navigate, Routes} from "react-router-dom";

import {Cache, JwtToken} from "@boomrank/react-components";
import {Account, Customer, Fee, FinancialInvoice, Identifier, Invoice} from "../../../models";
import {StandaloneFinancialInvoice} from "../../../models/standalone";
import {FinancialInvoiceApi} from "../../../services/financial_invoice/api";
import {FinancialInvoiceListResponse} from "../../../services/financial_invoice/responses";
import {FinancialInvoiceFactory, StandaloneFinancialInvoiceFactory} from "../../../services/financial_invoice/factory";
import {Route} from "react-router";
import {FinancialInvoiceListRoute} from "./list";
import {FinancialInvoiceEditRoute} from "./edit";


interface Props {
    token: JwtToken
    me: Account
    cacheAccounts: Cache<Account>
    cacheCustomers: Cache<Customer>
    cacheInvoices: Cache<Invoice>
    cacheFees: Cache<Fee>
    cacheUpdater: (obj: Identifier) => void
    refreshCacheInvoices: () => void
}

export function FinancialRoute(props: Props) {
    let [standaloneFinancialInvoices, setStandaloneFinancialInvoices] = useState<StandaloneFinancialInvoice[]>([])

    let getFinancialInvoices = (url?: string) => {
        if (props.me.isAdmin()) {
            FinancialInvoiceApi.list(props.token, url).then((res) => {
                if (res.statusCode >= 200 && res.statusCode < 300) {
                    res = res as FinancialInvoiceListResponse

                    let standalones = res.data.results.map((fragment) => {
                        let financialInvoice = FinancialInvoiceFactory.fromFragment(fragment)

                        return StandaloneFinancialInvoiceFactory.fromFinancialInvoice(
                            financialInvoice,
                            props.cacheCustomers.values(),
                            props.cacheFees.values(),
                            props.cacheAccounts.values(),
                        )
                    })
                    setStandaloneFinancialInvoices(standalones)

                    if (res.data.next) {
                        return getFinancialInvoices(res.data.next)
                    }
                }
            })
        }
    }

    let onSubmit = (financialInvoice: FinancialInvoice) => {
        getFinancialInvoices()
        props.refreshCacheInvoices()
    }

    useEffect(() => {
        getFinancialInvoices()
    }, [props.cacheInvoices, props.cacheCustomers, props.cacheFees]);


    if (!props.me.isAdmin()) {
        return <Navigate to={`/dashboard/`} replace={true}/>
    }

    return (
        <Routes>
            <Route
                index
                element={
                    <FinancialInvoiceListRoute
                        me={props.me}
                        token={props.token}
                        cacheFees={props.cacheFees}
                        cacheCustomers={props.cacheCustomers}
                        standaloneFinancialInvoices={standaloneFinancialInvoices}
                    />
                }
            />
            <Route
                path={'/:financialInvoiceId'}
                element={
                    <FinancialInvoiceEditRoute
                        me={props.me}
                        token={props.token}
                        cacheFees={props.cacheFees}
                        cacheCustomers={props.cacheCustomers}
                        standaloneFinancialInvoices={standaloneFinancialInvoices}
                        onSubmit={onSubmit}
                        cacheUpdater={props.cacheUpdater}
                    />
                }
            />
        </Routes>
    )
}