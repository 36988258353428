import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {
    ProviderAccountInvoiceListResponse
} from "./responses";
import {endpoints} from "../api";
import {ProviderAccount} from "../../models";


export class ProviderAccountInvoiceApi {
    static list(
        token: JwtToken,
        providerAccount: ProviderAccount,
        baseUrl?: string
    ): Promise<FormErrorResponse | ProviderAccountInvoiceListResponse> {
        let url = endpoints.providerAccountInvoice.list.replace(':pk', providerAccount.id.toString())

        if (baseUrl) {
            url = baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }
}