import React from "react";
import {NavLink} from "react-router-dom";

import {getTranslation} from "../../../intl";
import {Account, Role} from "../../../models";


interface Props {
    me?: Account
    customerId: number
}

export function CustomerLinks(props: Props) {
    let activeLink = "bg-br-green no-underline w-fit p-2 text-white text-center rounded-sm"
    let inactiveLink = "btn-blue no-underline w-fit p-2 text-white text-center rounded-sm"

    return (
        <div className={'w-fit flex flex-col gap-y-1 sm:flex-row justify-end items-end sm:gap-x-2 sm:gap-y-0'}>
            <NavLink
                to={`/dashboard/customer/${props.customerId}/detail`}
                title={getTranslation('DUMMIES.CUSTOMER.LINKS.CAMPAIGN.TITLE')}
                className={({ isActive }) => isActive ? activeLink : inactiveLink}
            >
                {getTranslation('DUMMIES.CUSTOMER.LINKS.CAMPAIGN.CONTENT')}
            </NavLink>

            <NavLink
                to={`/dashboard/customer/${props.customerId}/campaign-groups`}
                title={getTranslation('DUMMIES.CUSTOMER.LINKS.CAMPAIGN_GROUP.TITLE')}
                className={({ isActive }) => isActive ? activeLink : inactiveLink}
            >
                {getTranslation('DUMMIES.CUSTOMER.LINKS.CAMPAIGN_GROUP.CONTENT')}
            </NavLink>

            <NavLink
                to={`/dashboard/customer/${props.customerId}/budgets`}
                title={getTranslation('DUMMIES.CUSTOMER.LINKS.BUDGET.TITLE')}
                className={({ isActive }) => isActive ? activeLink : inactiveLink}
            >
                {getTranslation('DUMMIES.CUSTOMER.LINKS.BUDGET.CONTENT')}
            </NavLink>

            <NavLink
                to={`/dashboard/customer/${props.customerId}/invoices`}
                title={getTranslation('DUMMIES.CUSTOMER.LINKS.INVOICE.TITLE')}
                className={({ isActive }) => isActive ? activeLink : inactiveLink}
            >
                {getTranslation('DUMMIES.CUSTOMER.LINKS.INVOICE.CONTENT')}
            </NavLink>

            {
                props.me && props.me.isAdmin() &&
                <NavLink
                    to={`/dashboard/customer/${props.customerId}/fees`}
                    title={getTranslation('DUMMIES.CUSTOMER.LINKS.FEE.TITLE')}
                    className={({ isActive }) => isActive ? activeLink : inactiveLink}
                >
                    {getTranslation('DUMMIES.CUSTOMER.LINKS.FEE.CONTENT')}
                </NavLink>
            }

            <NavLink
                to={`/dashboard/customer/${props.customerId}/logs`}
                title={getTranslation('DUMMIES.CUSTOMER.LINKS.LOG.TITLE')}
                className={({ isActive }) => isActive ? activeLink : inactiveLink}
            >
                {getTranslation('DUMMIES.CUSTOMER.LINKS.LOG.CONTENT')}
            </NavLink>

            <NavLink
                to={`/dashboard/customer/${props.customerId}/edit`}
                title={getTranslation('DUMMIES.CUSTOMER.LINKS.EDIT.TITLE')}
                className={({ isActive }) => isActive ? activeLink : inactiveLink}
            >
                {getTranslation('DUMMIES.CUSTOMER.LINKS.EDIT.CONTENT')}
            </NavLink>
        </div>
    )
}