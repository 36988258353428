import React from "react";
import {useParams} from "react-router";
import {Navigate} from "react-router-dom";

import {
    CampaignGroup,
    Customer,
} from "../../../../models";

import {Cache} from "@boomrank/react-components";


interface Props {
    cacheCustomers: Cache<Customer>
    cacheCampaignGroups: Cache<CampaignGroup>
}

export function CustomerRoute(props: Props) {
    let params = useParams()

    let customer = props.cacheCustomers.values()
        .find(c => c.id === parseInt(params.customerId!))

    if (!customer) {
        return <Navigate to={`/dashboard/`} replace={true}/>
    }

    let getCustomerCampaignGroups = (customer: Customer) => {
        return props.cacheCampaignGroups.values()
            .filter(cg => cg.customerId === customer.id)
    }

    if (getCustomerCampaignGroups(customer).length > 0) {
        return (
            <Navigate
                to={`/dashboard/customer/${customer.id}/campaign-groups`}
                replace={true}
            />
        )
    } else {
        return (
            <Navigate
                to={`/dashboard/customer/${customer.id}/detail`}
                replace={true}
            />
        )
    }
}
