import React from "react";
import {CardLoader} from "../loaders/card";


interface Props {
    title: string
    createdAt?: Date
    children: React.ReactElement | React.ReactElement[] | string
    isLoading?: boolean
}

export function CardTemplate(props: Props) {
    let isLoading = props.isLoading || false

    if (isLoading) {
        return <CardLoader />
    }

    return (
        <div className={
            'relative w-full h-auto p-3 my-3 flex flex-col border-2 border-slate-200 rounded-xl ' +
            'overflow-hidden lg:w-1/4 lg:h-[26rem] lg:ml-2 lg:my-0 lg:overflow-y-auto'
        }>
            <div className={'w-full mt-2 mb-5 flex justify-between items-center'}>
                <h4 className={'my-0 font-medium'}>{props.title}</h4>

                {
                    props.createdAt &&
                    <span className={'p-1 px-2 bg-slate-100 text-sm text-slate-500 rounded-md'}>
                        créé le {props.createdAt.toLocaleDateString(
                            'fr-Fr', {day: "numeric", month: "short", year: "numeric"}
                        )}
                    </span>
                }
            </div>
            { props.children }
        </div>
    )
}