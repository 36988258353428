import {endpoints} from "../api";
import {JwtToken} from "@boomrank/react-components";

export class ExportApi {
    static csv(
        token: JwtToken,
        obj: any[],
        filename = 'monitoring_sea.csv'
    ): Promise<any> {
        let url = endpoints.export.csv

        let columns = Object.keys(obj[0])
        let data = obj.map((o) => {
            return Object.values(o)
        })

        return fetch(
            url, {
                method: 'POST',
                body: JSON.stringify({
                    'data': data,
                    'columns': columns
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        ).then(
            (response) => response.blob()
        ).then((blob) => {
            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(blob);
            a.download = filename;
            a.click();
        }).catch((err) => {
            console.log(err);
        })
    }
}