import {StatCampaignFragment} from "./fragments";
import {StatCampaign} from "../../../../models/stats";
import {Step} from "../../../../models/stepPeriod";


export class StatCampaignFactory {
    static fromFragment = (fragment: StatCampaignFragment): StatCampaign => {
        let statCampaign = new StatCampaign()
        statCampaign.periodKey = new Date(fragment.period_key)
        statCampaign.startedAt = new Date(fragment.started_at)
        statCampaign.loginId = fragment.login_id
        statCampaign.campaignKey = fragment.campaign_key
        statCampaign.cost = fragment.cost
        statCampaign.step = fragment.step as Step
        statCampaign.trend = fragment.trend
        statCampaign.name = fragment.name
        statCampaign.impressions = fragment.impressions
        statCampaign.purchases = fragment.purchases
        statCampaign.purchaseRoas = fragment.purchase_roas
        statCampaign.costPerPurchase = fragment.cost_per_purchase
        statCampaign.leads = fragment.leads
        statCampaign.costPerLead = fragment.cost_per_lead
        statCampaign.conversions = fragment.conversions
        statCampaign.conversionsValue = fragment.conversions_value
        return statCampaign
    }
}