import React from "react";
import {getTranslation} from "../../../../../intl";

import {StandaloneCustomerFee} from "../../../../../models/standalone";
import {DataFilters} from "@boomrank/react-components";


export interface Option {
    value: any;
    active: boolean;
    selected: boolean;
}

interface FilterGroups {
    accounts: Option[],
    activeCustomers: Option[],
    activeFees: Option[]
}

export interface Props {
    standaloneCustomerFees: StandaloneCustomerFee[]
    onFilter: (standaloneCustomerFees: StandaloneCustomerFee[]) => void
    filterGroups: FilterGroups
    isLoading?: boolean
}

export function CustomerFeeFilters(props: Props) {
    let filterGroups: any[] = [
        {
            field: 'activeCustomer',
            label: getTranslation('MONITORING.VIEWS.CUSTOMER.FEE.FILTERS.CUSTOMER_STATE'),
            options: props.filterGroups.activeCustomers,
            operator: 'custom',
            test: (row: StandaloneCustomerFee, search: string) => {
                return (row.activeCustomer && search === 'Actif') || (!row.activeCustomer && search === 'En pause')
            }
        },
        {
            field: 'activeFee',
            label: getTranslation('MONITORING.VIEWS.CUSTOMER.FEE.FILTERS.ACTIVE_FEE'),
            options: props.filterGroups.activeFees,
            operator: 'custom',
            test: (row: StandaloneCustomerFee, search: string) => {
                return (row.activeFee && search === 'Appliqué') || (!row.activeFee && search === 'Non renseigné')
            }
        },
        {
            field: 'customerAccount',
            label: getTranslation('MONITORING.VIEWS.CUSTOMER.FEE.FILTERS.CUSTOMER_ACCOUNT'),
            options: props.filterGroups.accounts,
            operator: 'contains',
        },
    ]

    return (
        <DataFilters
            data={props.standaloneCustomerFees}
            onFilter={props.onFilter}
            filterGroups={filterGroups}
            isLoading={props.isLoading || false}
        />
    )
}