
export const LANG = {
    MONTHS: {
        0: 'Janvier',
        1: 'Février',
        2: 'Mars',
        3: 'Avril',
        4: 'Mai',
        5: 'Juin',
        6: 'Juillet',
        7: 'Août',
        8: 'Septembre',
        9: 'Octobre',
        10: 'Novembre',
        11: 'Décembre',
    },

    REACT_TABLE: {
        'SORTED': 'Filtrer par {0}',
        'SORTED_ASC': 'Filtrage par ordre croissant',
        'SORTED_DESC': 'Filtrage par ordre décroissant',
    },
    REACT_TABLE_PAGINATION: {
        'FIRST_PAGE': 'Aller à la 1ère page',
        'PREVIOUS_PAGE': 'Page précédente',
        'NEXT_PAGE': 'Page suivante',
        'LAST_PAGE': 'Aller à la dernière page',
        'SHOW_DATA': `Afficher {0} données`,
    },

    TEMPLATE: {
        HELLO: `Bonjour ...`,
        HELLO_USER: `Bonjour {0}`,
    },

    SELECT_PERIOD: {
        CURRENT_WEEK: 'Semaine en cours',
        PREVIOUS_WEEK: 'Semaine précédente',
        CURRENT_MONTH: 'Mois en cours',
        PREVIOUS_MONTH: 'Mois précédent',
    },

    STATE: {
        '-1': 'Inconnue',
        0: 'Non spécifiée',
        1: 'Active',
        2: 'Archivée',
        3: 'Supprimée',
        4: 'En pause',
    },

    MONITORING: {
        ROUTES: {
            HOME: {
                TITLE: 'Monitoring SEA',
                BREADCRUMB: 'Notifications',
            },
            CUSTOMER: {
                ADD_TITLE: 'Ajouter un annonceur | Monitoring SEA',
                GET_TITLE: '{0} | Monitoring SEA',
                EDIT_TITLE: '{0} | Modifier | Monitoring SEA',
            },
            BUDGET: {
                EDIT_TITLE: '{0} | Budgets | Monitoring SEA',
            },
            CAMPAIGN: {
                GET_TITLE: '{0} | {1} | Monitoring SEA',
                BREADCRUMB: 'Campagne {0}',
            },
            CAMPAIGN_GROUP: {
                LIST_TITLE: '{0} | Groupes de campagnes | Monitoring SEA',
                GET_TITLE: '{0} | {1} | Monitoring SEA',
                BREADCRUMB: 'Groupes de campagnes',
            },
            LOG: {
                LIST_TITLE: '{0} | Historique | Monitoring SEA',
                BREADCRUMB: 'Historique',
            },
            FEE: {
                TITLE:{
                    DEFAULT: 'Honoraires | Monitoring SEA',
                    LIST:'{0} | Honoraires | Monitoring SEA',
                    ADD:'{0} | Ajouter | Monitoring SEA'
                },
                BREADCRUMB: {
                    LIST: 'Honoraires',
                    ADD: 'Nouveau'
                }
            },
            INVOICE: {
                LIST_TITLE: {
                    DEFAULT: 'Factures | Monitoring SEA',
                    CUSTOMER: '{0} | Factures | Monitoring SEA',
                },
                ADD_TITLE: ' {0} | Ajouter une facture | Monitoring SEA',
                EDIT_TITLE: '{0} | Modifier une facture | Monitoring SEA',
                BREADCRUMB: {
                    DEFAULT: 'Montants investis',
                    ADD: 'Ajouter',
                    EDIT: 'Modifier'
                }
            },
            FINANCIAL_INVOICE: {
                LIST_TITLE: 'Factures | Monitoring SEA',
                EDIT_TITLE: 'Modifier une facture | Monitoring SEA',
                BREADCRUMB: {
                    DEFAULT: 'Montants investis',
                    ADD: 'Ajouter',
                    EDIT: 'Modifier'
                }
            },
        },

        VIEWS: {
            HOME: {
                BUTTONS: {
                    ADD_CUSTOMER: 'Ajouter un annonceur',
                },
                SUMMARY: {
                    FOLLOWED_CUSTOMERS: 'Clients suivis',
                    COMPANY_CUSTOMERS: 'Clients de l\'agence',
                }
            },
            ACCOUNT_HAS_CUSTOMER: {
                NOT_PERMITTED: 'Veuillez affecter un nouveau consultant pour retirer de vos clients',
                UNFOLLOW: 'Ne plus suivre ce client',
                FOLLOW: 'Ajouter aux clients suivis'
            },
            CUSTOMER: {
                GET: {
                    PAUSED: 'La récupération des statistiques est {0}. Pour modifier, aller dans {1}'
                },
                EDIT: {
                    SUCCESS: {
                        UPDATE: 'Vos modifications ont été enregistrées.',
                        STATE_UPDATE: {
                            1: 'Modification enregistrée, le client est actuellement ACTIF',
                            2: 'Modification enregistrée, le client est actuellement en PAUSE',
                        },
                    },
                    DATE: {
                        CREATED_AT: 'Client créée le :',
                        LAST_UPDATE: 'Dernier crawl le :',
                    },
                },
                DELETE: {
                    SUBMIT_BUTTON: 'Supprimer',
                    CONFIRM_JS: 'Suppression définitive du client, ses comptes, groupes de campagnes, campagnes, factures, etc',
                },
                EXPORT: {
                    SUBMIT_BUTTON: 'Télécharger',
                    CONFIRM_JS: 'Souhaitez-vous lancer le téléchargement ? \nVous recevrez les données par email : {0}',
                    ERROR: 'Une erreur est survenue',
                    SUCCESS: 'Vous allez recevoir les données sur <b>{0}</b>',
                },
                TABLE: {
                    NAME: 'Annonceur',
                    STATE: 'Status',
                    BUDGET: 'Budget global',
                    BUDGET_MIN: 'Budget min / mois',
                    BUDGET_MAX: 'Budget max / mois',
                    COST: 'Dépense',
                    TREND: 'Trend',
                    ACTION: 'Actions',
                    OUT_OF_BUDGET: 'Le trend devrait être en dehors du budget définit. Veuillez apporter les correctifs nécessaires.',
                    PAUSED: 'La récupération des statistiques est en pause.',
                    PLAYING: 'La récupération des statistiques est active.'
                },
                OPERATIONAL: {
                    NAME: 'Compte',
                    STATE: 'Status',
                    PROVIDER: 'Levier',
                    BUDGET: 'Budget',
                    COST: 'Dépenses',
                    SUGGESTED: 'Suggestion quotidienne',
                    TREND: 'Trend',
                    ACTION: 'Actions',
                },
                FEE: {
                    FILTERS: {
                        CUSTOMER_ACCOUNT: 'Consultants',
                        CUSTOMER_STATE: 'Clients',
                        ACTIVE_FEE: 'Honoraires',
                    }
                },
            },
            PROVIDER_ACCOUNT: {
                UPSERT: {
                    SUCCESS: {
                        UPDATE: 'Vos modifications ont été enregistrées.',
                        CREATE: 'Nouveau(x) compte(s) enregistré(s).',
                        PARTIAL: 'Nouveau(s) compte(s) enregistré(s) mais certains présentent des erreurs..',
                    },
                    ERRORS: {
                        'GX000': 'Un identifiant Google est constitué de 10 chiffres',
                        'MX000': 'Un identifiant Meta est uniquement consituté de chiffres',
                        'ER000': 'L\'identifiant du compte est déjà utilisé...',
                        'ER001': 'Une erreur s\'est produite, veuillez réessayer.',
                        '2X100' : '[Compte *] et [Identifiant *] ne peuvent être vides',
                        '2X101' : '[Identifiant *] doit être une chaine de caractère',
                        '2X102' : '[Compte *] renseigné inexistant',
                        '2X103' : '[Identifiant *] Google invalide (ex: 012-345-6789)',
                        '2X104' : '[Identifiant *] Meta invalide (ex: 0123456789012345)',
                        '2X105' : 'Permissions refusés par Google Ads',
                        '2X106' : 'Permissions refusés par Meta Ads',
                        '2X107' : 'Client non reconnu par Google Ads',
                        '2X108' : 'Client non reconnu par Meta Ads',
                        '2X109' : 'L\'identifiant du compte est déjà utilisé',
                    }
                }
            },
            BUDGET: {
                CUSTOMER: {
                    SUCCESS: {
                        UPDATE: 'Vos modifications ont été enregistrées.',
                    },
                },
                CAMPAIGN_GROUP: {
                    SUCCESS: {
                        UPDATE: 'Vos modifications ont été enregistrées.',
                        CREATE: 'Nouveau(x) groupe(s) de campagnes enregistré(s).',
                    },
                },
                PROVIDER_ACCOUNT: {
                    SUCCESS: {
                        UPDATE: 'Vos modifications ont été enregistrées.'
                    },
                    ERRORS: {
                        LOGIN_ID: 'le champs [Identifiant *] ne peut être vide'
                    },
                },
            },
            CAMPAIGN_GROUP: {
                LIST: {
                    NO_GROUP: {
                        CUSTOMER: `Aucun groupe de campagne n'est associé à {0}`,
                        CREATE_LINK: `Ajouter un groupe de campagne`,
                    }
                },
                EDIT: {
                    SUCCESS: {
                        UPDATE: 'Vos modifications ont été enregistrées.',
                        CREATE: 'Nouveau(x) groupe(s) de campagnes enregistré(s).',
                    }
                },
                DELETE: {
                    SUBMIT_BUTTON: 'Supprimer',
                    CONFIRM_JS: 'Suppression définitive du groupe de campagnes et les statistiques associées à ce dernier ?',
                },
                TABLE: {
                    NAME: 'Groupe',
                    KEY: 'Identifiant',
                    BUDGET: 'Budget',
                    BUDGET_MIN: 'Budget min / mois',
                    BUDGET_MAX: 'Budget max / mois',
                    COST: 'Dépense',
                    TREND: 'Trend',
                    ACTION: 'Actions',
                    SUGGESTED_BUDGET: 'Suggestion quotidienne',
                    NB_CAMPAIGNS: 'Campagnes',
                    DELETE: 'Supprimer'
                }
            },
            CAMPAIGN: {
                TABLE_BUTTONS: {
                    FILTERED: 'Campagnes avec dépenses',
                    ALL: 'Toutes les campagnes',
                },
                TABLE: {
                    NAME: 'Campagne',
                    STATUS: 'Status',
                    COST: 'Dépense',
                    TREND: 'Trend',
                    CAMPAIGN_TYPE: 'Type',
                    PROVIDER_NAME: 'Plateforme',
                    CONVERSIONS: 'Conversions',
                    COST_PER_CONVERSION: 'Valeur des conversions',
                    IMPRESSIONS: 'Impressions',
                    PURCHASES: 'Purchases',
                    PURCHASE_ROAS: 'Purchase ROAS',
                    COST_PER_PURCHASE: 'Coût par Purchase',
                    LEADS: 'Leads',
                    COST_PER_LEAD: 'Coût par Leads',
                },
                STATUS: {
                    '-1': 'Inconnue',
                    0: 'Non spécifiée',
                    1: 'Active',
                    2: 'Archivée',
                    3: 'Supprimée',
                    4: 'En pause',
                },
                ICON_STATUS: {
                    '-1': 'fa-solid fa-question text-br-blue',
                    0: 'fa-solid fa-question text-orange-500',
                    1: 'fa-solid fa-play text-br-green',
                    2: 'fa-solid fa-box-archive text-br-blue-500',
                    3: 'fa-solid fa-ban text-br-red',
                    4: 'fa-solid fa-pause text-slate-400',
                }
            },
            INVOICE: {
                REMINDER: {
                    BUTTON: 'Envoyer un rappel aux consultants',
                    RESPONSE: {
                        SUCCESS: 'Envoie de notifications et emails de rappel aux consultants n\'ayant pas renseignés tous leurs montants.',
                        ERROR: 'Une erreur s\'est produite, veuillez réessayer.',
                    },
                    CONFIRM_JS: 'Souhaitez vous envoyer un email et une notification de rappel pour chacun des consultants ?'
                },
                MISSING: {
                    TITLE: 'Clients actifs sans aucune facture renseignée pour le mois précédent'
                },
                TABLE: {
                    REF: 'Référence (code analytic)',
                    CUSTOMER_NAME: 'Client',
                    ACCOUNT_NAME: 'Consultant',
                    REF_DATE: 'Période de facturation',
                    INVOICE_TYPE: 'Type',
                    TOTAL_ITEMS: 'Total HT',
                    TOTAL_FEES: 'Honoraires',
                    COMMENT: 'Commentaire',
                    CREATED_AT: 'Créée',
                    UPDATED_AT: 'Modifiée',
                    SUBMITTED_AT: 'Confirmée',
                    TITLE : {
                        UPDATE: 'Modifier / Valider la facture',
                        READ: 'Consulter le détail de la facture'
                    }
                },
                INVOICE_TYPE: {
                    1: 'Payée',
                    2: 'Non payée',
                    3: 'Avancée',
                },
                FILTER: {
                    OPTIONS: {
                        YEARS: 'Années',
                        MONTHS: 'Mois',
                        CUSTOMERS: 'Clients',
                        ACCOUNTS: 'Consultants',
                        INVOICE_TYPES: 'Types',
                        SUBMITTED: 'Confirmées',
                        CUSTOMER_STATES: 'Actifs',
                    }
                },
                EDIT: {
                    SUCCESS: {
                        CREATE: 'Nouvelle facture enregistrée.',
                        UPDATE: 'Vos modifications ont été enregistrées.',
                        SUBMIT: 'Facture définitivement validée.',
                    }
                },
            },
            FINANCIAL_INVOICE: {
                EDIT: {
                    BUTTON: {
                        SUBMIT: 'Confirmer définitivement l\'ensemble des factures',
                        REJECT: 'Rejeter / Demander modification',
                    },
                    SUBMIT: {
                        SUCCESS_UPDATE: 'Facture validée : aucune modification ne pourra être apportée',
                        SUCCESS_REJECT_INVOICE: 'Facture rejetée : un email va être envoyé au consultant en charge du client',
                        ERROR: 'Une erreur s\'est produite, veuillez recommencer',
                    }
                },
                TABLE: {
                    ID: 'Facture',
                    CUSTOMER_NAME: 'Client',
                    REF_DATE: 'Période de facturation',
                    TOTAL_INVOICES: 'Total HT',
                    TOTAL_NB_INVOICES: 'Factures (Consultants)',
                    TOTAL_FEES: 'Honoraires',
                    FEES_RULE: 'Règle appliquée',
                    COMMENT: 'Commentaire',
                    CREATED_AT: 'Créée',
                    UPDATED_AT: 'Modifiée',
                    SUBMITTED_AT: 'Confirmée (Pôle Finance)',
                    TITLE : {
                        UPDATE: 'Modifier / Valider la facture',
                        READ: 'Consulter le détail de la facture'
                    }
                },
                INVOICE: {
                    TYPE: {
                        1: 'Payée',
                        2: 'Non payée',
                        3: 'Avancée',
                    },
                }
            },
            LOG: {
                TABLE: {
                    CREATED_AT: 'Date',
                    ACCOUNT: 'Responsable',
                    MESSAGE: 'Message',
                },
                CODES: {
                    'LX100': `<b>{0}</b> - budget minimum <b>supprimé</b>`,
                    'LX101': `<b>{0}</b> - budget minimum passé de <b>{1}</b> à <b>{2}</b>`,
                    'LX102': `<b>{0}</b> - budget minimum défini à <b>{1}</b>`,
                    'LX103': `<b>{0}</b> - budget maximum supprimé`,
                    'LX104': `<b>{0}</b> - budget maximum passé de <b>{1}</b> à <b>{2}</b>`,
                    'LX105': `<b>{0}</b> - budget maximum défini à <b>{1}</b>`,
                    'LX106': `Client ajouté : {0}`,
                    'LX107': `Nom du client modifié : {0}`,
                    'LX108': `Consultant(e) opérationnel(le) ajouté(e) : {0}`,
                    'LX109': `Consultant(e) opérationnel(le) modifié(e) : {0}`,

                    'LX200': `[Compte] <b>{0}</b> - budget minimum <b>supprimé</b>`,
                    'LX201': `[Compte] <b>{0}</b> - budget minimum passé de <b>{1}</b> à <b>{2}</b>`,
                    'LX202': `[Compte] <b>{0}</b> - budget minimum défini à <b>{1}</b>`,
                    'LX203': `[Compte] <b>{0}</b> - budget maximum supprimé`,
                    'LX204': `[Compte] <b>{0}</b> - budget maximum passé de <b>{1}</b> à <b>{2}</b>`,
                    'LX205': `[Compte] <b>{0}</b> - budget maximum défini à <b>{1}</b>`,
                    'LX206': `Compte ajouté : {0}`,
                    'LX207': `Nom du compte modifié : {0}`,

                    'LX300': `[Groupe de campagnes] <b>{0}</b> - budget minimum <b>supprimé</b>`,
                    'LX301': `[Groupe de campagnes] <b>{0}</b> - budget minimum passé de <b>{1}</b> à <b>{2}</b>`,
                    'LX302': `[Groupe de campagnes] <b>{0}</b> - budget minimum défini à <b>{1}</b>`,
                    'LX303': `[Groupe de campagnes] <b>{0}</b> - budget maximum supprimé`,
                    'LX304': `[Groupe de campagnes] <b>{0}</b> - budget maximum passé de <b>{1}</b> à <b>{2}</b>`,
                    'LX305': `[Groupe de campagnes] <b>{0}</b> - budget maximum défini à <b>{1}</b>`,
                    'LX306': `Groupe de campagnes ajouté : {0}`,
                    'LX307': `Nom du groupe de campagnes modifié : {0}`,
                    'LX308': `Identifiant du groupe de campagnes ajouté : {0}`,
                    'LX309': `Identifiant du groupe de campagnes modifié : {0}`,

                    'LX400': `[Compte (groupe de campagnes)] <b>{0}</b> - budget minimum <b>supprimé</b>`,
                    'LX401': `[Compte (groupe de campagnes)] <b>{0}</b> - budget minimum passé de <b>{1}</b> à <b>{2}</b>`,
                    'LX402': `[Compte (groupe de campagnes)] <b>{0}</b> - budget minimum défini à <b>{1}</b>`,
                    'LX403': `[Compte (groupe de campagnes)] <b>{0}</b> - budget maximum supprimé`,
                    'LX404': `[Compte (groupe de campagnes)] <b>{0}</b> - budget maximum passé de <b>{1}</b> à <b>{2}</b>`,
                    'LX405': `[Compte (groupe de campagnes)] <b>{0}</b> - budget maximum défini à <b>{1}</b>`,
                    'LX406': `Compte (groupe de campagnes) ajouté : {0}`,
                    'LX407': `Nom du compte (du groupe de campagnes) modifié : {0}`,

                    'LX500': `Personne(s) liée(s) au client ajoutée(s) : {0}`,
                    'LX501': `Personne(s) liée(s) au client modifiée(s) : {0}`,

                    'LX601': `Client activé`,
                    'LX602': `Client mis en pause (aucune statistique ne sera récupérée)`,

                    'LX700': `Groupe de campagnes supprimé : {0}`,
                }
            },
            FEE: {
                ACCESS_DENIED: 'Vous n\'êtes pas autorisé à accéder à cette page.',
                ERROR: 'Une erreur est survenue',
                LIST: {
                    TITLE: 'Honoraires',
                    ADD_NEW_FEE: 'Ajouter des honoraires',
                    CARD: {
                        LABEL_LIMIT: 'Facturation',
                        LABEL_NO_LIMIT: 'Aucune limite',
                        LABEL_BETWEEN: 'Entre',
                        LABEL_MIN: 'Minimum',
                        LABEL_MAX: 'Maximum',
                        LABEL_AND: 'et',
                        TITLE_RULES: 'Règle(s)',
                        BUTTON_ENABLE: 'Appliquer',
                        ENABLED_AT: 'Appliqué depuis le {0}',
                    },
                },
                TABLE: {
                    CUSTOMER: {
                        NAME: 'Client',
                        ACCOUNT: 'Consultant',
                        CREATED_AT: 'Créé',
                        LAST_UPDATE: 'Dernier crawl',
                        BUDGET_MIN: 'Budget Min',
                        BUDGET_MAX: 'Budget Max',
                        STATE: 'Status',
                        STATE_OPTIONS: {
                            1: 'Actif',
                            2: 'En pause'
                        },
                        INVOICES: 'Nb de factures'
                    },
                    FEE: 'Honoraire appliqué',
                }
            }
        },

        FORMS: {
            ACCOUNT: {
                OPTION: {
                    CONTENT: 'Sélectionner un(e) consultant(e)',
                    TITLE: 'Sélectionner la personne responsable de cet annonceur',
                }
            },
            BUDGET_ACCOUNT_GROUP: {
                SUBMIT_BUTTON: 'Sauvegarder',
                BUDGET: {
                    OPTION: {
                        CONTENT: 'Sélectionner un compte',
                        TITLE: 'Sélectionner un compte qui contiennent les campagnes demandées',
                    }
                },
                ERRORS: {
                    BUDGET_MIN: 'Total supérieur au budget max du groupe de campagne ou de l\'annonceur',
                    BUDGET_MAX: 'Total supérieur au budget max du groupe de campagne ou de l\'annonceur',
                }
            },
            CAMPAIGN_GROUP: {
                TITLE: 'Groupe(s) de campagnes',
                SUBMIT_BUTTON: 'Sauvegarder',
                LABELS: {
                    NAME: 'Nom *',
                    KEY: 'Noms des campagnes contiennent *',
                    BUDGET_MIN: 'Budget min / mois',
                    BUDGET_MAX: 'Budget max / mois',
                },
                PLACEHOLDERS: {
                    NAME: 'Nom *',
                    KEY: 'Noms des campagnes contiennent *',
                },
                SUCCESS: {
                    BUDGET_ACCOUNT_GROUP: {
                        UPDATE: 'Vos modifications relatives au(x) compte(s) ont été enregistrées.',
                        CREATE: 'Nouveau(x) compte(s) ajouté(s) au groupe de campagnes.',
                    }
                },
                ERRORS: {
                    BUDGET_MIN: 'Total supérieur au budget max de l\'annonceur',
                    BUDGET_MAX: 'Total supérieur au budget max de l\'annonceur',
                }
            },
            CUSTOMER: {
                TITLE: 'Nom',
                SUBMIT_BUTTON: 'Sauvegarder',
                LABELS: {
                    ACCOUNT: 'Consultant(e) opérationnel(le)',
                    ACCOUNT_HAS_CUSTOMER: 'Personne(s) liée(s) à l\'annonceur',
                    NAME: 'Nom *',
                    COMMENT: 'Commentaire',
                    FINANCIAL_COMMENT: 'Commentaire financier',
                },
                PLACEHOLDERS: {
                    NAME: 'Nom de l\'annonceur',
                    COMMENT: 'Informations utiles au BizDev, au pôle finance, ...',
                },
                BUDGET: {
                    TITLE: 'Budget de l\'annonceur',
                    SUBMIT_BUTTON: 'Sauvegarder',
                },
                STATE: {
                    TITLE: `Récupération des statistiques :`,
                    SUBMIT_BUTTON: 'Sauvegarder',
                    OPTIONS: {
                        ACTIVE: 'Actif',
                        PAUSED: 'En pause'
                    }
                },
                ERRORS: {
                    NAME: 'Le champ [Nom *] ne peut être vide'
                }
            },
            PROVIDER_ACCOUNT: {
                TITLE: 'Compte(s)',
                SUBMIT_BUTTON: 'Sauvegarder',
                ADD_EMAIL: 'Veuillez ajouter it@digimood.com (pour chaque compte Meta renseigné) sur Meta Ads Manager',
                LABELS: {
                    PROVIDER: 'Plateforme *',
                    LOGIN_ID: 'Identifiant du compte *',
                    NAME: 'Nom du compte',
                },
                ERRORS: {
                    BUDGET_MIN: 'Total supérieur au budget max de l\'annonceur',
                    BUDGET_MAX: 'Total supérieur au budget max de l\'annonceur',
                }
            },
            FEE: {
                LABEL_LIMIT_MIN: 'Honoraires minimums à facturer',
                PLACEHOLDER_LIMIT_MIN: '0',
                LABEL_LIMIT_MAX: 'Honoraires maximums à facturer',
                PLACEHOLDER_LIMIT_MAX: '100',
                ADD_NEW_BRACKET: 'Ajouter une règle',
                BRACKET: {
                    LABEL_BRACKET_MIN: 'Entre: (€)',
                    PLACEHOLDER_BRACKET_MIN: '0',
                    LABEL_BRACKET_MAX: 'et: (€)',
                    PLACEHOLDER_BRACKET_MAX: '100',
                    LABEL_RATE: 'Appliqué: (%)',
                    PLACEHOLDER_RATE: '8',
                    LABEL_PRICE_HT: 'Montant fixe: (€)',
                    PLACEHOLDER_PRICE_HT: '500',
                    RATE_OR_PRICE_HT_ZERO: 'Un montant fixe ou/et un pourcentage doit être supérieur à 0.',
                    IS_VALID: 'Validé',
                    SUBMIT_BUTTON: 'Valider',
                    DELETE_BUTTON: 'Supprimer'
                },
                BUTTON_SAVE: 'Enregistrer',
                TEMPLATE : 'Template de règle (pourcentage, montant fixe)',
                INVALID_FEE: 'Les honoraires semblent vides.',
                INVALIDATE_BRACKETS: 'Certaines règles ne sont pas validées',
                INVALID_LIMIT: 'Les honoraires minimums à facturer doivent être inférieures aux honoraires maximums',
                INFO_FEE: '<b>Exemple</b><br/>\n' +
                    'Si le calcul des honoraires est <b>inférieur au montant minimum</b>, nous facturons le montant minimum en honoraires.<br/>\n' +
                    'Si le calcul des honoraires est <b>supérieur au montant maximum</b>, nous facturons le montant maximum en honoraires.<br/>',
                INFO_FEE_BRACKET: '<b>Exemple</b><br/>\n' +
                    'Entre 0€ et 100€ de dépenses<br/>\n' +
                    'Nous appliquons 8% d\'honoraires + 500€'

            },
            INVOICE: {
                TITLE: 'Montant(s) investi(s)',
                ITEMS_TITLE: 'Support(s)',
                UPDATE_BUTTON: 'Sauvegarder',
                SUBMIT_BUTTON: 'Valider (non modifiable)',
                CONFIRM_JS: 'Action irréversible, vous ne pourrez plus éditer la facture une fois celle-ci confirmée',
                LEGENDS: {
                    REF: 'Référence code analytic (optionnel)',
                    REF_DATE: 'Période de facturation *',
                    INVOICE_TYPE: 'Type *',
                    COMMENT: 'Commentaire destiné au pôle Finance (optionnel)',
                },
                PLACEHOLDERS: {
                    REF: 'Exemple : 202310-DGM',
                    REF_DATE: 'Période de facturation *',
                    INVOICE_TYPE: 'Type *',
                    TOTAL_ITEMS: 'Nombre de labels',
                },
                OPTION: {
                    CONTENT: 'Type de facturation',
                    TITLE: 'Sélectionner un type de facturation',
                    PAYING: 'Mandataire payeur',
                    NON_PAYING: 'Mandataire non payeur',
                    ADVANCED: 'Avancé par Digimood',
                },
                ITEM: {
                    SUBMIT_BUTTON: 'Ajouter une entrée',
                    CSS_BUTTON: 'Ajouter un montant CSS',
                    LABELS: {
                        FEES: 'Honoraires',
                        LABEL: 'Support *',
                        PRICE_HT: 'Prix HT *',
                        PROVIDER_ACCOUNT: 'N° de compte (optionnel)',
                    },
                    PLACEHOLDERS: {
                        LABEL: 'Exemple : Google Ads ...',
                        PRICE_HT: 'Exemple : 500',
                    },
                    OPTION: {
                        DEFAULT: 'Selectionner un compte'
                    },
                    BUTTON: {
                        DELETE: 'Supprimer cette entrée'
                    }
                }
            }
        }
    },

    NOTIFICATION: {
        ROUTES: {
            LIST: {
                TITLE: 'Notifications | Monitoring SEA',
                BREADCRUMB: 'Notifications',
            }
        },

        VIEWS: {
            EDIT: {
                BUTTONS: {
                    DISABLE: 'Désactiver les notifications',
                    ENABLE: 'Activer les notifications',
                }
            },
            LIST: {
                BUTTONS: {
                    READ: 'Lues',
                    UNREAD: 'Non Lues',
                    MARK_AS_READ: 'Tout marquer comme lue',
                    MARK_AS_UNREAD: 'Tout marquer comme non lue',
                },
            },
            LIST_UNREAD: {
                CODES: {
                    'NX000': `Vous pouvez dès maintenant renseigner vos montants dépensés`,
                    'NX001': `Le pôle Finance semble ne pas avoir tous vos montants investis. Veuillez vérifier.`,
                    'NX101': `Le trend ({0} €) est en dehors du <b>budget global</b> (min: {1} €, max: {2} €)`,
                    'NX102': `Le trend ({0} €) est en dehors du <b>budget du compte</b> (min: {1} €, max: {2} €)`,
                    'NX103': `Le trend ({0} €) est en dehors du <b>budget du groupe de campagne</b> (min: {1} €, max: {2} €)`,
                    'NX104': `Le trend ({0} €) est en dehors du <b>budget du compte lié au groupe de campagne</b> (min: {1} €, max: {2} €)`,
                }
            },
            TABLE: {
                SELECT_OPTION: {
                    DEFAULT: 'Filtrer par annonceur',
                    TITLE: 'Sélectionner un annonceur pour afficher uniquement ses notifications',
                },
                CODES: {
                    'NX000': `Vous pouvez dès maintenant renseigner vos montants dépensés`,
                    'NX001': `Le pôle Finance semble ne pas avoir tous vos montants investis. Veuillez vérifier.`,
                    'NX101': `Le trend ({0} €) est en dehors du <b>budget global</b> (min: {1} €, max: {2} €)`,
                    'NX102': `Le trend ({0} €) est en dehors du <b>budget du compte</b> (min: {1} €, max: {2} €)`,
                    'NX103': `Le trend ({0} €) est en dehors du <b>budget du groupe de campagne</b> (min: {1} €, max: {2} €)`,
                    'NX104': `Le trend ({0} €) est en dehors du <b>budget du compte lié au groupe de campagne</b> (min: {1} €, max: {2} €)`,
                }
            }
        },
    },

    DUMMIES: {
        BUDGET: {
            SUGGESTED: {
                BUDGET: 'Suggestion budget quotidien : ',
                MODIFICATOR: 'Modificateur : ',
            }
        },
        CARD: {
            CUSTOMER: {
                CUMULATIVE_STATS: {
                    TITLE: 'Mes clients',
                    COST: 'Dépenses cumulées',
                    TREND: 'Trends cumulés',
                },
                GET: {
                    TITLE: `Modifier {0}`,
                }
            },
            CAMPAIGN_GROUP: {
                LIST: {
                    TITLE: 'Groupes de campagnes'
                },
            },
        },
        CUSTOMER: {
            LINKS: {
                BUDGET: {
                    CONTENT: 'Budgets',
                    TITLE: 'Voir / Éditer les budgets',
                },
                CAMPAIGN: {
                    CONTENT: 'Campagnes',
                    TITLE: 'Voir les campagnes',
                },
                CAMPAIGN_GROUP: {
                    CONTENT: 'Groupes de campagnes',
                    TITLE: 'Voir les groupes de campagnes',
                },
                INVOICE: {
                    CONTENT: 'Montants investis',
                    TITLE: 'Voir / Éditer les montants investis',
                },
                LOG: {
                    CONTENT: 'Historique',
                    TITLE: 'Voir l\'historique des activités',
                },
                FEE: {
                    CONTENT: 'Honoraires',
                    TITLE: 'Voir les honoraires',
                },
                EDIT: {
                    CONTENT: 'Paramètres',
                    TITLE: 'Modifier le client',
                }
            },
        },
        FORM: {
            EVENTS: {
                ADD_BUTTON: 'Ajouter',
                SAVE_BUTTON: 'Enregistrer'
            },
        },
        GRAPH: {
            COMPOSED: {
                TITLE: 'Cumul des dépenses (€)',
                BUDGET_MIN : 'budget minimum',
                BUDGET_MAX : 'budget maximum',
            },
            LINE: {
                TITLE: 'Dépenses quotidiennes (€)',
                CONVERSION_TITLE: 'Conversions',
            },
            TOOLTIP: {
                TITLE: 'Total : ',
                THEORETICAL: {
                    MIN_EXPENSE : 'Dépense théorique min : <b>{0}</b>',
                    MAX_EXPENSE : 'Dépense théorique max : <b>{0}</b>',
                    EXPENSE : 'Dépense théorique : <b>{0}</b> ~ <b>{1}</b>',
                },
            },
        },
        INPUT: {
            BUDGET: {
                BUDGET_MIN: 'Budget min / mois',
                BUDGET_MAX: 'Budget max / mois',
                BUDGET_ERROR: 'Attention, le budget minimum dépasse le budget maximum !'
            }
        },
        SELECT: {
            STEP_PERIOD: {
                RANGE: `Du <b>{0}</b> au <b>{1}</b>`
            },
            INVOICE_TEMPLATE: {
                TITLE : 'Template de montants investis',
                OPTIONS: {
                    DEFAULT_CONTENT: 'Sélectionner',
                    DEFAULT_TITLE: 'Sélectionner un template de montant(s) investi(s)'
                },
            },
        },
        TEMPLATE: {
            MENU_ITEMS: {
                ME: 'Profil',
                INVOICES: 'Facturation',
                FEES: 'Honoraires',
                FINANCIAL_INVOICES: 'Finance',
                LOGOUT: 'Déconnexion',
                NOTIFICATION_TITLE: 'Consulter mes notifications',
            },
        },
        PROGRESS_BAR: {
            ELEMENT: {
                TITLE: {
                    COST : `Dépenses : {0}`,
                    BUDGET_MIN : `Budget min : {0}`,
                    BUDGET_MAX : `Budget max : {0}`,
                    TREND : `Trend : {0}`,
                }
            }
        }
    },
}
