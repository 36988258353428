import React, {useEffect} from "react";
import {PeriodKey, StepPeriod, StepPeriodFactory} from "../../../models/stepPeriod";
import {defaultLanguage, getTranslation} from "../../../intl";
import {SecureHTML} from "@boomrank/react-components";


interface Props {
    stepPeriod: StepPeriod
    onChange: (stepPeriod: StepPeriod) => void
}

export function StepPeriodSelect(props: Props) {
    let stepPeriods: StepPeriod[] = [
        StepPeriodFactory.getStepPeriod(PeriodKey.CURRENT_MONTH),
        StepPeriodFactory.getStepPeriod(PeriodKey.CURRENT_WEEK),
        StepPeriodFactory.getStepPeriod(PeriodKey.ONE_WEEK_AGO),
        StepPeriodFactory.getStepPeriod(PeriodKey.ONE_MONTH_AGO),
    ]

    let onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let id = parseInt(event.target.value)
        props.onChange(stepPeriods[id])
    }

    useEffect(() => {
        props.onChange(props.stepPeriod)
    }, [props.stepPeriod])


    return (
        <div className={'w-full mb-2 flex flex-col sm:flex-row flex-1 sm:items-center sm:gap-x-2 lg:mb-0'}>
            <select
                onChange={onChange}
                value={props.stepPeriod.id}
                className={'p-2 border'}
            >
                {
                    stepPeriods.map((stepPeriod, idx) => {
                        return (
                            <option
                                key={idx}
                                value={stepPeriod.id}
                                title={stepPeriod.period.label}
                            >
                                {stepPeriod.period.label}
                            </option>
                        )
                    })
                }
            </select>

            <SecureHTML className={'w-full sm:w-fit p-2 text-sm border border-gray-300 rounded-sm'}>
                {
                    getTranslation('DUMMIES.SELECT.STEP_PERIOD.RANGE')
                        .format(
                            props.stepPeriod.period.start.toLocaleDateString(defaultLanguage),
                            props.stepPeriod.period.end.toLocaleDateString(defaultLanguage)
                        )
                }
            </SecureHTML>
        </div>
    )
}