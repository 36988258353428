import React from "react";
import {StepPeriod} from "../../../models/stepPeriod";
import {defaultLanguage} from "../../../intl";


interface Props {
    budgetMin: number | any
    budgetMax: number | any
    stepPeriod: StepPeriod
}

export function BudgetCell(props: Props) {
    let budgetMin = props.budgetMin
    let budgetMax = props.budgetMax

    let setPeriodBudget = (budget: number, stepPeriod: StepPeriod) => {
        if (budget && props.stepPeriod.stepTable === '1W') {
            let month = stepPeriod.period.end.getMonth()
            let year = stepPeriod.period.end.getFullYear()
            let lastDay = new Date(year, month +1, 0).getDate()

            return (budget / lastDay) * 7
        }
        return budget
    }

    if (budgetMin && budgetMax) {
        let localeBudgetMin = setPeriodBudget(budgetMin, props.stepPeriod)
            .toLocaleString(
                defaultLanguage,
                { style: 'currency', currency: 'EUR'}
            )
        let localeBudgetMax = setPeriodBudget(budgetMax, props.stepPeriod)
            .toLocaleString(
                defaultLanguage,
                { style: 'currency', currency: 'EUR'}
            )

        return (
            <div
                title={`Le budget minimum est de ${localeBudgetMin} - Le budget maximum de ${localeBudgetMax}`}
                className={'cursor-help'}
            >
                {localeBudgetMin} ~ {localeBudgetMax}
            </div>
        )
    }

    if (budgetMin && !budgetMax) {
        let localeBudgetMin = setPeriodBudget(budgetMin, props.stepPeriod).toLocaleString(
            defaultLanguage,
            { style: 'currency', currency: 'EUR'}
        )

        return (
            <div
                title={`Seul le budget minimum du client est défini (${localeBudgetMin})`}
                className={'cursor-help'}
            >
                &gt; {localeBudgetMin}
            </div>
        )
    }

    if (!budgetMin && budgetMax) {
        let localeBudgetMax = setPeriodBudget(budgetMax, props.stepPeriod).toLocaleString(
            defaultLanguage,
            { style: 'currency', currency: 'EUR'}
        )

        return (
            <div
                title={`Seul le budget maximum du client est défini (${localeBudgetMax})`}
                className={'cursor-help'}
            >
                &lt; {localeBudgetMax}
            </div>
        )
    }

    return <div>-</div>
}