import React, {useState} from "react";

import {
    Account,
    BudgetAccountGroup,
    CampaignGroup,
    Customer,
    Identifier,
    Provider,
    ProviderAccount,
} from "../../../../../models";
import {Flag, FlaggedCampaignGroup} from "../models";

import {CampaignGroupApi} from "../../../../../services/campaign-group/api";
import {
    CampaignGroupCreateResponse,
    CampaignGroupUpdateResponse
} from "../../../../../services/campaign-group/responses";
import {CampaignGroupFactory} from "../../../../../services/campaign-group/factory";

import {FormErrorResponse, Cache, PlaceHolder, JwtToken} from "@boomrank/react-components";
import {Errors} from "../../../../dummies/errors";
import {getTranslation} from "../../../../../intl";
import {FormNotification} from "../../../forms/models";
import {CampaignGroupForm} from "../../../forms/campaign-group";


interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    campaignGroups: CampaignGroup[]
    providerAccounts: ProviderAccount[]
    budgetAccountGroups: BudgetAccountGroup[]
    cacheProviders: Cache<Provider>
    cacheUpdater: (obj: Identifier, remove: boolean) => void
}

export function CampaignGroupFormView(props: Props) {
    let [loading, setLoading] = useState<boolean>(false);
    let [errors, setErrors] = useState<Errors>({});
    let [message, setMessage] = useState(new FormNotification())

    let persistCampaignGroup = (fcg: FlaggedCampaignGroup) => {
        if (fcg.flag === Flag.CREATED) {
            addCampaignGroup(fcg.campaignGroup)
        }
        if (fcg.flag === Flag.UPDATED) {
            editCampaignGroup(fcg.campaignGroup)
        }
    }

    let addCampaignGroup = (campaignGroup: CampaignGroup) => {
        if (campaignGroup.name !== '' && campaignGroup.key !== '') {
            setLoading(true)

            CampaignGroupApi.create(
                props.token,
                campaignGroup.name,
                campaignGroup.key,
                campaignGroup.budgetMin,
                campaignGroup.budgetMax,
                campaignGroup.customerId,
            ).then(res => {
                if (res.statusCode >= 200 && res.statusCode < 300) {
                    res = res as CampaignGroupCreateResponse;
                    props.cacheUpdater(CampaignGroupFactory.fromFragment(res.data), false);

                    setMessage(
                        message.setSuccess(
                            getTranslation('MONITORING.VIEWS.BUDGET.CAMPAIGN_GROUP.SUCCESS.CREATE')
                        )
                    )
                } else {
                    res = res as FormErrorResponse;
                    setErrors(res.data)
                }
                setLoading(false)
            })
        }
    }

    let editCampaignGroup = (campaignGroup: CampaignGroup) => {
        setLoading(true)

        CampaignGroupApi.update(
            props.token,
            campaignGroup.id,
            campaignGroup.name,
            campaignGroup.key,
            campaignGroup.budgetMin,
            campaignGroup.budgetMax,
            campaignGroup.customerId,
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as CampaignGroupUpdateResponse;
                props.cacheUpdater(CampaignGroupFactory.fromFragment(res.data), false)

                setMessage(
                    message.setSuccess(
                        getTranslation('MONITORING.VIEWS.BUDGET.CAMPAIGN_GROUP.SUCCESS.UPDATE')
                    )
                )
            } else {
                res = res as FormErrorResponse;
                setErrors(res.data);
            }
            setLoading(false)
        })
    }

    let onSubmit = (flaggedCampaignGroups: FlaggedCampaignGroup[]) => {
        setMessage(message.clear())

        if (flaggedCampaignGroups.length > 0) {
            flaggedCampaignGroups.forEach((campaignGroup) => {
                persistCampaignGroup(campaignGroup);
            })
        }
    }


    return (
        <CampaignGroupForm
            token={props.token}
            me={props.me}
            customer={props.customer}
            campaignGroups={props.campaignGroups}
            onSubmit={onSubmit}
            message={message}
            budgetAccountGroups={props.budgetAccountGroups}
            providerAccounts={props.providerAccounts}
            cacheProviders={props.cacheProviders}
            cacheUpdater={props.cacheUpdater}
            disabled={loading}
        />
    )
}