import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";

import {
    Account,
    AccountHasCustomer,
    Campaign,
    CampaignType,
    Customer,
    Provider,
    ProviderAccount,
} from "../../../../models";
import {StepPeriod} from "../../../../models/stepPeriod";

import {Breadcrumb, Cache, JwtToken, PageNotFoundView} from "@boomrank/react-components";
import {CustomerGetView} from "../../views/customer/get";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheCampaigns: Cache<Campaign>
    cacheAccounts: Cache<Account>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheProviderAccounts: Cache<ProviderAccount>
    cacheProviders: Cache<Provider>
    cacheCampaignTypes: Cache<CampaignType>
    stepPeriod: StepPeriod
    onStepPeriodChange: (stepPeriod: StepPeriod) => void
}

export function CustomerGetRoute(props: Props) {
    let location = useLocation()
    let params = useParams()

    let customer = props.cacheCustomers.values()
        .find(c => c.id === parseInt(params.customerId!))

    let [campaigns, setCampaigns] = useState<Campaign[] | []>([]);

    let getProviderAccountIds = () => {
        let providerAccounts = props.cacheProviderAccounts.values()
            .filter(pa => pa.customerId === parseInt(params.customerId!))
        return providerAccounts.map((providerAccount) => providerAccount.id)
    }

    let getCampaigns = (providerAccountIds: number[]) => {
        let campaigns = props.cacheCampaigns.values()
            .filter(c => providerAccountIds.indexOf(c.providerAccountId) !== -1)
        setCampaigns(campaigns);
    }

    useEffect(() => {
        let providerAccountIds = getProviderAccountIds();
        getCampaigns(providerAccountIds)
    }, [params.customerId, props.cacheProviderAccounts, props.cacheCampaigns]);

    if (!customer) {
        return <PageNotFoundView />
    }

    document.title = getTranslation('MONITORING.ROUTES.CUSTOMER.GET_TITLE')
        .format(customer.name);


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {
                        to: `/dashboard/customer/${customer.id}/detail`,
                        content: <>{customer.name}</>,
                        disabled: true
                    },
                ]}
            />

            <CustomerGetView
                token={props.token}
                me={props.me}
                customer={customer}
                campaigns={campaigns}
                cacheAccounts={props.cacheAccounts}
                cacheAccountHasCustomers={props.cacheAccountHasCustomers}
                cacheProviderAccounts={props.cacheProviderAccounts}
                cacheProviders={props.cacheProviders}
                cacheCampaignTypes={props.cacheCampaignTypes}
                stepPeriod={props.stepPeriod}
                onStepPeriodChange={props.onStepPeriodChange}
            />
        </>
    )
}
