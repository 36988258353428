import React from "react";
import {getTranslation} from "../../../../../intl";

import {StandaloneInvoice} from "../../../../../models/standalone";
import {DataFilters} from "@boomrank/react-components";


export interface Option {
    value: any;
    active: boolean;
    selected: boolean;
}

interface FilterGroups {
    years: Option[],
    months: Option[],
    customers: Option[],
    invoiceTypes: Option[],
    submitted: Option[],
    accounts: Option[]
}

export interface Props {
    standaloneInvoices: StandaloneInvoice[]
    onFilter: (standaloneInvoices: StandaloneInvoice[]) => void
    filterGroups: FilterGroups
    isAdmin: boolean
    isLoading?: boolean
}

export function InvoiceFilters(props: Props) {
    let filterGroups: any[] = [
        {
            field: 'refDateYear',
            label: getTranslation('MONITORING.VIEWS.INVOICE.FILTER.OPTIONS.YEARS'),
            options: props.filterGroups.years,
            operator: 'custom',
            test: (row: StandaloneInvoice, search: number) => {
                return row.refDateYear === search
            }
        },
        {
            field: 'refDateMonth',
            label: getTranslation('MONITORING.VIEWS.INVOICE.FILTER.OPTIONS.MONTHS'),
            options: props.filterGroups.months,
            operator: 'custom',
            test: (row: StandaloneInvoice, search: string) => {
                return row.refDateMonth === search
            }
        },
        {
            field: 'customerName',
            label: getTranslation('MONITORING.VIEWS.INVOICE.FILTER.OPTIONS.CUSTOMERS'),
            options: props.filterGroups.customers,
            operator: 'contains'
        },
        {
            field: 'invoiceTypeName',
            label: getTranslation('MONITORING.VIEWS.INVOICE.FILTER.OPTIONS.INVOICE_TYPES'),
            options: props.filterGroups.invoiceTypes,
            operator: 'contains'
        },
        {
            field: 'isSubmitted',
            label: getTranslation('MONITORING.VIEWS.INVOICE.FILTER.OPTIONS.SUBMITTED'),
            options: props.filterGroups.submitted,
            operator: 'contains',
        },
    ]

    if (props.isAdmin) {
        filterGroups.splice(4, 0, {
            field: 'accountName',
            label: getTranslation('MONITORING.VIEWS.INVOICE.FILTER.OPTIONS.ACCOUNTS'),
            options: props.filterGroups.accounts,
            operator: 'contains',
        })
    }

    return (
        <DataFilters
            data={props.standaloneInvoices}
            onFilter={props.onFilter}
            filterGroups={filterGroups}
            isLoading={props.isLoading || false}
        />
    )
}