import React from "react";

import {StandaloneCustomer} from "../../../../models/standalone";
import {defaultLanguage, getTranslation} from "../../../../intl";


interface Props {
    standaloneCustomers : StandaloneCustomer[]
}

export function CardCumulativeStatCustomer(props: Props) {
    let options = {style: 'currency', currency: 'EUR'}


    return (
        <div className={'w-full mb-3 gap-y-2 lg:flex lg:gap-x-2 lg:gap-y-0'}>
            <div className={'w-full p-6 relative text-xl lg:text-3xl overflow-hidden border-2 border-slate-100 rounded-lg bg-slate-100'}>
                <i
                    className={`fa-solid fa-people-group text-5xl lg:text-6xl absolute z-0 opacity-50 text-br-blue-700`}
                    title={getTranslation('DUMMIES.CARD.CUSTOMER.CUMULATIVE_STATS.TITLE')}
                />
                <div className={'text-right text-br-blue relative z-10'}>
                    <div className={'text-base'}>{
                        getTranslation('DUMMIES.CARD.CUSTOMER.CUMULATIVE_STATS.TITLE')
                    }</div>
                    <div>{
                        props.standaloneCustomers.length.toString()
                    }</div>
                </div>
            </div>

            <div className={'w-full p-6 relative text-xl lg:text-3xl overflow-hidden border-2 border-slate-100 rounded-lg bg-slate-100'}>
                <i
                    className={`fa-solid fa-euro-sign text-5xl lg:text-6xl absolute z-0 opacity-50 text-br-blue-700`}
                    title={getTranslation('DUMMIES.CARD.CUSTOMER.CUMULATIVE_STATS.COST')}
                />
                <div className={'text-right text-br-blue relative z-10'}>
                    <div className={'text-base'}>{
                        getTranslation('DUMMIES.CARD.CUSTOMER.CUMULATIVE_STATS.COST')
                    }</div>
                    <div>{
                        props.standaloneCustomers.reduce((cost, customer) => cost + customer.cost!, 0)
                            .toLocaleString(
                                defaultLanguage,
                                options
                            )
                    }</div>
                </div>
            </div>

            <div className={'w-full p-6 relative text-xl lg:text-3xl overflow-hidden border-2 border-slate-100 rounded-lg bg-slate-100'}>
                <i
                    className={`fa-solid fa-arrow-trend-up text-5xl lg:text-6xl absolute z-0 opacity-50 text-br-blue-700`}
                    title={getTranslation('DUMMIES.CARD.CUSTOMER.CUMULATIVE_STATS.TREND')}
                />
                <div className={'text-right text-br-blue relative z-10'}>
                    <div className={'text-base'}>{
                        getTranslation('DUMMIES.CARD.CUSTOMER.CUMULATIVE_STATS.TREND')
                    }</div>
                    <div>{
                        props.standaloneCustomers.reduce((trend, customer) => trend + customer.trend!, 0)
                            .toLocaleString(
                                defaultLanguage,
                                options
                            )
                    }</div>
                </div>
            </div>
        </div>
    )
}