import React, {useEffect, useState} from "react";

import {Customer, Identifier, Provider, ProviderAccount,} from "../../../../models";
import {Flag, FlaggedProviderAccount} from "../budget/models";

import {ProviderAccountApi} from "../../../../services/provider-account/api";
import {
    ProviderAccountBulkCreateResponse,
    ProviderAccountCreateResponse,
    ProviderAccountUpdateResponse
} from "../../../../services/provider-account/responses";
import {ProviderAccountFactory} from "../../../../services/provider-account/factory";

import {Cache, JwtToken} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";
import {FormNotification} from "../../forms/models";
import {ProviderAccountForm} from "../../forms/provider-account";
import {StandaloneProviderAccount} from "../../../../models/standalone";


interface Props {
    token: JwtToken
    customer: Customer
    providerAccounts: ProviderAccount[]
    cacheProviders: Cache<Provider>
    standaloneProviderAccounts: StandaloneProviderAccount[]
    cacheUpdater: (obj: Identifier, remove: boolean) => void
}

export function ProviderAccountEditView(props: Props) {
    let [flaggedProviderAccounts, setFlaggedProviderAccounts] = useState<FlaggedProviderAccount[]>([])
    let [loading, setLoading] = useState<boolean>(false)
    let [message, setMessage] = useState(new FormNotification())

    let editProviderAccount = (providerAccount: ProviderAccount) => {
        setLoading(true)

        ProviderAccountApi.update(
            props.token,
            providerAccount.id,
            providerAccount.loginId,
            providerAccount.name,
            providerAccount.budgetMin,
            providerAccount.budgetMax,
            providerAccount.providerId,
            providerAccount.customerId,
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as ProviderAccountUpdateResponse

                let updatedProviderAccount = ProviderAccountFactory.fromFragment(res.data)

                if (updatedProviderAccount.error) {
                    updatedProviderAccount.id = providerAccount.id

                    let fpa = flaggedProviderAccounts.find(fpa => fpa.providerAccount.id === providerAccount.id)
                    if (fpa) {
                        fpa.error = true
                        let code = getErrorCode(providerAccount)
                        fpa.errorMessage = getTranslation(`MONITORING.VIEWS.PROVIDER_ACCOUNT.UPSERT.ERRORS.${code}`)
                    }
                    setFlaggedProviderAccounts([...flaggedProviderAccounts])
                } else {
                    props.cacheUpdater(updatedProviderAccount, false)
                    onSuccess(res.statusCode)
                }
            }
            setLoading(false)
        })
    }

    let bulkCreateProviderAccount = (providerAccounts: ProviderAccount[]) => {
        setLoading(true)

        ProviderAccountApi.bulkCreate(
            props.token,
            providerAccounts
        ).then(res => {
            let response = res as ProviderAccountBulkCreateResponse

            if (!response.data.success && !response.data.errors) {
                let response = res as ProviderAccountCreateResponse
                let providerAccount = ProviderAccountFactory.fromFragment(response.data)

                if (res.statusCode === 400) {
                    providerAccount.id = (new Date()).getTime()
                    providerAccount.createdAt = new Date()

                    setFlaggedProviderAccounts([
                        ...flaggedProviderAccounts,
                        newFlagged(providerAccount, true)
                    ])
                }

                if (res.statusCode === 201) {
                    props.cacheUpdater(providerAccount, false)
                    onSuccess(res.statusCode)
                }
                setLoading(false)
            }

            if (response.data.success && response.data.errors) {
                if (response.data.success.count > 0) {
                    response.data.success.results.map((pa) => {
                        props.cacheUpdater(ProviderAccountFactory.fromFragment(pa), false)
                    })
                    onSuccess(res.statusCode)
                }

                if (response.data.errors.count > 0) {
                    let errorsCreate = response.data.errors.results.map((pa) => {
                        return newFlagged(ProviderAccountFactory.fromFragment(pa), true)
                    })

                    setFlaggedProviderAccounts([
                        ...flaggedProviderAccounts,
                        ...errorsCreate,
                    ])
                }

                setLoading(false)
            }
        })
    }

    let onSubmit = (flaggedProviderAccounts: FlaggedProviderAccount[]) => {
        if (flaggedProviderAccounts.length > 0) {
            setMessage(message.clear())

            let creates = flaggedProviderAccounts.filter(fpa => fpa.flag === Flag.CREATED)
                .map(fpa => fpa.providerAccount)
            if (creates.length > 0) {
                bulkCreateProviderAccount(creates)
            }

            let updates = flaggedProviderAccounts.filter(fpa => fpa.flag === Flag.UPDATED)
            if (updates.length > 0) {
                updates.forEach((fpa) => {
                    editProviderAccount(fpa.providerAccount)
                })
            }
        }
    }

    let newFlagged = (providerAccount: ProviderAccount, created: boolean) => {
        let fpa = new FlaggedProviderAccount()
        fpa.flag = created ? Flag.CREATED : Flag.UPDATED
        fpa.providerAccount = providerAccount

        if (providerAccount.error) {
            fpa.error = true
            let code = getErrorCode(providerAccount)
            fpa.errorMessage = getTranslation(`MONITORING.VIEWS.PROVIDER_ACCOUNT.UPSERT.ERRORS.${code}`)
        }
        return fpa
    }

    let onSuccess = (statusCode: number) => {
        if (statusCode === 201) {
            setMessage(message.setSuccess(
                getTranslation('MONITORING.VIEWS.PROVIDER_ACCOUNT.UPSERT.SUCCESS.CREATE')
            ))
        }
        if (statusCode === 200) {
            setMessage(message.setSuccess(
                getTranslation('MONITORING.VIEWS.PROVIDER_ACCOUNT.UPSERT.SUCCESS.UPDATE')
            ))
        }
        if (statusCode === 206) {
            setMessage(message.setSuccess(
                getTranslation('MONITORING.VIEWS.PROVIDER_ACCOUNT.UPSERT.SUCCESS.PARTIAL')
            ))
        }
    }

    let getErrorCode = (provider: ProviderAccount) => {
        if (provider.error) {
            let code = provider.error.toString().substring(1, 6)
            return code.toUpperCase()
        }
        return 'ER001'
    }

    useEffect(() => {
        let flaggedPas = props.providerAccounts
            .map((providerAccount) => {
                let fpa = new FlaggedProviderAccount()
                fpa.flag = Flag.NO_CHANGES
                fpa.providerAccount = providerAccount
                return fpa
            })

        flaggedPas.reverse()
        setFlaggedProviderAccounts(flaggedPas)
    }, [props.providerAccounts]);


    return (
        <ProviderAccountForm
            customer={props.customer}
            cacheProviders={props.cacheProviders}
            standaloneProviderAccounts={props.standaloneProviderAccounts}
            flaggedProviderAccounts={flaggedProviderAccounts}
            onSubmit={onSubmit}
            message={message}
            disabled={loading}
        />
    )
}