import React, {useState} from "react";
import {Cache} from "@boomrank/react-components";

import {Account, AccountHasCustomer, Customer} from "../../../../models";
import {FormNotification} from "../models";
import {Errors} from "../../../dummies/errors";

import {getTranslation} from "../../../../intl";
import {FormEvents} from "../../../dummies/form/events";
import {FormMessage} from "../../../dummies/form/message";

import {CustomerBasisForm} from "./basis";
import {AccountHasCustomerForm} from "../account-has-customer";
import {AccountForm} from "../account";


interface Props {
    me: Account
    customer: Customer
    cacheAccounts: Cache<Account>
    cacheCustomers?: Cache<Customer>
    accountHasCustomers?: AccountHasCustomer[]
    onSubmit: (customer: Customer) => void
    disabled: boolean
    errors: Errors
}

export function CustomerForm(props: Props) {
    let [customer, setCustomer] = useState<Customer>(props.customer)
    let [message, setMessage] = useState<FormNotification>(new FormNotification())

    let onSubmit = (customer: Customer) => {
        if (customer.name === '') {
            setMessage(
                message.setError(
                    getTranslation('MONITORING.FORMS.CUSTOMER.ERRORS.NAME')
                )
            )
        } else {
            props.onSubmit(customer)
        }
    }

    let onChange = (c: Customer) => {
        setCustomer(c)
    }

    let onChangeAccount = (accountId: number) => {
        setCustomer({
            ...customer,
            account: accountId
        })
    }

    let onChangeAccountIds = (AccountIds: number[]) => {
        setCustomer({
            ...customer,
            accountIds: AccountIds
        })
    }

    return (
        <div className={'w-full mt-6 py-6 px-5 border-2 border-slate-200 rounded-xl'}>
            <FormEvents
                className={'w-full flex justify-end gap-x-1'}
                onSubmit={() => onSubmit(customer)}
                submitTitle={getTranslation('MONITORING.FORMS.CUSTOMER.SUBMIT_BUTTON')}
                disabled={props.disabled}
            />

            <FormMessage formNotification={message}/>

            <div className={'w-full mt-3 py-0.5 lg:grid lg:grid-cols-5 lg:gap-x-5'}>
                <CustomerBasisForm
                    me={props.me}
                    customer={customer}
                    cacheCustomers={props.cacheCustomers}
                    onChange={onChange}
                    disabled={props.disabled}
                />

                <div className={'w-full mb-3 lg:mb-0 lg:col-start-1 lg:col-end-3'}>
                    <span className={'text-gray-500 font-medium'}>
                        {getTranslation('MONITORING.FORMS.CUSTOMER.LABELS.ACCOUNT')}
                    </span>

                    <AccountForm
                        customer={customer}
                        accountId={customer.account ? customer.account : 0}
                        cacheAccounts={props.cacheAccounts}
                        onChange={onChangeAccount}
                        disabled={props.disabled}
                    />
                </div>

                <div className={'w-full bg-slate-50 p-3 rounded-lg lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-3'}>
                    <span className={'text-gray-500 font-medium'}>
                        {getTranslation('MONITORING.FORMS.CUSTOMER.LABELS.ACCOUNT_HAS_CUSTOMER')}
                    </span>

                    <AccountHasCustomerForm
                        customer={customer}
                        cacheAccounts={props.cacheAccounts}
                        onChangeAccountIds={onChangeAccountIds}
                        accountHasCustomers={props.accountHasCustomers!}
                        disabled={props.disabled}
                    />
                </div>
            </div>
        </div>
    )
}
