import React, {useEffect, useState} from "react";
import {Customer, Provider, ProviderAccount} from "../../../../models";
import {getTranslation} from "../../../../intl";
import {Cache} from "@boomrank/react-components";
import {Flag, FlaggedProviderAccount} from "../../views/budget/models";
import {FormEvents} from "../../../dummies/form/events";
import {FormMessage} from "../../../dummies/form/message";
import {ProviderAccountBasisForm} from "./basis";
import {FormNotification} from "../models";
import {StandaloneProviderAccount} from "../../../../models/standalone";


interface Props {
    customer: Customer
    standaloneProviderAccounts: StandaloneProviderAccount[]
    cacheProviders: Cache<Provider>
    flaggedProviderAccounts: FlaggedProviderAccount[]
    onSubmit: (flaggedProviderAccount: FlaggedProviderAccount[]) => void
    message: FormNotification
    disabled: boolean
}

export function ProviderAccountForm(props: Props) {
    let [flaggedProviderAccounts, setFlaggedProviderAccounts] = useState<FlaggedProviderAccount[]>([])
    let [message, setMessage] = useState<FormNotification>(props.message)

    let onAdd = () => {
        let fpa = new FlaggedProviderAccount()
        fpa.flag = Flag.CREATED
        fpa.providerAccount.id = (new Date()).getTime()
        fpa.providerAccount.customerId = props.customer.id
        fpa.providerAccount.providerId = props.cacheProviders.values()[0].id

        setFlaggedProviderAccounts([fpa, ...flaggedProviderAccounts])
        setMessage(message.clear())
    }

    let onChange = (providerAccount: ProviderAccount) => {
        setMessage(message.clear())
        let flagged = flaggedProviderAccounts.find(fpa => fpa.providerAccount.id === providerAccount.id)

        if (flagged) {
            flagged.error = false
            if (flagged.flag === Flag.CREATED) {
                flagged.existingProviderAccounts = props.standaloneProviderAccounts.filter(
                    (pa) => {
                        let loginId = pa.loginId.replace('act_', '').replaceAll('-', '')
                        return loginId.match(providerAccount.loginId) && pa.providerId === providerAccount.providerId
                    }
                )

                if (providerAccount.loginId === '') {
                    flagged.existingProviderAccounts = []
                }
            }

            if (flagged.flag === Flag.NO_CHANGES) {
                flagged.flag = Flag.UPDATED
            }

            flagged.providerAccount = providerAccount
        }

        setFlaggedProviderAccounts([...flaggedProviderAccounts])
    }

    let onSubmit = (flaggedProviderAccounts: FlaggedProviderAccount[]) => {
        props.onSubmit(flaggedProviderAccounts)
    }

    useEffect(() => {
        setFlaggedProviderAccounts(props.flaggedProviderAccounts)
    }, [props.flaggedProviderAccounts]);


    return (
        <div className={'w-full mt-6 py-6 px-5 border-2 border-slate-200 rounded-xl'}>
            <FormEvents
                className={'w-full flex justify-end gap-x-1'}
                onSubmit={() => onSubmit(flaggedProviderAccounts)}
                submitTitle={getTranslation('MONITORING.FORMS.PROVIDER_ACCOUNT.SUBMIT_BUTTON')}
                disabled={props.disabled}
                onAdd={onAdd}
            />

            <h3>{getTranslation('MONITORING.FORMS.PROVIDER_ACCOUNT.TITLE')}</h3>

            <div className={'w-full py-2 px-6 my-8 border bg-slate-100 border-slate-100 text-slate-500 rounded-lg'}>
                <i className="fa-solid fa-triangle-exclamation"/>

                <span className={'ml-4'}>
                    {getTranslation('MONITORING.FORMS.PROVIDER_ACCOUNT.ADD_EMAIL')}
                </span>
            </div>

            <FormMessage formNotification={props.message}/>

            {
                flaggedProviderAccounts.length > 0 &&
                <div>
                    <div className={'w-full flex px-1 text-gray-500 font-medium lg:pr-8'}>
                        <span
                            className={'w-full px-1'}>{getTranslation('MONITORING.FORMS.PROVIDER_ACCOUNT.LABELS.PROVIDER')}</span>
                        <span
                            className={'w-full px-1'}>{getTranslation('MONITORING.FORMS.PROVIDER_ACCOUNT.LABELS.LOGIN_ID')}</span>
                        <span
                            className={'w-full px-1'}>{getTranslation('MONITORING.FORMS.PROVIDER_ACCOUNT.LABELS.NAME')}</span>
                    </div>

                    {
                        flaggedProviderAccounts.map((fpa: FlaggedProviderAccount) => (
                            <div
                                key={fpa.id}
                                className={'w-full relative'}
                            >
                                <ProviderAccountBasisForm
                                    key={fpa.id}
                                    providerAccount={fpa.providerAccount}
                                    cacheProviders={props.cacheProviders}
                                    onChange={onChange}
                                    error={fpa.error}
                                    errorMessage={fpa.errorMessage}
                                    disabled={props.disabled}
                                />
                                {
                                    fpa.flag === Flag.CREATED &&
                                    fpa.existingProviderAccounts.length > 0 &&
                                    <div
                                        className={'w-full p-2 bg-red-200 border border-gray-100 absolute top-[100%] rounded z-10'}>
                                        <span className={'text-red-500 font-medium'}>L'identifiant semble être déjà utilisé :</span>

                                        <div className={'flex flex-wrap gap-2'}>
                                            {
                                                fpa.existingProviderAccounts.map((pa) => {
                                                    return (
                                                        <span
                                                            key={pa.id}
                                                            className={'p-1 text-red-500 underline'}
                                                        >
                                                            {pa.customerName} - {pa.loginId}
                                                        </span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}
