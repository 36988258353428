import React, {useState} from "react";

import {Provider} from "../../../models";
import {StandaloneCampaign, StandaloneCustomer} from "../../../models/standalone";

import {Cache} from "@boomrank/react-components";
import {ProviderIcon} from "./provider";


interface Props {
    standaloneCustomer: StandaloneCustomer
    standaloneCampaign?: StandaloneCampaign
    cacheProviders: Cache<Provider>
}


export function ProviderAccountIcon(props: Props) {
    let providers = ['Google', 'Meta', 'Bing']

    let [hoverProvider, setHoverProvider] = useState<string>('')

    let getProvidersAccounts = (providerName: string) => {
        let provider = props.cacheProviders.values()
            .find(p => p.name === providerName)

        if (provider) {
            if (props.standaloneCampaign) {
                let providerAccounts = props.standaloneCustomer.providerAccounts
                    .filter(pa => pa.loginId === props.standaloneCampaign!.loginId)

                return providerAccounts.filter((pa) => pa.providerId === provider!.id)
            }

            if (props.standaloneCustomer.providerAccounts.length > 0) {
                return props.standaloneCustomer.providerAccounts
                    .filter((pa) => (
                        pa.providerId === provider!.id
                    ))
            }
        }
        return []
    }

    let showDetails = (provider: string, sup: number) => {
        if (sup > 0) {
            setHoverProvider(provider)
        }
    }


    return (
        <div className={'w-2/3 h-14 flex justify-evenly items-end'}>
            {
                providers.map((provider, idx) => {
                    let providerAccounts = getProvidersAccounts(provider)

                    return (
                        <div
                            key={idx}
                            role={'tooltip'}
                            className={'my-3'}
                            onMouseEnter={() => showDetails(provider, providerAccounts.length)}
                            onMouseLeave={() => showDetails('', providerAccounts.length)}
                        >
                            <div className={'w-10 h-max-content rounded-sm flex justify-between'}>
                                <ProviderIcon providerName={provider} />

                                <sup className={'py-2 px-1 bg-slate-200 rounded-full text-slate-600'}>
                                    {providerAccounts.length}
                                </sup>
                            </div>

                            {
                                hoverProvider === provider &&
                                <div
                                    className={'absolute w-10/12 border border-slate-300 rounded p-2 flex flex-col text-slate-600 text-sm z-10 bg-white shadow-2xl'}
                                    style={{bottom: '10%', left: '50%', transform: 'translate(-50%, -50%)'}}
                                >
                                    {
                                        providerAccounts.length > 0 &&
                                        providerAccounts.map((pa, idx) => {
                                            return (
                                                <span key={idx}>Compte - {pa.name ? pa.name : pa.loginId}</span>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}