import React, {useState} from "react";

import {Account, Customer, Identifier} from "../../../../models";

import {CustomerApi} from "../../../../services/customer/api";
import {CustomerCreateResponse} from "../../../../services/customer/responses";
import {CustomerFactory} from "../../../../services/customer/factory";

import {Cache, FormErrorResponse, JwtToken, PlaceHolder} from "@boomrank/react-components";
import {Errors} from "../../../dummies/errors";

import {CustomerForm} from "../../forms/customer";
import {CustomerHasAccountApi} from "../../../../services/customer/has-account/api";
import {AccountHasCustomerListResponse} from "../../../../services/customer/has-account/responses";
import {AccountHasCustomerFactory} from "../../../../services/customer/has-account/factory";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheAccounts: Cache<Account>
    cacheUpdater: (obj: Identifier) => void
    onRedirect: (customerId: number) => void
}

export function CustomerAddView(props: Props) {
    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState<Errors>({});


    let getCustomerHasAccounts = (customer: Customer) => {
        CustomerHasAccountApi.list(
            props.token,
            customer.id
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as AccountHasCustomerListResponse;

                res.data.results.forEach((fragment) => {
                    props.cacheUpdater(AccountHasCustomerFactory.fromFragment(fragment))
                })
            }
            setLoading(false);
        })
    }

    let onSubmit = (customer: Customer) => {
        setLoading(true)

        if (customer.account === 0) {
            customer.account = null
        }

        CustomerApi.create(
            props.token,
            customer.name,
            customer.budgetMin,
            customer.budgetMax,
            customer.account,
            customer.accountIds,
            customer.comment,
            customer.financial_comment
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as CustomerCreateResponse;

                let newCustomer = CustomerFactory.fromFragment(res.data);

                props.cacheUpdater(newCustomer);
                getCustomerHasAccounts(newCustomer);

                props.onRedirect(newCustomer.id);
            } else {
                res = res as FormErrorResponse;
                setErrors(res.data)
                setLoading(false);
            }
        })
    }

    return (
        <CustomerForm
            me={props.me}
            customer={new Customer()}
            cacheCustomers={props.cacheCustomers}
            cacheAccounts={props.cacheAccounts}
            onSubmit={onSubmit}
            errors={errors}
            disabled={loading}
        />
    )
}