import React from "react";
import {Cache} from "@boomrank/react-components";

import {Account, Provider} from "../../../../models";
import {StandaloneCampaign, StandaloneCustomer} from "../../../../models/standalone";
import {StepPeriod} from "../../../../models/stepPeriod";

import {CardTemplate} from "../index";
import {CardIconSection} from "../icon";
import {CardBudgetSection} from "../budget";


interface Props {
    standaloneCustomer: StandaloneCustomer
    standaloneCampaigns: StandaloneCampaign[]
    cacheAccounts: Cache<Account>
    cacheProviders: Cache<Provider>
    stepPeriod: StepPeriod
    isLoading?: boolean
}

export function CardCustomer(props: Props) {
    return (
        <CardTemplate
            title={props.standaloneCustomer.name}
            isLoading={props.isLoading || false}
            createdAt={props.standaloneCustomer.createdAt}
        >
            <div className={'h-full flex flex-col justify-between'}>
                <CardBudgetSection
                    cost={props.standaloneCustomer.cost}
                    trend={props.standaloneCampaigns.reduce(
                        (trend, sc) => trend + sc.trend!, 0
                    )}
                    budgetMin={props.standaloneCustomer.providerAccounts.reduce(
                        (budget, pa) => budget + pa.budgetMin!, 0
                    )}
                    budgetMax={props.standaloneCustomer.providerAccounts.reduce(
                        (budget, pa) => budget + pa.budgetMax!, 0
                    )}
                    stepPeriod={props.stepPeriod}
                    legend={true}
                />

                <CardIconSection
                    standaloneCustomer={props.standaloneCustomer}
                    cacheAccounts={props.cacheAccounts}
                    cacheProviders={props.cacheProviders}
                />
            </div>
        </CardTemplate>
    )
}