import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useLocation} from "react-router-dom";

import {Breadcrumb, Cache, JwtToken} from "@boomrank/react-components";
import {Account, Customer, Fee, FinancialInvoice, Identifier} from "../../../models";
import {getTranslation} from "../../../intl";
import {StandaloneFinancialInvoice} from "../../../models/standalone";
import {FinancialInvoiceEditView} from "../views/edit";


interface Props {
    token: JwtToken
    me: Account
    cacheFees: Cache<Fee>
    cacheCustomers: Cache<Customer>
    standaloneFinancialInvoices: StandaloneFinancialInvoice[]
    onSubmit: (financialInvoice: FinancialInvoice) => void
    cacheUpdater: (obj: Identifier) => void
}

export function FinancialInvoiceEditRoute(props: Props) {
    let location = useLocation()
    let params = useParams()
    document.title = getTranslation('MONITORING.ROUTES.FINANCIAL_INVOICE.EDIT_TITLE')

    let [financialInvoice, setFinancialInvoice] = useState<StandaloneFinancialInvoice | null>(null)

    let getFinancialInvoice = () => {
        let financialInvoice =  props.standaloneFinancialInvoices.find(
            fi => fi.id === parseInt(params.financialInvoiceId!)
        )
        setFinancialInvoice(financialInvoice!)
    }

    useEffect(() => {
        getFinancialInvoice()
    }, [props.standaloneFinancialInvoices]);


    if (financialInvoice) {
        return (
            <>
                <Breadcrumb
                    location={location}
                    links={[
                        {to: `/dashboard/`, content: 'Dashboard'},
                        {to: `/dashboard/financial-invoices/`, content: <>Finances</>},
                        {
                            to: `/dashboard/financial-invoices/${financialInvoice.id}`,
                            content: <>Détails de facture</>
                        },
                    ]}
                />

                <FinancialInvoiceEditView
                    token={props.token}
                    cacheFees={props.cacheFees}
                    cacheCustomers={props.cacheCustomers}
                    standaloneFinancialInvoice={financialInvoice}
                    onSubmit={props.onSubmit}
                    cacheUpdater={props.cacheUpdater}
                />
            </>
        )
    }

    return <></>
}