import {StatCustomerFragment} from "./fragments";
import {StatCustomer} from "../../../../models/stats";
import {Step} from "../../../../models/stepPeriod";
import {ProviderAccount} from "../../../../models";
import {StandaloneStatCustomer} from "../../../../models/standalone";

export class StatCustomerFactory {
    static fromFragment = (fragment: StatCustomerFragment): StatCustomer => {
        let statCustomer = new StatCustomer()
        statCustomer.periodKey = new Date(fragment.period_key)
        statCustomer.startedAt = new Date(fragment.started_at)
        statCustomer.loginId = fragment.login_id
        statCustomer.cost = fragment.cost
        statCustomer.step = fragment.step as Step
        statCustomer.trend = fragment.trend
        statCustomer.name = fragment.name
        statCustomer.impressions = fragment.impressions
        statCustomer.purchases = fragment.purchases
        statCustomer.purchaseRoas = fragment.purchase_roas
        statCustomer.costPerPurchase = fragment.cost_per_purchase
        statCustomer.leads = fragment.leads
        statCustomer.costPerLead = fragment.cost_per_lead
        statCustomer.conversions = fragment.conversions
        statCustomer.conversionsValue = fragment.conversions_value
        return statCustomer
    }
}

export class StandaloneStatCustomerFactory {
    static fromStatCustomer = (
        statCustomer: StatCustomer,
        providerAccounts: ProviderAccount[],
    ): StandaloneStatCustomer => {
        let standalone = {...statCustomer} as StandaloneStatCustomer
        let providerAccount = providerAccounts.find(pa => pa.loginId === statCustomer.loginId)

        standalone.cost = parseFloat(standalone.cost.toFixed(2))
        if (providerAccount) {
            standalone.providerAccount = providerAccount
        }
        return standalone
    }
}