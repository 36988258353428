import React, {useEffect, useState} from "react";

import {Customer, Invoice, InvoiceItem, ProviderAccount, ProviderAccountInvoice} from "../../../../models";
import {FlaggedInvoiceItem, FormNotification} from "../models";
import {Errors} from "../../../dummies/errors";

import {getTranslation} from "../../../../intl";
import {BlueButton, GreenButton} from "@boomrank/react-components";
import {BasisInvoiceForm} from "./basis";
import {InvoiceItemForm} from "./item";
import {FormMessage} from "../../../dummies/form/message";
import {StatCustomer} from "../../../../models/stats";
import {SuggestionProviderAccountInvoicesForm, SuggestionStatsCustomerForm} from "./suggestion";
import {StandaloneStatCustomerFactory} from "../../../../services/customer/stats/customer/factory";
import {StandaloneProviderAccountInvoice, StandaloneStatCustomer} from "../../../../models/standalone";
import {StandaloneProviderAccountInvoiceFactory} from "../../../../services/provider-account-invoice/factory";


interface Props {
    customer: Customer
    invoice: Invoice
    providerAccounts: ProviderAccount[]
    onSubmit: (invoice: Invoice) => void
    errors: Errors
    disabled: boolean
    onSubmitMessage: FormNotification
    statsCustomer: StatCustomer[]
    providerAccountInvoices: ProviderAccountInvoice[]
}

export function InvoiceForm(props: Props) {
    let [invoice, setInvoice] = useState<Invoice>(props.invoice)
    let [message, setMessage] = useState<FormNotification>(props.onSubmitMessage)
    let [error, setError] = useState({show: false, content: ''})

    let statsCustomer = props.statsCustomer || []
    let standaloneStatsCustomer = statsCustomer.map(
        sc => StandaloneStatCustomerFactory.fromStatCustomer(
            sc,
            props.providerAccounts
        )
    )

    let providerAccountInvoices = props.providerAccountInvoices || []
    let standaloneProviderAccountInvoices = providerAccountInvoices.map(
        pai => StandaloneProviderAccountInvoiceFactory.fromProviderAccountInvoice(
            pai,
            props.providerAccounts
        )
    )

    let clearNotification = () => {
        setMessage(message.clear())
        setError({show: false, content: ''})
    }

    let checkValues = (invoice: Invoice) => {
        // Remove empty invoiceItems rows
        let emptyInvoiceItemIds = invoice.invoiceItems.filter(
            item => item.priceHt === 0 && item.label === ''
        ).map(item => item.id)

        let invoiceItems = invoice.invoiceItems.filter(item =>
            emptyInvoiceItemIds.indexOf(item.id) === -1
        )
        invoice.invoiceItems = invoiceItems

        // Check for missing or incorrect format
        let uncompletedItems = invoice.invoiceItems.filter(item =>
            (isNaN(item.priceHt) || item.priceHt <= 0) || item.label === ''
        )

        if (invoice.refDate.toString() !== 'Invalid Date' && uncompletedItems.length === 0) {
            clearNotification()
            props.onSubmit(invoice)
        } else {
            if (uncompletedItems.length > 0) {
                setError({
                    show: true,
                    content: 'Veuillez remplir tous les champs obligatoires ou supprimer la ligne'
                })
            }
            if (invoice.refDate.toString() === 'Invalid Date') {
                setError({
                    show: true,
                    content: 'Veuillez sélectionner une date'
                })
            }
        }
    }

    let onSubmit = (invoice: Invoice) => {
        let confirm = window.confirm(
            getTranslation('MONITORING.FORMS.INVOICE.CONFIRM_JS')
        )

        if (confirm) {
            invoice.submittedAt = new Date()
            checkValues(invoice)
        }
    }

    let onUpdate = (invoice: Invoice) => {
        checkValues(invoice)
    }

    let onChange = (invoice: Invoice) => {
        clearNotification()
        setInvoice(invoice)
    }

    let onChangeItems = (flaggedInvoiceItems: FlaggedInvoiceItem[]) => {
        clearNotification()
        setInvoice({
            ...invoice,
            invoiceItems: flaggedInvoiceItems.map(fit => fit.invoiceItem),
        })
    }

    let onClickStatsCustomerSuggest = (standaloneStatsCustomer: StandaloneStatCustomer) => {
        let invoiceItem = new InvoiceItem()
        invoiceItem.id = (new Date()).getTime()
        invoiceItem.priceHt = standaloneStatsCustomer.cost
        invoiceItem.label = `Dépenses pour le compte ${standaloneStatsCustomer.providerAccount.name}(${standaloneStatsCustomer.providerAccount.loginId})`
        invoiceItem.providerAccountId = standaloneStatsCustomer.providerAccount.id

        setInvoice({
            ...invoice,
            invoiceItems: [
                ...invoice.invoiceItems,
                invoiceItem
            ]
        })
    }

    let onClickProviderAccountInvoiceSuggest = (standaloneProviderAccountInvoice: StandaloneProviderAccountInvoice) => {
        let invoiceItem = new InvoiceItem()
        invoiceItem.id = (new Date()).getTime()
        invoiceItem.priceHt = standaloneProviderAccountInvoice.totalAmount
        invoiceItem.label = `Facture du compte ${standaloneProviderAccountInvoice.providerAccount.name}(${standaloneProviderAccountInvoice.providerAccount.loginId})`
        invoiceItem.providerAccountId = standaloneProviderAccountInvoice.providerAccount.id

        setInvoice({
            ...invoice,
            invoiceItems: [
                ...invoice.invoiceItems,
                invoiceItem
            ]
        })
    }

    useEffect(() => {
        setInvoice(props.invoice)
    }, [props.invoice])


    return (
        <div className={'w-full mt-6 py-6 px-5 border-2 border-slate-200 rounded-xl'}>
            <div className={'w-full mt.reverse()-6 mb-10 flex justify-end gap-x-1'}>
                <GreenButton
                    data-testid={'invoice-submit-btn'}
                    text={getTranslation('MONITORING.FORMS.INVOICE.SUBMIT_BUTTON')}
                    onClick={() => onSubmit(invoice)}
                    disabled={invoice.submittedAt !== null}
                />
                <BlueButton
                    data-testid={'invoice-update-btn'}
                    text={getTranslation('MONITORING.FORMS.INVOICE.UPDATE_BUTTON')}
                    onClick={() => onUpdate(invoice)}
                    disabled={invoice.submittedAt !== null}
                />
            </div>

            {
                props.customer.comment !== '' &&
                <div className={'mt-10 p-4 bg-slate-100 text-br-blue-900 rounded-md'}>
                    <span><b>Commentaire</b> :</span>
                    <p className={'whitespace-pre'}>{props.customer.comment}</p>
                </div>
            }

            {
                error.show &&
                <div className={'font-medium py-6 px-4 my-3 rounded-lg border text-red-500  bg-red-100 border-red-100'}>
                    <span>{error.content}</span>
                </div>
            }

            <FormMessage formNotification={message} />

            <div className={'w-full my-2'}>
                <BasisInvoiceForm
                    invoice={invoice}
                    onChange={onChange}
                    errors={props.errors}
                    disabled={invoice.submittedAt !== null || props.disabled}
                />
                {
                    standaloneStatsCustomer.length > 0 &&
                    <SuggestionStatsCustomerForm
                        onClick={onClickStatsCustomerSuggest}
                        standaloneStatsCustomer={standaloneStatsCustomer}
                        disabled={props.disabled}
                    />
                }
                {
                    standaloneProviderAccountInvoices.length > 0 &&
                    <SuggestionProviderAccountInvoicesForm
                        onClick={onClickProviderAccountInvoiceSuggest}
                        standaloneProviderAccountInvoices={standaloneProviderAccountInvoices}
                        disabled={props.disabled}
                    />
                }
                <InvoiceItemForm
                    invoice={invoice}
                    providerAccounts={props.providerAccounts}
                    errors={props.errors}
                    onChange={onChangeItems}
                    disabled={invoice.submittedAt !== null || props.disabled}
                />
            </div>
        </div>
    )
}
