import {AccountHasCustomerFragment} from "./fragments";
import {AccountHasCustomer} from "../../../models";


export class AccountHasCustomerFactory {
    static fromFragment = (fragment: AccountHasCustomerFragment): AccountHasCustomer =>  {
        let accountHasCustomer = new AccountHasCustomer()
        accountHasCustomer.id = fragment.id
        accountHasCustomer.createdAt = new Date(fragment.created_at)
        accountHasCustomer.accountId = fragment.account
        accountHasCustomer.customerId = fragment.customer
        accountHasCustomer.notificationEnabled = fragment.notification_enabled
        return accountHasCustomer
    }
}