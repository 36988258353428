import React from "react";
import {Cache} from "@boomrank/react-components";
import {Account, Provider,} from "../../../../models";
import {StepPeriod} from "../../../../models/stepPeriod";
import {StatCampaign} from "../../../../models/stats";
import {CardTemplate} from "../index";
import {CardIconSection} from "../icon";
import {CardBudgetSection} from "../budget";
import {StandaloneCampaign, StandaloneCustomer} from "../../../../models/standalone";


interface Props {
    standaloneCustomer: StandaloneCustomer
    standaloneCampaign: StandaloneCampaign
    statCampaigns: StatCampaign[]
    cacheAccounts: Cache<Account>
    cacheProviders: Cache<Provider>
    stepPeriod: StepPeriod
    isLoading?: boolean
}

export function CardCampaign(props: Props) {
    let providerAccount = props.standaloneCustomer.providerAccounts.find((pa) =>
        pa.loginId === props.standaloneCampaign.loginId
    )

    return (
        <CardTemplate
            title={props.standaloneCampaign.name}
            isLoading={props.isLoading || false}
        >
            <div className={'h-full flex flex-col justify-between'}>
                <CardBudgetSection
                    cost={props.statCampaigns.reduce(
                        (cost, stat) => cost + stat.cost, 0
                    )}
                    trend={props.statCampaigns.reduce(
                        (trend, stat) => trend + stat.trend, 0
                    )}
                    budgetMin={providerAccount!.budgetMin!}
                    budgetMax={providerAccount!.budgetMax!}
                    stepPeriod={props.stepPeriod}
                    legend={true}
                    hideMetrics={true}
                />

                <div className={'w-full py-1.5 border-y border-slate-200 flex justify-between'}>
                    <span className={'text-sm text-gray-500'}>Type : </span>
                    <b>{props.standaloneCampaign.campaignType}</b>
                </div>

                <CardIconSection
                    standaloneCustomer={props.standaloneCustomer}
                    standaloneCampaign={props.standaloneCampaign}
                    cacheAccounts={props.cacheAccounts}
                    cacheProviders={props.cacheProviders}
                />
            </div>
        </CardTemplate>
    )
}