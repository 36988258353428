import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {
    ProviderAccountListResponse,
    ProviderAccountCreateResponse,
    ProviderAccountUpdateResponse, ProviderAccountBulkCreateResponse
} from "./responses";
import {endpoints} from "../api";
import {ProviderAccount} from "../../models";


export class ProviderAccountApi {
    static list(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | ProviderAccountListResponse> {
        let url = endpoints.providerAccount.list
        if (baseUrl) {
            url = baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static create(
        token: JwtToken,
        loginId: string,
        name: string,
        budgetMin: number | null,
        budgetMax: number | null,
        providerId: number,
        customerId: number,
    ): Promise<FormErrorResponse | ProviderAccountCreateResponse> {
        let url = endpoints.providerAccount.create

        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    provider: providerId,
                    customer: customerId,
                    name: name,
                    login_id: loginId,
                    budget_min: budgetMin,
                    budget_max: budgetMax
                })
            }
        );
    }

    static bulkCreate(
        token: JwtToken,
        providerAccounts: ProviderAccount[]
    ): Promise<FormErrorResponse | ProviderAccountCreateResponse | ProviderAccountBulkCreateResponse> {
        let url = endpoints.providerAccount.create

        let formattedProviderAccounts = providerAccounts.map((pa) => {
            return {
                provider: pa.providerId,
                customer: pa.customerId,
                name: pa.name,
                login_id: pa.loginId,
                budget_min: pa.budgetMin,
                budget_max: pa.budgetMax,
            }
        })

        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify(
                    formattedProviderAccounts
                )
            }
        );
    }

    static update(
        token: JwtToken,
        pk: number,
        loginId: string,
        name: string,
        budgetMin: number | null,
        budgetMax: number | null,
        providerId: number,
        customerId: number,
    ): Promise<FormErrorResponse | ProviderAccountUpdateResponse> {
        let url = endpoints.providerAccount.update.replace(':pk', pk.toString())

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    login_id: loginId,
                    name: name,
                    budget_min: budgetMin,
                    budget_max: budgetMax,
                    provider: providerId,
                    customer: customerId
                })
            }
        );
    }
}