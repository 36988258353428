import React from "react";
import {useLocation} from "react-router-dom";

import {
    Customer,
    ProviderAccount,
    Notification,
    AccountHasCustomer,
    CampaignGroup,
    Identifier,
    Invoice,
    Account
} from "../../../models";

import {StepPeriod} from "../../../models/stepPeriod";
import {Breadcrumb, Cache, JwtToken} from "@boomrank/react-components";
import {HomeView} from "../views/home";
import {getTranslation} from "../../../intl";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheAccounts: Cache<Account>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheProviderAccounts: Cache<ProviderAccount>
    cacheCampaignGroups: Cache<CampaignGroup>
    cacheInvoices: Cache<Invoice>
    cacheNotifications: Cache<Notification>
    cacheUpdater: (obj: Identifier) => void
    stepPeriod: StepPeriod
    onStepPeriodChange: (stepPeriod: StepPeriod) => void
}

export function HomeRoute (props: Props) {
    let location = useLocation()
    document.title = getTranslation('MONITORING.ROUTES.HOME.TITLE');


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: <>Dashboard</>},
                ]}
            />

            <HomeView
                token={props.token}
                me={props.me}
                cacheCustomers={props.cacheCustomers}
                cacheAccounts={props.cacheAccounts}
                cacheAccountHasCustomers={props.cacheAccountHasCustomers}
                cacheProviderAccounts={props.cacheProviderAccounts}
                cacheCampaignGroups={props.cacheCampaignGroups}
                cacheInvoices={props.cacheInvoices}
                cacheNotifications={props.cacheNotifications}
                cacheUpdater={props.cacheUpdater}
                stepPeriod={props.stepPeriod}
                onStepPeriodChange={props.onStepPeriodChange}
            />
        </>
    )
}