import {ProviderFragment} from "./fragments";
import {Provider} from "../../models";


export class ProviderFactory {
    static fromFragment = (fragment: ProviderFragment): Provider => {
        let provider = new Provider()
        provider.id = fragment.id
        provider.createdAt = new Date(fragment.created_at)
        provider.name = fragment.name
        return provider
    }
}