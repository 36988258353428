import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {
    InvoiceListResponse,
    InvoiceCreateResponse,
    InvoiceUpdateResponse
} from "./responses";
import {endpoints} from "../api";
import {InvoiceItem} from "../../models";
import {FinancialInvoiceUpdateResponse} from "../financial_invoice/responses";


export class InvoiceApi {
    static list(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | InvoiceListResponse> {
        let url = endpoints.invoice.list
        if (baseUrl) {
            url = baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static create(
        token: JwtToken,
        accountId: number,
        customerId: number,
        invoiceType: number,
        ref: string,
        refDate: Date,
        invoiceItems: InvoiceItem[],
        comment: string,
    ): Promise<FormErrorResponse | InvoiceCreateResponse> {
        let url = endpoints.invoice.create

        let totalItems = 0
        let items = invoiceItems.map(item => {
            if (item.priceHt) {
                totalItems += item.priceHt
            }

            return {
                provider_account: item.providerAccountId,
                label: item.label,
                price_ht: item.priceHt || 0,
                applicable_fee: item.applicableFee
            }
        })

        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    account: accountId,
                    customer: customerId,
                    invoice_type: invoiceType,
                    ref: ref,
                    ref_date: refDate,
                    invoice_items: items,
                    total_items: totalItems,
                    comment: comment
                })
            }
        );
    }

    static update(
        token: JwtToken,
        pk: number,
        invoiceType: number,
        ref: string,
        refDate: Date,
        invoiceItems: InvoiceItem[],
        comment: string,
        submittedAt: Date | null,
    ): Promise<FormErrorResponse | InvoiceUpdateResponse> {
        let url = endpoints.invoice.update.replace(':pk', pk.toString())

        let totalItems = 0
        let items = invoiceItems.map(item => {
            if (item.priceHt) {
                totalItems += item.priceHt
            }

            return {
                invoice: item.invoiceId,
                provider_account: item.providerAccountId,
                label: item.label,
                price_ht: item.priceHt || 0,
                applicable_fee: item.applicableFee
            }
        })

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    invoice_type: invoiceType,
                    ref: ref,
                    ref_date: refDate,
                    invoice_items: items,
                    total_items: totalItems,
                    comment: comment,
                    submitted_at : submittedAt
                })
            }
        );
    }

    static reject(
        token: JwtToken,
        pk: number,
    ): Promise<FormErrorResponse | InvoiceUpdateResponse> {
        let url = endpoints.invoice.update.replace(':pk', pk.toString())

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    submitted_at : null
                })
            }
        );
    }
}