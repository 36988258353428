import React from "react";
import classNames from "classnames";
import {FormNotification} from "../../monitoring/forms/models";


export interface Props {
    formNotification: FormNotification
}

export function FormMessage(props: Props) {
    return (
        <div className={classNames(
            'font-medium py-6 px-4 my-3 rounded-lg',
            {'hidden' : !props.formNotification.show},
            {'border text-white bg-br-green-500 border-br-green-500' : props.formNotification.success},
            {'border text-red-500  bg-red-100 border-red-100' : !props.formNotification.success},

        )}>
            <span>{props.formNotification.content}</span>
        </div>
    )
}
