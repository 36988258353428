import React, {useState} from "react";

import {Customer, Identifier} from "../../../../../models";

import {CustomerApi} from "../../../../../services/customer/api";
import {CustomerUpdateResponse} from "../../../../../services/customer/responses";
import {CustomerFactory} from "../../../../../services/customer/factory";

import {FormErrorResponse, JwtToken, PlaceHolder} from "@boomrank/react-components";
import {Errors} from "../../../../dummies/errors";
import {CustomerBudgetForm} from "../../../forms/customer/budget";
import {getTranslation} from "../../../../../intl";
import {FormNotification} from "../../../forms/models";


interface Props {
    token: JwtToken
    customer: Customer
    cacheUpdater: (obj: Identifier, remove: boolean) => void
}

export function CustomerBudgetFormView(props: Props) {
    let [loading, setLoading] = useState<boolean>(false);
    let [errors, setErrors] = useState<Errors>({});
    let [message, setMessage] = useState(new FormNotification())

    let onSubmit = (customer: Customer) => {
        setLoading(true)

        CustomerApi.update(
            props.token,
            customer.id,
            customer.name,
            customer.budgetMin!,
            customer.budgetMax!,
            customer.account,
            customer.accountIds,
            customer.comment,
            customer.financial_comment
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as CustomerUpdateResponse;
                props.cacheUpdater(CustomerFactory.fromFragment(res.data), false);

                setMessage(
                    message.setSuccess(getTranslation('MONITORING.VIEWS.BUDGET.CUSTOMER.SUCCESS.UPDATE'))
                );
            } else {
                res = res as FormErrorResponse;
                setErrors(res.data);
            }
            setLoading(false)
        })
    }


    return (
        <CustomerBudgetForm
            customer={props.customer}
            onSubmit={onSubmit}
            message={message}
            disabled={loading}
        />
    )
}