import React, {useEffect, useState} from "react";

import {
    BudgetAccountGroup,
    CampaignGroup,
    Customer,
    Identifier,
    Account,
    Provider,
    ProviderAccount
} from "../../../../models";

import {getTranslation} from "../../../../intl";
import {FormEvents} from "../../../dummies/form/events";
import {FormMessage} from "../../../dummies/form/message";
import classNames from "classnames";
import {Flag, FlaggedCampaignGroup} from "../../views/budget/models";
import {CampaignGroupBasisForm} from "./basis";
import {FormNotification} from "../models";
import {BudgetAccountGroupFormView} from "../../views/budget/budget-account-group";
import {Cache, JwtToken} from "@boomrank/react-components";
import {FormBudgets} from "../../../dummies/form/budgets";
import {CampaignGroupDeleteView} from "../../views/campaign-group/delete";


interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    campaignGroups: CampaignGroup[]
    onSubmit: (flaggedCampaignGroups: FlaggedCampaignGroup[]) => void
    message: FormNotification
    providerAccounts: ProviderAccount[]
    budgetAccountGroups: BudgetAccountGroup[]
    cacheProviders: Cache<Provider>
    cacheUpdater: (obj: Identifier, remove: boolean) => void
    disabled?: boolean
}

export function CampaignGroupForm(props: Props) {
    let [flaggedCampaignGroups, setFlaggedCampaignGroups] = useState<FlaggedCampaignGroup[]>([])
    let [message, setMessage] = useState<FormNotification>(props.message)

    let onAdd = () => {
        let flaggedCg = new FlaggedCampaignGroup()
        flaggedCg.flag = Flag.CREATED
        flaggedCg.campaignGroup.customerId = props.customer.id
        flaggedCg.campaignGroup.id = (new Date()).getTime()

        setFlaggedCampaignGroups([flaggedCg, ...flaggedCampaignGroups])
        setMessage(message.clear())
    }

    let onChange = (campaignGroup: CampaignGroup) => {
        let flagged = flaggedCampaignGroups.find(fcg => fcg.campaignGroup.id === campaignGroup.id)

        if (flagged) {
            if (flagged.flag === Flag.NO_CHANGES) {
                flagged.flag = Flag.UPDATED
            }
            flagged.campaignGroup = campaignGroup
        }

        setFlaggedCampaignGroups([...flaggedCampaignGroups])
    }

    let onSubmit = (flaggedCampaignGroups: FlaggedCampaignGroup[]) => {
        props.onSubmit(flaggedCampaignGroups)
    }

    let submittedResponse = (edited: boolean) => {
        let content = getTranslation(
            'MONITORING.FORMS.CAMPAIGN_GROUP.SUCCESS.BUDGET_ACCOUNT_GROUP.CREATE'
        )

        if (edited) {
            content = getTranslation(
                'MONITORING.FORMS.CAMPAIGN_GROUP.SUCCESS.BUDGET_ACCOUNT_GROUP.UPDATE'
            )
        }
        setMessage(message.setSuccess(content))
    }

    useEffect(() => {
        let flaggedCgs = props.campaignGroups.map((campaignGroup: CampaignGroup) => {
            let fcg = new FlaggedCampaignGroup()
            fcg.flag = Flag.NO_CHANGES
            fcg.campaignGroup = campaignGroup
            return fcg
        })

        flaggedCgs.reverse()
        setFlaggedCampaignGroups(flaggedCgs)
    }, [props.campaignGroups])


    return (
        <div className={'w-full mt-6 py-6 px-5 border-2 border-slate-200 rounded-xl'}>
            <div className={'w-full my-3 lg:flex-col lg:items-end'}>
                <FormEvents
                    className={'w-full flex justify-end gap-x-1'}
                    onSubmit={() => onSubmit(flaggedCampaignGroups)}
                    submitTitle={getTranslation('MONITORING.FORMS.CAMPAIGN_GROUP.SUBMIT_BUTTON')}
                    disabled={props.disabled || false}
                    onAdd={onAdd}
                />

                <div className={'w-full my-3 lg:flex lg:justify-between lg:mb-3 lg:pr-16'}>
                    <h3 className={'w-full lg:w-2/4 my-0'}>{
                        getTranslation('MONITORING.FORMS.CAMPAIGN_GROUP.TITLE')
                    }</h3>

                    <FormBudgets
                        parentBudget={props.customer.budgetMax}
                        budgetMin={flaggedCampaignGroups.reduce(
                            (cost, flagged) => cost + flagged.campaignGroup.budgetMin!, 0
                        )}
                        budgetMax={flaggedCampaignGroups.reduce(
                            (cost, flagged) => cost + flagged.campaignGroup.budgetMax!, 0
                        )}
                        budgetMinError={getTranslation('MONITORING.FORMS.CAMPAIGN_GROUP.ERRORS.BUDGET_MIN')}
                        budgetMaxError={getTranslation('MONITORING.FORMS.CAMPAIGN_GROUP.ERRORS.BUDGET_MAX')}
                    />
                </div>

                <FormMessage formNotification={props.message} />

                {
                    flaggedCampaignGroups.length > 0 &&
                    <div>
                        <div className={'hidden w-full lg:flex text-gray-500 font-medium mb-1 pl-16 lg:pl-24 lg:pr-16'}>
                            <span className={'w-full text-sm'}>
                                {getTranslation('MONITORING.FORMS.CAMPAIGN_GROUP.LABELS.NAME')}
                            </span>
                            <span className={'w-full text-sm'}>
                                {getTranslation('MONITORING.FORMS.CAMPAIGN_GROUP.LABELS.KEY')}
                            </span>

                            <div className={'w-fit flex justify-between'}>
                                <span className={'w-52 text-sm'}>
                                    {getTranslation('MONITORING.FORMS.CAMPAIGN_GROUP.LABELS.BUDGET_MIN')}
                                </span>
                                <span className={'w-52 text-sm'}>
                                    {getTranslation('MONITORING.FORMS.CAMPAIGN_GROUP.LABELS.BUDGET_MAX')}
                                </span>
                            </div>
                        </div>

                        {
                            flaggedCampaignGroups.map((flaggedCampaignGroup) => (
                                <details
                                    key={flaggedCampaignGroup.id}
                                    className={'[&_svg]:open:-rotate-180 mb-0.5 odd:bg-white even:bg-slate-50'}
                                >
                                    <summary className={'w-full flex cursor-pointer list-none'}>
                                        <div className={'w-16 lg:w-24 flex items-center justify-center'}>
                                            <svg
                                                className="rotate-0 transform text-blue-700 transition-all duration-300"
                                                fill="none" height="20" width="20" stroke="currentColor"
                                                strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                viewBox="0 0 24 24"
                                            >
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                            </svg>
                                        </div>

                                        <div className={classNames(
                                            'w-full flex gap-x-2',
                                            {'font-medium': flaggedCampaignGroup.flag !== Flag.NO_CHANGES}
                                        )}>
                                            <CampaignGroupBasisForm
                                                campaignGroup={flaggedCampaignGroup.campaignGroup}
                                                onChange={onChange}
                                                disabled={props.disabled}
                                            />
                                            <CampaignGroupDeleteView
                                                token={props.token}
                                                campaignGroup={flaggedCampaignGroup.campaignGroup}
                                                cacheUpdater={props.cacheUpdater}
                                            />
                                        </div>
                                    </summary>

                                    <BudgetAccountGroupFormView
                                        token={props.token}
                                        customer={props.customer}
                                        campaignGroup={flaggedCampaignGroup.campaignGroup}
                                        budgetAccountGroups={props.budgetAccountGroups}
                                        providerAccounts={props.providerAccounts}
                                        cacheProviders={props.cacheProviders}
                                        cacheUpdater={props.cacheUpdater}
                                        submittedResponse={submittedResponse}
                                    />
                                </details>
                            ))
                        }
                    </div>
                }
            </div>
        </div>
    )
}
