import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import {Customer, Identifier,} from "../../../../models";
import {CustomerApi} from "../../../../services/customer/api";

import {FormErrorResponse, JwtToken, RedButton} from "@boomrank/react-components";
import {Errors} from "../../../dummies/errors";

import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    customer: Customer
    cacheUpdater: (obj: Identifier, remove: boolean) => void
    navigateTo?: string
}

export function CustomerDeleteView(props: Props) {
    let navigate = useNavigate()

    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState<Errors>({});


    let onClick = (customer: Customer) => {
        setLoading(true)

        let confirm = window.confirm(
            getTranslation('MONITORING.VIEWS.CUSTOMER.DELETE.CONFIRM_JS')
        )

        if (confirm) {
            CustomerApi.delete(
                props.token,
                customer.id,
            ).then(res => {
                if (res.statusCode === 204) {
                    props.cacheUpdater(customer, true)

                    if (props.navigateTo) {
                        return navigate(props.navigateTo)
                    }
                } else {
                    res = res as FormErrorResponse;
                    setErrors(res.data)
                    setLoading(false)
                }
            })
        }
    }


    return (
        <div className={
            'w-full lg:w-80 mt-6 py-6 px-5 border-2 border-red-100 bg-red-100 rounded-xl'
        }>
            <span className={'text-red-500 font-medium md:w-3/5'}>
                Suppression du compte client :
            </span>

            <RedButton
                data-testid={'customer-form-delete-btn'}
                text={getTranslation('MONITORING.VIEWS.CUSTOMER.DELETE.SUBMIT_BUTTON')}
                onClick={() => onClick(props.customer)}
                disabled={loading}
                className={'mt-3 mx-auto'}
            />
        </div>
    )
}