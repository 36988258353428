import React from "react";

import {Budget, BudgetAccountGroup, Provider, ProviderAccount} from "../../../../models";
import {Cache} from "@boomrank/react-components";
import {SelectInput} from "../../../dummies/select";
import BudgetInput from "../../../dummies/input/budget";
import {getTranslation} from "../../../../intl";


interface Props {
    budgetAccountGroup: BudgetAccountGroup
    providerAccounts: ProviderAccount[]
    cacheProviders: Cache<Provider>
    onChange: (budgetAccountGroup: BudgetAccountGroup) => void
    disabled?: boolean
}

export function BudgetAccountGroupBasisForm(props: Props) {
    let selectedOptions = () => {
        let options = props.providerAccounts.map((pa) => {
            let provider = props.cacheProviders.values()
                .find(p => p.id === pa.providerId)

            let text = `${pa.providerId} - ${pa.loginId}`;
            if (provider) {
                text = `${provider.name} - ${pa.name ? pa.name : pa.loginId}`;
            }

            return {
                id: pa.id,
                content: text,
                title: text,
            }
        })

        options.unshift({
            id: 0,
            content: getTranslation('MONITORING.FORMS.BUDGET_ACCOUNT_GROUP.BUDGET.OPTION.CONTENT'),
            title: getTranslation('MONITORING.FORMS.BUDGET_ACCOUNT_GROUP.BUDGET.OPTION.TITLE'),
        })

        return options
    }

    let onChange = (bagId: number) => {
        let selectedProviderAccount = props.providerAccounts.find(pa => pa.id === bagId)

        props.onChange({
            ...props.budgetAccountGroup,
            providerAccountId: selectedProviderAccount!.id
        });
    }

    let budgetUpdater = (budget: Budget) => {
        props.onChange({
            ...props.budgetAccountGroup,
            budgetMin: budget.budgetMin,
            budgetMax: budget.budgetMax,
        });
    }


    return (
        <div className={'w-full flex flex-col lg:flex-row border-gray-100 mb-0.5 lg:mb-0 lg:pl-36'}>
            <SelectInput
                options={selectedOptions()}
                onChange={onChange}
                name={"provider"}
                value={props.budgetAccountGroup.providerAccountId ? props.budgetAccountGroup.providerAccountId : 0}
                disabled={props.disabled || false}
            />
            <BudgetInput
                budgetMin={props.budgetAccountGroup.budgetMin}
                budgetMax={props.budgetAccountGroup.budgetMax}
                budgetUpdater={budgetUpdater}
                className={'w-full lg:w-fit lg:flex'}
                disabled={props.disabled || false}
            />
        </div>
    )
}