import React from "react";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";

import {
    Account,
    AccountHasCustomer,
    CampaignGroup,
    CampaignType,
    Customer,
    Provider,
    ProviderAccount,
} from "../../../../models";

import {StepPeriod} from "../../../../models/stepPeriod";

import {Breadcrumb, Cache, JwtToken, PageNotFoundView} from "@boomrank/react-components";
import {CampaignGroupGetView} from "../../views/campaign-group/get";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheCampaignGroups: Cache<CampaignGroup>
    cacheAccounts: Cache<Account>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheProviderAccounts: Cache<ProviderAccount>
    cacheProviders: Cache<Provider>
    cacheCampaignTypes: Cache<CampaignType>
    stepPeriod: StepPeriod
    onStepPeriodChange: (stepPeriod: StepPeriod) => void
}

export function CampaignGroupGetRoute(props: Props) {
    let location = useLocation()
    let params = useParams()

    let customer = props.cacheCustomers.values()
        .find(c => c.id === parseInt(params.customerId!))

    let campaignGroup = props.cacheCampaignGroups.values()
        .find(cg => cg.id === parseInt(params.campaignGroupId!))


    if (!customer || !campaignGroup) {
        return <PageNotFoundView />
    }

    document.title = getTranslation('MONITORING.ROUTES.CAMPAIGN_GROUP.GET_TITLE').format(
        customer.name,
        campaignGroup.name
    );


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {to: `/dashboard/customer/${customer.id}/detail`, content: <>{customer.name}</>},
                    {
                        to: `/dashboard/customer/${customer.id}/campaign-groups`,
                        content: getTranslation('MONITORING.ROUTES.CAMPAIGN_GROUP.BREADCRUMB')
                    },
                    {
                        to: `/dashboard/customer/${customer.id}/campaign-group/${campaignGroup.id}`,
                        content: <>{campaignGroup.name}</>,
                        disabled: true
                    },
                ]}
            />

            <CampaignGroupGetView
                token={props.token}
                me={props.me}
                customer={customer}
                campaignGroup={campaignGroup}
                cacheAccounts={props.cacheAccounts}
                cacheAccountHasCustomers={props.cacheAccountHasCustomers}
                cacheProviderAccounts={props.cacheProviderAccounts}
                cacheProviders={props.cacheProviders}
                cacheCampaignTypes={props.cacheCampaignTypes}
                onStepPeriodChange={props.onStepPeriodChange}
                stepPeriod={props.stepPeriod}
            />
        </>
    )
}
