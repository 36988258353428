import {SecureHTML} from "@boomrank/react-components";
import {defaultLanguage, getTranslation} from "../../../../intl";
import React from "react";


interface Props {
    minExpense: number
    maxExpense: number
}

export function TheoreticalExpense(props: Props) {
    if (props.minExpense === 0 && props.maxExpense > 0) {
        return (
            <SecureHTML className={'my-2'}>
                {getTranslation('DUMMIES.GRAPH.TOOLTIP.THEORETICAL.MAX_EXPENSE').format(
                    props.maxExpense.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    )
                )}
            </SecureHTML>
        )
    }

    if (props.maxExpense === 0 && props.minExpense > 0) {
        return (
            <SecureHTML className={'my-2'}>
                {getTranslation('DUMMIES.GRAPH.TOOLTIP.THEORETICAL.MIN_EXPENSE').format(
                    props.minExpense.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    )
                )}
            </SecureHTML>
        )
    }

    if (props.minExpense > 0 && props.maxExpense > 0) {
        return (
            <SecureHTML className={'my-2'}>
                {getTranslation('DUMMIES.GRAPH.TOOLTIP.THEORETICAL.EXPENSE').format(
                    props.minExpense.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    ),
                    props.maxExpense.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    )
                )}
            </SecureHTML>
        )
    }

    return <></>
}