import React, {useMemo} from "react";
import {Link} from "react-router-dom";

import {Account, Identifier, Role} from "../../../../models";
import {StandaloneCampaignGroup} from "../../../../models/standalone";
import {StepPeriod} from "../../../../models/stepPeriod";

import {ExportApi} from "../../../../services/export/api";

import {Column, ColumnType, FullTable, JwtToken, PaginateAdvancedTable} from "@boomrank/react-components";
import {defaultLanguage, getTranslation, translator} from "../../../../intl";
import {BudgetCell} from "../../../dummies/budget/budget-cell";
import {CampaignGroupDeleteView} from "./delete";
import {TableLoader} from "../../../dummies/loaders/table";


interface Props {
    token: JwtToken
    me: Account
    standaloneCampaignGroups: StandaloneCampaignGroup[]
    stepPeriod: StepPeriod
    columns: (keyof StandaloneCampaignGroup)[]
    cacheUpdater: (obj: Identifier, remove: boolean) => void
    isLoading?: boolean
}

export function CampaignGroupsTableView(props: Props) {
    let isLoading = props.isLoading || false

    let getDatesInRange = (start: Date, end: Date) => {
        const date = new Date(start.getTime());
        const dates = [];

        while (date <= end) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return dates;
    }

    let getPeriodBudget = (budget: number, stepPeriod: StepPeriod) => {
        if (budget && stepPeriod.stepTable === '1W') {
            let month = stepPeriod.period.end.getMonth()
            let year = stepPeriod.period.end.getFullYear()
            let lastDay = new Date(year, month +1, 0).getDate()

            return (budget / lastDay) * 7
        }
        return budget
    }

    let setSuggestedBudget = (stepPeriod: StepPeriod, row: any) => {
        if (stepPeriod.period.key === '1W' || stepPeriod.period.key === '1M') {
            let dates = getDatesInRange(stepPeriod.period.start, stepPeriod.period.end)
            let remainingDays = dates.length

            let yesterday = new Date().getDate() - 1
            let date = dates.map((d) => d.getDate()).indexOf(yesterday)

            if (date !== -1) {
                remainingDays = dates.length - (date + 1)
            }

            if (row.budgetMax) {
                return (getPeriodBudget(row.budgetMax, stepPeriod) - row.cost) / remainingDays
            }
        }

        return '-'
    }

    let defaultColumns: Column<StandaloneCampaignGroup>[] = [
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN_GROUP.TABLE.NAME'),
            type: ColumnType.STRING,
            accessor: 'name',
            cell: (row: StandaloneCampaignGroup, value: any) => {
                return (
                    <Link
                        to={`/dashboard/customer/${row.customerId}/campaign-group/${row.id}`}
                        className={'w-full xs:my-0 sm:my-0'}
                        title={'Voir les statistiques du groupe de campagnes'}
                    >
                        {value}
                    </Link>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN_GROUP.TABLE.KEY'),
            type: ColumnType.STRING,
            accessor: 'key',
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN_GROUP.TABLE.COST'),
            type: ColumnType.NUMBER,
            accessor: "cost",
            cell: (row: StandaloneCampaignGroup, value: any) => {
                if (value) {
                    return row.cost.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    )
                }
                return '-'
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN_GROUP.TABLE.TREND'),
            type: ColumnType.NUMBER,
            accessor: "trend",
            cell: (row: StandaloneCampaignGroup, value: any) => {
                if (value) {
                    return row.trend.toLocaleString(
                        defaultLanguage,
                        {style: 'currency', currency: 'EUR'}
                    )
                }
                return '-'
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN_GROUP.TABLE.BUDGET'),
            type: ColumnType.NUMBER,
            accessor: "budgetMin",
            cell: (row: StandaloneCampaignGroup, value: any) => {
                return (
                    <BudgetCell
                        budgetMin={row.budgetMin}
                        budgetMax={row.budgetMax}
                        stepPeriod={props.stepPeriod}
                    />
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN_GROUP.TABLE.SUGGESTED_BUDGET'),
            type: ColumnType.NUMBER,
            accessor: "budgetMax",
            cell: (row: StandaloneCampaignGroup, value: any) => {
                let suggestedBudget = setSuggestedBudget(props.stepPeriod, row)

                return (
                    <div>
                        {suggestedBudget.toLocaleString(
                            defaultLanguage,
                            {style: 'currency', currency: 'EUR'}
                        )}
                    </div>
                )
            }
        },
        {
            header: getTranslation('MONITORING.VIEWS.CAMPAIGN_GROUP.TABLE.NB_CAMPAIGNS'),
            type: ColumnType.NUMBER,
            accessor: "nbCampaigns"
        },
    ]

    if (
        props.me.role === Role.ASSOCIATE ||
        props.me.role === Role.FINANCE ||
        props.me.role === Role.HEAD_OF ||
        props.me.role === Role.TEAM_LEADER ||
        props.me.role === Role.USER
    ) {
        defaultColumns.push(
            {
                header: getTranslation('MONITORING.VIEWS.CAMPAIGN_GROUP.TABLE.DELETE'),
                type: ColumnType.NUMBER,
                accessor: "id",
                cell: (row: StandaloneCampaignGroup, value: any) => {
                    return (
                        <CampaignGroupDeleteView
                            token={props.token}
                            campaignGroup={row}
                            cacheUpdater={props.cacheUpdater}
                        />
                    )
                }
            },
        )
    }

    let onClickExport = (campaignGroups: StandaloneCampaignGroup[]) => {
        if (campaignGroups.length > 0) {
            ExportApi.csv(props.token, campaignGroups)
        }
    }

    const sortBy = useMemo(() => [
        { id: "cost", desc: true }
    ], [])


    if (isLoading) {
        return (
            <TableLoader defaultColumns={defaultColumns} />
        )
    }

    return (
        <FullTable
            rows={props.standaloneCampaignGroups}
            formatter={() => props.standaloneCampaignGroups}
            showColumns={props.columns}
            columns={defaultColumns}
            onClickExport={onClickExport}
        >
            <PaginateAdvancedTable
                translator={translator}
                mobileColumnsDisplayed={[0, 2, 3]}
                initialState={{
                    sortBy: sortBy,
                    pageSize: 100
                }}
            />
        </FullTable>
    )
}