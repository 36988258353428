import React, {useState} from "react";
import {SelectInput} from "./index";
import {Invoice} from "../../../models";
import {getTranslation} from "../../../intl";


export interface Props {
    invoices: Invoice[]
    onSelect: (invoiceId: number) => void
    disabled: boolean
}

export function SelectInvoiceTemplate(props: Props) {
    let [selectedOption, setSelectedOption] = useState<number>(0)

    let options = props.invoices.map((invoice) => {
        let year = invoice.refDate.getFullYear()
        let month = invoice.refDate.toLocaleDateString('fr-Fr', {month: "2-digit"})

        let ref = `${year.toString()}-${month}-${invoice.customerId}${invoice.id}`

        return {
            id: invoice.id,
            content: ref,
            title: `Sélectionner le template du ${ref}`,
        }
    })

    options.unshift({
        id: 0,
        content: getTranslation('DUMMIES.SELECT.INVOICE_TEMPLATE.OPTIONS.DEFAULT_CONTENT'),
        title: getTranslation('DUMMIES.SELECT.INVOICE_TEMPLATE.OPTIONS.DEFAULT_TITLE'),
    })

    let onChange = (optionId: number) => {
        setSelectedOption(optionId)
        props.onSelect(optionId)
    }

    return (
        <div className={
            'w-full my-6 py-6 px-5 md:flex items-center justify-between ' +
            'border-2 border-slate-100 bg-slate-50 rounded-xl'
        }>
            <h3 className={'w-full md:w-2/4'}>
                {getTranslation('DUMMIES.SELECT.INVOICE_TEMPLATE.TITLE')}
            </h3>

            <SelectInput
                dataTestId={'form-invoice-template'}
                name={'invoiceTemplate'}
                value={selectedOption}
                options={options}
                onChange={onChange}
                disabled={props.disabled}
            />
        </div>
    )
}