import React from "react";
import {useLocation} from "react-router-dom";

import {Account, AccountHasCustomer, Customer, Invoice} from "../../../../models";

import {Breadcrumb, Cache, JwtToken} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";
import {InvoiceView} from "../../views/invoice";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheInvoices: Cache<Invoice>
    cacheAccounts: Cache<Account>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
}

export function InvoiceRoute(props: Props) {
    let location = useLocation()
    document.title = getTranslation('MONITORING.ROUTES.INVOICE.LIST_TITLE.DEFAULT')

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {
                        to: `/dashboard/invoices`,
                        content: getTranslation('MONITORING.ROUTES.INVOICE.BREADCRUMB.DEFAULT'),
                        disabled: true
                    },
                ]}
            />

            <InvoiceView
                token={props.token}
                me={props.me}
                cacheCustomers={props.cacheCustomers}
                cacheInvoices={props.cacheInvoices}
                cacheAccounts={props.cacheAccounts}
                cacheAccountHasCustomers={props.cacheAccountHasCustomers}
            />
        </>
    )
}

