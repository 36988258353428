
export enum Status {
    UNKNOWN = -1,
    UNSPECIFIED = 0,
    ACTIVE = 1,
    ARCHIVED = 2,
    DELETED = 3,
    PAUSED = 4,
}

export enum State {
    ACTIVE = 1,
    PAUSED = 2,
}

export enum Role {
    USER = 1,
    TEAM_LEADER = 2,
    HEAD_OF = 3,
    FINANCE = 4,
    ASSOCIATE = 5,
}

export enum InvoiceType {
    PAYING = 1,
    NON_PAYING = 2,
    ADVANCED = 3,
}

export interface Identifier {
    id: number | string
}

/**
 * @param {number} id
 * @param {string} username
 * @param {string} email
 * @param {string} firstname
 * @param {string} lastname
 * @param {boolean} isAdmin
 * @param {boolean} isActive
 * @param {string[]} permissions
 * */
export class User implements Identifier {
    id: number = 0
    username: string = ''
    email: string = ''
    firstname: string = ''
    lastname: string = ''
    isAdmin: boolean = false
    isActive: boolean = false
    permissions: string[] = []
}

/**
 * @param {number} id
 * @param {User} user
 * @param {Date} created_at
 * @param {Role} role
 * */
export class Account implements Identifier {
    id: number = 0
    user: User = new User()
    createdAt: Date = new Date()
    role: Role = Role.USER || Role.TEAM_LEADER || Role.ASSOCIATE || Role.FINANCE || Role.HEAD_OF

    isAdmin() {
        return (
            this.role === Role.ASSOCIATE ||
            this.role === Role.HEAD_OF ||
            this.role === Role.FINANCE
        )
    }
}

/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {Date | null} createdAt
 * @param {string} name
 * @param {number | null} budgetMin
 * @param {number | null} budgetMax
 * @param {number} account
 * @param {Account[]} accounts
 * @param {State} state
 * @param {string} comment
 * */
export class Customer implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    lastUpdate: Date | null = null
    name: string = ''
    budgetMin: number | null = null
    budgetMax: number | null = null
    account: number | null = null
    accountIds: number[] = []
    state: State = State.ACTIVE || State.PAUSED
    comment: string = ''
    financial_comment: string = ''
}

/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {number} accountId
 * @param {number} customerId
 * @param {boolean} notificationEnabled
 * */
export class AccountHasCustomer implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    accountId: number = 0
    customerId: number = 0
    notificationEnabled: boolean = true
}

/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {string} name
 * */
export class Provider implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    name: string = ''
}

/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {string} loginId
 * @param {string} name
 * @param {number | null} budgetMin
 * @param {number | null} budgetMax
 * @param {number} providerId
 * @param {number} customerId
 * */
export class ProviderAccount implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    loginId: string = ''
    name: string = ''
    budgetMin: number | null = null
    budgetMax: number | null = null
    providerId: number = 0
    customerId: number = 0
    cost: number | null = null
    trend: number | null = null
    error: string | undefined = undefined
}

/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {string} name
 * @param {number} provider
 * */
export class CampaignType implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    name: string = ''
    providerId: number = 0
}

/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {string} name
 * @param {string} key
 * @param {number} campaignTypeId
 * @param {number} providerAccountId
 * @param {number} [cost]
 * @param {number} [trend]
 * @param {Status} status
 * */
export class Campaign implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    name: string = ''
    key: string = ''
    campaignTypeId: number = 0
    providerAccountId: number = 0
    cost: number | null = null
    trend: number | null = null
    status: Status = (
        Status.ACTIVE ||
        Status.UNSPECIFIED ||
        Status.ARCHIVED ||
        Status.DELETED ||
        Status.PAUSED ||
        Status.UNKNOWN
    )
}

/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {string} name
 * @param {string} key
 * @param {number | null} budgetMin
 * @param {number | null} budgetMax
 * @param {number} customerId
 * @param {Campaign[]} campaigns
 * @param {number} [cost]
 * @param {number} [trend]
 * */
export class CampaignGroup implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    name: string = ''
    key: string = ''
    budgetMin: number | null = null
    budgetMax: number | null = null
    customerId: number = 0
    campaigns: Campaign[] = []
    cost: number | null = null
    trend: number | null = null
}

/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {number | null} budgetMin
 * @param {number | null} budgetMax
 * @param {number} campaignGroupId
 * @param {number} providerAccountId
 * */
export class BudgetAccountGroup implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    budgetMin: number | null = null
    budgetMax: number | null = null
    campaignGroupId: number = 0
    providerAccountId: number = 0
}


/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {Date} updatedAt
 * @param {Date | null} submittedAt
 * @param {number} customerId
 * @param {number | null} feeId
 * @param {number | null} feeBracketId
 * @param {Date} refDate
 * @param {number} totalInvoices
 * @param {number | null} totalFees
 * @param {Invoice} invoices
 * */
export class FinancialInvoice implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    updatedAt: Date = new Date()
    submittedAt: Date | null = null
    customerId: number = 0
    feeId: number | null = null
    feeBracketId: number | null = null
    refDate: Date = new Date()
    totalInvoices: number = 0
    totalFees: number | null = null
    invoices: Invoice[] = []
}

/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {Date} updatedAt
 * @param {Date | null} submittedAt
 * @param {number} accountId
 * @param {number} customerId
 * @param {number | null} feeId
 * @param {InvoiceType} invoiceType
 * @param {string} ref
 * @param {Date} refDate
 * @param {number} totalItems
 * @param {number | null} totalFees
 * @param {string} comment
 * */
export class Invoice implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    updatedAt: Date = new Date()
    submittedAt: Date | null = null
    accountId: number = 0
    accountName: string = ''
    customerId: number = 0
    invoiceType: InvoiceType = (
        InvoiceType.PAYING ||
        InvoiceType.NON_PAYING ||
        InvoiceType.ADVANCED
    )
    ref: string = ''
    refDate: Date = new Date()
    totalItems: number = 0
    comment: string = ''
    invoiceItems: InvoiceItem[] = []
}

/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {number} invoiceId
 * @param {number | null} providerAccountId
 * @param {string} label
 * @param {number} priceHt
 * */
export class InvoiceItem implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    invoiceId: number = 0
    providerAccountId: number | null = null
    label: string = ''
    priceHt: number = 0
    description: string = ''
    applicableFee: boolean = true
    isCss: boolean = false
}

/**
 * @param {number | null} budgetMin
 * @param {number | null} budgetMax
 * */
export class Budget {
    budgetMin: number | null = null
    budgetMax: number | null = null
}

/**
 * @param {string} id
 * @param {Date} createdAt
 * @param {number} customerId
 * @param {number} instanceId
 * @param {string} instanceName
 * @param {string} code
 * @param {(string | number)[]} args
 * @param {boolean} markAsRead
 * */
export class Notification implements Identifier {
    id: string = ''
    createdAt: Date = new Date()
    username: string = ''
    customerId: number = 0
    customerName: string = ''
    instanceName: string = ''
    code: string = ''
    args: (string | number)[] = []
    message: string = ''
    markAsRead: boolean = false
}

/**
 * @param {string} id
 * @param {Date} createdAt
 * @param {number} customerId
 * @param {number} instanceId
 * @param {string} instanceName
 * @param {string} code
 * @param {number | string | null[]} args
 * @param {boolean} markAsRead
 * */
export class Log implements Identifier {
    id: string = ''
    createdAt: Date = new Date()
    username: string = ''
    customerId: number = 0
    instanceId: number = 0
    instanceName: string = ''
    code: string = ''
    args: number | string | null[] = []
}

/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {number} customerId
 * @param {number | null} limitMin
 * @param {number | null} limitMax
 * @param {Date | null} enabledAt
 * @param {FeeBracket[]} feeBrackets
 * */
export class Fee implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    customerId: number = 0
    limitMin: number | null = null
    limitMax: number | null = null
    enabledAt: Date | null = null
    feeBrackets : FeeBracket[] = []
}

/**
 * @param {number} id
 * @param {Date} createdAt
 * @param {number | null} bracketMin
 * @param {number | null} bracketMax
 * @param {number} priceHt
 * @param {number} rate
 * */
export class FeeBracket implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    bracketMin: number | null = null
    bracketMax: number | null = null
    priceHt: number = 0
    rate: number = 0
}

export class ProviderAccountInvoice implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    period: Date = new Date()
    providerAccountId: number = 0
    ref: string = ''
    currencyCode: string = 'EUR'
    totalAmount: number = 0
}