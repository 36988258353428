import React, {useEffect, useState} from "react";
import {ProgressBarElement} from "./element";


interface Props {
    minValue: number | null
    cost: number | null
    maxValue: number | null
    trend: number | null
}

export function ProgressBar (props: Props) {
    let [minPosition, setMinPosition] = useState<string>('0')
    let [maxPosition, setMaxPosition] = useState<string>('0')
    let [costPosition, setCostPosition] = useState<string>('0')
    let [trendPosition, setTrendPosition] = useState<string>('0')

    let trendColor = () => {
        if (props.trend) {
            if ((props.maxValue && props.trend > props.maxValue) ||
                (props.minValue && props.trend < props.minValue)) {
                return 'bg-br-red-500 text-br-red-500'
            }
        }
        return 'bg-green-500 text-green-500'
    }

    let sortedValues = () => {
        let budget = []

        if (props.cost) {
            budget.push(props.cost)
        }
        if (props.trend) {
            budget.push(props.trend)
        }
        if (props.maxValue) {
            budget.push(props.maxValue)
        }
        if (props.minValue) {
            budget.push(props.minValue)
        }

        return budget.sort((a, b) => {
            return a!-b!
        })
    }

    let getPosition = (values: number[], value: number) => {
        if (values.indexOf(value) === 0) {
            return '0'
        }
        if (values.indexOf(value) === 3) {
            return '100'
        }

        let maxValue = values.slice(-1)[0]
        let percent = 100 - (((maxValue! - value) / (maxValue!)) * 100)
        return percent.toFixed(0)
    }

    let setPositions = (values: number[]) => {
        setMinPosition(getPosition(values, props.minValue!))
        setMaxPosition(getPosition(values, props.maxValue!))
        setCostPosition(getPosition(values, props.cost!))
        setTrendPosition(getPosition(values, props.trend!))
    }

    let values = sortedValues()

    useEffect(() => {
        setPositions(values)
    }, [values]);


    return (
        <div className={'w-3/4 mx-auto my-3 flex justify-center items-center h-20 relative'}>
            <div className={'absolute w-full h-1 bg-slate-300 rounded z-0'} />
            {
                props.minValue !== null &&
                <ProgressBarElement
                    value={props.minValue}
                    leftPercent={minPosition}
                    className={`text-br-blue-300`}
                    pointerClassName={`w-1 h-6 text-2xl leading-none z-0 progressBarMinValue`}
                    translationField={'BUDGET_MIN'}
                />
            }
            {
                props.cost !== null &&
                <ProgressBarElement
                    value={props.cost}
                    leftPercent={costPosition}
                    className={`text-br-blue-900`}
                    pointerClassName={`w-2 h-2 bg-br-blue-900 z-10`}
                    translationField={'COST'}
                />
            }
            {
                props.maxValue !== null &&
                <ProgressBarElement
                    value={props.maxValue}
                    leftPercent={maxPosition}
                    className={`text-br-blue-500`}
                    pointerClassName={`w-1 h-6 text-2xl leading-none z-0 progressBarMaxValue`}
                    translationField={'BUDGET_MAX'}
                />
            }
            {
                props.trend !== null &&
                (props.trend! > 0 || props.trend!.toFixed(0) !== props.cost!.toFixed(0)) &&
                <ProgressBarElement
                    value={props.trend}
                    leftPercent={trendPosition}
                    className={`${trendColor()}`}
                    pointerClassName={`w-2 h-2 ${trendColor()} z-10 progressBarTrendValue`}
                    translationField={'TREND'}
                />
            }
        </div>
    )
}
