import React from "react";

import {Invoice, InvoiceType} from "../../../../models";
import {Errors} from "../../../dummies/errors";
import {BasisInput} from "../../../dummies/input";
import {getTranslation} from "../../../../intl";
import {SelectInput} from "../../../dummies/select";


interface Props {
    invoice: Invoice
    onChange: (invoice: Invoice) => void
    errors: Errors
    disabled: boolean
}

export function BasisInvoiceForm(props: Props) {
    let onSelectChange = (type: InvoiceType) => {
        props.onChange({
            ...props.invoice,
            invoiceType: type.valueOf()
        })
    }

    let onChange = (name: string, value: string | Date) => {
        if (name === 'refDate') {
            let date = new Date(value)
            let startDate = date.setDate(1)

            props.onChange({
                ...props.invoice,
                refDate: new Date(startDate),
            })
        } else {
            props.onChange({
                ...props.invoice,
                [name]: value,
            })
        }
    }


    return (
        <div className={'w-full'}>
            <div className={'w-full mb-3 lg:flex lg:gap-x-3'}>
                <BasisInput
                    dataTestId={'invoice-ref'}
                    type={"text"}
                    name={"ref"}
                    value={props.invoice.ref}
                    onChange={onChange}
                    placeholder={getTranslation('MONITORING.FORMS.INVOICE.PLACEHOLDERS.REF')}
                    legend={getTranslation('MONITORING.FORMS.INVOICE.LEGENDS.REF')}
                    disabled={props.disabled}
                />

                <SelectInput
                    dataTestId={'invoice-invoiceType'}
                    options={[
                        {
                            id: 1,
                            content: getTranslation('MONITORING.FORMS.INVOICE.OPTION.PAYING'),
                            title: getTranslation('MONITORING.FORMS.INVOICE.OPTION.PAYING'),
                        },
                        {
                            id: 2,
                            content: getTranslation('MONITORING.FORMS.INVOICE.OPTION.NON_PAYING'),
                            title: getTranslation('MONITORING.FORMS.INVOICE.OPTION.NON_PAYING'),
                        },
                        {
                            id: 3,
                            content: getTranslation('MONITORING.FORMS.INVOICE.OPTION.ADVANCED'),
                            title: getTranslation('MONITORING.FORMS.INVOICE.OPTION.ADVANCED'),
                        }
                    ]}
                    onChange={onSelectChange}
                    name={"invoiceType"}
                    value={props.invoice.invoiceType}
                    legend={getTranslation('MONITORING.FORMS.INVOICE.LEGENDS.INVOICE_TYPE')}
                    disabled={props.disabled}
                />

                <BasisInput
                    dataTestId={'invoice-refDate'}
                    type={"date"}
                    name={"refDate"}
                    value={props.invoice.refDate.toLocaleDateString(
                        'fr-CA',
                        {year: "numeric", month: "2-digit", day: "2-digit"}
                    )}
                    onChange={onChange}
                    placeholder={getTranslation('MONITORING.FORMS.INVOICE.PLACEHOLDERS.REF_DATE')}
                    legend={getTranslation('MONITORING.FORMS.INVOICE.LEGENDS.REF_DATE')}
                    disabled={props.disabled}
                />
            </div>

            <legend className={'text-gray-500'}>
                {getTranslation('MONITORING.FORMS.INVOICE.LEGENDS.COMMENT')}
            </legend>
            <textarea
                data-testid={'invoice-comment'}
                value={props.invoice.comment}
                name={"comment"}
                onChange={e => onChange('comment', e.target.value)}
                className={'w-full p-2 border border-gray-200'}
                disabled={props.disabled}
            />
        </div>
    )
}
