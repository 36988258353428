import React, {useEffect, useState} from "react";

import {Account, Customer, Fee, Identifier, Role} from "../../../../models";
import {JwtToken} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";
import {FeeApi} from "../../../../services/fee/api";
import {FeeCreateResponse} from "../../../../services/fee/responses";
import {FeeForm} from "../../forms/fee";
import {FeeFactory} from "../../../../services/fee/factory";
import {CustomerLinks} from "../../../dummies/customer/links";


interface Props {
    token: JwtToken
    me: Account
    customer: Customer
    fees: Fee[]
    onSubmit?: (fee: Fee) => void
    cacheUpdater: (obj: Identifier) => void
}

export function FeeAddView (props: Props) {
    let [fee, setFee] = useState<Fee>(new Fee())
    let [isLoading, setIsLoading] = useState(false)
    let [error, setError] = useState('')

    let onError = () => {
        setError('')
    }

    let onSubmit = (fee: Fee) => {
        setIsLoading(true)
        setError('')

        FeeApi.create(props.token, fee).then((response) => {
            if(response.statusCode >= 200 && response.statusCode < 300) {
                props.fees.forEach((fee) => {
                    fee.enabledAt = null
                    props.cacheUpdater(fee)
                })

                response = response as FeeCreateResponse;
                fee = FeeFactory.fromFragment(response.data)

                props.cacheUpdater(fee)
                if (props.onSubmit) {
                    props.onSubmit(fee)
                }
            } else {
                setFee(fee)
                setError(
                    getTranslation('MONITORING.VIEWS.FEE.ERROR')
                )
            }
            setIsLoading(false)
        })
    }

    useEffect(() => {
        let fee = new Fee()
        fee.customerId = props.customer.id
        fee.enabledAt = new Date()
        setFee(fee)
    }, [props.customer])

    if (!props.me.isAdmin()) {
        return (
            <div role={'alert'}>
                { getTranslation('MONITORING.VIEWS.FEE.ACCESS_DENIED') }
            </div>
        )
    }

    return (
        <div className={'mt-6'}>
            <div className={'w-full lg:flex lg:flex-row-reverse'}>
                <CustomerLinks customerId={props.customer.id} me={props.me} />
            </div>

            <div className={'py-6'}>
                {
                    error &&
                    <div role={'alert'} className={'p-6 text-br-red border border-br-red bg-red-50 my-3'}>
                        {error}
                    </div>
                }

                <FeeForm
                    fee={fee}
                    onSubmit={onSubmit}
                    onError={onError}
                    disabled={isLoading}
                />
            </div>
        </div>
    )
}