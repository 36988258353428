import {
    AccountHasCustomer,
    Campaign,
    CampaignGroup,
    CampaignType,
    Customer, Fee, FeeBracket, FinancialInvoice,
    Invoice,
    Log,
    Provider,
    ProviderAccount,
    ProviderAccountInvoice,
    State,
} from './index';
import {StatCustomer} from "./stats";


/**
 * @param {AccountHasCustomer[]} accountHasCustomers
 * @param {ProviderAccount[]} providerAccounts
 * @param {Invoice[]} invoices
 * @param {number} cost
 * @param {number} trend
 * @param {number} nbCampaignGroups
 * @param {number} nbCampaigns
 * @param {string} purchases
 * @param {string} purchaseRoas
 * @param {string} costPerPurchase
 * @param {string} leads
 * @param {string} costPerLead
 * @param {string} impressions
 * @param {string} conversions
 * @param {string} conversionsValue
 * */
export class StandaloneCustomer extends Customer {
    accountHasCustomers: AccountHasCustomer[] = []
    providerAccounts: ProviderAccount[] = []
    invoices: Invoice[] = []
    cost: number = 0
    trend: number = 0
    nbCampaignGroups: number = 0
    nbCampaigns: number = 0
    purchases: number = 0
    purchaseRoas: number = 0
    costPerPurchase: number = 0
    leads: number = 0
    costPerLead: number = 0
    impressions: number = 0
    conversions: number = 0
    conversionsValue: number = 0
}

/**
 * @param {extends ProviderAccount} ProviderAccount
 * @param {string} customerName
 * @param {string} providerName
 * */
export class StandaloneProviderAccount extends ProviderAccount {
    customerName: Customer['name'] = ''
    providerName: Provider['name'] = ''
}

/**
 * @param {number} id
 * @param {number} customerId
 * @param {string} customerName
 * @param {string} provider
 * @param {string} loginId
 * @param {string} campaignType
 * @param {string} name
 * @param {string} key
 * @param {number} cost
 * @param {number} trend
 * @param {string} status
 * @param {string} purchases
 * @param {string} purchaseRoas
 * @param {string} costPerPurchase
 * @param {string} leads
 * @param {string} costPerLead
 * @param {string} impressions
 * @param {string} conversions
 * @param {string} conversionsValue
 * */
export class StandaloneCampaign {
    id: Campaign['id'] = 0
    customerId: Customer['id'] = 0
    customerName: Customer['name'] = ''
    provider: Provider['name'] = ''
    loginId: ProviderAccount['loginId'] = ''
    campaignType: CampaignType['name'] = ''
    name: Campaign['name'] = ''
    key: Campaign['key'] = ''
    cost: number = 0
    trend: number = 0
    status: string = ''
    purchases: number = 0
    purchaseRoas: number = 0
    costPerPurchase: number = 0
    leads: number = 0
    costPerLead: number = 0
    impressions: number = 0
    conversions: number = 0
    conversionsValue: number = 0
}

/**
 * @param {number} id
 * @param {number} customerId
 * @param {string} customerName
 * @param {string} name
 * @param {string} key
 * @param {number | null} budgetMin
 * @param {number | null} budgetMax
 * @param {number} cost
 * @param {number} trend
 * @param {number} nbCampaigns
 * @param {string} purchases
 * @param {string} purchaseRoas
 * @param {string} costPerPurchase
 * @param {string} leads
 * @param {string} costPerLead
 * @param {string} impressions
 * @param {string} conversions
 * @param {string} conversionsValue
 * */
export class StandaloneCampaignGroup {
    id: CampaignGroup['id'] = 0
    customerId: Customer['id'] = 0
    customerName: Customer['name'] = ''
    name: CampaignGroup['name'] = ''
    key: CampaignGroup['key'] = ''
    budgetMin: null | number = null
    budgetMax: null | number = null
    cost: number = 0
    trend: number = 0
    nbCampaigns: number = 0
    purchases: number = 0
    purchaseRoas: number = 0
    costPerPurchase: number = 0
    leads: number = 0
    costPerLead: number = 0
    impressions: number = 0
    conversions: number = 0
    conversionsValue: number = 0
}

/**
 * @param {extends Log} Log
 * @param {string} accountUser
 * */
export class StandaloneLog extends Log {
    accountUser: string = ''
}

/**
 * @param {extends Invoice} Invoice
 * @param {string} customerName
 * @param {string} accountName
 * @param {number[]} accountIds
 * @param {string} invoiceTypeName
 * @param {string} isSubmitted
 * @param {string} refDateMonth
 * @param {number} refDateYear
 * */
export class StandaloneInvoice extends Invoice {
    customerName: string = ''
    accountName: string = ''
    accountIds: number[] = []
    invoiceTypeName: string = ''
    isSubmitted: string = ''
    refDateYear: number = 0
    refDateMonth: string = ''
}

/**
 * @param {extends FinancialInvoice} FinancialInvoice
 * @param {string} customerName
 * @param {string} isSubmitted
 * @param {string} refDateMonth
 * @param {number} refDateYear
 * */
export class StandaloneFinancialInvoice extends FinancialInvoice {
    customerName: string = ''
    customerComment: string = ''
    customerState: State = State.ACTIVE || State.PAUSED
    customerFinancialComment: string = ''
    isSubmitted: string = ''
    refDateYear: number = 0
    refDateMonth: string = ''
    refId: number = 0
    totalNbInvoices: number = 0
    fee: Fee | undefined = undefined
    feeBracket: FeeBracket | undefined = undefined
    feesRule: string = ''
    totalInvoicesFees: number = 0
    totalInvoicesNoFees: number = 0
}

export class StandaloneFinancialInvoiceItem {
    financialInvoiceId: number = 0
    invoiceItemId: number = 0
    invoiceItemLabel: string = ''
    invoiceItemPriceHt: number = 0
    providerAccountId: number | null = null
    refDate: string = ''
    customerName: string = ''
    customerPriceHt: number = 0
    customerFees: number | null = null
    feesRule: string = ''
}

/**
 * @param {number} customerId
 * @param {string} customerName
 * @param {string} customerAccount
 * @param {Date} customerCreatedAt
 * @param {Date | null } customerLastUpdate
 * @param {null | number} customerBudgetMin
 * @param {null | number} customerBudgetMax
 * @param {State} customerState
 * @param {boolean} activeCustomer
 * @param {boolean} activeFee
 * @param {number} nbInvoices
 * */
export class StandaloneCustomerFee {
    customerId: number = 0
    customerName: string = ''
    customerAccount: string = ''
    customerCreatedAt: Date = new Date()
    customerLastUpdate: Date | null = null
    customerBudgetMin: null | number = null
    customerBudgetMax: null | number = null
    customerState: State = (State.ACTIVE || State.PAUSED)
    activeCustomer: boolean = false
    activeFee: boolean = false
    nbInvoices: number = 0
}

export class StandaloneStatCustomer extends StatCustomer {
    providerAccount: ProviderAccount = new ProviderAccount()
}

export class StandaloneProviderAccountInvoice extends ProviderAccountInvoice {
    providerAccount: ProviderAccount = new ProviderAccount()
}