import {BudgetAccountGroup, CampaignGroup, ProviderAccount} from "../../../../models";
import {v4} from "uuid";
import {StandaloneProviderAccount} from "../../../../models/standalone";


export enum Flag {
    NO_CHANGES = 'NC',
    UPDATED = 'UPDATED',
    CREATED = 'CREATED',
}

export class FlaggedCampaignGroup {
    public id = v4()
    public flag: Flag = Flag.NO_CHANGES
    public campaignGroup: CampaignGroup = new CampaignGroup()
}

export class FlaggedBudgetAccountGroup {
    public id = v4()
    public flag: Flag = Flag.NO_CHANGES
    public budgetAccountGroup: BudgetAccountGroup = new BudgetAccountGroup()
}

export class FlaggedProviderAccount {
    public id = v4()
    public flag: Flag = Flag.NO_CHANGES
    public providerAccount: ProviderAccount = new ProviderAccount()
    public existingProviderAccounts: StandaloneProviderAccount[] = []
    public error: boolean = false
    public errorMessage: string = ''
}