import React from "react";
import {Fee, FeeBracket} from "../../../../models";
import {useEffect, useState} from "react";
import {getTranslation} from "../../../../intl";
import {BlueButton, SecureHTML} from "@boomrank/react-components";
import {FeeBracketForm} from "./bracket";
import {SelectFeeTemplate} from "../../../dummies/select/feeTemplate";

interface Props {
    fee: Fee,
    onSubmit: (fee: Fee) => void,
    onError?: () => void,
    disabled?: boolean
}

interface CustomFeeBracket {
    feeBracket: FeeBracket
    isValid: boolean
}

export function FeeForm(props: Props) {
    let [fee, setFee] = useState<Fee>(props.fee)
    let [customFeeBrackets, setCustomFeeBrackets] = useState<CustomFeeBracket[]>([])
    let [error, setError] = useState('')

    let onSubmit = () => {
        let isValid = true
        setError('')

        if (fee.limitMin !== null && fee.limitMin.toString().length === 0) {
            fee.limitMin = null
        }
        if (fee.limitMax !== null && fee.limitMax.toString().length === 0) {
            fee.limitMax = null
        }

        let validBrackets = customFeeBrackets.filter(cfb => cfb.isValid)
        if (
            customFeeBrackets.length !== validBrackets.length
        ) {
            setError(
                getTranslation('MONITORING.FORMS.FEE.INVALIDATE_BRACKETS')
            )
            isValid = false
        }

        if (
            fee.limitMin === null &&
            fee.limitMax === null &&
            customFeeBrackets.length === 0
        ) {
            setError(
                getTranslation('MONITORING.FORMS.FEE.INVALID_FEE')
            )
            isValid = false
        }

        if (
            fee.limitMin !== null &&
            fee.limitMax !== null &&
            parseFloat(fee.limitMin.toString()) >= parseFloat(fee.limitMax.toString())
        ) {
            setError(
                getTranslation('MONITORING.FORMS.FEE.INVALID_LIMIT')
            )
            isValid = false
        }

        if (isValid) {
            let feeBrackets = validBrackets.map(cfb => cfb.feeBracket)
            props.onSubmit({
                ...fee,
                feeBrackets: feeBrackets
            })
        } else {
            if (props.onError) {
                props.onError()
            }
        }
    }

    let onSubmitFeeBracket = (feeBracket: FeeBracket) => {
        let index = customFeeBrackets.findIndex(f => f.feeBracket.id === feeBracket.id)
        if (index !== -1) {
            let customBrackets: CustomFeeBracket[] = [...customFeeBrackets]
            customBrackets[index].isValid = true
            setCustomFeeBrackets(customBrackets)
            setCustomFeeBrackets(customBrackets)
        }
    }

    let onChangeFeeBracket = (feeBracket: FeeBracket) => {
        let index = customFeeBrackets.findIndex(f => f.feeBracket.id === feeBracket.id)
        if (index !== -1) {
            let customBrackets: CustomFeeBracket[] = [...customFeeBrackets]
            customBrackets[index].isValid = false
            customBrackets[index].feeBracket = feeBracket
            setCustomFeeBrackets(customBrackets)
        }
    }

    let onDeleteFeeBracket = (feeBracket: FeeBracket) => {
        let index = customFeeBrackets.findIndex(f => f.feeBracket.id === feeBracket.id)
        if (index !== -1) {
            let customBrackets: CustomFeeBracket[] = [...customFeeBrackets]
            customBrackets.splice(index, 1)
            setCustomFeeBrackets(customBrackets)
        }
    }

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: string | null = event.target.value.trim()
        let f = {
            ...fee,
            [event.target.name]: value
        }
        setFee(f)
    }

    let onClickNewBracket = () => {
        let feeBracket = new FeeBracket()
        feeBracket.id = (new Date()).getTime()

        setCustomFeeBrackets([
            ...customFeeBrackets,
            {
                feeBracket: feeBracket,
                isValid: false
            }
        ])
    }

    let onSelectTemplate = (fee: Fee) => {
        fee.id = (new Date()).getTime()
        fee.customerId = props.fee.customerId
        fee.enabledAt = new Date()
        setFee(fee)

        let customFeeBrackets = fee.feeBrackets.map(feeBracket => {
            feeBracket.id = (new Date()).getTime()
            return {
                feeBracket: feeBracket,
                isValid: true
            }
        })
        setCustomFeeBrackets(customFeeBrackets)
    }

    useEffect(() => {
        setFee(props.fee)
    }, [props.fee])


    return (
        <div className={'w-full mt-10'}>
            {
                error &&
                <div role={'alert'} className={'p-6 text-br-red border border-br-red bg-red-50 my-3'}>
                    {error}
                </div>
            }

            <div className={'flex flex-row-reverse'}>
                <BlueButton
                    disabled={props.disabled}
                    onClick={onSubmit}
                    text={
                        getTranslation('MONITORING.FORMS.FEE.BUTTON_SAVE')
                    }
                />
            </div>

            <div className={'w-full my-6 md:flex justify-between'}>
                <h4 className={'w-full md:w-2/4'}>
                    {getTranslation('MONITORING.FORMS.FEE.TEMPLATE')}
                </h4>
                <SelectFeeTemplate
                    onSelect={onSelectTemplate}
                    disabled={props.disabled || false}
                />
            </div>

            <SecureHTML className={'border border-1 border-br-green rounded p-6 mb-6 text-br-green'}>
                {getTranslation('MONITORING.FORMS.FEE.INFO_FEE')}
            </SecureHTML>

            <div className={'grid grid-cols-2 gap-6'}>
                <div>
                    <div className={'pb-1 font-bold'}>
                        {getTranslation('MONITORING.FORMS.FEE.LABEL_LIMIT_MIN')}
                    </div>
                    <input
                        className={'p-3 border rounded w-full'}
                        placeholder={fee.limitMin ? fee.limitMin.toString() : getTranslation('MONITORING.FORMS.FEE.PLACEHOLDER_LIMIT_MIN')}
                        onChange={onChange}
                        type={'text'}
                        name={'limitMin'}
                        value={fee.limitMin || ''}
                        disabled={props.disabled || false}
                    />
                </div>

                <div>
                    <div className={'pb-1 font-bold'}>
                        {getTranslation('MONITORING.FORMS.FEE.LABEL_LIMIT_MAX')}
                    </div>
                    <input
                        className={'p-3 border rounded w-full'}
                        placeholder={fee.limitMax ? fee.limitMax.toString() : getTranslation('MONITORING.FORMS.FEE.PLACEHOLDER_LIMIT_MAX')}
                        onChange={onChange}
                        type={'text'}
                        name={'limitMax'}
                        value={fee.limitMax || ''}
                        disabled={props.disabled || false}
                    />
                </div>
            </div>

            <div>
                {
                    customFeeBrackets.length > 0 &&
                    <>
                        <SecureHTML className={'border border-1 border-br-green rounded p-6 my-6 text-br-green'}>
                            {getTranslation('MONITORING.FORMS.FEE.INFO_FEE_BRACKET')}
                        </SecureHTML>
                    </>
                }
                {
                    customFeeBrackets.length === 0 &&
                    <div className={'flex flex-row-reverse my-6'}>
                        <BlueButton
                            disabled={props.disabled}
                            onClick={onClickNewBracket}
                            text={
                                getTranslation('MONITORING.FORMS.FEE.ADD_NEW_BRACKET')
                            }
                        />
                    </div>
                }
                {
                    customFeeBrackets.length > 0 &&
                    <>
                        <div className={'grid grid-cols-5 gap-6 pb-1'}>

                            <>
                                <div className={'font-bold'}>
                                    {getTranslation('MONITORING.FORMS.FEE.BRACKET.LABEL_BRACKET_MIN')}
                                </div>
                                <div className={'font-bold'}>
                                    {getTranslation('MONITORING.FORMS.FEE.BRACKET.LABEL_BRACKET_MAX')}
                                </div>
                                <div className={'font-bold'}>
                                    {getTranslation('MONITORING.FORMS.FEE.BRACKET.LABEL_RATE')}
                                </div>
                                <div className={'col-span-2 font-bold'}>
                                    {getTranslation('MONITORING.FORMS.FEE.BRACKET.LABEL_PRICE_HT')}
                                </div>
                            </>
                            {
                                customFeeBrackets.map((cfb, idx: number) => {
                                    return (
                                        <React.Fragment key={cfb.feeBracket.id}>
                                            <FeeBracketForm
                                                feeBracket={cfb.feeBracket}
                                                onSubmit={onSubmitFeeBracket}
                                                onChange={onChangeFeeBracket}
                                                onDelete={onDeleteFeeBracket}
                                                isValid={cfb.isValid}
                                            />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </>
                }
                {
                    customFeeBrackets.length > 0 &&
                    <div className={'flex flex-row-reverse my-6'}>
                        <BlueButton
                            disabled={props.disabled}
                            onClick={onClickNewBracket}
                            text={
                                getTranslation('MONITORING.FORMS.FEE.ADD_NEW_BRACKET')
                            }
                        />
                    </div>
                }
            </div>
        </div>
    )
}