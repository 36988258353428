import React from "react";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";

import {Account, Customer, Invoice} from "../../../../models";

import {Breadcrumb, Cache, JwtToken, PageNotFoundView} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";
import {InvoiceListView} from "../../views/invoice/list";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheInvoices: Cache<Invoice>
    cacheAccounts: Cache<Account>
}

export function InvoiceListRoute(props: Props) {
    let location = useLocation()
    let params = useParams()

    let customer = props.cacheCustomers.values()
        .find(c => c.id === parseInt(params.customerId!))

    if (!customer) {
        return <PageNotFoundView />
    }

    document.title = getTranslation('MONITORING.ROUTES.INVOICE.LIST_TITLE.CUSTOMER').format(customer.name)


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {to: `/dashboard/customer/${customer.id}/detail`, content: <>{customer.name}</>},
                    {
                        to: `/dashboard/customer/${customer.id}/invoices`,
                        content: getTranslation('MONITORING.ROUTES.INVOICE.BREADCRUMB.DEFAULT'),
                        disabled: true
                    },
                ]}
            />

            <InvoiceListView
                token={props.token}
                me={props.me}
                customer={customer}
                cacheInvoices={props.cacheInvoices}
                cacheAccounts={props.cacheAccounts}
            />
        </>
    )
}

