import {AccountFragment} from "./fragments";
import {Account} from "../../models";


export class AccountFactory {
    static fromFragment = (fragment: AccountFragment): Account =>  {
        let account = new Account()
        account.id = fragment.id
        account.createdAt = new Date(fragment.created_at)
        account.user.id = fragment.user.id
        account.user.email = fragment.user.email
        account.user.username = fragment.user.username
        account.user.firstname = fragment.user.firstname
        account.user.lastname = fragment.user.lastname
        account.user.isAdmin = fragment.user.is_admin
        account.user.isActive = fragment.user.is_active
        return account
    }
}