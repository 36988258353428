import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";

import {Customer, Invoice, Identifier, ProviderAccount, Account, ProviderAccountInvoice} from "../../../../models";

import {Breadcrumb, Cache, JwtToken, PageNotFoundView} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";
import {InvoiceEditView} from "../../views/invoice/edit";
import {StatCustomer} from "../../../../models/stats";
import {CustomerStatCustomerApi} from "../../../../services/customer/stats/customer/api";
import {StatCustomerListResponse} from "../../../../services/customer/stats/customer/responses";
import {StatCustomerFactory} from "../../../../services/customer/stats/customer/factory";
import {ProviderAccountInvoiceApi} from "../../../../services/provider-account-invoice/api";
import {ProviderAccountInvoiceListResponse} from "../../../../services/provider-account-invoice/responses";
import {ProviderAccountInvoiceFactory} from "../../../../services/provider-account-invoice/factory";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheInvoices: Cache<Invoice>
    cacheProviderAccounts: Cache<ProviderAccount>
    cacheUpdater: (obj: Identifier) => void
}

export function InvoiceEditRoute(props: Props) {
    let location = useLocation()
    let params = useParams()

    let customer = props.cacheCustomers.values()
        .find(c => c.id === parseInt(params.customerId!))

    let invoice = props.cacheInvoices.values()
        .find(i => i.id === parseInt(params.invoiceId!))

    let providerAccounts = props.cacheProviderAccounts.values()
        .filter(pa => pa.customerId === parseInt(params.customerId!))

    let [statsCustomer, setStatsCustomer] = useState<StatCustomer[]>([])
    let [providerAccountInvoices, setProviderAccountInvoices] = useState<ProviderAccountInvoice[]>([])


    let getLastStatsCustomer = () => {
        let thisMonth = new Date()
        let lastMonth = new Date(thisMonth.getFullYear(), thisMonth.getMonth()-1, 1)

        CustomerStatCustomerApi.list(
            props.token,
            parseInt(params.customerId!),
            lastMonth,
            lastMonth,
            '1M'
        ).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as StatCustomerListResponse
                let statsCustomer = response.data.map((fragment) => {
                    return StatCustomerFactory.fromFragment(fragment);
                })
                setStatsCustomer(statsCustomer)
            }
        })
    }

    let getProviderAccountInvoices = (providerAccount: ProviderAccount) => {
        ProviderAccountInvoiceApi.list(
            props.token,
            providerAccount
        ).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as ProviderAccountInvoiceListResponse
                let providerAccountInvoices = response.data.results.map((fragment) => {
                    let providerAccountInvoice = ProviderAccountInvoiceFactory.fromFragment(fragment);
                    providerAccountInvoice.providerAccountId = providerAccount.id
                    return providerAccountInvoice
                })
                setProviderAccountInvoices(pai => [...pai, ...providerAccountInvoices])
            }
        })
    }

    useEffect(() => {
        getLastStatsCustomer()
        providerAccounts.forEach((pa) => {
            getProviderAccountInvoices(pa)
        })
    }, [])

    if (!customer || !invoice) {
        return <PageNotFoundView />
    }

    document.title = getTranslation('MONITORING.ROUTES.INVOICE.EDIT_TITLE').format(customer.name)

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {to: `/dashboard/customer/${customer.id}/detail`, content: <>{customer.name}</>},
                    {
                        to: `/dashboard/customer/${customer.id}/invoices`,
                        content: getTranslation('MONITORING.ROUTES.INVOICE.BREADCRUMB.DEFAULT'),
                    },
                    {
                        to: `/dashboard/customer/${customer.id}/invoices/${invoice.id}/edit`,
                        content: getTranslation('MONITORING.ROUTES.INVOICE.BREADCRUMB.EDIT'),
                        disabled: true
                    },
                ]}
            />

            <InvoiceEditView
                token={props.token}
                me={props.me}
                customer={customer}
                invoice={invoice}
                providerAccounts={providerAccounts}
                cacheUpdater={props.cacheUpdater}
                statsCustomer={statsCustomer}
                providerAccountInvoices={providerAccountInvoices}
            />
        </>
    )
}

